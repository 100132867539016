import EndPointConfig from "src/models/api/endPointConfig";

const controller = 'AgentsManagement';

/// Define all EndPoints for Client Data.
export const AgentsManagementEP : EndPointConfig[] = [
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('getAvailableMoney')
        .setActionName("availableMoney"),      
];

