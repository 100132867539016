import * as React from 'react';
import { useContext, useState, useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import Home from '../../components/home/home'
import { Contact } from '../../components/contact/contact';
import { PrivacyPolicies } from '../../components/legalTexts/privacyPolicies/privacyPolicies'
import { CookiesPolicy } from '../../components/legalTexts/cookiesPolicy/cookiesPolicy';
import { GeneralConditions } from '../../components/legalTexts/generalConditions/generalConditions';
import { ParticularConditions } from '../../components/legalTexts/generalConditions/particularConditions';
import RecoverPassW from '../../components/modalsLogin/recoverPassW/recoverPassW';
import Register from '../../components/register/register';
import DeleteAccount from '../../components/deleteAccount/deleteAccount';
import ValidateAddNewAgency from '../../components/register/validateAddNewAgency/validateAddNewAgency';
import { AppRoute } from '../appLanguages';
import LocalizedSwitch from '../localizedSwitch';
import { LangContext } from '../../hooks/context/lang';
import { useTranslation } from 'react-i18next';
import { LANGS } from '../../constants/constants'
import { LegalAdviceNewAgency } from 'src/components/legalTexts/legalAdviceNewAgency/legalAdviceNewAgency';
import { TermsConditionsCashback } from 'src/components/legalTexts/termsConditionsCashback/termsConditionsCashback';


const RoutesContent = ({history}) => {

    const language = useContext(LangContext);
    const { i18n } = useTranslation();

    useEffect( () => {
        history.listen(() => {
            // Recargamos la pagina en caso de navegacion hacia delante o hacia atras
            if (history.action === "POP") {
                const languageP = LANGS.find((e) => { return e.url === history.location.pathname.split('/')[1] });
                const langP = languageP.lang;
                language.UpdateLangContext(langP);
                i18n.changeLanguage(langP);
            }
        });
    },[])

    return (
        <LocalizedSwitch>
            <Route
                exact
                path={ AppRoute.Home }
                component = { Home }
            />
            <Route
                exact
                path={ AppRoute.Advantages }
                component={ Home }
            />
            <Route
                exact
                path={ AppRoute.Faqs }
                component={ Home }
            />
            <Route
                exact
                path={ AppRoute.Contact }
                component={ Contact}
            />
            <Route
                exact
                path={ AppRoute.PrivacyPolicies }
                component={ PrivacyPolicies }
            />
            <Route
                exact
                path={ AppRoute.CookiesPoliciesR }
                component={ CookiesPolicy }
            />
            <Route
                exact
                path={ AppRoute.GeneralConditionsR }
                component={ GeneralConditions }
            />
            <Route
                exact
                path={ AppRoute.ParticularConditionsR }
                component={ ParticularConditions }
            />
            <Route
                exact
                path={ AppRoute.Register }
                component={ Register }
            />
            <Route
                exact
                path={ AppRoute.RecoverPassword }
                component={ RecoverPassW }
            />
            <Route
                exact
                path={ AppRoute.DeleteAccount }
                component={ DeleteAccount }
            />
            <Route
                exact
                path={ AppRoute.ValidateAddNewAgency }
                component={ ValidateAddNewAgency }
            />
            <Route
                exact
                path={ AppRoute.LegalAdvice }
                component={LegalAdviceNewAgency  }
            />

            <Route component={ Home } />
        </LocalizedSwitch>
    );
}

export default withRouter(RoutesContent);
