import * as React from "react";
import AlternateComponent from "../../utils/alternateComponent/alternateComponent";
import RewardList from "../rewardResultList/rewardResultList";
import RewardTable from "../rewardTableResult/rewardTableResult";
import { Typography } from "antd";

// Componente con gestion de visual de los resultados obtenidos
const RewardResult = (param) => {
  // Modulamos la info para la versión de escritorio
  const desktopVersion = (param) => {
    return (
      <RewardTable
        result={param.result}
        setPageResults={param.setPageResults}
        pageResults={param.pageResults}
        orderResults={param.orderResults}
        setOrderResults={param.setOrderResults}
        searchHeaderResults={param.searchHeaderResults}
        setSearchHeaderResults={param.setSearchHeaderResults}
      />
    );
  };

  // Modulamos la info para la versión mediana (tablet)
  const tabletVersion = (param) => {
    return desktopVersion(param);
  };

  // Modulamos la info para la versión móvil
  const mobileVersion = (param) => {
    return (
      <RewardList
        result={param.result}
        setPageResults={param.setPageResults}
        pageResults={param.pageResults}
        orderResults={param.orderResults}
        setOrderResults={param.setOrderResults}
        searchHeaderResults={param.searchHeaderResults}
        setSearchHeaderResults={param.setSearchHeaderResults}
      />
    );
  };
  return (
    <AlternateComponent
      desktopVersionLarge={desktopVersion(param)}
      tabletVersion={tabletVersion(param)}
      mobileVersionLarge={mobileVersion(param)}
    />
  );
};
export default RewardResult;
