import React, { useState, useEffect, useContext, Suspense } from "react";
import "../profile.sass";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { Form, Button, Select, Typography } from "antd";
import { Skeleton } from "antd";
import { showNotification } from "../../../utils/notificationsGeneric";
import { UserContext } from "../../../hooks/context/userInfo";
import { Link } from "react-router-dom";
import { linkActiveContext } from "../../../hooks/context/linkActive";
import ModalPassword from "../modalPassword/modalPassword";
import {
  useCurrentUserLanguage,
  useLanguages,
  useUpdateLanguage,
} from "src/api/api";

const { Title, Paragraph } = Typography;

// Componente de la sección de Cambiar contraseña
const SettingsAccount = (param) => {
  const { t } = useTranslation(["profile", "urls", "linksNames"]);

  const linkActive = useContext(linkActiveContext);
  const userContext = useContext(UserContext);
  let userToken = userContext.GetUserToken();

  const [modalPassword, setModalPassword] = useState(false);

  const [form] = Form.useForm();

  const { Option } = Select;

  const languages = useLanguages();
  const userLanguage = useCurrentUserLanguage();
  const updateLanguage = useUpdateLanguage();

  const onFinish = async (values) => {
    const res = await updateLanguage(values.language);
    if (res.data) {
      showNotification("success", res.title, res.message, "topRight");
    } else {
      showNotification("error", res.title, res.message, "topRight");
    }
    param.setChangeData(false)
  };

  const onChangeData = () => {
    param.setDisabledBtn(false);
    param.setChangeData(true);
  };

  // Initial methods
  useEffect(() => {
    if (!param.changeData) {
      form.resetFields();
    }
  }, [param.changeData]);

  return (
    <>
      <div className="row">
        <div className="col-lg-3 p-0"></div>
        <div className="col-lg-6 col-12 p-0">
          <Form
            name="form-change-pass"
            className="row form-change-pass"
            onFinish={onFinish}
            onChange={onChangeData}
            form={form}
            layout="vertical"
            id="form-settings-account"
            initialValues={{
             language: userLanguage.id
            }}
          >
            <Form.Item
              className="col-12 form-item-profile"
              label={t("sections.settings-account.language")}
              name="language"
              rules={[
                {
                  required: true,
                  message: t("validations.language"),
                },
              ]}
            >
              <Select
                className="main-select"
                placeholder={t("sections.settings-account.select-option")}
                onChange={onChangeData}
              >
                {languages.map((s, i) => {
                  return (
                    <Option key={i} id={`l-${i}`} value={s.id}>
                      {s.id}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item className="col-12 form-item">
              <div className="row f-right">
                <Button
                  id="btn-send-new-agency"
                  className="primary-button"
                  type="primary"
                  htmlType="submit"
                  disabled={param.disabledBtn}
                >
                  {t("btn.save-changes")}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
        <div className="col-lg-3 p-0"></div>
        <div className="col-lg-3 p-0"></div>
        <div className="col-lg-6 col-12 p-0 mt-5">
          <Title level={4}>
            {" "}
            {t("sections.settings-account.removed-account-title")}
          </Title>
          <Paragraph>{t("sections.settings-account.message-info-1")}</Paragraph>
          <strong>
            {t("sections.settings-account.message-info-2")}

            <Link
              id="link-home"
              className="primary-links"
              target="_blank"
              to={t("urls:private.privacy-policies")}
              onClick={() =>
                linkActive.updateValueUrl(t("urls:private.privacy-policies"))
              }
            >
              {t("sections.settings-account.privacy-policy")}.
            </Link>
          </strong>
          <div className="col-12 p-0 mt-4">
            <div className="row f-right">
              <Button
                id="btn-account"
                danger
                className="col-12"
                onClick={() => setModalPassword(true)}
              >
                {t("sections.settings-account.button")}
              </Button>
            </div>
          </div>
        </div>
        <div className="col-lg-3 p-0"></div>
      </div>
      {modalPassword && <ModalPassword setModalPassword={setModalPassword} />}
    </>
  );
};

const SettingsAccountWrapper = (param) => (
    <Suspense fallback={<Skeleton />}>
      <SettingsAccount {...param}/>
    </Suspense>
  );
  
export default SettingsAccountWrapper;
