import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import './general-conditions.sass';
import { Helmet } from 'react-helmet-async'
import '../../../i18n';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../../hooks/context/config';
import { Skeleton, Typography } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { RestApiClientContext } from '../../../hooks/context/pageContext';
import { ClientDataModel } from '../../../models/config/clientData';
import { URL_BASE } from '../../../constants/constants';
import { LangContext } from 'src/hooks/context/lang';

const { Title } = Typography;

// tslint:disable-next-line:no-var-requires
const scrollLib = require('react-scroll');
const scroll = scrollLib.animateScroll;

// Componente de información de Condiciones Generales de la app
export const GeneralConditions = () => {
  const language = useContext(LangContext);

  const infoClient = useContext(ConfigContext); // TODO: Actualizar cuando obtengamos los datos de infoClient confirmados
  const restApiClient = useContext(RestApiClientContext);
  const { t } = useTranslation(['generalConditions', 'seo', 'urls']);

  const [clientData, setClientData] = useState<ClientDataModel>(
    new ClientDataModel()
  );

  useEffect(() => {
    scroll.scrollToTop();
  });

  useEffect(()=>{
    getGeneralConditions();
  },[language.lang])

  useEffect(() => {
    getLegalTextApi();
  }, []);

  const getLegalTextApi = async () => {
    restApiClient.getCustomizations().then((data) => {
      if (data) {
        setClientData(data);
      }
    });
  };

  const [loadingGeneralTexts, setLoadingGeneralTexts] = useState<boolean>(false);
  const [generalConditions, setGeneralConditions] = useState<string>();
  const getGeneralConditions = async () => {
    setLoadingGeneralTexts(true);
    restApiClient.getGeneralConditions(language.lang).then((data) => {
      if (data) {
        setGeneralConditions(data);
      }
    }).finally(()=>{
      setLoadingGeneralTexts(false);
    }); 
  }; 

  const defaultGeneralConditions : JSX.Element = <div className="container mt-3 special-mb">
                                                    <Title level={ 4 }>{ t("generalConditions:main-title") }</Title>

                                                    { ReactHtmlParser(t("generalConditions:section.title.1")) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.1", { 0: clientData.incentiveSiteName, 1: clientData.socialName, 2: clientData.socialAddress, 3: clientData.identityCode })) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.2")) }

                                                    { ReactHtmlParser(t("generalConditions:section.title.2")) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.3", { 0: clientData.incentiveSiteName })) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.4", { 0: clientData.emailContact })) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.5", { 0: clientData.socialName })) }

                                                    { ReactHtmlParser(t("generalConditions:section.title.3")) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.6", { 0: clientData.socialName })) }

                                                    { ReactHtmlParser(t("generalConditions:section.title.4")) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.7", { 0: clientData.socialName })) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.8")) }

                                                    { ReactHtmlParser(t("generalConditions:section.title.5")) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.9")) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.10")) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.11", { 0: clientData.incentiveSiteName })) }

                                                    { ReactHtmlParser(t("generalConditions:section.title.6")) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.12", { 0: clientData.incentiveSiteName, 1: clientData.socialName })) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.13", { 0: clientData.socialName })) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.14", { 0: clientData.socialName })) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.15", { 0: clientData.socialName })) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.16")) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.17", { 0: clientData.socialName, 1: clientData.emailContact, 2: clientData.incentiveSiteName, 3: clientData.socialAddress })) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.18", { 0: clientData.incentiveSiteName, 1: clientData.emailContact })) }
                                                    { ReactHtmlParser(t("generalConditions:section.paragraph.19", { 0: clientData.legislationType, 1: clientData.provincialJurisdiction })) }
                                                  </div>

  return (
    <>
      <Helmet>
        <title>{ clientData.incentiveSiteName } | { t('seo:title.general-conditions') }</title>
        <meta name="description" content={ t('seo:description.general-conditions', { 0: clientData.incentiveSiteName }) } />
        <meta name="robots" content="noindex,nofollow" />
        <link rel="canonical" href={URL_BASE + t('urls:public.general-conditions') } />
        <link rel="icon" type="image/ico" href={ infoClient.favicon } sizes="32x32" />
        <link href={ URL_BASE + t('urls:public.general-conditions') } hrefLang="es-ES" rel="alternate" />
      </Helmet>

      <Skeleton loading={loadingGeneralTexts} active>
        {generalConditions && generalConditions.length > 0 ? ReactHtmlParser(generalConditions) : defaultGeneralConditions}
      </Skeleton>

      
    </>
  );
}

