import Hotels from "./hotels";
import Client from "./clients";

// Modelo de los datos de una reserva
export default class Reservations {
    constructor(
        public key: string = '',
        public bookingReference: string = '',
        public numRooms: number = null,
        public checkInDate: string = '',
        public checkOutDate: string = '',
        public client: Client = new Client(),
        public hotel: Hotels = new Hotels()
    ) {

    }
}
