// Modelo para manejar el contexto del popup
export class PopupManagement {
    public visible: boolean;

    constructor() {
        this.visible = true;
    }

    UpdateVisible = (newValue: boolean) => {
        this.visible = newValue;
    }

    GetVisible = (): boolean => {
        return this.visible;
    }
}
