import EndPointConfig from '../../models/api/endPointConfig';

const controller = 'Contacts';

/// Define all EndPoints for contact section.
export const ContactEP : EndPointConfig[] = [

    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetListNumberContact'),

    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setActionName('webagent/form')
        .setAlias('SendFormContact'),

];