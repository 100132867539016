import React from "react";
import "../../i18n";
import "./rewardGenericBalance.sass";
import { IntlProvider, FormattedNumber } from "react-intl";
import { useAvailableMoney } from "src/api/api";

const AvailableMoney = () => {
  const availableMoney = useAvailableMoney();
  return (
    <>
      <FormattedNumber
        useGrouping={true}
        value={availableMoney.money}
        style="currency"
        currency={availableMoney.currencies.id}
      />
    </>
  );
};

export default AvailableMoney;
