import React, { useEffect } from "react";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { Form, TreeSelect } from "antd";
import { TreeNode } from "antd/lib/tree-select";
import "./remunerationForm.sass";
import { useConfigsList } from "src/api/api";

// Componente formulario de la remuneracion para el gráfico
const RemunerationForm = (param) => {
  const { t } = useTranslation(["remuneration", "regularizations"]);
  const [form] = Form.useForm();

  // Get elements options
  const listElement = useConfigsList();

  const onChange = (value: any) => {
    let data = value && JSON.parse(value);
    param.setSelectForm(data);
  };

  useEffect(() => {
    param.setSelectForm(listElement[0]);
  }, []);

  return (
    <>
      {listElement.length > 0 && (
        <Form
          form={form}
          name="addReservation"
          initialValues={{ remember: true }}
          className="row form-remuneration"
          layout="vertical"
          id="remuneration"
        >
          <Form.Item className="col-12 form-item" label={t("remuneration:form.label")}>
            <TreeSelect
              onChange={onChange}
              showSearch
              style={{ width: "100%" }}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              defaultValue={listElement[0].name}
              treeDefaultExpandAll={false}
              treeDefaultExpandedKeys={[1]}
              className="main-select"
            >
              {listElement.map((s: any, i) => {
                return (
                  <TreeNode key={JSON.stringify(s)} value={JSON.stringify(s)} title={s.name}>
                    {s.establishmentsList?.map((y: any, index) => {
                      return (
                        <TreeNode
                          disabled={true}
                          key={"h-" + JSON.stringify(y)}
                          value={"h-" + JSON.stringify(y)}
                          title={y.name}
                        />
                      );
                    })}
                  </TreeNode>
                );
              })}
            </TreeSelect>
          </Form.Item>
        </Form>
      )}
    </>
  );
};
export default RemunerationForm;
