import Reservations from "./reservations";
import Channel from "./channels";

// Modelo de los datos de una regularización
export default class Regularizations {
    constructor(
        public regularizedDate: string = '',
        public state: string = '',
        public amount: number = null,
        public currency: string = '',
        public reservations: Reservations = new Reservations(),
        public channel: Channel = new Channel()
    ) {

    }
}
