import React, { Suspense } from "react";
import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Typography, Skeleton } from "antd";
import { PickerLocale } from "antd/lib/date-picker/generatePicker";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "../../../i18n";
import "./detailNews.sass";
import { linkActiveContext } from "../../../hooks/context/linkActive";
import ReactHtmlParser from "react-html-parser";
import NotResults from "../../noResultGeneric/notResults";
import { getLocaleDatePicker } from "../../../utils/datePicker";
import { useNewsDetail } from "src/api/api";
import i18n from "src/i18n";

const { Title, Paragraph } = Typography;

// Componente con el detalle de una noticia
const DetailNews = ({
  match: {
    params: { id },
  },
}) => {
  const { t } = useTranslation(["urls", "news", "linksNames"]);
  const linkActive = useContext(linkActiveContext);
  const local: PickerLocale = getLocaleDatePicker();

  const [messageApi, setMessageApi] = useState("");

  const newsDetail = useNewsDetail(id);
  const content = newsDetail.contents.filter((element) => (element.idLanguage = i18n.language))[0];
  return (
    <>
      {newsDetail.contents ? (
        <div className="mt-5 news-container">
          <div className="mt-3 row">
            <div className="col-12 box-news-details">
              <Title level={3}> {content.title} </Title>
            </div>
            {newsDetail.urlImage && (
              <div className="col-12 box-img-news-details box-news-details-img">
                <img title={"img-" + newsDetail.id} src={newsDetail.urlImage} />
              </div>
            )}
            <div className="col-12 box-news-details">
              <Paragraph className="date-news">{moment(newsDetail.creationDate).format(local.dateFormat)}</Paragraph>
              <Paragraph>{ReactHtmlParser(content.description)}</Paragraph>
            </div>
            <div className="mt-3 p-0 col-12 box-navegate">
              <div className="p-md-0 pl-3 pr-3 col-6 link-back">
                <Link
                  id={2}
                  to={t("urls:private.news")}
                  className="secondary-links"
                  onClick={() => linkActive.updateValueUrl(t("urls:private.news"))}
                >
                  {ReactHtmlParser(t("news:navegate.back"))}
                </Link>
              </div>
              {/* <div className="p-md-0 pl-3 pr-3 col-6 link-next">
                {news.nextNews !== 0 && (
                  <Link
                    id={3}
                    to={t("urls:private.details") + "/" + `${news.nextNews}`}
                    className="secondary-links-navegate-next"
                  >
                    {ReactHtmlParser(t("news:navegate.next"))}
                  </Link>
                )}
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        <NotResults section="news" messageApi={messageApi} />
      )}
    </>
  );
};

const DetailNewsWrapper = ({
  match: {
    params: { id },
  },
}) => (
  <Suspense fallback={<Skeleton />}>
    <DetailNews
      match={{
        params: { id },
      }}
    />
  </Suspense>
);

export default DetailNewsWrapper;
