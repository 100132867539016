import * as React from 'react';
import { useContext, useState } from 'react';
import './recoverPassW.sass';
import { Helmet } from 'react-helmet-async'
import '../../../i18n';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../../hooks/context/config';
import { Typography, Modal } from 'antd';
import FormResetPassword  from '../formResetPassword/formResetPassword';
import { URL_IMAGE } from '../../../constants/constants';
import { RestApiClientContext } from 'src/hooks/context/pageContext';

const { Title, Paragraph } = Typography;

// Componente de recuperar contraseña, con token caducado
const RecoverPassW = () => {

  const infoClient = useContext(ConfigContext);
  const { t } = useTranslation(['recoverPassword', 'resetPassword', 'seo', 'urls']);
  const [visibleFormPassword, setVisibleFormPassword] = useState(false);

  // Funcion Abrir modal contraseña
  const openModalPassword = () => {
    setVisibleFormPassword(true) 
  } 

  const handleCancel = () => {
    setVisibleFormPassword(false);
  };

  const restApiClient = useContext(RestApiClientContext);

  const [noIndexNoFollow, setNoIndexNoFollow] = React.useState(false);
  const getNoIndexNoFollow = () : void => {
    restApiClient.getNoIndexNoFollowConfig().then(v => {
      setNoIndexNoFollow(v);
    });
  }
  
  React.useEffect(() => {
    getNoIndexNoFollow();
  }, []);

  return (
    <>
      <Helmet>
        <title>{ infoClient.incentiveSiteName } { t('seo:title.register', { 0: infoClient.incentiveSiteName }) } | { t('seo:title.register') }</title>
        <meta name="description" content={ t('seo:description.register', { 0: infoClient.incentiveSiteName }) } />
        
        {
          noIndexNoFollow ? 
            <meta name="robots" content="noindex, nofollow" />
          : <meta name="robots" content="index,follow" />
        }

        <link rel="canonical" href={ infoClient.urlWA + t('urls:public.register') } />
        <link rel="icon" type="image/ico" href={ infoClient.favicon } sizes="32x32" />
        <link href={ infoClient.urlWA + t('urls:public.register') } hrefLang="es-ES" rel="alternate" />
      </Helmet>

      <div className="container">
        <div className="col-12">
          <div className="col-6 margin-box">
            <img className="img-recover" src={ URL_IMAGE + "recoverPass/ico-link-no-valido.svg" } />
            <Title> { t('recoverPassword:texts.title') } </Title>
            <Paragraph>
              { t('recoverPassword:texts.paragraph') } 
              <span className="link-open-modal" onClick={ openModalPassword }>{ t('recoverPassword:texts.link') }</span>
            </Paragraph>
          </div>           
        </div>
      </div>

      { visibleFormPassword && 
        <Modal
          title= { t("resetPassword:form.title") }
          visible={ visibleFormPassword }
          onCancel={ handleCancel }
          footer={ null } 
          key="1" 
        >  
          <FormResetPassword setVisibleFormPassword={ setVisibleFormPassword } />
        </Modal>
      }
    </>
  );
}

export default RecoverPassW;
