import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import './general-conditions.sass';
import { Helmet } from 'react-helmet-async'
import '../../../i18n';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../../hooks/context/config';
import { Typography } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { RestApiClientContext } from '../../../hooks/context/pageContext';
import { ClientDataModel } from '../../../models/config/clientData';
import { URL_BASE } from '../../../constants/constants';
import { useParticularConditions } from "src/api/api";

const { Title } = Typography;

// tslint:disable-next-line:no-var-requires
const scrollLib = require('react-scroll');
const scroll = scrollLib.animateScroll;

// Componente de información de Condiciones Generales de la app
export const ParticularConditions = () => {

  const infoClient = useContext(ConfigContext); // TODO: Actualizar cuando obtengamos los datos de infoClient confirmados
  const restApiClient = useContext(RestApiClientContext);
  const { t } = useTranslation(['generalConditions', 'seo', 'urls']);
  const conditions = useParticularConditions("2");

  const [clientData, setClientData] = useState<ClientDataModel>(
    new ClientDataModel()
  );

  useEffect(() => {
    scroll.scrollToTop();
});

  useEffect(() => {
    getLegalTextApi();
  }, []);

  const getLegalTextApi = async () => {
    restApiClient.getCustomizations().then((data) => {
      if (data) {
        setClientData(data);
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>{ clientData.incentiveSiteName } | { t('seo:title.general-conditions') }</title>
        <meta name="description" content={ t('seo:description.general-conditions', { 0: clientData.incentiveSiteName }) } />
        <meta name="robots" content="noindex,nofollow" />
        <link rel="canonical" href={URL_BASE + t('urls:public.general-conditions') } />
        <link rel="icon" type="image/ico" href={ infoClient.favicon } sizes="32x32" />
        <link href={ URL_BASE + t('urls:public.general-conditions') } hrefLang="es-ES" rel="alternate" />
      </Helmet>

      <div className="container mt-3 special-mb">
        { ReactHtmlParser(conditions) }
      </div>
    </>
  );
}

