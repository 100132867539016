import React, { Suspense } from "react";
import App from "../components/app/app";
import "../i18n";
import ErrorBoundary from "src/components/ErrorBoundary/ErrorBoundary";

const AppWrapper = () => {
  return (
    <Suspense fallback={null}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Suspense>
  );
};

export default AppWrapper;
