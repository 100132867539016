import React, { useEffect, useContext } from "react";
import "./formContact.sass";
import { Form, Input, Button, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { linkActiveContext } from "../../../hooks/context/linkActive";
import DataContact from "./dataFormContact";
import { useSendContactForm } from "src/api/api";
import { showNotification } from "src/utils/notificationsGeneric";

// Componente del formulario de contacto de la web
export const FormContact = () => {
  const sendContactForm = useSendContactForm();

  // Necesario para estructura del formulario
  const tailLayout = {
    wrapperCol: {
      span: 25,
    },
  };

  const linkActive = useContext(linkActiveContext);
  const zone = window.location.pathname.includes("app") ? "private" : "public";

  // Idioma para los textos
  const { t } = useTranslation(["contact", "urls"]);

  const [form] = Form.useForm();

  //#region Methods to get info
  const sendFormContact = async () => {
    const dataContact = {
      name: form.getFieldValue("name"),
      email: form.getFieldValue("mail"),
      subject: form.getFieldValue("subject"),
      message: form.getFieldValue("message"),
    } as DataContact;
    const res = await sendContactForm(dataContact);
    if (res.data) {
        showNotification('success', res.title, res.message, "topRight");
    } else {
        showNotification('error', res.title, res.message, "topRight");
    }
    form.resetFields();
  };

  const onFinish = async () => {
    sendFormContact();
  };

  useEffect(() => {
    form.resetFields();
  });

  return (
    <Form name="basic" onFinish={onFinish} form={form}>
      <div className="row">
        <div className="col-12 col-lg-6 contain-main-data">
          <Form.Item
            className="row-form-item"
            label={t("contact:form.name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("contact:form.errors.message"),
              },
              {
                whitespace: true,
                message: t("contact:form.errors.message"),
              },
            ]}
          >
            <Input className="main-input" id="name" />
          </Form.Item>
          <Form.Item
            className="row-form-item"
            label={t("contact:form.email")}
            name="mail"
            rules={[
              {
                required: true,
                message: t("contact:form.errors.message"),
              },
              {
                type: "email",
                message: t("contact:form.errors.invalid-mail"),
              },
            ]}
          >
            <Input className="main-input" id="email" />
          </Form.Item>
          <Form.Item
            className="row-form-item"
            label={t("contact:form.subject")}
            name="subject"
            rules={[
              {
                required: true,
                message: t("contact:form.errors.message"),
              },
              {
                whitespace: true,
                message: t("contact:form.errors.message"),
              },
            ]}
          >
            <Input className="main-input" id="subject" />
          </Form.Item>
        </div>
        <div className="col-12 col-lg-6 contain-text-area">
          <Form.Item
            className="row-form-item"
            label={t("contact:form.message")}
            name="message"
            rules={[
              {
                required: true,
                message: t("contact:form.errors.message"),
              },
              {
                whitespace: true,
                message: t("contact:form.errors.message"),
              },
            ]}
          >
            <Input.TextArea className="main-input" id="message" />
          </Form.Item>
        </div>
      </div>
      <div className="col-12 contain-button-link-form">
        <Form.Item
          className="margin-bottom-check"
          {...tailLayout}
          name="remember"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(t("contact:form.errors.message")),
            },
          ]}
        >
          <Checkbox className="main-input box-check">
            <div className="align-text-check">
              {t("form.check")}
              <Link
                id="link-privacy-policies"
                className="primary-links"
                to={t("urls:" + zone + ".privacy-policies")}
                onClick={() =>
                  linkActive.updateValueUrl(
                    t("urls:" + zone + ".privacy-policies")
                  )
                }
              >
                {t("contact:form.link")}
              </Link>
            </div>
          </Checkbox>
        </Form.Item>
        <Form.Item className="col-12 col-lg-4" {...tailLayout}>
          <Button
            id="btn-send-form-contact"
            className="col-12 primary-button"
            type="primary"
            size="large"
            htmlType="submit"
          >
            {t("contact:form.button")}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};
