import EndPointConfig from "src/models/api/endPointConfig";

const controller = 'Currencies';

/// Define all EndPoints for Client Data.
export const CurrenciesEP : EndPointConfig[] = [
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('getAllCurrencies')
        .setActionName("all")
];

