import * as React from "react";
import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Form, Input, Button, Select, Modal, Typography, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import "./newAgency.sass";
import { ONLY_NUMBERS } from "../../../constants/regex";
import { useAddNewAgencies, useCurrenciesAll } from "src/api/api";
import GoogleMapsAutoCompleteComponent from "src/components/google/geocode/GoogleMapsAutoCompleteComponent";
import { LoadingOutlined } from '@ant-design/icons'
const { Option } = Select;
const { Paragraph, Title } = Typography
import { linkActiveContext } from "../../../hooks/context/linkActive";

// Necesario para estructura del formulario
const tailLayout = {
  wrapperCol: {
    span: 25,
  },
};

interface NewAgencyProps{
  onAgencyCreated: (newAgencyId: string) => void,
  openNewAgency: any,
  setOpenNewAgency: any,
}

// Componente del formulario de alta de agencia
const FormNewAgency = (props: NewAgencyProps) => {
  // Idioma para los textos
  const { t } = useTranslation(["newAgency","urls"]);

  const [disabled, setDisabled] = useState(true);
  const currencies = useCurrenciesAll();
  const linkActive = useContext(linkActiveContext);

  // History para cuando cerremos sesion monte la url base de la home
  let history = useHistory();

  const [form] = Form.useForm();

  const addNewAgency = useAddNewAgencies();

  const [googleData, setGoogleData] = useState<any>();


  useEffect(()=>{
    if (googleData)
      checkData();
  },[googleData])

  const checkData = () : void => {

    form.validateFields().then(r => {
      //Todo el formulario está relleno ok
      setDisabled(!googleData || googleData.formatted_address?.length == 0)
    }).catch(e => {
      //Hay algún error en el formulario
      setDisabled(true);
    })

  }

  // Submit boton
  const onFinish = async (values) => {
    var addressData = {
      address_components: googleData.address_components,
      formatted_address: googleData.formatted_address,
      name: googleData.name,
      place_id: googleData.place_id,
      latitude: `${googleData.geometry.location.lat()}`,
      longitude: `${googleData.geometry.location.lng()}`
    };

    const body: any = {
      identityCode: values.taxIdentification,
      name: values.companyName,
      brandName: values.commercialName,
      idCurrency: values.idCurrency,
      email: values.email,
      telephone: values.phone,
      contactPerson: values.contactName,
      addressData: addressData
    };

    setSavingData(true);
    addNewAgency(body).then((res: any)=>{
      if (res.data && res.data.result) {
        /// Con esto conseguimos que se haga la auto búsqueda pero hay que conseguir limpiar los campos del formulario
        props.onAgencyCreated(values.taxIdentification);
        //Hacer que navegue al formulario de registro, paso 2
        //history.push(t("urls:public.new-agency") + "/success");
      } else {
        setExistsAgency(true);
        setMessageExists(res.message);
        //history.push(t("urls:public.new-agency") + "/error");
      }
    }).finally(()=>{
      setSavingData(false);
    })

    
    
  };

  const [existsAgency, setExistsAgency] = useState<boolean>(true);
  const [messageExists, setMessageExists] = useState<string>('');
  const [savingData, setSavingData] = useState<boolean>(false)
  const [checkPolicy, setCheckPolicy] = useState(false);
  
  // Boton cancelar
  const HandCancel = () => {
    props.setOpenNewAgency(false);
  };


  // Initial methods
  useEffect(() => {
  }, []);

  return (
    <Modal
      visible={props.openNewAgency}
      onCancel={HandCancel}
      footer={null}
      key="1"
      className="col-10 width-modal"
      bodyStyle={{ maxHeight: 780, overflow: "auto" }}
    >
      <div className="container form-new-agency">
        <Title level={4}>
          {t("title-main")}
        </Title>
        <Paragraph>{t("subtitle")}</Paragraph>
        <Form
          onChange={checkData}
          form={form}
          name="agency"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          className="row"
          layout="vertical"
          id="newAgency"
        >
          <div className="my-1 col-12">
            <Title className="title-main" level={5}>
              {t("title-agency")}
            </Title>
          </div>
          <Form.Item
            className="col-12 col-lg-6 form-item"
            label={t("tax-identification")}
            name="taxIdentification"
            help={<span className="error">{existsAgency ? messageExists : ''}</span>}
            rules={[
              {
                whitespace: true,
                required: true,
                message: t("validations.tax-identification"),
              },
            ]}
          >
            <Input className="main-input" id="taxIdentification" onChange={()=>{setMessageExists(""); setExistsAgency(false)}}/>
          </Form.Item>
          <Form.Item
            className="col-12 col-lg-6 form-item"
            label={t("company-name")}
            name="companyName"
            rules={[
              {
                whitespace: true,
                required: true,
                message: t("validations.company-name"),
              },
            ]}
          >
            <Input className="main-input" id="companyName" />
          </Form.Item>
          <Form.Item
            className="col-12 col-lg-6 form-item"
            label={t("commercial-name")}
            name="commercialName"
            rules={[
              {
                whitespace: true,
                required: true,
                message: t("validations.commercial-name"),
              },
            ]}
          >
            <Input className="main-input" id="commercialName" />
          </Form.Item>
          <Form.Item
            className="col-12 col-lg-6 form-item"
            label={t("currency")}
            name="idCurrency"
            rules={[
              {
                required: true,
                message: t("validations.position"),
              },
            ]}
          >
            <Select className="main-select" placeholder={t("place-holder.select-option")}>
              {currencies.map((c) => {
                return (
                  <Option key={c.id} id={`c-${c.id}`} value={c.id}>
                    {`${c.id} (${c.symbol})`}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          
          <div className="col-12 form-item">
            <GoogleMapsAutoCompleteComponent isRequiredField showSelectedInfo={false} onSetData={setGoogleData}/>
            <br />
          </div>
          
          <div className="my-1 col-12">
            <Title className="title-main" level={5}>
              {t("title-personal")}
            </Title>
          </div>
          <Form.Item
            className="col-12 col-lg-6 form-item"
            label={t("contact-name")}
            name="contactName"
            rules={[
              {
                whitespace: true,
                required: true,
                message: t("validations.contact-name"),
              },
            ]}
          >
            <Input className="main-input" id="contactName" />
          </Form.Item>
          <Form.Item
            className="col-12 col-lg-6 form-item"
            label={t("phone")}
            name="phone"
            rules={[
              {
                whitespace: true,
                required: true,
                message: t("validations.phone"),
              },
              {
                validator: (_, value) =>
                  value
                    ? ONLY_NUMBERS.test(value)
                      ? Promise.resolve()
                      : Promise.reject(t("validations.number"))
                    : Promise.resolve(),
              },
            ]}
          >
            <Input className="main-input" id="phone" />
          </Form.Item>
          <Form.Item
            className="col-12 col-lg-6 form-item"
            label={t("email")}
            name="email"
            rules={[
              {
                whitespace: true,
                required: true,
                message: t("validations.mail"),
              },
              {
                type: "email",
                message: t("validations.invalid-mail"),
              },
            ]}
          >
            <Input className="main-input" id="email" />
          </Form.Item>


          <Form.Item
          className="col-12 form-item check-policy"
          name="props.selectedData.checkPolicy"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => (value ? Promise.resolve() : Promise.reject(t("validations.checkbox"))),
            },
          ]}
        >
          <Checkbox className="main-input box-check" id="check-policy" checked={checkPolicy}>
            {t("newAgency:check-terms.text-1")}

              
            <Link
              id="link-privacy-policies"
              className="primary-links"
              to={t("urls:public.legal-advice")}
              target="_blank"
              onClick={() => setDisabled(true)}
            >
            {t("newAgency:check-terms.link-name")}
            </Link>

          </Checkbox>
        </Form.Item>


          
          <Form.Item {...tailLayout} className="col-12 form-item">
            <div className="row f-right">
              <Button
                id="btn-cancel-new-agency"
                onClick={HandCancel}
                className="default-button margin-right-button"
              >
                {t("buttons.cancel")}
              </Button>
              <Button
                disabled={disabled || savingData}
                id="btn-send-new-agency"
                className="primary-button"
                type="primary"
                htmlType="submit"
              >
                <span>
                  { savingData ? <LoadingOutlined /> : <></>}
                  {t("buttons.send")}
                </span>
                
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};
export default FormNewAgency;
