import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import '../../../i18n';
import { useTranslation } from 'react-i18next';
import { Skeleton, Typography } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { LangContext } from 'src/hooks/context/lang';

const { Title } = Typography;

// tslint:disable-next-line:no-var-requires
const scrollLib = require('react-scroll');
const scroll = scrollLib.animateScroll;

// Componente de información de Condiciones Generales de la app
export const LegalAdviceNewAgency = () => {
  const language = useContext(LangContext);
  const { t } = useTranslation(['legalAdvice']);

  useEffect(() => {
    scroll.scrollToTop();
  });

  useEffect(()=>{
  },[language.lang])



  const [loadingGeneralTexts, setLoadingGeneralTexts] = useState<boolean>(false);
  const [legalAdvice, setlegalAdvice] = useState<string>();


  const defaultlegalAdvice : JSX.Element = <div className="container mt-3 special-mb">

                                                    { ReactHtmlParser(t("legalAdvice:section.title.1")) }
                                                    { ReactHtmlParser(t("legalAdvice:section.paragraph.1")) }



                                                    { ReactHtmlParser(t("legalAdvice:section.title.2")) }
                                                    { ReactHtmlParser(t("legalAdvice:section.paragraph.2")) }


                                                    { ReactHtmlParser(t("legalAdvice:section.title.3")) }
                                                    { ReactHtmlParser(t("legalAdvice:section.paragraph.3")) }


                                                    { ReactHtmlParser(t("legalAdvice:section.title.4")) }
                                                    { ReactHtmlParser(t("legalAdvice:section.paragraph.4")) }

                                                    { ReactHtmlParser(t("legalAdvice:section.title.5")) }
                                                    { ReactHtmlParser(t("legalAdvice:section.paragraph.5")) }


                                                    { ReactHtmlParser(t("legalAdvice:section.title.6")) }
                                                    { ReactHtmlParser(t("legalAdvice:section.paragraph.6")) }


                                                    { ReactHtmlParser(t("legalAdvice:section.title.7")) }
                                                    { ReactHtmlParser(t("legalAdvice:section.paragraph.7")) }



                                                    { ReactHtmlParser(t("legalAdvice:section.title.8")) }
                                                    { ReactHtmlParser(t("legalAdvice:section.paragraph.8")) }
                                                    { ReactHtmlParser(t("legalAdvice:section.paragraph.9")) }
                                                    { ReactHtmlParser(t("legalAdvice:section.paragraph.10")) }
                                                    { ReactHtmlParser(t("legalAdvice:section.paragraph.11")) }
                                                    { ReactHtmlParser(t("legalAdvice:section.paragraph.12")) }
                                                    

                                                  </div>

  return (
    <>
      <Skeleton loading={loadingGeneralTexts} active>
        {legalAdvice && legalAdvice.length > 0 ? ReactHtmlParser(legalAdvice) : defaultlegalAdvice}
      </Skeleton>  
    </>
  );
}

