import '../../../i18n';
import { useTranslation } from 'react-i18next';

export const Columns = () => {

    const { t } = useTranslation(['cookiesPolicy']);

    return [
        {
            title: 'COOKIE',
            dataIndex: 'cookie',
            key: 'cookie',
            width: 100
        },
        {
            title: t("table.columns.purpose"),
            dataIndex: 'purpose',
            key: 'purpose',
        },
        {
            title: t("table.columns.duration"),
            dataIndex: 'expiration',
            key: 'expiration',
            width: 200
        }
    ];
}

export const Data1 = () => {

    const { t } = useTranslation(['cookiesPolicy']);

    return [
        {
            key: '1',
            cookie: '_utma',
            purpose: t("table.1.purpose.1"), 
            expiration: t("table.1.expiration.1")
        },
        {
            key: '2',
            cookie: '_utmb',
            purpose: t("table.1.purpose.2"),
            expiration: t("table.1.expiration.2")
        },
        {
            key: '3',
            cookie: '_utmc',
            purpose: t("table.1.purpose.3"),
            expiration: t("table.1.expiration.3")
        },
        {
            key: '4',
            cookie: '_utmz',
            purpose: t("table.1.purpose.4"),
            expiration: t("table.1.expiration.4")
        },
    ];
}
  
export const Data2 = () => {

    const { t } = useTranslation(['cookiesPolicy']);

    return [
        {
            key: '1',
            cookie: 'VISITOR_INFO1_LIVE',
            purpose: t("table.2.purpose.1"), 
            expiration: t("table.2.expiration.1")
        },
        {
            key: '2',
            cookie: 'PREF, YSC',
            purpose: t("table.2.purpose.2"), 
            expiration: t("table.2.expiration.2")
        },
        {
            key: '3',
            cookie: 'SSID, SID, APISID, AID, O1_LIVE',
            purpose: t("table.2.purpose.3"), 
            expiration: t("table.2.expiration.3")
        }
    ];
}
  
export const Data3 = () => {

    const { t } = useTranslation(['cookiesPolicy']);

    return [
        {
            key: '1',
            cookie: 'khcookie, NID, PREF, testcookie',
            purpose: t("table.3.purpose.1"), 
            expiration: t("table.3.expiration.1")
        }
    ];
}
  
export const Data4 = () => { 

    const { t } = useTranslation(['cookiesPolicy']);

    return [
        {
            key: '1',
            cookie: 'Qtrans Front Language',
            purpose: t("table.4.purpose.1"), 
            expiration: t("table.4.expiration.1")
        },
        {
            key: '2',
            cookie: 'Mi_cookies OK',
            purpose: t("table.4.purpose.2"), 
            expiration: t("table.4.expiration.2")
        },
        {
            key: '3',
            cookie: '_atuvc, __atuvs',
            purpose: t("table.4.purpose.3"), 
            expiration: t("table.4.expiration.3")
        },
        {
            key: '4',
            cookie: '_cfduid',
            purpose: t("table.4.purpose.4"), 
            expiration: t("table.4.expiration.4")
        }
    ];
}
export const Data5 = () => { 

    const { t } = useTranslation(['cookiesPolicy']);

    return [
        {
        key: '1',
        cookie: '_cfduid',
        purpose: t("table.5.purpose.1"), 
        expiration: t("table.5.expiration.1")
        }
    ];
}
export const Data6 = () => { 

    const { t } = useTranslation(['cookiesPolicy']);

    return [
        {
        key: '1',
        cookie: 'ID',
        purpose: t("table.6.purpose.1"), 
        expiration: t("table.6.expiration.1")
        }
    ];
}
