import { Alert } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';

import './GoogleMapsAutoCompleteComponent.sass'


const apiKey = 'AIzaSyDIdhJZxHzb9cNHbj_e10MEeRH6zYynVlY';
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';

// load google map api js

function loadAsyncScript(src){
    return new Promise(resolve => {
        const script = document.createElement("script");
        Object.assign(script, {
            type:"text/javascript",
            async: true,
            src
        });

        script.addEventListener("load", ()=>{ resolve(script)});
        document.head.appendChild(script);
    })

}

interface AddressData {
    city: string,
    state:  string,
    zip:  string,
    country:  string,
    street:  string,
    formatted_address:  string,
} 

const extractAddress = (place) => {

    const address : AddressData = {
        city: "",
        state: "",
        zip: "",
        country: "",
        street: "",
        formatted_address: "",
    };

    if (!Array.isArray(place.address_components)){
        return null;
    }

    address.formatted_address = place.formatted_address;

    place.address_components.forEach(component => {
        const types = component.types;
        const value = component.long_name;

        if (types.includes("locality")){
            address.city = value;
        }

        if (types.includes("administrative_area_level_1")){
            address.state = value;
        }

        if (types.includes("postal_code")){
            address.zip = value;
        }

        if (types.includes("country")){
            address.country = value;
        }

        if (types.includes("route")){
            address.street = value;
        }
    })

    return address;
}


interface GoogleMapsAutoCompleteComponentProps{
    onSetData : (googleData: any) => void,
    showSelectedInfo: boolean,
    isRequiredField: boolean
}

const GoogleMapsAutoCompleteComponent = (props: GoogleMapsAutoCompleteComponentProps) :JSX.Element => {
    const searchInput = useRef(null);
    const { t } = useTranslation(["independentAgentData", "googleMaps"]);

    const [address, setAddress] = useState<AddressData>({
        city: "",
        state: "",
        zip: "",
        country: "",
        street: "",
        formatted_address: "",
    })

    const initMapScript = () => {
        if ((window as any).google){
            return Promise.resolve();
        }

        const src = `${mapApiJs}?key=${apiKey}&libraries=places`;
        return loadAsyncScript(src);
    }

    const [error, setError]  = useState<string>();
    const onChangeAddress = (autocomplete) => {
        const place = autocomplete.getPlace();

        props.onSetData(place);
        let address = extractAddress(place);

        if (address){
            setError('');
            setAddress(address);
        }else{
            setError("invalid_address");
        }
    }


    // init autocomplete
    const initAutocomplete = () => {
        if (!searchInput.current){
            return;
        }

        const autocomplete = new (window as any).google.maps.places.Autocomplete(searchInput.current);
        autocomplete.setFields(["address_component", "geometry", "place_id", "name", "formatted_address", "plus_code"]);
        autocomplete.addListener("place_changed", ()=>onChangeAddress(autocomplete))

    }

    useEffect(()=>{
        initMapScript().then(()=>initAutocomplete());
    },[])

    return (<div className="google-maps-autocomplete">
                <label className={`ant-form-item-required form-label ${props.isRequiredField ? 'required' : ''}`} title={t("independentAgentData:address")}>
                    {t("independentAgentData:address")}
                </label>
                <div className="search">
                    <input onChange={(input : any)=>{
                        props.onSetData(undefined);
                    }} className='ant-input main-input' ref={searchInput} placeholder={t("independentAgentData:place-holder.search-location")} style={{width: "100%"}}/>
                </div>

                {
                    props.showSelectedInfo
                    ? 
                        <div className="address">
                            <p>Country: {address.country}</p>
                            <p>State: {address.state}</p>
                            <p>City: {address.city}</p>
                            <p>Zip: {address.zip}</p>
                            <p>Address: {address.street}</p>
                            <p>Formatted Address: {address.formatted_address}</p>
                        </div>
                    :
                        <></>
                }
                {
                    error && error.length > 0 ? <Alert type='warning' showIcon message={t('googleMaps:invalid-address')} style={{marginTop: "10px"}}/> : <></>
                }
            </div>)
}


export default GoogleMapsAutoCompleteComponent;