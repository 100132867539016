import { createContext } from "react";
import { User } from '../../models/user/userModel'

// Valores por defecto
const userDefault = new User();

// Creación de Context para guardar datos del usuario
export const UserContext = createContext(userDefault);

// Creación de Context login para control si esta o no logado
export const LoginContext = createContext(null);
