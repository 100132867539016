import React, { Suspense } from "react";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import "./topRanking.sass";
import { Typography, Avatar, Skeleton } from "antd";
import { FormattedNumber } from "react-intl";
import NotResults from "../../noResultGeneric/notResults";
import { Ranking } from "./interfaceRanking";
import { UserContext } from "../../../hooks/context/userInfo";
import { useRankings } from "src/api/api";

const { Paragraph, Title } = Typography;

// Componente que muestra listado de posiciones del ranking de los usuarios
const TopRankingPosition = () => {
  const { t } = useTranslation("ranking");

  const userContext = useContext(UserContext);

  const listRanking = useRankings().data

  return (
    <div className="col-12 background-color-ranking">
      <div className="col-12 title-ranking">
        <Title level={4}>{t("title")}</Title>
      </div>
        <>
          {listRanking.length <= 0 ? (
            <NotResults section="ranking" />
          ) : (
            <div className="col-12 list-ranking row p-3">
              {listRanking.map((s: Ranking, index) => {
                if (s)
                  return (
                    <div
                      className={
                        (index === 0 ? "col-md-12 " : "col-md-4 col-sm-6 ") +
                        "col-lg-6 col-6"
                      }
                      key={index}
                    >
                      <div
                        className={
                          (s.own ? "border-ranking " : "") +
                          "padding-box-ranking width-box-ranking"
                        }
                      >
                        <div className="margin-img-ranking">
                          <Avatar className="box-img-width" src={s.image} />
                        </div>
                        <div className="text-align">
                          <Paragraph className="mb-0 color-position font-size-position">
                            {s.position}
                          </Paragraph>
                          <Paragraph className="mb-0">
                            <strong>{s.own ? t("name-own") : s.name}</strong>
                          </Paragraph>
                          {userContext.GetCurrency() && (
                            <FormattedNumber
                              useGrouping={true}
                              value={parseInt(s.quantity, 10)}
                              style="currency"
                              currency={userContext.GetCurrency()}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  );
              })}
            </div>
          )}
        </>
    </div>
  );
};

const TopRankingPositionWrapper = () => (
    <Suspense fallback={<Skeleton />}>
      <TopRankingPosition />
    </Suspense>
  );

export default TopRankingPositionWrapper;
