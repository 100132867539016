import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import '../../../i18n';
import { useTranslation } from 'react-i18next';
import { Skeleton, Typography } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { LangContext } from 'src/hooks/context/lang';

// tslint:disable-next-line:no-var-requires
const scrollLib = require('react-scroll');
const scroll = scrollLib.animateScroll;


export const TermsConditionsCashback = () => {
  const language = useContext(LangContext);
  const { t } = useTranslation(['termsConditionsCashback']);

  useEffect(() => {
    scroll.scrollToTop();
  });

  useEffect(()=>{
  },[language.lang])



  const [loadingGeneralTexts, setLoadingGeneralTexts] = useState<boolean>(false);
  const [termsConditions, settermsConditions] = useState<string>();


  const defaulttermsConditions : JSX.Element = <div className="container mt-3 special-mb">
                                                   <p><strong> { ReactHtmlParser(t("termsConditionsCashback:main-title")) } </strong></p>

                                                    { ReactHtmlParser(t("termsConditionsCashback:section.title.1")) }
                                                    { ReactHtmlParser(t("termsConditionsCashback:section.paragraph.1")) }


                                                    { ReactHtmlParser(t("termsConditionsCashback:section.title.2")) }
                                                    { ReactHtmlParser(t("termsConditionsCashback:section.paragraph.2")) }


                                                    { ReactHtmlParser(t("termsConditionsCashback:section.title.3")) }
                                                    { ReactHtmlParser(t("termsConditionsCashback:section.paragraph.3")) }


                                                    { ReactHtmlParser(t("termsConditionsCashback:section.title.4")) }
                                                    { ReactHtmlParser(t("termsConditionsCashback:section.paragraph.4")) }
                                                    { ReactHtmlParser(t("termsConditionsCashback:section.paragraph.5")) }
                                                    { ReactHtmlParser(t("termsConditionsCashback:section.paragraph.6")) }


                                                    { ReactHtmlParser("\t" +  t("termsConditionsCashback:section.subtitle.1")) }
                                                    { ReactHtmlParser(t("termsConditionsCashback:section.paragraph.7")) }
                                                    { ReactHtmlParser(t("termsConditionsCashback:section.paragraph.8")) }

                                                    { ReactHtmlParser(t("termsConditionsCashback:section.title.5")) }
                                                    { ReactHtmlParser(t("termsConditionsCashback:section.paragraph.9")) }                                                


                                                    { ReactHtmlParser(t("termsConditionsCashback:section.title.6")) }
                                                    { ReactHtmlParser(t("termsConditionsCashback:section.paragraph.10")) }
                                                  


                                                    { ReactHtmlParser(t("termsConditionsCashback:section.title.7")) }
                                                    { ReactHtmlParser(t("termsConditionsCashback:section.paragraph.11")) }


                                                    { ReactHtmlParser(t("termsConditionsCashback:section.title.8")) }
                                                    { ReactHtmlParser(t("termsConditionsCashback:section.paragraph.12")) }
                                                    { ReactHtmlParser(t("termsConditionsCashback:section.paragraph.13")) }
                                                    { ReactHtmlParser(t("termsConditionsCashback:section.paragraph.14")) }
                                                

                                                  </div>

  return (
    <>
      <Skeleton loading={loadingGeneralTexts} active>
        {termsConditions && termsConditions.length > 0 ? ReactHtmlParser(termsConditions) : defaulttermsConditions}
      </Skeleton>  
    </>
  );
}

