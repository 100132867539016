import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { Form, Input, Button, Modal, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import { FormattedNumber, IntlProvider } from "react-intl";
import { UserContext } from "../../../../hooks/context/userInfo";
import ModalLegal from "../../modalLegalGeneric/modalLegalGeneric";
import { convertStringToNumber } from "../../../../utils/convertStringToNumber";
import { showNotification } from "../../../../utils/notificationsGeneric";
import { numberFormat } from "../../../../utils/formatAmount";
import {
  ONLY_NUMBER_D,
  NO_CHART_REPITED,
  NO_CHART_REPITED_AND_NUMBER_DECIMAL,
} from "../../../../constants/regex";
import { useGetReward } from "src/api/api";

import "../modalReward.sass";

interface ParamProps {
  isModalRewardVisible: boolean;
  setModalRewardVisible: (value: boolean) => void;
  valueOpen: any;
  setAvailableMoney: (value: any) => void;
  isOwn: boolean;
}

// Modal reward
const ModalRewardGeneric = (params: ParamProps) => {
  const [confirmRedeem, setConfirmRedeem] = useState<boolean>(false);
  const [visible, setVisible] = useState(false);
  const [checkPolicy, setCheckPolicy] = useState(false);
  const [openModalLegalB, setOpenModalLegalB] = useState(false);

  const userContext = useContext(UserContext);
  const [form] = Form.useForm();
  const { t } = useTranslation(["modalLogin", "profile", "reward"]);
  const getReward = useGetReward();

  const [amount, setAmount] = useState(0);

  useEffect(() => {
    setVisible(params.isModalRewardVisible);
  }, [params.isModalRewardVisible]);

  const onFinish = async (values) => {
    setConfirmRedeem(false);
    let valueConver = convertStringToNumber(values.amount);
    getReward({
      idReward: params.valueOpen?.id,
      amount: valueConver,
      currency: params.valueOpen?.currency.id,
      ownReward: params.valueOpen?.own === true ? true : false,
    })
      .then((v) => {

        showNotification(v.type.toLocaleLowerCase(), v.title, v.message, "topRight");

        if ( v.type == "Success" ){
          // own and success
          form.resetFields();
          
          params.setModalRewardVisible(false);

          setVisible(false);
          
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        } 
      })
      .catch((error) => {
        setAmount(0);
        cleanForm();
      });
  };

  const cleanForm = () => {
    setCheckPolicy(false);
    setConfirmRedeem(false);
    form.resetFields();
  };

  const handleCancel = () => {
    cleanForm();
    setAmount(0);
    params.setModalRewardVisible(false);
    setVisible(false);
  };

  const openModal = (e) => {
    params.setModalRewardVisible(false);
    setVisible(false);
    setOpenModalLegalB(true);
    e.stopPropagation();
  };

  
  return (
    <>
      <IntlProvider locale={userContext.GetLanguageUser()}>
        <Modal
          title={t("reward:modal.title")}
          visible={visible}
          onCancel={handleCancel}
          footer={
            <div className="body-modal-reward">
              <div className="col-1 display-xs-reward"></div>
              <div className="footer-modal-reward col-md-10 col-12">
                {params.valueOpen?.messageMinMaxAmount}
              </div>
              <div className="col-1 display-xs-reward"></div>
            </div>
          }
          key="1"
          className="box-modal-reward"
        >
          <div className="body-modal-reward">
            <div className="col-1 display-xs-reward"></div>
            <div className="col-md-10 col-12">
              <div className="col-12 img-modal-reward padding-elements-form-reward">
                <img src={params.valueOpen?.img} />
              </div>
              <div className="col-12 col-sm-8 mb-3 padding-elements-form-reward-green">
                {params.valueOpen?.increment > 0 && (
                  <div className="box-max-amount-modal margin-element">
                    {ReactHtmlParser(
                      t("reward:table-result.max-amount-percentage", {
                        0: params.valueOpen?.increment,
                      })
                    )}
                  </div>
                )}
              </div>
              <div className="col-12 margin-element mt-3 mb-3 padding-elements-form-reward">
                {t("reward:modal.have")}
                <strong>
                  <FormattedNumber
                    useGrouping={true}
                    value={params.valueOpen?.amount}
                    style="currency"
                    currency={params.valueOpen?.currency.id}
                  />
                </strong>
                {t("reward:modal.sentence", { 0: params.valueOpen?.name })}
              </div>
              <div className="col-12 mb-3 padding-elements-form-reward">
                <div className="margin-element">
                  <strong>{t("reward:modal.label")}</strong>
                </div>
              </div>
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                form={form}
              >
                <Form.Item
                  name="amount"
                  className="col-12 row-form-item margin-element"
                  rules={[
                    {
                      required: true,
                      message: t("reward:modal.validations.required"),
                    },
                    {
                      whitespace: true,
                      message: t("reward:modal.validations.empty"),
                    },
                    {
                      validator: (_, value) =>
                        value &&
                        convertStringToNumber(value) >
                          params.valueOpen?.maximumAmount
                          ? Promise.reject(
                              t(
                                "reward:modal.validations.redeem-more-money-provider"
                              )
                            )
                          : value &&
                            convertStringToNumber(value) <
                              params.valueOpen?.minimumAmount
                          ? Promise.reject(
                              t(
                                "reward:modal.validations.redeem-less-money-provider"
                              )
                            )
                          : value
                          ? ONLY_NUMBER_D.test(value)
                            ? NO_CHART_REPITED.test(value)
                              ? NO_CHART_REPITED_AND_NUMBER_DECIMAL.test(value)
                                ? value === "0"
                                  ? Promise.reject(
                                      t("reward:modal.validations.zero")
                                    )
                                  : convertStringToNumber(value) >
                                    params.valueOpen?.amount
                                  ? Promise.reject(
                                      t(
                                        "reward:modal.validations.redeem-more-money-have"
                                      )
                                    )
                                  : params.valueOpen?.maxRedeemable !== null &&
                                    params.valueOpen?.minRedeemable !== null
                                  ? convertStringToNumber(value) >
                                    params.valueOpen?.maxRedeemable
                                    ? Promise.reject(
                                        t(
                                          "reward:modal.validations.redeem-more-money-provider"
                                        )
                                      )
                                    : convertStringToNumber(value) <
                                      params.valueOpen?.minRedeemable
                                    ? Promise.reject(
                                        t(
                                          "reward:modal.validations.redeem-less-money-provider"
                                        )
                                      )
                                    : Promise.resolve()
                                  : Promise.resolve()
                                : Promise.reject(
                                    t("reward:modal.validations.num-decimals")
                                  )
                              : Promise.reject(
                                  t("reward:modal.validations.formated")
                                )
                            : Promise.reject(
                                t("reward:modal.validations.number")
                              )
                          : Promise.resolve(),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    className="main-input amount"
                    id="amount"
                    placeholder={numberFormat(
                      0.0,
                      userContext.GetLanguageUser()
                    )}
                    suffix={params.valueOpen?.simbol}
                    value={amount}
                    onChange={(e) =>
                      setAmount(convertStringToNumber(e.target.value))
                    }
                  />
                </Form.Item>
                {
                  //Checkbox para validar que se ha leido la politica de privacidad
                  params.valueOpen?.legalB && (
                    <Form.Item
                      className="col-12 margin-element-check mt-3"
                      name="legal"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  t("reward:modal.validations.check")
                                ),
                        },
                      ]}
                    >
                      <Checkbox
                        id="check-policy"
                        className="main-input box-check-reward"
                        checked={checkPolicy}
                      >
                        <label htmlFor="thing" onClick={openModal}>
                          <div className="align-text-check-reward">
                            {t("reward:modal.linkModal-1")}
                            <span className="span-legal-b">
                              {t("reward:modal.linkModal-2")}
                            </span>
                            {t("reward:modal.linkModal-3")}
                          </div>
                        </label>
                      </Checkbox>
                    </Form.Item>
                  )
                }

                <Form.Item className="col-12 margin-element mt-4">
                  <Button
                    id="btn-form-login"
                    className="col-12 primary-button"
                    type="primary"
                    size="large"
                    disabled={!confirmRedeem}
                    htmlType="submit"
                  >
                    {t("reward:table-result.button")}
                  </Button>
                </Form.Item>
              </Form>
              <div className="col-12 subtitle-modal-reward mb-3">
                {t("reward:modal.max")}
                {params.valueOpen?.minimumAmount ? (
                  <FormattedNumber
                    value={params.valueOpen?.minimumAmount}
                    style="currency"
                    currency={params.valueOpen?.currency.id}
                  />
                ) : (
                  0
                )}
                {t("reward:modal.min")}
                {params.valueOpen?.maximumAmount ? (
                  <FormattedNumber
                    value={params.valueOpen?.maximumAmount}
                    style="currency"
                    currency={params.valueOpen?.currency.id}
                  />
                ) : (
                  0
                )}

                <div style={{ marginTop: "10px" }}>
                  <Checkbox
                    checked={confirmRedeem}
                    onChange={(e) => {
                      setConfirmRedeem(e.target.checked);
                    }}
                  >
                    {t("reward:modal.confirm-redeem")}
                  </Checkbox>
                </div>
              </div>
            </div>
            <div className="col-1 display-xs-reward"></div>
          </div>
        </Modal>

        {openModalLegalB && (
          <ModalLegal
            legaBText={params.valueOpen?.legaBText}
            setVisible={setVisible}
            setOpenModalLegalB={setOpenModalLegalB}
          />
        )}
      </IntlProvider>
    </>
  );
};
export default ModalRewardGeneric;
