// #region Tipo Media para la parte multimedia de Init
export enum MediaType {
    Image = "IM",
    Video = "VI",
}
// #endregion

// #region Estados del registro del usuario
export enum StateUser {
    Inactive = 1,
    oK,
    IncorrectData,
    PendingConfirmation,
    PendingValidation,
}
// #endregion

// #region Estado de una reserva
export enum StateReservations {
    Pending = 1,
    Regularized = 2,
    Validated = 3,
    Cancelled = 4,
    Duplicated = 5,
    Rejected = 6
}
// #endregion

// #region Tipo de dato
export enum IncreaseInfo {
    porcent = 0,
    currency = 1
}
// #endregion

//#region AjaxApi response types
export enum ApiResponseStatus{
    NoShow = "NoShow",
    Success = "Success",
    Error = "Error",
    Warning = "Warning",
    Information = "Information",
    Validation = "Validation"
}