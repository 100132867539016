import React, { useState, useContext, Suspense } from "react";
import { Helmet } from "react-helmet-async";
import { ConfigContext } from "../../hooks/context/config";
import "../../i18n";
import "./balanceHistory.sass";
import { useTranslation } from "react-i18next";
import { Typography, Tabs } from "antd";
import Results from "./results/results";
import { Skeleton } from "antd";
import { UserContext } from "../../hooks/context/userInfo";
import Pagination from "../../models/pagination/pagination";
import NotResults from "../noResultGeneric/notResults";
import { getLocaleDatePicker } from "../../utils/datePicker";
import { PickerLocale } from "antd/es/date-picker/generatePicker";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faRetweet, faListAlt } from "@fortawesome/free-solid-svg-icons";
import { BALANCE_GIFT_CARD, BALANCE_RESERVATION } from "../../constants/constants";
import { FormattedNumber } from "react-intl";
import RewardGeneric from "../rewardGenericBalance/rewardGenericBalance";
import { RestApiClientContext } from "src/hooks/context/pageContext";

const { Title, Paragraph, Text } = Typography;
const { TabPane } = Tabs;

// Componente que recoge el historial de movimientos de las recompensas
const BalanceHistory = () => {
  const infoClient = useContext(ConfigContext);
  const userData = useContext(UserContext);
  const { t } = useTranslation(["urls", "seo", "balanceHistory"]);
  // Estado que almacena la página actual y el número de elementos por página de la paginación en la tabla y lista
  const [pageResults, setPageResults] = useState(new Pagination());
  // Estado que almacena los criterios de ordenación aplicados a la tabla
  const [orderResults, setOrderResults] = useState([]);
  // Recogida de mensaje de la API si fuera necesario (caso de error)
  const [messageApi, setMessageApi] = useState("no-result");
  const [filter, setFilter] = useState(3);

  const local: PickerLocale = getLocaleDatePicker();

  const restApiClient = useContext(RestApiClientContext);

  const [noIndexNoFollow, setNoIndexNoFollow] = React.useState(false);
  const getNoIndexNoFollow = () : void => {
    restApiClient.getNoIndexNoFollowConfig().then(v => {
      setNoIndexNoFollow(v);
    });

  }
  React.useEffect(() => {
    getNoIndexNoFollow();
  }, []);

  const columns = [
    {
      title: t("balanceHistory:header-elements.date"),
      dataIndex: "date",
      width: 125,
      sorter: {
        compare: (a, b) => a.regularizedDate - b.regularizedDate,
      },
      render: (text, row, index) => (
        <>
          <Text className="reservation-text-date">{moment(text).format(local.dateFormat)}</Text>
        </>
      ),
    },
    {
      title: t("balanceHistory:header-elements.concept"),
      dataIndex: "concept",
      render: (text, row, index) => {
        return (
          <>
            <Paragraph className="margin-line-info">
              <FontAwesomeIcon
                className="edit-icon-input gray-faint-color"
                icon={row.type === BALANCE_GIFT_CARD ? faCoins : faRetweet}
              />
              <Text className="gray-faint-color">
                {row.type === BALANCE_GIFT_CARD
                  ? t("balanceHistory:body.redeemed-reward")
                  : t("balanceHistory:body.validated-reservation")}
              </Text>
            </Paragraph>
            <Paragraph className="margin-line-info">
              <strong>
                {row.description} <Text className="custom-color-value">{row.percentage}</Text>
              </strong>
            </Paragraph>
            <Paragraph className="gray-faint-color margin-line-info">
              {row.amount && (
                <>
                  <Text>{t("balanceHistory:body.value")} </Text>
                  <FormattedNumber useGrouping={true} value={row.amount} style="currency" currency={row.currency} />
                </>
              )}
            </Paragraph>
          </>
        );
      },
    },
    {
      title: t("balanceHistory:header-elements.total-amount"),
      dataIndex: "totalAmount",
      align: "right",
      render: (text, row, index) => {
        return (
          <>
            <Paragraph
              className={
                "margin-line-info " + (row.amountPostPercentage <= 0 ? "negative-color-value" : "custom-color-value")
              }
            >
              {row.amountPostPercentage <= 0 ? "" : "+ "}
              <FormattedNumber
                useGrouping={true}
                value={row.amountPostPercentage}
                style="currency"
                currency={row.currency}
              />
            </Paragraph>
            <Paragraph className="gray-faint-color margin-line-info">
              <FormattedNumber useGrouping={true} value={row.totalAmount} style="currency" currency={row.currency} />
            </Paragraph>
            <Paragraph className="gray-faint-color margin-line-info"></Paragraph>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          {infoClient.incentiveSiteName} {t("seo:title.register", { 0: infoClient.incentiveSiteName })} |{" "}
          {t("seo:title.register")}
        </title>
        <meta
          name="description"
          content={t("seo:description.register", {
            0: infoClient.incentiveSiteName,
          })}
        />
        {
          noIndexNoFollow ? 
            <meta name="robots" content="noindex, nofollow" />
          : <meta name="robots" content="index,follow" />
        }
        <link rel="canonical" href={infoClient.urlWA + t("urls.public.register")} />
        <link rel="icon" type="image/ico" href={infoClient.favicon} sizes="32x32" />
        <link href={infoClient.urlWA + t("urls.public.register")} hrefLang="es-ES" rel="alternate" />
      </Helmet>

      <div className="mt-3">
        <RewardGeneric />

        {history.length > 0 && (
          <div className="col-12 table-balance table-reward container">
            <div className="align-text-regularization">
              <Title level={4}>{t("balanceHistory:title-balance-history")}</Title>
              <Paragraph>{t("balanceHistory:subtitle-balance-history")}</Paragraph>
            </div>
            <div className="table-balance-tags col-12 row">
              <Tabs
                defaultActiveKey="0"
                onChange={(newFilter) => {
                  setFilter(parseInt(newFilter));
                }}
              >
                <TabPane
                  key={3}
                  tab={
                    <div>
                      <FontAwesomeIcon className="edit-icon-input" icon={faListAlt} />
                      {t("balanceHistory:tabs.all-tab")}
                    </div>
                  }
                >
                  <div className="col-12 p-form-elements list-balance-history">
                    <Results
                      cols={columns}
                      filter={filter}
                      pageResults={pageResults}
                      setPageResults={setPageResults}
                      orderResults={orderResults}
                      setOrderResults={setOrderResults}
                    />
                  </div>
                </TabPane>
                <TabPane
                  key={1}
                  tab={
                    <div>
                      <FontAwesomeIcon className="edit-icon-input" icon={faCoins} />
                      {t("balanceHistory:tabs.gift-card-tab")}
                    </div>
                  }
                >
                  <div className="col-12 p-form-elements list-balance-history">
                    <Results
                      cols={columns}
                      filter={filter}
                      pageResults={pageResults}
                      setPageResults={setPageResults}
                      orderResults={orderResults}
                      setOrderResults={setOrderResults}
                    />
                  </div>
                </TabPane>
                <TabPane
                  key={2}
                  tab={
                    <div>
                      <FontAwesomeIcon className="edit-icon-input" icon={faRetweet} />
                      {t("balanceHistory:tabs.reservations-tab")}
                    </div>
                  }
                >
                  <div className="p-form-elements list-balance-history col-12 p-0">
                    <Results
                      cols={columns}
                      filter={filter}
                      pageResults={pageResults}
                      setPageResults={setPageResults}
                      orderResults={orderResults}
                      setOrderResults={setOrderResults}
                    />
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </div>
        )}
        {history.length === 0 && <NotResults section="regularizations" messageApi={messageApi} />}
      </div>
    </>
  );
};

const BalanceHistoryWrapper = (param) => (
  <Suspense fallback={<Skeleton />}>
    <BalanceHistory {...param} />
  </Suspense>
);

export default BalanceHistoryWrapper;
