import React, { Suspense, useContext, useEffect, useState } from "react";
import TableResults from "./table";
import ListResults from "./list";
import { useTranslation } from "react-i18next";
import NotResults from "../../noResultGeneric/notResults";
import { Typography } from "antd";
import AlternateComponent from "../../utils/alternateComponent/alternateComponent";
import { Skeleton } from "antd";
import { RestApiClientContext } from "src/hooks/context/pageContext";

const { Paragraph } = Typography;

interface AgenciesList{
  agencies: any[],
  totalRecord: number
}

// Componente intermedio que identifica si renderizar Table o List en función de la resolución del dispositivo
const Results = (param) => {
  const { t } = useTranslation(["searchAgency", "ajaxApi"]);
  const restApiClient = useContext(RestApiClientContext);

  restApiClient.setLanguage(t);

  const [loadingAgencies, setLoadingAgencies] = useState<boolean>(false);
  const [agencies, setAgencies] = useState<AgenciesList>({ agencies: [], totalRecord: 0 });

  useEffect(()=>{
    if (param.forceRefreshData == undefined || param.forceRefreshData == null || param.forceRefreshData == true){
      setLoadingAgencies(true);
      restApiClient.getFilterAgencies(
        param.searchTermName,
        param.searchTermAddress,
        param.searchTermPhone,
        param.searchTermTaxid,
        param.pageResults.current,
        param.pageResults.pageSize
      ).then((r)=>{
        if (r){
          setAgencies(r);
        }else{
          setAgencies({ agencies: [], totalRecord: 0 });
        }
      })
      .finally(()=>{
        setLoadingAgencies(false);
      })
    }
  },[param])
 
  // Modulamos la info para la versión de escritorio
  const desktopVersion = () => {
    return (
      <TableResults
        func={param.selectedData}
        setDisabled={param.setDisabled}
        paramsRegister={param.paramsRegister}
        columns={param.cols}
        rows={agencies}
        selectData={param.selectData}
        pageResults={param.pageResults}
        selectedData={param.selectedData}
        setPageResults={param.setPageResults}
        setPageResultsInput={param.setPageResultsInput}
      />
    );
  };

  // Modulamos la info para la versión mediana (tablet)
  const tabletVersion = () => {
    return desktopVersion();
  };

  // Modulamos la info para la versión móvil
  const mobileVersion = () => {
    return (
      <ListResults
        func={param.selectedData}
        setDisabled={param.setDisabled}
        rows={agencies}
        selectData={param.selectData}
        selectedData={param.selectedData}
        pageResults={param.pageResults}
        setPageResults={param.setPageResults}
        setPageResultsInput={param.setPageResultsInput}
      />
    );
  };

  const onAgencyCreated = (newAgencyId: string) : void => {
    /**
     * Si doy de alta una agencia accediendo al formulario desde el enlace de "solicita el alta de tu agencia" 
     * que aparece cuando escribes un nombre que no existe y no hay ningún resultado
     * termina aquí
     * 
     * ¿Por qué? Nunca lo sabremos!
     * 
     */
    param.onFinishCreationNewAgency(newAgencyId);
  }

  return (
    // Condicional para cargar un componente u otro
    <Skeleton active loading={loadingAgencies}>
      {!agencies || agencies.totalRecord === 0 || agencies.totalRecord === undefined ? (
        <>
          <NotResults section="searchAgency" onAgencyCreated={onAgencyCreated}/>
        </>
      ) : (
        <>
          <AlternateComponent
            desktopVersionLarge={desktopVersion()}
            tabletVersion={tabletVersion()}
            mobileVersionLarge={mobileVersion()}
          />

          <Paragraph className="total-results-content">
            {agencies.totalRecord ? agencies.totalRecord : 0} {t("results")}
          </Paragraph>
          {agencies.totalRecord < 7 && (
            <Paragraph className="mt-3 text-not-found-agency">
              {t("not-found-agency")}
              <span id="link-sign-up" className="primary-links" onClick={() => param.setOpenNewAgency(true)}>
                {t("sign-up")}.
              </span>
            </Paragraph>
          )}
        </>
      )}
    </Skeleton>
  );
};

const ResultsWrapper = (param) => (
  <Suspense fallback={<Skeleton active />}>
    <Results {...param} />
  </Suspense>
);

export default ResultsWrapper;
