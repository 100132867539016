import { useFetch } from 'ruse-fetch'
import { useCookies } from 'react-cookie'
import { API_URL_TEST } from 'src/constants/constants'

const base = API_URL_TEST || 'http://localhost:8080/'

const formEncode = (params?: Object): string => params ? Object.entries(params)
  .map(([key, value]) => {
    if (!key || value == null || value === '') return false
    return encodeURIComponent(key) + '=' + encodeURIComponent(value)
  })
  .filter(t => t)
  .join('&') : ''

const getUrlAndOpts = (url: string, opts?: ExtendedRequestInit): { path: string, extOpts: RequestInit } => {
    if (opts && opts.params) {
    const encoded = formEncode(opts.params)
    const query = encoded.slice(-1) === '&' ? encoded.slice(0, -1) : encoded
    if (query.length) url += '?' + query
  } 
  const extOpts = { ...opts }
  if (opts && opts.body && typeof opts.body !== 'string') {
    extOpts.body = JSON.stringify(opts.body)
    extOpts.method = extOpts.method || 'POST'
    extOpts.headers['Content-Type'] = 'application/json'
  }
  return { path: url, extOpts }
}

export const useApiFetch = <T> (url: string, opts: ExtendedRequestInit = {}, key?: string): ApiResponse<T> => {
  const token = useCookies(['userToken'])[0]['userToken']
  opts.headers = { ...opts.headers, 'X-Authorization': token, 'Client-Tenant-URL': window.location.origin }
  const { path, extOpts } = getUrlAndOpts(url, opts)
  return useFetch(path && (base + path), extOpts, key)
}

export const useApiFetchCb = (): <T> (url: string, opts?: ExtendedRequestInit) => Promise<ApiResponse<T>> => {
  // Note: this does NOT share cache with useApiFetch
  const cookieToken = useCookies(['userToken'])[0]['userToken']
  return (url: string, opts: ExtendedRequestInit = {}) => {
    const token = opts.token || cookieToken
    opts.headers = { ...opts.headers, 'Client-Tenant-URL': window.location.origin  }
    if(token){
      opts.headers['X-Authorization'] = token
    }
    const { path, extOpts } = getUrlAndOpts(url, opts)
    return fetch(path && (base + path), extOpts)
      .then(res => res.json())
  }
}


export interface ExtendedRequestInit extends RequestInit {
  params?: Object
  body?: any
  token?: string
}

export interface ApiResponse<T> {
  code: string
  title: string
  message: string
  type: string
  additionalMessages: string[] // ?
  data?: T
  // On errors only:
  status?: number
  traceId?: string
  errors?: any
}
