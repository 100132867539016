import React, { Suspense } from "react";
import { useContext } from "react";
import { Helmet } from "react-helmet-async";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../hooks/context/config";
import FeaturedNews from "./featuredNews/featuredNews";
import ListNews from "./listNews/listNews";
import { Skeleton } from "antd";
import NotResults from "../noResultGeneric/notResults";
import { useNews, useNewsFeatured } from "src/api/api";
import { RestApiClientContext } from "src/hooks/context/pageContext";

// Componente de noticias padre Index
const News = () => {
  const infoClient = useContext(ConfigContext);
  const { t } = useTranslation(["urls", "seo"]);

  // Position del scroll en el top de la pagina
  var scrollLib = require("react-scroll");
  var scroll = scrollLib.animateScroll;

  const news = useNews(1, 10);
  if (!news.news) {
    news.news = [];
  }
  const newsFeatured = useNewsFeatured(1, 10);
  if (!newsFeatured.news) {
    newsFeatured.news = [];
  }
  const newsListFeatured = newsFeatured.news;
  const featuredIds = newsListFeatured.map((newFeatured) => newFeatured.id);
  const newsListShow = news.news.filter((newsElement) => !featuredIds.includes(newsElement.id));

  const restApiClient = useContext(RestApiClientContext);

  const [noIndexNoFollow, setNoIndexNoFollow] = React.useState(false);
  const getNoIndexNoFollow = () : void => {
    restApiClient.getNoIndexNoFollowConfig().then(v => {
      setNoIndexNoFollow(v);
    });
  }
  
  React.useEffect(() => {
    getNoIndexNoFollow();
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {infoClient.incentiveSiteName} {t("seo:title.register", { 0: infoClient.incentiveSiteName })} |{" "}
          {t("seo:title.register")}
        </title>
        <meta
          name="description"
          content={t("seo:description.register", {
            0: infoClient.incentiveSiteName,
          })}
        />
        
        {
          noIndexNoFollow ? 
            <meta name="robots" content="noindex, nofollow" />
          : <meta name="robots" content="index,follow" />
        }

        <link rel="canonical" href={infoClient.urlWA + t("urls.public.register")} />
        <link rel="icon" type="image/ico" href={infoClient.favicon} sizes="32x32" />
        <link href={infoClient.urlWA + t("urls.public.register")} hrefLang="es-ES" rel="alternate" />
      </Helmet>

      <div className="mt-3">
        <>
          {newsListShow === undefined && newsListFeatured === undefined ? (
            <NotResults section="news" messageApi={"aa"} />
          ) : (
            <>
              <FeaturedNews newsListFeatured={newsListFeatured} />
              {newsListShow !== undefined && <ListNews newsListShow={newsListShow} />}
            </>
          )}
        </>
      </div>
    </>
  );
};

const NewsWrapper = () => (
  <Suspense fallback={<Skeleton />}>
    <News />
  </Suspense>
);

export default NewsWrapper;
