import { notification } from 'antd';

// Notificación genérica que se muestra en una posicición determinada de la visual
export const showNotification = (type, title, message, position) => {
    return (
        notification[type]({
            message: title,
            description: message,
            placement: position,
            className: "notification-button-cancel"
        })
    );
}
