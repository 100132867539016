import React, { useState, useContext, useEffect } from "react";
import "../profile.sass";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { Upload, Typography, Form, Input, Button } from "antd";
import { Skeleton } from "antd";
import { JPEG_TYPE, PNG_TYPE } from "../../../constants/constants";
import { showNotification } from "../../../utils/notificationsGeneric";
import { UserContext } from "../../../hooks/context/userInfo";
import { useUpdateProfile } from "src/api/api";

const { Text, Paragraph } = Typography;

// Componente de la sección de Editar perfil
const EditProfile = (param) => {
  const { t } = useTranslation(["profile"]);
  const [newFile, setNewFile] = useState(undefined);
  const userContext = useContext(UserContext);

  const [form] = Form.useForm();

  const [prevUidImg, setPrevUidImg] = useState("-1");
  const updateProfile = useUpdateProfile();
  // Valores por defecto del elemento de la imagen que se precarga en el componente Upload
  const [defaultFileList, setDefaultFileList] = useState([
    {
      uid: "-1",
      name: "image.png",
      //status: 'done',
      url: userContext.GetImageName(),
      size: 0,
      type: "",
      originFileObj: null,
    },
  ]);

  // Valida el tipo y el tamaño del fichero subido en el componente Upload
  const validate = (file) => {
    const isJpgOrPng = file.type === JPEG_TYPE || file.type === PNG_TYPE;

    if (!isJpgOrPng) {
      showNotification("error", "Error", t("sections.edit-profile.error.upload"), "topRight");
    }

    const isLt2M = file.size / 1024 < 300;

    if (!isLt2M) {
      showNotification("error", "Error", t("sections.edit-profile.error.size"), "topRight");
    }

    return isJpgOrPng && isLt2M;
  };

  // Submit boton
  const onFinish = async (values) => {
    let req: any = {
      userName: userContext.GetNameUser(),
      email: values.email,
    };
    if (newFile !== undefined) {
      req = {
        ...req,
        imageName: newFile.name,
        base64Image: localStorage.getItem("fileBase64"),
        imageContentType: newFile.type,
      };
    }

    const res = await updateProfile(req);
    if (res.data) {
      param.setChangeData(false);
      param.setDisabledBtn(true);

      showNotification("success", res.title, res.message, "topRight");
    } else {
      showNotification("error", res.title, res.message, "topRight");
    }
  };

  // Evento que controla que si el campo input ha cambiado, habilita el botón
  const handleInputChange = () => {
    param.setDisabledBtn(false);
  };

  // Evento onchange del componente Upload para validar (TODO: Se duplica el mensaje de error cuando agragamos una imagen que no pasa la validación)
  const handleOnChange = ({ file, fileList, event }) => {
    if (validate(file)) {
      param.setDisabledBtn(false);
    }
    setDefaultFileList(fileList);
  };

  // Controla cuaqluier cambio en el formulario para activar la modal que aparece cuando cambiamos de pestaña sin salvar
  const onChangeData = () => {
    param.setChangeData(true);
  };

  // Codifica el fichero a base64
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(file);
    });
  };

  // Evento customRequest del componente Upload para personalizar el manejo del fichero a subir
  const uploadImage = (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    setNewFile(file);
    getBase64(file).then((base64) => {
      localStorage["fileBase64"] = base64;
      onSuccess(file);
    });
  };

  if (!param.userInfo.GetEmail()) {
    return <Skeleton />;
  }
  return (
    <div className="row">
      <div className="col-lg-2 p-0"></div>
      <div className="col-lg-8 col-12 p-0">
        <Form
          name="form-edit-profile"
          form={form}
          initialValues={{
            email: param.userInfo.GetEmail(),
          }}
          className="row"
          onFinish={onFinish}
          onChange={onChangeData}
          layout="vertical"
          id="form-edit-profile"
        >
          <div className="col-12 p-0">
            <Form.Item className="form-item-profile" name="avatar">
              <Upload
                accept="image/png, image/jpeg"
                customRequest={uploadImage}
                onChange={handleOnChange}
                listType="picture-card"
                defaultFileList={[
                  {
                    uid: "-1",
                    name: "image.png",
                    //status: 'done',
                    url: userContext.GetImageName(),
                    size: 0,
                    type: "",
                    originFileObj: null,
                  },
                ]}
                className="avatar-upload"
                name="avatar-user"
                multiple={false}
              >
                {userContext.GetImageName() !== undefined && defaultFileList.length !== 0 ? null : (
                  <div>+ {t("sections.edit-profile.upload-pic")}</div>
                )}
              </Upload>

              <Text className="lighter-gray max-size">{t("sections.edit-profile.info-upload")}</Text>
            </Form.Item>
          </div>
          <div className="col-12 p-0 m-0">
            <Paragraph className="col-12 form-item-username p-0 m-0">{t("sections.edit-profile.user-name")}</Paragraph>
            <Paragraph className="mb-3 text-username">{param.userInfo.userName}</Paragraph>
            <Form.Item
              className="col-12 form-item-profile"
              label={t("sections.edit-profile.email")}
              name="email"
              rules={[
                {
                  whitespace: true,
                  required: true,
                  message: t("validations.mail"),
                },
                {
                  type: "email",
                  message: t("validations.invalid-mail"),
                },
              ]}
            >
              <Input className="main-input" id="email" onChange={handleInputChange} />
            </Form.Item>
          </div>
          <Form.Item className="col-12 form-item">
            <div className="row f-right">
              <Button
                id="btn-send-new-agency"
                className="primary-button"
                type="primary"
                htmlType="submit"
                disabled={param.disabledBtn}
              >
                {t("btn.save-changes")}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
      <div className="col-lg-2 p-0"></div>
    </div>
  );
};

export default EditProfile;
