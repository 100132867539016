import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { Form, Input, Button, Modal, Checkbox, Alert } from "antd";
import { useTranslation } from "react-i18next";
import { UserContext, LoginContext } from "../../../hooks/context/userInfo";
import "./modalPassword.sass";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { useDeleteUser } from "src/api/api";

// Necesario para estructura del formulario
const tailLayout = {
  wrapperCol: {
    span: 25,
  },
};

// Formulario recover check
const ModalPassword = (params) => {
  const [visible, setVisible] = useState(true);
  const [checkBalance, setCheckBalance] = useState(false);
  // Estado para mostrar u ocultar alert warning
  const [visibleAlertError, setVisibleAlertError] = useState(false);
  // Estado para los mensajes a mostrar en los alert
  const [messageAlert, setMessageAlert] = useState({
    message: "",
    description: "",
  });

  // Idioma para los textos
  const { t } = useTranslation("profile");

  const [form] = Form.useForm();
  const userContext = useContext(UserContext);
  let userToken = userContext.GetUserToken();
  const loginContext = useContext(LoginContext);
  const [redirect, setRedirect] = useState(false);

  // History para cuando cerremos sesion monte la url base de la home
  let history = useHistory();
  const deleteAccount = useDeleteUser();
  // Cookie token usuario removed
  const [, , removeCookie] = useCookies(["userToken"]);

  const handleCancel = () => {
    setVisible(false);
    params.setModalPassword(false);
  };

  const deleteAccountRedirect = () => {
    // Borramos datos almacenados
    sessionStorage.removeItem("user");
    removeCookie("userToken", { path: "/" });
    loginContext.updateValue(false);

    // Redireccionamos a la home publica
    history.push(t("urls:public.delete-account"));
    window.location.reload();
  };

  const changeCheck = (e) => {
    setCheckBalance(e.target.checked);
  };

  const onFinish = async (values: any) => {
    const res = await deleteAccount(values.password);
    if (res.data) {
      setRedirect(true);
    } else {
      setVisibleAlertError(true);
      setMessageAlert({
        message: "",
        description: t("sections.settings-account.modal.data-error"),
      });
    }
  };

  // En caso de que cambie el estado del redirect entrara en el useEffect y hara la redireccion.
  // Hay que hacerlo asi ya que si no la redireccion no se hace correctamente al ser la llamada a la API async
  useEffect(() => {
    if (redirect) {
      deleteAccountRedirect();
    }
  }, [redirect]);

  return (
    <Modal
      title={t("profile:sections.settings-account.modal.title")}
      visible={visible}
      onCancel={handleCancel}
      footer={null}
      key="1"
      className="box-modal-profile"
    >
      <div className="mb-3">
        {t("profile:sections.settings-account.modal.text")}
      </div>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          label={t("profile:sections.settings-account.modal.label")}
          className="row-form-item"
          name="password"
          rules={[
            {
              required: true,
              message: t("profile:validations.required"),
            },
            {
              whitespace: true,
              message: t("profile:validations.password-min-max-whitespace"),
            },
          ]}
        >
          <Input.Password className="main-input" id="password" />
        </Form.Item>
        <Form.Item
          className="col-12 form-item check-policy"
          name="checkedBalance"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(t("validations.policy")),
            },
          ]}
        >
          <Checkbox
            className="main-input box-check"
            id="check-balance"
            onChange={changeCheck}
          >
            {t("sections.settings-account.modal.check-balance-text")}
          </Checkbox>
        </Form.Item>

        {visibleAlertError && (
          <div className="alert-message">
            <Alert
              message={messageAlert.message}
              description={messageAlert.description}
              type="error"
              showIcon
            />
          </div>
        )}

        <Form.Item {...tailLayout} className="col-12 form-item mt-4">
          <div className="row f-right">
            <Button
              id="btn-cancel-new-agency"
              onClick={handleCancel}
              className="default-button margin-right-button"
            >
              {t("profile:sections.settings-account.modal.button-cancel")}
            </Button>
            <Button
              id="btn-send-new-agency"
              className="primary-button"
              type="primary"
              htmlType="submit"
            >
              {t("profile:sections.settings-account.modal.button-accept")}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default ModalPassword;
