import EndPointConfig from '../../models/api/endPointConfig';

const controller = 'Customizations';

/// Define all EndPoints for Manage News.
export const InitEP: EndPointConfig[] = [

    // Obtiene el listado de elementos iniciales de la parte pública de la web
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('landing')
        .setAlias('GetLandingInit')
];
