import EndPointConfig from '../../models/api/endPointConfig';

const controller = 'Bookings';

// Define all EndPoints for Top Ranking methods.
export const BookingsEP : EndPointConfig[] = [
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('filtered')
        .setAlias("GetBookingFiltered")
];
