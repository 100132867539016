import { LanguageStrings } from './base-strings';

// Inglés Americano
export const fr: LanguageStrings = {
  /** Routes */
  'routes.home': '/',
  'routes.Advantages': '/#avantages',
  'routes.Faqs': '/#questions-fréquentes',
  'routes.contact': '/contact',
  'routes.recoverPassword':'/récupérer-mot-de-passe',
  'routes.privacyPolicies': '/politiques-confidentialité',
  'routes.cookiesPolicies': '/politiques-cookies',
  'routes.generalConditions': '/conditions-générales',
  'routes.particularConditions': '/conditions-particulières',
  'routes.register': '/inscription',
  'routes.homePrivate':'/app',
  'routes.AdvantagesPrivate':'/app/avantages',
  'routes.FaqsPrivate':'/app/questions-fréquente',
  'routes.privacyPoliciesPrivate': '/app/politiques-confidentialité',
  'routes.cookiesPoliciesPrivate': '/app/politiques-cookies',
  'routes.generalConditionsPrivate': '/app/conditions-générales',
  'routes.particularConditionsPrivate': '/app/conditions-particulières',
  'routes.contactPrivate': '/app/contact',
  'routes.regularization': '/app/régularisations',
  'routes.remuneration': '/app/rétributions',
  'routes.balanceHistory': '/app/mouvements',
  'routes.profile': '/app/mon-profil',
  'routes.reward': '/app/récompenses',
  'routes.news': '/app/nouvelles',
  'routes.details': '/app/détail-nouvelle/:id',
  'routes.deleteAccount': '/compte-supprimé',
  'routes.validateAddNewAgency': '/nouvelle-agence/:id',
  'routes.knowUs': '/app/faites-notre-connaissance',
  'routes.legalAdvice' : '/avis-juridique',
  'routes.termsConditionsCashback' : '/app/termes-conditions-revolut',
  'routes.termsConditionsCashbackPrivate' : '/termes-conditions-revolut'
};
