import React, { useState, useContext, useEffect } from "react";
import "../profile.sass";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Alert } from "antd";
import * as AjaxAPI from "../../../api/ajaxApi";
import { showNotification } from "../../../utils/notificationsGeneric";
import { UserContext } from "../../../hooks/context/userInfo";
import { useUpdatePassword } from "src/api/api";

// Componente de la sección de Cambiar contraseña
const ChangePass = (param) => {
  const userContext = useContext(UserContext);
  let userToken = userContext.GetUserToken();

  const updatePassword = useUpdatePassword();
  // Estado para mostrar u ocultar alert warning
  const [visibleAlertError, setVisibleAlertError] = useState(false);
  // Estado para los mensajes a mostrar en los alert
  const [messageAlert, setMessageAlert] = useState({
    message: "",
    description: "",
  });

  const [form] = Form.useForm();

  const { t } = useTranslation(["profile"]);

  // Submit boton
  const onFinish = async (values) => {
    if (values["new-pass"] === values["repeat-new-pass"]) {
      const res = await updatePassword({
        newPassword: values["new-pass"],
        oldPassword: values["current-pass"],
      });
      if (res.data) {
        param.setChangeData(false);
        param.setDisabledBtn(true);

        showNotification("success", res.title, res.message, "topRight");
      } else {
        setVisibleAlertError(true);
        showNotification("error", res.title, res.message, "topRight");
      }
    }
  };

  const onChangeData = () => {
    param.setChangeData(true);
  };

  const handleInputChange = () => {
    param.setDisabledBtn(false);
  };

  // Initial methods
  useEffect(() => {
    if (!param.changeData) {
      form.resetFields();
    }
  }, [param.changeData]);

  return (
    <div className="row">
      <div className="col-lg-2 p-0"></div>
      <div className="col-lg-8 col-12 p-0">
        <Form
          name="form-change-pass"
          className="row form-change-pass"
          onFinish={onFinish}
          onChange={onChangeData}
          form={form}
          layout="vertical"
          id="form-change-pass"
        >
          <Form.Item
            className="col-12 form-item-profile mb-4"
            label={t("sections.change-pass.current-pass")}
            name="current-pass"
            rules={[
              {
                required: true,
                message: t("validations.password"),
              },
              {
                whitespace: true,
                message: t("validations.password-min-max-whitespace"),
              },
            ]}
          >
            <Input.Password
              className="main-input"
              placeholder=""
              id="current-pass"
              onChange={handleInputChange}
            />
          </Form.Item>

          {visibleAlertError && (
            <div className="alert-message panel-to-up">
              <Alert
                message={messageAlert.message}
                description={messageAlert.description}
                type="error"
                showIcon
              />
            </div>
          )}

          <Form.Item
            className="col-12 form-item-profile mb-4"
            label={t("sections.change-pass.new-pass")}
            name="new-pass"
            rules={[
              {
                required: true,
                message: t("validations.password"),
              },
              {
                max: 25,
                message: t("validations.password-min-max-whitespace"),
              },
              {
                min: 8,
                message: t("validations.password-min-max-whitespace"),
              },
              {
                whitespace: true,
                message: t("validations.password-min-max-whitespace"),
              },
            ]}
          >
            <Input.Password
              className="main-input"
              placeholder=""
              id="new-pass"
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item
            className="col-12 form-item-profile mb-4"
            label={t("sections.change-pass.repeat-new-pass")}
            name="repeat-new-pass"
            rules={[
              {
                required: true,
                message: t("validations.password"),
              },
              // The password and repeatPassword fields must be identical
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue("new-pass") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(t("validations.password-repeat"));
                },
              }),
            ]}
          >
            <Input.Password
              className="main-input"
              placeholder=""
              id="repeat-new-pass"
              onChange={handleInputChange}
            />
          </Form.Item>
          <Form.Item className="col-12 form-item mt-3">
            <div className="row f-right">
              <Button
                id="btn-send-new-agency"
                className="primary-button"
                type="primary"
                htmlType="submit"
                disabled={param.disabledBtn}
              >
                {t("btn.save-changes")}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
      <div className="col-lg-2 p-0"></div>
    </div>
  );
};

export default ChangePass;
