// Referencia de los nombre de rutas que usaremos para dinamizar el enrutador
export enum AppRoute {
    Home = 'routes.home',
    Advantages = 'routes.Advantages',
    Faqs = 'routes.Faqs',
    Contact = 'routes.contact',
    RecoverPassword = 'routes.recoverPassword',
    PrivacyPolicies = 'routes.privacyPolicies',
    CookiesPoliciesR = 'routes.cookiesPolicies',
    GeneralConditionsR = 'routes.generalConditions',
    ParticularConditionsR = 'routes.particularConditions',
    Register = 'routes.register',
    HomePrivate = 'routes.homePrivate',
    AdvantagesPrivate = 'routes.AdvantagesPrivate',
    FaqsPrivate = 'routes.FaqsPrivate',
    KnowUs = 'routes.knowUs',
    PrivacyPoliciesPrivate = 'routes.privacyPoliciesPrivate',
    CookiesPoliciesRPrivate = 'routes.cookiesPoliciesPrivate',
    GeneralConditionsRPrivate = 'routes.generalConditionsPrivate',
    ParticularConditionsRPrivate = 'routes.particularConditionsPrivate',
    ContactPrivate = 'routes.contactPrivate',
    Regularizations = 'routes.regularization',
    Remuneration = 'routes.remuneration',
    BalanceHistory = 'routes.balanceHistory',
    Reward = 'routes.reward',
    Profile = 'routes.profile',
    News = 'routes.news',
    Details = 'routes.details',
    DeleteAccount = 'routes.deleteAccount',
    ValidateAddNewAgency = 'routes.validateAddNewAgency',
    LegalAdvice = 'routes.legalAdvice',
    TermsConditionsCashback = 'routes.termsConditionsCashback',
    TermsConditionsCashbackPrivate = 'routes.termsConditionsCashbackPrivate'
}
