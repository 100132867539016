// Conversor de cadena a formato numérico (decimal)
export const convertStringToNumber = (values) => {
    let value
 
    if (values.indexOf(',') === -1) {
        //No commas found, treat as float
        value = values 
    } else if (values.indexOf('.') === -1) {
        value = values.replace(/\,/g, '.');
    } else {
        value = ((values.indexOf(',') < values.indexOf('.')) ? (values.replace(/\,/g,'')) : (values.replace(/\./g,'').replace(',', '.')));
    }

    return parseFloat(value);
}
