import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Button, Result } from "antd";

import './ErrorBoundary.sass'

declare const __DEV__: boolean;

interface MyProps {
  children: React.ReactNode;
}
interface MyState {
  hasError: boolean;
  details?: any;
}
class ErrorBoundary extends React.Component<MyProps, MyState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static propTypes = {
    children: PropTypes.node,
  };

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      details: error,
    };
  }
  componentDidUpdate() {
    if (this.state.details && this.state.details.status === 401) {
      this.handleReload()
    }
  }
  handleReload = () => {
    document.cookie = "userToken= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    window.setTimeout(() => window.location.reload(), 0);
  };

  render() {
    if (this.state.hasError && this.state.details && this.state.details.status !== 401) {
      return (
        <div id="error-boundary">
          <Result
            status="500"
            title="500"
            subTitle={<FormattedMessage id="error.title" />}
            // extra={
            //   <Button onClick={this.handleReload} type="primary">Back</Button>
            // }
          />
          {__DEV__ && this.state.details && (
            <h2 className="error-dev">{this.state.details.toString()}</h2>
          )}
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
