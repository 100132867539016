import * as React from "react";
import "./init.sass";
import { MediaType } from "../../constants/enums";
import YouTube, { Options } from "react-youtube";
import styled from "styled-components";
import { HomeLandingResource } from "./interfaceInit";

function youtube_parser(url) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}

// Subcomponente de Init que recoge la parte multimedia a mostrar
const Multimedia = (param: HomeLandingResource) => {
  // Uso de elemento con styled-component para dinamizar la url de la imagen que se pone en la parte multimedia
  const InfoMedia = () => {
    if (
      typeof param !== "undefined" &&
      param.resourceType === MediaType.Image
    ) {
      const ImgInit = styled.div`
        background-image: url(${param.resourceLink}) !important;
        background-position: center !important;
        background-repeat: no-repeat !important;
        background-size: cover !important;
        width: 100%;
        box-sizing: border-box;
        -webkit-box-flex: 1;
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        display: -webkit-box;
        height: 100%;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        z-index: 1;
      `;

      return <ImgInit />;
    } else if (typeof param !== "undefined") {
      const opts: Options = {
        height: "315",
        width: "560",
        playerVars: {
          autoplay: 0,
          controls: 1,
          showinfo: 0,
          rel: 0,
        },
      };
      const id = youtube_parser(param.resourceLink);
      return (
        <YouTube videoId={id} opts={opts} containerClassName="visual-video" />
      );
    } else {
      return <></>;
    }
  };

  return <InfoMedia />;
};

export default Multimedia;
