import * as React from "react";
import { useContext, useState } from "react";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { linkActiveContext } from "../../../hooks/context/linkActive";
import InfiniteScroll from "react-infinite-scroll-component";
import { Skeleton, Card, Typography, Empty } from "antd";
import Meta from "antd/lib/card/Meta";
import "./listNews.sass";
import { Link } from "react-router-dom";
import { useNewsScroll } from "src/api/api";

const { Title, Paragraph } = Typography;

// Componente con la lista de noticias restantes que no son destacadas
const ListNews = (param) => {
  const { t } = useTranslation(["urls", "news"]);
  const [newsList, setNewsList] = useState(param.newsListShow);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const linkActive = useContext(linkActiveContext);

  const getMoreNews = useNewsScroll();

  // Funcion que obtiene mas noticias según vamos haciendo scrolldown
  const fetchMoreData = async () => {
    if (newsList.length < 10) {
      setHasMore(false);
      return;
    }
    const newNews = await getMoreNews(currentPage + 1);
    if (newNews.data.news.length < 10) {
      setHasMore(false);
    }
    setCurrentPage(currentPage + 1);
    setNewsList(newsList.concat(newNews.data.news));
  };
  if (param.newsListShow.length === 0) {
    return (
      <>
        <div className="mt-3 news-container">
          <Title level={4}>{t("news:title2")}</Title>
          <Empty description={"No News"} />
        </div>
      </>
    );
  }
  return (
    <>
      <div className="mt-3 news-container">
        <Title level={4}>{t("news:title2")}</Title>
        <InfiniteScroll
          dataLength={newsList.length}
          style={{ overflow: "unset" }}
          next={fetchMoreData}
          hasMore={hasMore}
          loader={<Skeleton active />}
        >
          <div className="display-feature-new-list row">
            {newsList.map((element, index) => (
              <div
                className={
                  (index % 3 === 0
                    ? "pr-md-2 pl-0 pr-0 "
                    : index % 3 === 1
                    ? "pl-0 pr-0 pl-md-1 pr-md-1 "
                    : "pl-md-2 pl-0 pr-0 ") + "mt-4 col-md-4 col-12 width-boxes-more-news"
                }
                key={index}
              >
                <Card className="p-0 padding-card-news" hoverable>
                  <Meta
                    description={
                      <Link
                        id={element.id}
                        className="p-0"
                        to={t("urls:private.details") + "/" + `${element.id}`}
                        onClick={() => linkActive.updateValueUrl(t("urls:private.news"))}
                      >
                        <div className="col-12 p-0 display-element row">
                          <div className="col-md-12 col-6 p-0 crop">
                            <img className="img-list" title={element.newsContents[0].title} src={element.image} />
                          </div>
                          <div className="p-0 col-md-12 col-6 box-text-list">
                            <Paragraph ellipsis={{ rows: 5, expandable: true }} className="p-3 text-list-news">
                              {element.newsContents[0].title}
                            </Paragraph>
                          </div>
                        </div>
                      </Link>
                    }
                  />
                </Card>
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
};

export default ListNews;
