import EndPointConfig from "src/models/api/endPointConfig";

const controller = 'Locations';

/// Define all EndPoints for Client Data.
export const LocationEP : EndPointConfig[] = [
        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('getCountries')
        .setActionName("getCountries"),
        
];

