import React, { Suspense } from "react";
import { useContext, useState } from "react";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { Typography, List, Skeleton, Button } from "antd";
import "../featuredNews/featuredNews.sass";
import { Link } from "react-router-dom";
import { linkActiveContext } from "../../../hooks/context/linkActive";
import NotResults from "../../noResultGeneric/notResults";

import { useNewsFeatured } from "src/api/api";

const { Paragraph } = Typography;

// Componente widget de noticias destacadas para el Dashboard (sección privada)
const NewsWidget = () => {
  const { t } = useTranslation(["urls", "news"]);

  const linkActive = useContext(linkActiveContext);
  const [messageApi, setMessageApi] = useState("");

  let newsListFeatured: any[] = useNewsFeatured(1, 2).news;
  if (!newsListFeatured) {
    newsListFeatured = [];
  }
  return (
    <>
      {newsListFeatured.length <= 0 ? (
        <NotResults section="news" messageApi={messageApi} />
      ) : (
        <>
          <div className="mt-3 news-container">
            <List
              itemLayout="vertical"
              size="large"
              dataSource={newsListFeatured}
              renderItem={(item, i) => (
                <List.Item className="pl-0 pr-0 line-down">
                  <List.Item.Meta
                    key={item.id}
                    description={
                      <Link id={item.id} to={t("urls:private.details") + "/" + `${item.id}`}>
                        {i % 3 === 0 ? (
                          <div className="col-12 p-0 display-feature-new row">
                            <div className="col-md-6 col-12  p-0 crop">
                              <img
                                className="img-featured img-radius-left"
                                title={item.newsContents[0].title}
                                src={item.image}
                              />
                            </div>
                            <div className="col-md-6 col-12">
                              <Paragraph ellipsis={{ rows: 4, expandable: true }} className="padding-text-title-news">
                                {item.newsContents[0].title}
                              </Paragraph>
                            </div>
                          </div>
                        ) : (
                          <div className="col-12 p-0 display-feature-new row">
                            <div className="col-md-6 col-12 order-12 order-md-1 heigth-box-text">
                              <Paragraph ellipsis={{ rows: 4, expandable: true }} className="padding-text-title-news">
                                {item.newsContents[0].title}
                              </Paragraph>
                            </div>
                            <div className="col-md-6 col-12  p-0 crop order-1 order-md-12">
                              <img
                                className="img-featured img-radius-rigth"
                                title={item.newsContents[0].title}
                                src={item.image}
                              />
                            </div>
                          </div>
                        )}
                      </Link>
                    }
                  />
                </List.Item>
              )}
            />
            <div className="col-lg-4 col-sm-6 col-10 p-md-1 mt-lg-3 mt-2 mb-lg-2 mb-3 margin-button-home">
              <Link
                id="link-reward"
                to={t("urls:private.news")}
                onClick={() => linkActive.updateValueUrl(t("urls:private.news"))}
              >
                <Button id="btn-reward" className="col-12 default-button-transparent margin-button-home" size="large">
                  {t("news:button-home")}
                </Button>
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
};

const NewsWidgetWrapper = () => (
  <Suspense fallback={<Skeleton />}>
    <NewsWidget />
  </Suspense>
);

export default NewsWidgetWrapper;
