import React, { useEffect, useState } from "react";
import { Tabs, Modal, Button } from "antd";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserEdit,
  faLock,
  faHouseUser,
  faCog,
  faGlobe,
  faMoneyCheck,
} from "@fortawesome/free-solid-svg-icons";
import EditProfile from "./tabs-content/editProfile";
import ChangePass from "./tabs-content/changePass";
import PersonalInfo from "./tabs-content/personalInfo";
import BankAccountData from "./tabs-content/bankAccountData";
import SettingsAccount from "./tabs-content/settingsAccount";
import DataAgency from "./tabs-content/dataAgency";
import { UserType } from "src/models/user/userModel";

const { TabPane } = Tabs;

// Componente de las pestañas personalizadas
const TabsResults = (param) => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [eventClick, setEventClick] = useState(null);
  const [changeData, setChangeData] = useState(false);
  const [disabledBtn, setDisabledBtn] = useState(true);

  const { t } = useTranslation(["profile"]);

  // Div event
  const changeDivTab = (event) => {
    if (changeData) {
      setVisibleModal(true);
      setEventClick(event.target.id);
      event.stopPropagation();
    }
  };

  // Modal events
  const handleStay = () => {
    setVisibleModal(false);
  };

  const handleNoSave = () => {
    const elem = document.getElementById("rc-tabs-0-tab-" + eventClick);
    elem.click();

    setDisabledBtn(true);
    setChangeData(false);
    setVisibleModal(false);
  };

  useEffect(()=>{
  },[param])

  return (
    <>
      <Tabs defaultActiveKey="1" className="profile-tabs" centered={true}>
        <TabPane
          key="1"
          id="tab-1"
          className="tab-height"
          tab={
            <div id="1" className="content-tab-list" onClick={changeDivTab}>
              <FontAwesomeIcon className="edit-icon-input" icon={faUserEdit} />
              {t("profile:tabs.edit-profile")}
            </div>
          }
        >
          <EditProfile
            userInfo={param.userInfo}
            setChangeData={setChangeData}
            changeData={changeData}
            setDisabledBtn={setDisabledBtn}
            disabledBtn={disabledBtn}
          />
        </TabPane>
        <TabPane
          key="2"
          id="tab-2"
          className="tab-height"
          tab={
            <div id="2" className="content-tab-list" onClick={changeDivTab}>
              <FontAwesomeIcon className="edit-icon-input" icon={faLock} />
              {t("profile:tabs.change-pass")}
            </div>
          }
        >
          <ChangePass
            userInfo={param.userInfo}
            setChangeData={setChangeData}
            changeData={changeData}
            setDisabledBtn={setDisabledBtn}
            disabledBtn={disabledBtn}
          />
        </TabPane>
        <TabPane
          key="3"
          id="tab-3"
          className="tab-height"
          tab={
            <div id="3" className="content-tab-list" onClick={changeDivTab}>
              <FontAwesomeIcon className="edit-icon-input" icon={faHouseUser} />
              {t("profile:tabs.personal-info")}
            </div>
          }
        >
          <PersonalInfo
            userInfo={param.userInfo}
            setChangeData={setChangeData}
            changeData={changeData}
            setDisabledBtn={setDisabledBtn}
            disabledBtn={disabledBtn}
          />
        </TabPane>
        <TabPane
          key="4"
          id="tab-4"
          className="tab-height"
          tab={
            <div id="4" className="content-tab-list" onClick={changeDivTab}>
              <FontAwesomeIcon className="edit-icon-input" icon={faMoneyCheck} /> 
              {t("profile:tabs.bank-account-data")}
            </div>
          }         
        >
          <BankAccountData
            userInfo={param.userInfo}
            setChangeData={setChangeData}
            changeData={changeData}
            setDisabledBtn={setDisabledBtn}
            disabledBtn={disabledBtn}
          />
        </TabPane>
        <TabPane
          key="5"
          id="tab-5"
          className="tab-height"
          tab={
            <div id="5" className="content-tab-list" onClick={changeDivTab}>
              <FontAwesomeIcon className="edit-icon-input" icon={faCog} />
              {t("profile:tabs.account-settings")}
            </div>
          }
        >
          <SettingsAccount
            userInfo={param.userInfo}
            setChangeData={setChangeData}
            changeData={changeData}
            setDisabledBtn={setDisabledBtn}
            disabledBtn={disabledBtn}
          />
        </TabPane>


        {
          param.userInfo.GetAgentType() == UserType.AgentIndependent ? '' : 
          <TabPane
            key="6"
            id="tab-6"
            className="tab-height"
            tab={
              <div id="6" className="content-tab-list" onClick={changeDivTab}>
                <FontAwesomeIcon className="edit-icon-input" icon={faGlobe} />
                {t("profile:tabs.agency-info")}
              </div>
            }
          >
            <DataAgency userInfo={param.userInfo} />
          </TabPane>
        }
        
      </Tabs>

      {visibleModal && (
        <Modal
          title={t("profile:modal.title")}
          visible={visibleModal}
          onCancel={handleStay}
          footer={null}
          centered
          maskClosable={false}
          key="1"
        >
          <div className="col-12 p-0 mb-3">
            {t("profile:modal.description")}
          </div>
          <div className="col-12 p-0" style={{ height: 25 }}>
            <Button
              id="btn-send-new-agency"
              className="primary-button f-right pl-3"
              onClick={handleStay}
              type="primary"
              htmlType="submit"
            >
              {t("profile:modal.stay")}
            </Button>
            <Button
              id="btn-cancel-new-agency"
              onClick={handleNoSave}
              className="default-button margin-right-button f-right"
            >
              {t("profile:modal.no-save")}
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default TabsResults;
