import * as React from "react";
import { useContext, useEffect, useState } from "react";
import "./remunerationInfo.sass";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../../hooks/context/userInfo";
import { Typography } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import { FormattedNumber } from "react-intl";
import { useConfigDirect, useLimitsAmount } from "src/api/api";
import ReactHtmlParser from "react-html-parser";

import {
  WarningOutlined
} from '@ant-design/icons';

const { Paragraph } = Typography;

// Componenete info con el incremento por venta directa del agente
const RemunerationInfo = () => {
  const userContext = useContext(UserContext);
  const { t } = useTranslation(["remuneration"]);

  // Recoge los elementos para la gráfica
  const amount = useConfigDirect();
  const limits = useLimitsAmount();

  return (
    <>
      {
        limits?.length > 0 && limits?.some(l=>l.limitAmount > 0 && l.currencyId == userContext.currency) ?
          <div className="col-12 display-inf-box border-box-info" style={{marginBottom: "5px"}}>
          <div className="col-2 col-sm-1 col-lg-2 p-0 position-icon">
            <div className="vertical-center">
              <WarningOutlined className="icon-info icon-info"/>
            </div>
          </div>
          <div className="col-10 col-sm-11 col-lg-10 p-0 position-icon">
            <div className="vertical-center">
              <Paragraph className="margin-p-info">
                {
                  limits?.map(limit => {
                    if (limit.currencyId == userContext.currency){
                      return ReactHtmlParser(t("remuneration:limit-rewards-amount", { 0: limit.limitAmount, 1: limit.currencyId }))
                    }
                  })
                }
              </Paragraph>
            </div>
          </div>
        </div>
        :
        ''
      }
    </>
  );
};

export default RemunerationInfo;
