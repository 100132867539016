import * as React from "react";
import { useContext } from "react";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { Typography, List } from "antd";
import "./featuredNews.sass";
import news from "../../../models/news/news";
import { Link } from "react-router-dom";
import { linkActiveContext } from "../../../hooks/context/linkActive";

const { Title, Paragraph } = Typography;

// Componente de noticias destacadas
const FeaturedNews = (param) => {
  const { t } = useTranslation(["urls", "news"]);
  const totalData: any[] = param.newsListFeatured;
  const linkActive = useContext(linkActiveContext);

  return (
    <>
      <div className="mt-3 news-container">
        <Title level={4}>{t("news:title1")}</Title>
        <List
          itemLayout="vertical"
          size="large"
          dataSource={totalData}
          renderItem={(item, i) => (
            <List.Item className="pl-0 pr-0 line-down">
              <List.Item.Meta
                key={item.id}
                description={
                  <Link
                    id={item.id}
                    to={t("urls:private.details") + "/" + `${item.id}`}
                    onClick={() =>
                      linkActive.updateValueUrl(t("urls:private.news"))
                    }
                  >
                    {i % 3 === 0 ? (
                      <div className="col-12 p-0 display-feature-new row">
                        <div className="col-md-6 col-12  p-0 crop">
                          <img
                            className="img-featured img-radius-left"
                            title={item.newsContents[0].title}
                            src={item.image}
                          />
                        </div>
                        <div className="col-md-6 col-12">
                          <Paragraph
                            ellipsis={{ rows: 4, expandable: true }}
                            className="padding-text-title-news"
                          >
                            {item.newsContents[0].title}
                          </Paragraph>
                        </div>
                      </div>
                    ) : (
                      <div className="col-12 p-0 display-feature-new row">
                        <div className="col-md-6 col-12 order-12 order-md-1 heigth-box-text">
                          <Paragraph
                            ellipsis={{ rows: 4, expandable: true }}
                            className="padding-text-title-news"
                          >
                            {item.newsContents[0].title}
                          </Paragraph>
                        </div>
                        <div className="col-md-6 col-12  p-0 crop order-1 order-md-12">
                          <img
                            className="img-featured img-radius-rigth"
                            title={item.newsContents[0].title}
                            src={item.image}
                          />
                        </div>
                      </div>
                    )}
                  </Link>
                }
              />
            </List.Item>
          )}
        />
      </div>
    </>
  );
};

export default FeaturedNews;
