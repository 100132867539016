import * as React from 'react';
import { useContext, useState, } from 'react';
import { useTranslation } from 'react-i18next';
import MenuLanguages  from '../../../../languageSelector/comboLanguage';
import { Link } from 'react-router-dom';
import { ConfigContext } from '../../../../../hooks/context/config';
import '../header.sass';
import './desktopMenu.sass';
import ModalLogin from '../../../../modalsLogin/login/login';
import { LINKS_DESKTOP_PUBLIC } from '../../../../../constants/constants';
import { faUser, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { linkActiveContext } from '../../../../../hooks/context/linkActive';

// Menú visible para versiones escritorio (de MD para arriba)
const DesktopMenu = (params) => {

  // Libreria i18n para traducir
  const { t } = useTranslation(['urls', 'linksNames']);
  const linkActive = useContext(linkActiveContext);
  
  // Hook Context de la configuracion
  const theme = useContext(ConfigContext);

  // Estado para mostrar o ocultar modal cookie
  const [modalLogin, setmodalLogin] = useState(false);

  return (
    <>
      <div className="col-12 box-header">
        <div className="col-6 left-part-header padding-left-header">
          <div className="col-lg-3 p-0 links-row">
            <div className="align-center-links">
              <Link
                id="link-public-main-logo"
                to={ t("urls:public.home") }
                onClick={ () => linkActive.updateValueUrl(t("urls:public.home")) }
                disabled={ (window.location.pathname + window.location.hash) == t("urls:public.home") }
              >
                <img className="logo" src={ theme.logo } title={ theme.name } alt={ theme.name } />
              </Link>
            </div>
          </div>
          <div className="col-9 links-row">
            <div className="align-center-links">
              {
                LINKS_DESKTOP_PUBLIC.map((element, index) => { 
                  if (index < 3 ) {
                    return (
                      <Link
                          id={ "link-public-" + element + "-" + index }
                          key={ index }
                          to={ t("urls:public."+ element)}
                          className={ "padding-link-desktop-public width-link secondary-links" + ((window.location.pathname + window.location.hash) == t("urls:public."+ element) ? " active" : "") }
                          disabled={ (window.location.pathname + window.location.hash) == t("urls:public."+ element) }
                          onClick={ () => linkActive.updateValueUrl(t("urls:public."+ element)) }
                        >
                          { t("linksNames:" + element) }
                      </Link>
                    )  
                  }
                })
              }
            </div>
          </div>
        </div>
        <div className="col-6 right-part-header padding-right-header">
          <div className="col-12 p-0 links-row">
            <div className="align-center-links float-link">
              <div className="padding-link-desktop-public">
                <Link
                  id="link-public-contact"
                  className={ window.location.pathname == t("urls:public.contact")? "secondary-links active": "secondary-links"}
                  to={ t("urls:public.contact") }
                  onClick={ () => linkActive.updateValueUrl(t("urls:public.contact")) }
                  disabled={ (window.location.pathname + window.location.hash) == t("urls:public.contact") }
                >
                  <FontAwesomeIcon 
                    className="icons-color-menu icons-color-marging" 
                    icon={ faPhoneAlt } 
                  />
                </Link>
              </div>            
              <div className="secondary-links secondary-links-svg padding-link-desktop-public">
                <Link
                  id="link-public-access"
                  className="secondary-links"
                  to="#"
                  onClick={ () => setmodalLogin(true) }
                >
                  <FontAwesomeIcon 
                    className="icons-color-menu icons-color-marging" 
                    icon={ faUser }
                  /> 
                </Link>
              </div>
              <div className="padding-top-flag">
                <MenuLanguages 
                  key="1" 
                  onClose={ null }
                />
              </div>              
            </div>
          </div>
        </div>
      </div>
      <div className="aux-div-header"></div>
      <div>
        { modalLogin && <ModalLogin setmodalLogin={ setmodalLogin } /> }
      </div>
    </>
  );
}

export default DesktopMenu;
