import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import './faqs.sass';
import { Typography, Collapse } from 'antd';
import { ConfigContext } from '../../hooks/context/config';
import '../../i18n';
import { useTranslation } from 'react-i18next';
import { RestApiClientContext } from 'src/hooks/context/pageContext';
import Faqs from '../../models/faqs/Faqs'
import {
    LangContext,
    LangUrlContext,
  } from "../../hooks/context/lang";

const { Paragraph } = Typography;
const { Panel } = Collapse;

// Componente de la lista de preguntas frecuentes
const Faqs = () => {
    const { t } = useTranslation(['faqs', 'urls']);
    const languageUrl = useContext(LangUrlContext);
    const language = useContext(LangContext);

    const infoClient = useContext(ConfigContext);
    const restApiClient = useContext(RestApiClientContext)
    
    const [listFaqs, setListFaqs] = useState<JSX.Element[]>([]);

    const GetFaqs = (): void=>{
        restApiClient.getFaqs(language.lang || 'es-ES'). then((faqs:Faqs[])=>{
            if (!faqs || faqs.length == 0){
                setListFaqs([
                                <Panel 
                                    header={ t('faqs:questions.1') } 
                                    key="1"
                                >
                                    <Paragraph>{ t('faqs:answers.1', { 0: infoClient.description }) }</Paragraph>
                                </Panel>,
                                <Panel 
                                    header={ t('faqs:questions.2') } 
                                    key="2"
                                >
                                    <Paragraph>{ t('faqs:answers.2', { 0: infoClient.description }) }</Paragraph>
                                </Panel>,
                                <Panel 
                                    header={ t('faqs:questions.3') } 
                                    key="3"
                                >
                                    <Paragraph>{ t('faqs:answers.3') }</Paragraph>
                                </Panel>,
                                <Panel 
                                    header={ t('faqs:questions.4', { 0: infoClient.incentiveSiteName }) } 
                                    key="4"
                                >
                                    <Paragraph>{ t('faqs:answers.4') }</Paragraph>
                                </Panel>,
                                <Panel 
                                    header={ t('faqs:questions.5', { 0: infoClient.incentiveSiteName }) } 
                                    key="5"
                                >
                                    <Paragraph>{ t('faqs:answers.5') }</Paragraph>
                                </Panel>,
                                <Panel 
                                    header={ t('faqs:questions.6') } 
                                    key="6"
                                >
                                    <Paragraph>{ t('faqs:answers.6') }</Paragraph>
                                </Panel>,
                                <Panel 
                                    header={ t('faqs:questions.7') } 
                                    key="7"
                                >
                                    <Paragraph>{ t('faqs:answers.7') }</Paragraph>
                                </Panel>,
                                <Panel 
                                    header={ t('faqs:questions.8') } 
                                    key="8"
                                >
                                    <Paragraph>{ t('faqs:answers.8') }</Paragraph>
                                </Panel>,
                                <Panel 
                                    header={ t('faqs:questions.9') } 
                                    key="9"
                                >
                                    <Paragraph>{ t('faqs:answers.9') }</Paragraph>
                                </Panel>,
                                <Panel 
                                    header={ t('faqs:questions.10') } 
                                    key="10"
                                >
                                    <Paragraph>{ t('faqs:answers.10') }</Paragraph>
                                </Panel>,
                                <Panel 
                                    header={ t('faqs:questions.11') } 
                                    key="11"
                                >
                                    <Paragraph>{ t('faqs:answers.11') }</Paragraph>
                                </Panel>,
                                <Panel 
                                    header={ t('faqs:questions.12') } 
                                    key="12"
                                >
                                    <Paragraph>{ t('faqs:answers.12') }</Paragraph>
                                </Panel>
                            ]);
            }else{
                setListFaqs(showClientFaqs(faqs))
            }
        })
    }

    const showClientFaqs = (faqs: Faqs[]) : JSX.Element[] => {
        var result : JSX.Element[] = [];

        faqs.map((faq: Faqs, index: number) => {
            result.push(<Panel 
                                header={ faq.title } 
                                key={index}
                            >
                                <Paragraph>{ faq.description }</Paragraph>
                            </Panel>)
        })

        return result;
    }


    useEffect(()=>{
        GetFaqs()
    },[,language.lang])

    return (
        <div id={ t("urls:public.faqs#") } className="margin-bottom-8">
            <Paragraph className="title-align">{ t('faqs:main-title') }</Paragraph>

            <Collapse 
                className="faqs-list" 
                bordered={ false }
            >
                { listFaqs }
            </Collapse>
        </div>
    )
}

export default Faqs;
