import * as React from "react";
import { useState, useContext } from "react";
import { Form, Input, Button, Alert } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";
import { UserContext, LoginContext } from "../../../hooks/context/userInfo";
import { DURATION_COOKIE, LIST_COOKIES_DEFAULT } from "../../../constants/constants";
import { UserModelSendDataLogin, UserModelResponseDataLogin } from "../../../models/user/userModelSendDataLogin";
import { User } from "../../../models/user/userModel";
import { CookiePopUp } from "../../../hooks/context/cookies";
import { RestApiClientContext } from "../../../hooks/context/pageContext";
import { LangContext, LangUrlContext } from "../../../hooks/context/lang";
import { useGetUserProfile } from "src/api/api";

import "./formLogin.sass"

// Formulario modal login
const FormModal = (params) => {
  // Necesario para estructura del formulario
  const tailLayout = {
    wrapperCol: {
      span: 25,
    },
  };

  // Idioma para los textos
  const { t } = useTranslation("modalLogin");

  // Contexto de información de usuario y login
  const userContext = useContext(UserContext);
  const loginContext = useContext(LoginContext);
  const restApiClient = useContext(RestApiClientContext);
  const language = useContext(LangContext);
  const languageUrl = useContext(LangUrlContext);
  // Context para mostrar o ocultar el componente popup
  const popupCookies = useContext(CookiePopUp);

  // Estado para mostrar u ocultar alert warning
  const [visibleAlertwarning, setVisibleAlertwarning] = useState(false);
  // Estado para mostrar u ocultar alert error
  const [visibleAlertError, setVisibleAlertError] = useState(false);
  // Estado para los mensajes a mostrar en los alert
  const [messageAlert, setMessageAlert] = useState({
    message: "",
    description: "",
  });
  // Estado con la lista de cookies aceptadas por el sistema
  const [cookiesSave] = useState(LIST_COOKIES_DEFAULT);

  // Cookie comprobador si se aceptaron o no las cookies
  const [, setCookies] = useCookies(["cookies"]);
  // Lista de cookies que se pueden modificar en la aplicacion
  const [, setCookiesList] = useCookies(["cookiesList"]);
  // Cookie token usuario
  const [, setUserToken] = useCookies(["userToken"]);

  // Funcion Abrir modal contraseña
  const openModalPassword = () => {
    params.setVisible(false);
    params.setVisibleFormPassword(true);
  };

  const registerShow = () => {
    params.setVisible(false);
    params.setmodalLogin(false);
  };

  const getMyRankingPosition = (): number => {
    restApiClient.getRankingMyPosition().then((data) => {
      if (data) {
        return data;
      }
    });

    return 0;
  };

  const getUserProfile = useGetUserProfile();

  const getUserInfo = (token?: string) => {
    getUserProfile(token).then(({ data }) => {
      if (data) {
        const userContextInfo = {
          id: data.id,
          idAgentType: data.idAgentType,
          userName: data.userName,
          urlLanguage: data.language.shortId,
          language: data.language.id,
          currency: data.idCurrency,
          imageName: data.imageName,
          email: data.email,
          uaid: data.uaid,
          countryCode: data.idCountry
        } as User;

        

        userContext.UpdateUserContext(userContextInfo);

        // Ocultar el popupCookies al hacer login correctamente
        popupCookies.UpdateVisible(false);

        // Determina si el login ha ido correctamente, almacenando su valor booleano en el contexto
        loginContext.updateValue(true);
      } else {
        setVisibleAlertError(true);
        setMessageAlert({
          message: t("modalLogin:list-errors.title-data-errors"),
          description: t("modalLogin:list-errors.data-errors"),
        });
      }
    });
  };

  // Submit form
  const onFinish = async (values) => {
    const userData = {
      agentData: values.username,
      password: values.password,
      language: language.lang,
    } as UserModelSendDataLogin;

    restApiClient.loginAgent(userData).then((response: any) => {
      const data = response.data;
      if (data && data.token) {
        const date = Date.now() + DURATION_COOKIE;

        // Almacenamos el token en sessionStorage y cookies
        sessionStorage.setItem("token", data.token);
        setUserToken("userToken", data.token, {
          path: "/",
          expires: new Date(date),
        });

        // Determina si el login ha ido correctamente, almacenando sus valores en cookies
        setCookies("cookies", "true", {
          path: "/",
          expires: new Date(date),
        });

        setCookiesList("cookiesList", cookiesSave, {
          path: "/",
          expires: new Date(date),
        });

        // Obtenemos info del usuario
        getUserInfo(data.token);
      } else {
        setVisibleAlertError(true);
        setMessageAlert({
          message: response.title || t("modalLogin:list-errors.title-data-errors"),
          description: response.message,
        });
      }
    });
  };

  return (
    <Form className="form-login" name="basic" initialValues={{ remember: true }} onFinish={onFinish}>
      <Form.Item
        label={t("modalLogin:first-data")}
        name="username"
        className="row-form-item"
        rules={[
          {
            required: true,
            message: t("modalLogin:list-errors.error-user-empty"),
          },
          {
            whitespace: true,
            message: t("modalLogin:list-errors.error-user-empty"),
          },
          {
            max: 50,
            message: t("modalLogin:list-errors.max-number-username"),
          },
        ]}
      >
        <Input className="main-input" id="name" />
      </Form.Item>
      <Form.Item
        label={t("modalLogin:second-data")}
        className="row-form-item"
        name="password"
        rules={[
          {
            required: true,
            message: t("modalLogin:list-errors.error-password-empty"),
          },
          {
            max: 25,
            message: t("modalLogin:list-errors.max-number-pass"),
          },
        ]}
      >
        <Input.Password className="main-input" id="password" />
      </Form.Item>
      <div className="align-text-link-right link-password">
        <span onClick={openModalPassword}> {t("modalLogin:link-forgotten-password")} </span>
      </div>
      {visibleAlertwarning && (
        <div className="alert-message">
          <Alert message={messageAlert.message} description={messageAlert.description} type="warning" showIcon />
        </div>
      )}
      {visibleAlertError && (
        <div className="alert-message">
          <Alert message={messageAlert.message} description={messageAlert.description} type="error" showIcon />
        </div>
      )}
      <Form.Item {...tailLayout}>
        <Button id="btn-form-login" className="col-12 primary-button" type="primary" size="large" htmlType="submit">
          {t("modalLogin:first-button")}
        </Button>
      </Form.Item>
      <div className="align-text-link">
        <Link className="primary-links align-text-link" onClick={registerShow} to={t("urls:public.register")}>
          {t("modalLogin:link-register")}
        </Link>
      </div>
    </Form>
  );
};

export default FormModal;
