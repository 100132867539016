import * as React from 'react';
import { useContext, useState } from 'react';
import { Button} from 'antd';
import { useTranslation } from 'react-i18next';
import './pop-up-cookies.sass';
import '../modalCookies/modal-cookies.sass';
import { CookiePopUp } from '../../../../hooks/context/cookies';
import { useCookies } from 'react-cookie';
import ModalCookies from '../modalCookies/modalCookies';
import { LIST_COOKIES_DEFAULT, DURATION_COOKIE } from '../../../../constants/constants';
import { Link } from 'react-router-dom';
import { linkActiveContext } from '../../../../hooks/context/linkActive';

// Componente popup de informacion de las cookies
const PopUpCookies = () => {
    
    // Lenguaje para los textos
    const { t } = useTranslation(['urls', 'cookies']);

    // Context para mostrar o ocultar el componente popup
    const popupCookies = useContext(CookiePopUp);
    
    // Estado para mostrar o ocultar modal cookie
    const [modalCookie, setmodalCookie] = useState(false);
    // Estado con la lista de cookies aceptadas por el sistema
    const [cookiesSave] = useState(LIST_COOKIES_DEFAULT);

    // Cookie comprobador si se aceptaron o no las cookies
    const [, setCookies] = useCookies(['cookies']);
    // Lista de cookies que se pueden modificar en la aplicacion
    const [, setCookiesList] = useCookies(['cookiesList']);

    // Manejador del boton aceptar del popup
    const validateCookies = () => {
        var date = Date.now() + DURATION_COOKIE;
        setCookies('cookies', "true", { path: '/', expires: new Date(date)}); 
        setCookiesList('cookiesList', cookiesSave, { path: '/', expires: new Date(date)}); 
        popupCookies.UpdateVisible(false);
    }

    const linkActive = useContext(linkActiveContext);

    return (
        <>
            { popupCookies.GetVisible() && 
                <div className="col-lg-12 container-cookies">
                    <div className="col-lg-8 col-md-9 col-sm-8 col-12 max-height-pop-up">
                        <p className="title-popup-switch">{ t("cookies:pop-up.title") }</p>
                        <p>
                            { t("cookies:pop-up.text") } 
                            <Link 
                                id="link-cookies-policy"
                                className="primary-links" 
                                to= { t("urls:public.cookies-policies") }
                                onClick={ () => linkActive.updateValueUrl( t("urls:public.cookies-policies") ) }
                            > 
                                { t("cookies:pop-up.link") }
                            </Link>
                        </p>
                    </div>
                    <div className="col-lg-4 col-md-3 col-sm-4 col-12 align-vertical">
                        <div className="col-sm-6 col-12 align-button">
                            <Button 
                                id="btn-cookie-settings"
                                className="width-button default-button-dark" 
                                onClick={ () => setmodalCookie(true) }
                            >
                                { t("cookies:pop-up.button-settings") }
                            </Button>
                        </div>
                        <div className="col-sm-6 col-12 align-button">
                            <Button 
                                id="btn-cookie-accept"
                                className="width-button primary-button" 
                                type="primary" 
                                onClick={ validateCookies }
                            >
                                { t("cookies:pop-up.button-accept") }
                            </Button>
                        </div>                
                    </div>
                    <div>
                        { modalCookie && <ModalCookies setmodalCookie={setmodalCookie} /> }  
                    </div>            
                </div>
            }  
        </>
    );      
}

export default PopUpCookies;
