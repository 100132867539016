import React, { useState } from "react";
import { Typography, List } from "antd";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faRetweet } from "@fortawesome/free-solid-svg-icons";
import CustomPagination from "../../utils/pagination/pagination";
import Pagination from "../../../models/pagination/pagination";
import { BALANCE_GIFT_CARD } from "../../../constants/constants";
import { getLocaleDatePicker } from "../../../utils/datePicker";
import { PickerLocale } from "antd/es/date-picker/generatePicker";
import moment from "moment";
import { FormattedNumber } from "react-intl";

const { Text, Paragraph } = Typography;

// Componente que devuelve un List de la lista de movimientos
const ListResults = (param) => {
  const totalData: any[] = param.rows.balanceMovements;

  const { t } = useTranslation(["balanceHistory"]);

  const local: PickerLocale = getLocaleDatePicker();

  // Función que se dispara en el evento de cambiar el elemento de paginación de la lista
  const onChangeCallApi = (pagination) => {
    const pag = new Pagination(pagination.current, pagination.pageSize);

    // Usuario pulsa página
    param.setPageResults(pag);
  };

  return (
    <>
      <List
        itemLayout="vertical"
        size="large"
        dataSource={totalData}
        renderItem={(item) => (
          <List.Item key={item.key} className="list-panel-content">
            <List.Item.Meta
              avatar={
                <div>
                  <FontAwesomeIcon
                    className="edit-icon-input gray-faint-color"
                    icon={item.type === BALANCE_GIFT_CARD ? faCoins : faRetweet}
                  />
                </div>
              }
              title={
                <div className="row">
                  <div className="col-12 reservation-text-date p-0">{moment(item.date).format(local.dateFormat)}</div>
                </div>
              }
              description={
                <div className="row">
                  <div className="col-8 p-0">
                    <Paragraph>
                      <Text className="gray-faint-color">
                        {item.type === BALANCE_GIFT_CARD ? t("body.redeemed-reward") : t("body.validated-reservation")}
                      </Text>
                    </Paragraph>
                    <Paragraph>
                      <strong>
                        {item.description} <Text className="custom-color-value">{item.percentage}</Text>
                      </strong>
                    </Paragraph>
                    <Paragraph className="gray-faint-color">
                      {item.amount && (
                        <>
                          <Text>{t("body.value")}</Text>
                          <FormattedNumber
                            useGrouping={true}
                            value={item.amount}
                            style="currency"
                            currency={item.currency}
                          />
                        </>
                      )}
                    </Paragraph>
                  </div>
                  <div className="col-4 p-0 text-right">
                    <Paragraph
                      className={
                        "margin-line-info " +
                        (item.amountPostPercentage <= 0 ? "negative-color-value" : "custom-color-value")
                      }
                    >
                      {item.amountPostPercentage <= 0 ? "" : "+ "}
                      <FormattedNumber
                        useGrouping={true}
                        value={item.amountPostPercentage}
                        style="currency"
                        currency={item.currency}
                      />
                    </Paragraph>
                    <Paragraph className="gray-faint-color">
                      <FormattedNumber
                        useGrouping={true}
                        value={item.totalAmount}
                        style="currency"
                        currency={item.currency}
                      />
                    </Paragraph>
                  </div>
                </div>
              }
            />
          </List.Item>
        )}
      />

      <div className="pagination-container col-12 p-0 mt-4">
        <CustomPagination
          data-testid="pagination-list-reservations"
          countData={param.rows.totalRecord}
          callApiChange={onChangeCallApi}
          pageResults={param.pageResults}
        />
      </div>
    </>
  );
};

export default ListResults;
