import * as React from "react";
import AlternateComponent from "../../utils/alternateComponent/alternateComponent";
import RemunerationGraphic from "../remunerationGraphic/remunerationGraphic";
import RemunerationTable from "../remunerationTable/remunerationTable";
import RemunerationList from "../remunerationList/remunerationList";

// Componente intermedio de las remuneraciones para determinar el componente hijo a mostrar en función de la resolución del dispositivo
const RemunerationResult = (param) => {
  const colors = ["#95b8f6", "#add5fa", "#f9d99a", "#f9a59a", "#fa5f49", "#d1052a", "#888a8a", "#acf7c3"];

  // Modulamos la info para la versión de escritorio
  const desktopVersion = (param) => {
    return (
      <>
        <RemunerationGraphic colors={colors} result={param} />
        <RemunerationTable colors={colors} result={param} />
      </>
    );
  };

  // Modulamos la info para la versión mediana (tablet)
  const tabletVersion = (param) => {
    return mobileVersion(param);
  };

  // Modulamos la info para la versión móvil
  const mobileVersion = (param) => {
    return <RemunerationList colors={colors} result={param} />;
  };

  return (
    // Condicional para cargar un componente u otro
    <AlternateComponent
      desktopVersionLarge={desktopVersion(param.result)}
      tabletVersion={tabletVersion(param.result)}
      mobileVersionLarge={mobileVersion(param.result)}
    />
  );
};

export default RemunerationResult;
