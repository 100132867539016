import React, { useState, useEffect, useContext } from "react";
import { Button, Select, Form, DatePicker } from "antd";
import "../../../../i18n";
import { useTranslation } from "react-i18next";
import { getLocaleDatePicker } from "../../../../utils/datePicker";
import { PickerLocale } from "antd/es/date-picker/generatePicker";
import RewardSearch from "../../../../models/rewardModel/rewardSearch";
import "./searchReward.sass";

const { Option } = Select;
const { RangePicker } = DatePicker;

// Componente de búsqueda de cheque canjeado del reward
const SearchReward = (param) => {
  // Formulario de búsqueda a usar
  const [form] = Form.useForm();

  // Textos - traducciones
  const { t } = useTranslation(["reward"]);

  // Local para el datePicker
  const local: PickerLocale = getLocaleDatePicker();

  // Limpiamos el formulario en el caso de que ocurra un error
  if (param.clearForm) {
    form.resetFields();
    param.setClearForm(false);
  }

  // Función que se dispara en el evento de ejecutar el formulario
  const onFinish = (values: any) => {
    const reg = new RewardSearch();

    if(values.date){
        let dateChecking = new Date(values.date[0]._d)
        const offset = dateChecking.getTimezoneOffset()
        dateChecking = new Date(dateChecking.getTime() - (offset*60*1000))
        let dateOut = new Date(values.date[1]._d)
        const offsetOut = dateOut.getTimezoneOffset()
        dateOut = new Date(dateOut.getTime() - (offsetOut*60*1000))
    
        reg.dateChecking = dateChecking.toISOString().split('T')[0];
        reg.dateOut = dateOut.toISOString().split('T')[0];
    }
    reg.nameId = values.nameCheckForm !== 0 ? values.nameCheckForm : undefined;

    // Almacena los criterios de búsqueda en el estado para su búsqueda
    param.setSearchHeaderResults(reg);
  };

  return (
    <Form
      form={form}
      layout="vertical"
      id="search-reservations"
      onFinish={onFinish}
      className="row form-search-reservations"
      initialValues={{
        nameCheckForm: 0,
      }}
    >
      <div className="col-12 col-sm-12 col-md-5 p-form-elements-reward">
        <Form.Item
          className="form-item main-select"
          label={t("table.form-search.labels.date")}
          name="date"
        >
          <RangePicker
            className="main-select reservations-dates"
            data-testid="rp-reservation-dates"
            locale={local}
            placeholder={[local.lang.dateFormat, local.lang.dateFormat]}
            style={{ width: "100%" }}
            format={local.dateFormat}
          />
        </Form.Item>
      </div>
      <div className="col-12 col-sm-12 col-md-4 p-form-elements-reward">
        <Form.Item
          name="nameCheckForm"
          label={t("table.form-search.labels.name")}
        >
          <Select
            id="name-check-form"
            data-testid="select-state"
            style={{ width: "100%" }}
            className="main-select"
          >
            <Option id={"check-0"} key="0" value={0}>
              {t("table.form-search.select-init")}
            </Option>
            {param.filtersList.map((v, i) => {
              return (
                <Option id={"check-" + v.id} key={v.id} value={v.id}>
                  {v.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      </div>
      <div className="col-12 col-sm-12 col-md-3 p-form-elements-reward-end">
        <Form.Item name="btn-search-reservation">
          <Button
            id="btn-search-reward"
            data-testid="search-button"
            className="col-12 primary-button"
            type="primary"
            htmlType="submit"
          >
            {t("table.form-search.button")}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default SearchReward;
