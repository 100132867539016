import React, { Suspense } from "react";
import { useContext, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { Skeleton } from "antd";
import { ConfigContext } from "../../hooks/context/config";
import { Typography } from "antd";
import RemunerationForm from "./remunerationForm/remunerationForm";
import RemunerationInfo from "./remunerationInfo/remunerationInfo";
import "./remuneration.sass";
import RemunerationResult from "./remunerationResult/remunerationResult";
import { useConfig } from "src/api/api";
import RemunerationSummary from "./remunerationSummary/remunerationSummary";
import { RestApiClientContext } from "src/hooks/context/pageContext";

const { Paragraph, Title } = Typography;

// Componente principal de remuneraciones
const Remuneration = () => {
  const restApiClient = useContext(RestApiClientContext);

  const { t } = useTranslation(["remuneration"]);

  const infoClient = useContext(ConfigContext);

  // const [result, setResult] = useState(new RemunerationResultClass());
  const [selectForm, setSelectForm] = useState(null);

  // Position del scroll en el top de la pagina
  var scrollLib = require("react-scroll");
  var scroll = scrollLib.animateScroll;

  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  // Recoge los elementos para la gráfica
  const result = useConfig(selectForm?.id);

  const [noIndexNoFollow, setNoIndexNoFollow] = React.useState(false);
  const getNoIndexNoFollow = () : void => {
    restApiClient.getNoIndexNoFollowConfig().then(v => {
      setNoIndexNoFollow(v);
    });

  }
  React.useEffect(() => {
    getNoIndexNoFollow();
  }, []);

  const [selectedConfigId, setSelectecConfigId] = useState<string>('');
  useEffect(()=>{
    setSelectecConfigId(selectForm?.id);
  },[selectForm])

  return (
    <>
      <Helmet>
        <title>
          {infoClient.incentiveSiteName} {t("seo:title.register", { 0: infoClient.incentiveSiteName })} |{" "}
          {t("seo:title.register")}
        </title>
        <meta name="description" content={t("seo:description.register", { 0: infoClient.incentiveSiteName })} />
        {
          noIndexNoFollow ? 
            <meta name="robots" content="noindex, nofollow" />
          : <meta name="robots" content="index,follow" />
        }
        <link rel="canonical" href={infoClient.urlWA + t("urls.public.register")} />
        <link rel="icon" type="image/ico" href={infoClient.favicon} sizes="32x32" />
        <link href={infoClient.urlWA + t("urls.public.register")} hrefLang="es-ES" rel="alternate" />
      </Helmet>

      <div className="col-12 mt-3 rew-container">
        <Title className="col-12" level={4}>
          {t("remuneration:title")}
        </Title>
        <Paragraph className="col-12">{t("remuneration:paragraph", { 0: infoClient.description })}</Paragraph>
      </div>
      <div className="row col-12 display-data-form-info mt-3">
        <div className="col-12 col-lg-7 order-12 order-lg-1 padding-form-remuneration">
          <RemunerationForm setSelectForm={setSelectForm} />
        </div>
        <div className="col-12 col-lg-5 order-1 order-lg-12 mb-3">
          <RemunerationInfo />
        </div>
      </div>
      <div className="col-12 mt-3">
        {
          result?.periods.length > 0 && <RemunerationResult result={result} />
        }
      </div>
      <div className="col-12 mt-3">
        <RemunerationSummary selectedConfigId={selectedConfigId}/>
      </div>
    </>
  );
};

const RemunerationWrapper = () => (
  <Suspense fallback={<Skeleton />}>
    <Remuneration />
  </Suspense>
);
export default RemunerationWrapper;
