import React, { Suspense } from 'react';
import { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Typography, Skeleton } from 'antd';
import './regularizations.sass'
import '../../i18n';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../hooks/context/config';
import RegularizationsReservation from './regularizationReservation/regularizationReservation';
import SearchReservations from './searchReservations/searchReservations';
import Results from './searchReservations/results';
import ModelRegularizations from '../../models/regularizations/regularizations';
import Pagination from '../../models/pagination/pagination';
import NotResults from '../noResultGeneric/notResults';
import RewardGeneric from '../rewardGenericBalance/rewardGenericBalance'
import { useReservations } from 'src/api/api'
import { RestApiClientContext } from "../../hooks/context/pageContext";

const { Paragraph } = Typography;

// Componente principal de regularizaciones
const Regularizations = () => {

  // Contexto del cliente logueado
  const infoClient = useContext(ConfigContext);

  // Textos - traducciones
  const { t } = useTranslation(['urls', 'seo', 'regularizations']);

  // Estado que recoge los datos de alta de regularización
  const [data, setData] = useState(null);
  // Estado que mantiene la info recogida en la búsqueda de reservas regularizadas
  const [searchResults, setSearchResults] = useState(new ModelRegularizations());
  // Estado que almacena los criterios de búsqueda aplicados a la cabecera de la tabla
  const [searchHeaderResults, setSearchHeaderResults] = useState('');

  const [clearForm, setClearForm] = useState(false);

  const [forceRefreshList, setForceRefreshList] = useState<number>(0);

  // Position del scroll en el top de la pagina
  const scrollLib = require('react-scroll');
  const scroll = scrollLib.animateScroll;

  const refreshList = () : void => {
    setForceRefreshList(forceRefreshList+1);

    
  }

  const restApiClient = useContext(RestApiClientContext);

  const [noIndexNoFollow, setNoIndexNoFollow] = React.useState(false);
  const getNoIndexNoFollow = () : void => {
    restApiClient.getNoIndexNoFollowConfig().then(v => {
      setNoIndexNoFollow(v);
    });
  }
  
  React.useEffect(() => {
    getNoIndexNoFollow();
  }, []);

  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  return (
    <>
      <Helmet>
        <title>{ infoClient.incentiveSiteName } { t('seo:title.register', { 0: infoClient.incentiveSiteName }) } | { t('seo:title.register') }</title>
        <meta name="description" content={ t('seo:description.register', { 0: infoClient.incentiveSiteName }) } />
        {
          noIndexNoFollow ? 
            <meta name="robots" content="noindex, nofollow" />
          : <meta name="robots" content="index,follow" />
        }
        <link rel="canonical" href={ infoClient.urlWA + t('urls.public.register') } />
        <link rel="icon" type="image/ico" href={ infoClient.favicon } sizes="32x32" />
        <link href={ infoClient.urlWA + t('urls.public.register') } hrefLang="es-ES" rel="alternate" />
      </Helmet>

      <div className="mt-3 reg-container">

        <RewardGeneric />

        <Suspense fallback={<Skeleton active />}>
          <RegularizationsReservation
            forceRefresh={refreshList}
            setData={ setData }
          />
        </Suspense>

        <Suspense fallback={<Skeleton active />}>
          <SearchReservations
            forceRefresh={refreshList}
            setSearchResults={ setSearchResults }
            setSearchHeaderResults={ setSearchHeaderResults }
            clearForm={ clearForm }
            setClearForm={ setClearForm }
          />
        </Suspense>

        <Suspense fallback={<Skeleton active />}>
          <SearchResults
            searchResults={ searchResults }
            searchHeaderResults={ searchHeaderResults }
            setSearchHeaderResults={ setSearchHeaderResults }
            setClearForm={ setClearForm }
            t={t}
            forceRefresh={forceRefreshList}
          />
        </Suspense>
      </div>
    </>
  );
}

const SearchResults = ({ searchResults, searchHeaderResults, setSearchHeaderResults, setClearForm, t, forceRefresh }) => {
  // Estado que almacena la página actual y el número de elementos por página de la paginación en la tabla y lista
  const [pageResults, setPageResults] = useState(new Pagination());
  // Estado que almacena los criterios de ordenación aplicados a la tabla
  const [orderResults, setOrderResults] = useState([]);
  const restApiClient = useContext(RestApiClientContext);

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const getData = () : void => {
    setLoadingData(true);
    restApiClient.getBookings({
      'Pagination.Page': pageResults.current || 1,
      'Pagination.ResultPage': pageResults.pageSize || 6,
      'BookingReference': searchResults?.reservations?.bookingReference || '',
      'BookingStatus': searchResults?.state || '',
      'NameSurnameClient': searchResults?.reservations?.client?.nameSurname || ''
    }).then((data) => {
      setParentResult(data);
    }).finally(()=>{
      setLoadingData(false);
    });
  }

  useEffect(()=>{
      getData();
  },[ pageResults, forceRefresh]);

  const [parentResults, setParentResult] = useState<any>()

  if (parentResults?.bookings?.length > 0) {
    return (
      <div className="table-reservations row">
        <div className="col-12 p-form-elements">
          <Skeleton active loading={loadingData}>
            <Results
              rows={ parentResults.bookings }
              totalRows={parentResults.totalRecord}
              setPageResults={ setPageResults }
              pageResults={ pageResults }
              orderResults={ orderResults }
              setOrderResults={ setOrderResults }
              searchHeaderResults={ searchHeaderResults }
              setSearchHeaderResults={ setSearchHeaderResults }
            />
          </Skeleton>
          <div className="container-results">
            <Paragraph className="total-results-content">{ parentResults.totalRecord } { t('regularizations:results') }</Paragraph>
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <NotResults
        section="regularizations"
        searchHeaderResults={ searchHeaderResults }
        setClearForm={ setClearForm }
      />
    )
  }
}

export default Regularizations;
