import { useContext } from 'react';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import { UserContext } from '../hooks/context/userInfo';
import { useTranslation } from 'react-i18next';

// Funcion que usamos para darle el formato a las fechas y los textos en el idioma que tiene el usuario seleccionado
// Esto sobreescribe lo aplicado por AntD y es necesario
export const getLocaleDatePicker = () => {

    const userContext = useContext(UserContext);
    const userLanguage = userContext.GetLanguageUser();
    const { t } = useTranslation();

    let local: PickerLocale = null;
    switch(userLanguage) {
        case "es-ES":
            local = {
                "lang": {
                    "locale": "es-ES",
                    "placeholder": "Seleccionar fecha",
                    "rangePlaceholder": ["Fecha entrada", "Fecha salida"],
                    "today": "Hoy",
                    "now": "Ahora",
                    "backToToday":  "Volver a hoy",
                    "ok": "Ok",
                    "clear": "Limpiar",
                    "month": "Mes",
                    "year":  "Año",
                    "timeSelect": "Seleccionar hora",
                    "dateSelect": "Seleccionar fecha",
                    "monthSelect": "Elige un mes",
                    "yearSelect": "Elige un año",
                    "decadeSelect": "Elige una década",
                    "yearFormat": "YYYY",
                    "dateFormat":   "dd/mm/yyyy",
                    "dayFormat": "D",
                    "dateTimeFormat": "D/M/YYYY HH:mm:ss",
                    "monthFormat": "MMMM",
                    "monthBeforeYear": true,
                    "previousMonth": "Mes anterior (PageUp)",
                    "nextMonth": "Próximo mes (PageDown)",
                    "previousYear": "El año pasado (Control + left)",
                    "nextYear": "El próximo año (Control + right)",
                    "previousDecade": "La última década",
                    "nextDecade": "Próxima década",
                    "previousCentury": "Siglo pasado",
                    "nextCentury": "Próximo siglo"
                },
                "timePickerLocale": {
                    "placeholder": "Seleccionar hora"
                },
                "dateFormat": "DD/MM/YYYY",
                "dateTimeFormat": "DD/MM/YYYY HH:mm:ss",
                "weekFormat": "wo-YYYY",
                "monthFormat": "MM-YYYY"
            }
         break;
         case "en-US":
            local = {
                "lang": {
                    "locale": "en-US",
                    "placeholder": "Select date",
                    "rangePlaceholder": ["Entry date", "Departure date"],
                    "today": "Today",
                    "now": "Now",
                    "backToToday": "Back to today",
                    "ok": "Ok",
                    "clear": "Clear",
                    "month": "Month",
                    "year": "Year",
                    "timeSelect": "Select time",
                    "dateSelect": "Select date",
                    "monthSelect": "Choose a month",
                    "yearSelect": "Choose a year",
                    "decadeSelect": "Choose a decade",
                    "yearFormat": "YYYY",
                    "dateFormat": "mm/dd/yyyy",
                    "dayFormat": "D",
                    "dateTimeFormat": "M/D/YYYY HH:mm:ss",
                    "monthFormat": "MMMM",
                    "monthBeforeYear": true,
                    "previousMonth": "Previous month (PageUp)",
                    "nextMonth": "Next month (PageDown)",
                    "previousYear": "Last year (Control + left)",
                    "nextYear": "Next year (Control + right)",
                    "previousDecade": "Last decade",
                    "nextDecade": "Next decade",
                    "previousCentury": "Last century",
                    "nextCentury": "Next century"
                  },
                  "timePickerLocale": {
                    "placeholder": "Select time"
                  },
                  "dateFormat": "MM/DD/YYYY",
                  "dateTimeFormat": "MM/DD/YYYY HH:mm:ss",
                  "weekFormat": "YYYY-wo",
                  "monthFormat": "YYYY-MM"
            }
            break;
            default:
                local = {
                    "lang": {
                        "locale": t("translation:lang.locale"),
                        "placeholder": t("translation:lang.placeholder"),
                        "rangePlaceholder": t("translation:lang.rangePlaceholder"),
                        "today": t("translation:lang.today"),
                        "now": t("translation:lang.now"),
                        "backToToday": t("translation:lang.backToToday"),
                        "ok": t("translation:lang.ok"),
                        "clear": t("translation:lang.clear"),
                        "month": t("translation:lang.month"),
                        "year": t("translation:lang.year"),
                        "timeSelect": t("translation:lang.timeSelect"),
                        "dateSelect": t("translation:lang.dateSelect"),
                        "monthSelect": t("translation:lang.monthSelect"),
                        "yearSelect": t("translation:lang.yearSelect"),
                        "decadeSelect": t("translation:lang.decadeSelect"),
                        "yearFormat": t("translation:lang.yearFormat"),
                        "dateFormat":  t("translation:lang.dateFormat"),
                        "dayFormat": t("translation:lang.dayFormat"),
                        "dateTimeFormat": t("translation:lang.dateTimeFormat"),
                        "monthFormat": t("translation:lang.monthFormat"),
                        "monthBeforeYear": true,
                        "previousMonth": t("translation:lang.previousMonth"),
                        "nextMonth": t("translation:lang.nextMonth"),
                        "previousYear": t("translation:lang.previousYear"),
                        "nextYear": t("translation:lang.nextYear"),
                        "previousDecade": t("translation:lang.previousDecade"),
                        "nextDecade": t("translation:lang.nextDecade"),
                        "previousCentury": t("translation:lang.previousCentury"),
                        "nextCentury": t("translation:lang.nextCentury"),
                    },
                    "timePickerLocale": {
                        "placeholder": t("translation:timePickerLocale.placeholder"),
                    },
                    "dateFormat": t("translation:dateFormat"),
                    "dateTimeFormat": t("translation:dateTimeFormat"),
                    "weekFormat": t("translation:weekFormat"),
                    "monthFormat": t("translation:monthFormat"),
            }
            break;
    }
    
    return local;
}
