import EndPointConfig from '../../models/api/endPointConfig';

const controller = 'Customizations';

/// Define all EndPoints for Client Data.
export const ClientDataEP : EndPointConfig[] = [
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetCustomizations'),
        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('getChainPayTaxes')
        .setAlias('getChainPayTaxes'),
];
