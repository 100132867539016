import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Form, Button, Row, Col, Modal, DatePicker, Select, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import * as AjaxAPI from '../../../../api/ajaxApi';
import { showNotification } from '../../../../utils/notificationsGeneric';
import './formEditReservation.sass'
import { getLocaleDatePicker } from '../../../../utils/datePicker';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import moment from 'moment';
import Channel from  '../../../../models/regularizations/channels';
import ReactHtmlParser from 'react-html-parser';
import { UserContext } from '../../../../hooks/context/userInfo';

const { RangePicker } = DatePicker;
const { Option } = Select;

// Componente de formulario de editar reserva
const FormEditReservation = (param) => {

    // Contexto usuario
    const userContext= useContext(UserContext);

    // Necesario para estructura del formulario
    const tailLayout = {
        wrapperCol: {
            span: 25,
        },
    };

    const [form] = Form.useForm();

    // Idioma para los textos
    const { t } = useTranslation(['reservations']);

    // Estado de envío de formulario
    const [sendForm, setSendForm] = useState(false);
    // Estado con fecha limited para regularizar reserva informada desde la API (mockeado -> 01-01-2020)
    const [dateLimit, setDateLimit] = useState("");
    // Estado con fecha limited para regularizar reserva informada desde la API (mocekado -> 01-01-2020)
    const [disable, setDisable] = useState(true);
    // Estado con fecha limited para regularizar reserva informada desde la API (mocekado -> 01-01-2020)
    const [millisec, setMillisec] = useState(null);
    // Estados del número de habitaciones
    const [numRooms, setNumRooms] = useState([]);
    // Estados de la lista de hoteles
    const [hotelsList, setHotelsList] = useState([]);

    // Local para el datePicker
    const local: PickerLocale = getLocaleDatePicker();

    // Comparador de fechas para saber si debe estar disable o seleccionable para el usuario
    const disabledDate = (current) => {
        // Can not select days before today and today
        return current && current < moment(dateLimit);
    }

    // Función de llamada a la API para editar datos de la reserva regularizada
    const sendEditReservation = (values) => {

        AjaxAPI.doActionOld('SendEditReservation', {
            values, 
            lang: userContext.GetLanguageUser()
        })
            .then(v => {
                if (v.data.send.correctly) {
                    form.resetFields();
                    showNotification('success', v.data.send.title, v.data.send.message, "topRight");
                } else {
                    showNotification('error', v.data.send.titleError, v.data.send.messageError, "topRight");
                }
                
                param.setOpenEditReservation(false);
            })
            .catch(e => {});  
    }

    // Submit boton
    const onFinish = async(values) => {
        /// TODO : Quitar el true para que los datos no vengan mockeados.
        AjaxAPI.init(t, true);

        sendEditReservation(values);
    }

    // Boton cancelar
    const HandCancel = () => {
        param.setOpenEditReservation(false);
    }

    // Evento de activar el calendario 
    const changeCalendarState = () => {
        setDisable(false);
    }

    // Recoge de la API la fecha mas tardia que podria introducir un agente para regularizar una reserva en [Fecha entrada - Fecha salida] .
    const getDateDisable = () => {
        AjaxAPI.doActionOld('getDateDisable', null)
            .then(v => {
                var myDate = new Date(v.data.date);
                var offset = myDate.getTimezoneOffset() * 60 * 1000;
                ​
                var withOffset = myDate.getTime();
                var withoutOffset = withOffset - offset;

                setDateLimit(v.data.date);
                setMillisec(withoutOffset);
            })
            .catch(e => {});
    }

    // Recoge el numero maximo habitaciones de la API
    const getNumberRooms = () => {
        AjaxAPI.doActionOld('GetNumRooms', null)
            .then(v => {
                for (let i = 1; i <= v.data.numRooms; i++) {
                    setNumRooms(numRooms => [...numRooms, i ]);
                }
            })
            .catch(e => {});  
    }

    // Recoge los hoteles de la API
    const getHotels = () => {
        AjaxAPI.doActionOld('GetHotels', null)
            .then(v => {
                setHotelsList(v.data.listHotels);
            })
            .catch(e => {});  
    }

    // Llamada inicial para traer elementos de los options
    useEffect(() => {
        AjaxAPI.init(t, true);
        
        getHotels();
        getNumberRooms(); 
        getDateDisable();
    }, []);

    return (
        <>
            { param.record !== 'undefined' && param.record.hotelName !== 'undefined' && 
                <Modal
                    title="Editar reserva"
                    visible={ param.openEditReservation }
                    onCancel={ HandCancel }
                    footer={ null } 
                    key="1"
                    className="col-10 modal-edit-reservation"
                    centered
                    maskClosable={ false }
                    bodyStyle={ 
                        { 
                            overflow: 'auto'
                        } 
                    }
                >  
                    <Form
                        name="basic"
                        className="row"
                        layout="vertical"
                        initialValues={ 
                            { 
                                hotel: param.record.hotelName,
                                date: 
                                    [
                                        moment(param.record.checkInDate), 
                                        moment(param.record.checkOutDate)
                                    ],
                                numberRooms: param.record.numberRooms
                            }
                        }
                        onFinish={ onFinish }
                        form={ form }
                    >
                        <Form.Item 
                            className="col-12 form-item"
                            label="Hotel"
                            name="hotel"
                            rules={
                                [
                                    {
                                        required: true,
                                        message: t('regularizations:form.errors.hotel-selector')
                                    }
                                ]
                            }
                        >
                            <Select 
                                className="main-select"
                                id="edit-hotels-list" 
                                data-testid="select-hotels-list"
                                placeholder={ t("regularizations:form.placeholder.select") }
                                showSearch
                            >
                                { 
                                    hotelsList.map((s: Channel, i) => {
                                        return(
                                            <Option 
                                                key={ i } 
                                                id={ `t-${i}` } 
                                                value={ JSON.stringify(s) }
                                            >
                                                { s.name }
                                            </Option>
                                        )
                                    })
                                }
                            </Select>
                        </Form.Item> 
                        <Form.Item 
                            className="col-12 col-md-7 form-item"
                            label="Entrada - Salida"
                            name="date"
                            rules={ 
                                [
                                    {
                                        required: true,
                                        message: t('regularizations:form.errors.date')
                                    }
                                ] 
                            }
                        >
                            <RangePicker
                                className="main-select reservations-dates" 
                                data-testid="rp-reservation-dates"
                                locale={ local } 
                                disabledDate={ disabledDate }
                                onCalendarChange={ changeCalendarState }
                                placeholder={ 
                                    [ 
                                        local.lang.dateFormat, 
                                        local.lang.dateFormat 
                                    ] 
                                }
                                style={ { width: '100%' } } 
                                renderExtraFooter={ 
                                    () => ReactHtmlParser(t('regularizations:form.text-info-date', { 0: moment(millisec).format(local.dateFormat) })) 
                                }
                                format={ local.dateFormat }
                            />
                        </Form.Item>
                        <div className="col-md-1"></div>
                        <Form.Item 
                            className="col-12 col-md-4 form-item"
                            label="Nº habitaciones"
                            name="numberRooms"
                            rules={
                                [
                                    {
                                        required: true,
                                        message: t('regularizations:form.errors.num-rooms')
                                    }
                                ]
                            }
                        >
                            <Select 
                                className="main-select"
                                id="edit-number-rooms" 
                                data-testid="select-number-rooms"
                                placeholder="1"
                            >
                                { 
                                    numRooms.map((s: number, i) => {
                                        return(
                                            <Option 
                                                key={ i } 
                                                id={ `y-${i}` } 
                                                value={ JSON.stringify(s) }
                                            >
                                                { s }
                                            </Option>
                                        ) 
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item 
                            className="col-12 form-item footer-edit-reservation"
                            { ...tailLayout }
                        >
                            <Divider />

                            <Row>
                                <Col 
                                    span={ 24 }
                                    className="align-text-buttons"
                                >
                                    <Button 
                                        id="btn-send-reset-password"
                                        type="primary" 
                                        htmlType="submit"
                                        className="col-6 col-sm-4 primary-button"
                                    >
                                        { t('regularizations:form.edit.save') }
                                    </Button>
                                    <Button 
                                        id="btn-cancel-reset-password"
                                        onClick={ HandCancel }
                                        className="col-5 col-sm-4 default-button margin-right-button"
                                    >
                                        { t('regularizations:form.edit.cancel') }
                                    </Button>
                                </Col>
                            </Row>
                        </Form.Item>
                    </Form>
                </Modal>
            }
        </>
    );
}

export default FormEditReservation;
