import React, { useEffect } from "react";
import "./init.sass";
import Advantages from "../advantages/advantages";
import Multimedia from "./multimedia";
import Faqs from "../faqs/faqs";
import { scroller } from "react-scroll";
import { Info } from "./text";
import { useLandingCustomizations } from "src/api/api";
import ReactHtmlParser from 'react-html-parser';

// Componente que carga el elemento de presentación de la aplicación en su parte pública
export const Init = () => {
  const scrollLib = require("react-scroll");
  const scroll = scrollLib.animateScroll;


  const init = useLandingCustomizations()
  useEffect(() => {
    if (window.location.href.indexOf("#") > -1) {
      const scrollElement = window.location.href.split("#")[1];
      scroller.scrollTo(scrollElement, { offset: -100, smooth: true });
    } else {
      scroll.scrollToTop();
    }
  });

  // Componente específico para una seguna línea de contenido dentro de los cuadros de la home
  const SecondLine = (param) => {
    return (
      param.data != null && (
        <>
          <div className="col-12 col-md-6 image-container">
            <Multimedia
              resourceType={param.data.resource.resourceType}
              resourceLink={param.data.resource.resourceLink}
            />
          </div>
          <div className="col-12 col-md-6 text-container">
            <Info
              title={ ReactHtmlParser(param.data.texts.title) }
              subtitle={ReactHtmlParser(param.data.texts.subtitle)}
              body={(param.data.texts.body)}
              principal={false}
            />
          </div>
        </>
      )
    );
  };
  
  const FirstLine = (param) => {
    return (
      param.data != null && (
        <>
            <div className="col-12 col-md-6 text-container">
              <Info
                title={ReactHtmlParser(param.data.texts.title)}
                subtitle={ReactHtmlParser(param.data.texts.subtitle)}
                body={(param.data.texts.body)}
                principal={param.includeButtons}
              />
            </div>
            <div className="col-12 col-md-6 image-container">
              <Multimedia
                resourceType={param.data.resource.resourceType}
                resourceLink={param.data.resource.resourceLink}
              />
            </div>
          </>
      )
    );
  };

  return (
    <>
      <div id="initPage" className="row m-0 mb-5">
        {
          init?.map((info: any, index: number) => {
            if (index % 2 == 0){
              return <FirstLine data={info} includeButtons={index == 0}/>
            }else{
              return <SecondLine data={info} />
            }
          })
        }
      </div>
      <div id="home" className="container">
        <Advantages />
        <Faqs />
      </div>
    </>
  );
};
