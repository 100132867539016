import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Button, Alert, Typography, List, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import "./rewardBalance.sass";
import { FormattedNumber } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import ReactHtmlParser from "react-html-parser";
import FormRewardBalance from "../modalReward/modalReward";
import { useAvailableMoney } from "src/api/api";
import { RestApiClientContext } from "src/hooks/context/pageContext";
import { UserContext } from "src/hooks/context/userInfo";

const { Title, Text } = Typography;

// Componente de lista de cheques

interface ParamProps {}

interface AvailableMoney{
  money: number;
  currencies: {
    id: string;
    symbol: string;
  };

}


const RewardBalance = (param: ParamProps): JSX.Element => {
  const restApiClient = useContext(RestApiClientContext);

  const { t } = useTranslation(["urls", "reward"]);

  const [valueOpen, setValueOpen] = useState();
  const [visibleModal, setVisibleModal] = useState(false);
  const [availableMoney, setAvailableMoney] = useState<AvailableMoney>({money: 0, currencies: {id: "", symbol: ""}});
  const [isOwn, setIsOwn] = useState(false);

  const userContext = useContext(UserContext);

  useEffect(() => {
    /**
     * Workaround:
     * 
     * El contexto de usuario (userContext) se carga de forma asíncrona, por lo que no estará disponible inmediatamente.
     * 
     * Eso produce que cuando se hace la comprobación de los suppliers que se han traido de la API con el currency del usuario en el contexto, al no estar listo el contexto
     * da error.
     * 
     * Se añade una espera para comprobar si el contexto está disponible y si lo está ya puede cargar los datos.
     * 
     */
    const intervalId = setInterval(() => {
      if (userContext && userContext.currency) {
        setIsUserContextReady(true);
        clearInterval(intervalId); // Detener el intervalo cuando userContext esté listo
      }
    }, 200); // Verifica cada 200ms si userContext está listo

    return () => clearInterval(intervalId); // Limpiar el intervalo si el componente se desmonta
  }, [userContext]);

  const [isUserContextReady, setIsUserContextReady] = useState(false);
  // Este efecto se asegura de que userContext esté listo
  useEffect(() => {
    if (userContext && userContext.currency) {
      setIsUserContextReady(true);
    }else{
      setIsUserContextReady(false);
    }
  }, [userContext]);

  const [loadingAvailableMoney, setLoadingAvailableMoney] = useState<boolean>(false);

  useEffect(()=>{
    setLoadingAvailableMoney(true);
    restApiClient.getAvailableMoney().then((response) => {
      setAvailableMoney(response);
    }).finally(() => {
      setLoadingAvailableMoney(false);
    });
  },[])

  const openModal = (value) => {
    value.amount = availableMoney.money + (availableMoney.money * value.increment) / 100;

    setValueOpen(value);
    setVisibleModal(true);
    setIsOwn(value.own);
  };

  const [loadingOwnRewards, setLoadingOwnRewards] = useState<boolean>(false);
  const [ownRewardList, ownSetRewardList] = useState<any[]>([]);
  const getOwnRewardsConfig = (): void => {
    setLoadingOwnRewards(true);
    restApiClient
      .getRewardsConfigurationOwn()
      .then((response) => {
        const finalRewardList = [];

        response.ownRewards.forEach((ownReward) => {
          const supplierRewardCurrency = ownReward.currency.id;
          const userCurrency = userContext.currency;
          //El problema es que a veces el context no se ha cargado cuando llega aquí y por eso falla sin mostrar los suppliers

          if (supplierRewardCurrency === userCurrency) {
            finalRewardList.push({
              ...ownReward,
              image: response.image,
              name: response.name,
              own: true,
            });
          }
        });
        ownSetRewardList(finalRewardList);
      })
      .finally(() => {
        setLoadingOwnRewards(false);
      });
  };

  const [loadingSuppliersRewards, setLoadingSuppliersRewards] = useState<boolean>(false);
  const [totalData, setTotalData] = useState<any[]>([]);
  const getSupplierRewards = (): void => {
    setLoadingSuppliersRewards(true);
    restApiClient
      .getSuppliersConfiguration(1, 100)
      .then((response) => {

        const finalRewardList = [];

        response.suppliersRewards.forEach((supplierReward) => {
          const supplierRewardCurrency = supplierReward.currency.id;
          const userCurrency = userContext.currency;
          //El problema es que a veces el context no se ha cargado cuando llega aquí y por eso falla sin mostrar los suppliers

          if (supplierRewardCurrency === userCurrency) {
            finalRewardList.push(supplierReward);
          }
        });

        setTotalData(finalRewardList);
      })
      .finally(() => {
        setLoadingSuppliersRewards(false);
      });
  };


  useEffect(() => {
    if (isUserContextReady){
      getOwnRewardsConfig();
      getSupplierRewards();
    }
  }, [isUserContextReady]);

  return (
    <div className="form-add-reservation" data-testid="list-rewards">
      <div className="col-12 row row-balance-generic">
        <div className="col-12 head-section">
          <div className="col-12 title">
            <Title level={4}>{t("reward:balance.title")}</Title>
          </div>
          <div className="col-12 question">
            <Text className="font-size">{t("reward:balance.question")}</Text>
            <div className="text-color-main money-balance-info">
              {
                availableMoney && <FormattedNumber
                  useGrouping={true}
                  value={availableMoney.money}
                  style="currency"
                  currency={availableMoney.currencies.id}
                />
              }
            </div>
          </div>
          <div className="col-12 message-info">
            <div className="alert-message">
              <Alert
                message=""
                description={t("reward:balance.message-info")}
                type="info"
                className="info-balance"
                closable
                showIcon
              />
            </div>
          </div>
        </div>
        <div id="body-section" className="col-12 body-section">
          <Skeleton
            active
            loading={loadingOwnRewards || loadingSuppliersRewards || loadingAvailableMoney}
          >
            {availableMoney && ownRewardList && ownRewardList.length > 0 && (
              <List
                className="padding-list-check"
                data-testid="list-own-rewards-show"
                itemLayout="vertical"
                size="large"
                dataSource={ownRewardList}
                renderItem={(item) => (
                  <List.Item
                    key={item.id}
                    className={
                      "list-rewards col-12" +
                      (item.own
                        ? " list-panel-content-prominent"
                        : " list-panel-content")
                    }
                  >
                    <List.Item.Meta
                      className="col-12 display-box-info"
                      description={
                        <div className="col-12 p-0 row">
                          <div className="col-xl-3 col-md-4 col-12 box-img-balance-logo">
                            <img src={item.image} />
                          </div>
                          <div className="col-md-6 col-12 box-display-info-button">
                            <div className="row col-12 position-info-amount-center">
                              <div className="row amount-show">
                                <div className="col-xl-1 col-md-2 not-visible-sm p-0">
                                  <FontAwesomeIcon
                                    className="icons-color-reward"
                                    icon={faCoins}
                                  />
                                </div>
                                <div className="row col-xl-11 col-md-10 col-12 p-0 content-info-amount">
                                  <strong className="order-xl-1 order-12 float-left">
                                    <FontAwesomeIcon
                                      className="icons-color-reward visible-sm"
                                      icon={faCoins}
                                    />
                                    <FormattedNumber
                                      useGrouping={true}
                                      value={
                                        availableMoney.money +
                                        (availableMoney.money *
                                          item.increment) /
                                          100
                                      }
                                      style="currency"
                                      currency={availableMoney.currencies.id}
                                    />
                                  </strong>
                                  {item.increment > 0 && (
                                    <div className="order-xl-12 order-1 box-max-amount">
                                      {ReactHtmlParser(
                                        t(
                                          "reward:table-result.max-amount-percentage",
                                          { 0: item.increment }
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="col-xl-1 col-md-2 not-visible-sm p-0"></div>
                                <div className="col-xl-11 col-md-10 col-12 p-0 text-info-aditional">
                                  {item.messageList}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-md-2 col-12 p-0 btn-get-reward">
                            <Button
                              id={item.id}
                              className="col-md-10 col-6 primary-button"
                              disabled={availableMoney.money <= 0}
                              type="primary"
                              onClick={() => openModal(item)}
                            >
                              {t("reward:table-result.button")}
                            </Button>
                          </div>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            )}

            {availableMoney && totalData && totalData.length > 0 && (
              <List
                className="padding-list-check"
                data-testid="list-rewards-show"
                itemLayout="vertical"
                size="large"
                dataSource={totalData}
                renderItem={(item) => (
                  <List.Item
                    key={item.id}
                    className={
                      "list-rewards col-12" +
                      (item.own
                        ? " list-panel-content-prominent"
                        : " list-panel-content")
                    }
                  >
                    <List.Item.Meta
                      className="col-12 display-box-info"
                      description={
                        <div className="col-12 p-0 row">
                          <div className="col-xl-3 col-md-4 col-12 box-img-balance-logo">
                            <img src={item.image} />
                          </div>
                          <div className="col-md-6 col-12 box-display-info-button">
                            <div className="row col-12 position-info-amount-center">
                              <div className="row amount-show">
                                <div className="col-xl-1 col-md-2 not-visible-sm p-0">
                                  <FontAwesomeIcon
                                    className="icons-color-reward"
                                    icon={faCoins}
                                  />
                                </div>
                                <div className="row col-xl-11 col-md-10 col-12 p-0 content-info-amount">
                                  <strong className="order-xl-1 order-12 float-left">
                                    <FontAwesomeIcon
                                      className="icons-color-reward visible-sm"
                                      icon={faCoins}
                                    />
                                    <FormattedNumber
                                      useGrouping={true}
                                      value={
                                        availableMoney.money +
                                        (availableMoney.money *
                                          item.increment) /
                                          100
                                      }
                                      style="currency"
                                      currency={availableMoney.currencies.id}
                                    />
                                  </strong>
                                  {item.increment > 0 && (
                                    <div className="order-xl-12 order-1 box-max-amount">
                                      {ReactHtmlParser(
                                        t(
                                          "reward:table-result.max-amount-percentage",
                                          { 0: item.increment }
                                        )
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="col-xl-1 col-md-2 not-visible-sm p-0"></div>
                                <div className="col-xl-11 col-md-10 col-12 p-0 text-info-aditional">
                                  {item.messageList}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-3 col-md-2 col-12 p-0 btn-get-reward">
                            <Button
                              id={item.id}
                              className="col-md-10 col-6 primary-button"
                              disabled={availableMoney.money <= 0}
                              type="primary"
                              onClick={() => openModal(item)}
                            >
                              {t("reward:table-result.button")}
                            </Button>
                          </div>
                        </div>
                      }
                    />
                  </List.Item>
                )}
              />
            )}
          </Skeleton>
        </div>
      </div>

      <FormRewardBalance
        isModalRewardVisible={visibleModal}
        setModalRewardVisible={setVisibleModal}
        valueOpen={valueOpen}
        setAvailableMoney={setAvailableMoney}
        isOwn={isOwn}
      />
    </div>
  );
};

export default RewardBalance;
