import EndPointConfigOld, { MockDataOld } from '../../models/api/endPointConfigOld';

import * as newsMocks from '../mocks/newsMocks.json';
import * as newsMoreMocks from '../mocks/newsMocksMore.json';
import * as detailNewsMocks from '../mocks/detailNewsMocks.json';

/// Define all EndPoints for news methods.
export const NewsEP : EndPointConfigOld[] = [
    // Get first news
    new EndPointConfigOld('GET', 'News', 'GetNews', new MockDataOld(newsMocks)),
    // Get more news
    new EndPointConfigOld('GET', 'News', 'GetNewsAdd', new MockDataOld(newsMoreMocks)),
    // Get details news
    new EndPointConfigOld('GET', 'News', 'GetDetailNews', new MockDataOld(detailNewsMocks)),
];
