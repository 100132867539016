import React, { useState, useLayoutEffect } from "react";
import { Input, Popover, Tooltip } from "antd";
import { InfoCircleOutlined, CheckOutlined } from "@ant-design/icons";
import SuggestionList from "./suggestionList";
import "./suggestionsInputStyle.sass";
import AlternateComponent from "../../utils/alternateComponent/alternateComponent";

/**
 * Este componente permite mostrar al usuario diferentes sugerencias sobre textos para que pueda seleccionarlos.
 * Por ejemplo, usernames alternativos al que está poniendo
 * @param props listado de propiedades que pueden enviarse:
 *      - suggestions: Lista de sugerencias a mostrar
 *      - onSelect: función a realizar cuando el usuario seleccione un item de la lista de suggestions
 *      - id: ID para poner en el Input
 *      - placeholder: Texto que aparecerá en el placeholder
 *      - onChange: función a realizar cuando se cambie el texto del input
 *      - value: valor a mostrar en el input
 *      - placement: Posición del popover. right por defecto. Admite los valores descritos por antd: https://ant.design/components/popover/#header
 *      - trigger: Momento en el que aparecerá el popover. focus por defecto. Admite los valores descritos por antd: https://ant.design/components/popover/#header
 *      - suggestionTitle: Título que acompañará al listado
 *      - suggestionTitleType: Tipo del mensaje => alert|info
 *      - suggestionSubTitle: Subtítulo que acompañará al título
 */
const SuggestionsInput = (props: any) => {
  const [suffix, setSuffix] = useState(null);

  const onSelectSuggestion = (selected: string): void => {
    setSuffix(<CheckOutlined className="check" />);
    props.onSelect(selected);
  };

  const showMobileSuggestions = () => {};

  const getSuggestions = (version: string = "desktop") => {
    if (props.value === "") {
      return undefined;
    }
    return (
      <SuggestionList
        suggestions={props.suggestions}
        onSelect={onSelectSuggestion}
        title={props.suggestionTitle}
        titleType={props.suggestionTitleType}
        subTitle={props.suggestionSubTitle}
        version={version}
      />
    );
  };

  const resetSuffix = () => {
    /// FIX: Lost focus when add or remove the suffix: https://ant.design/components/input/#FAQ
    setSuffix(<span />);
  };

  const getInput = (version: string = "desktop") => {
    return (
      <Input
        id={props.id}
        placeholder={props.placeholder}
        className="main-input"
        onKeyUp={resetSuffix}
        onChange={props.onChange}
        onClick={version === "desktop" ? null : showMobileSuggestions}
        value={props.value}
        suffix={suffix}
      />
    );
  };

  const desktopVersion = (placement: string = "bottomLeft") => {
    return (
      <Popover
        content={getSuggestions("desktop")}
        trigger={props.trigger || "focus"}
        placement={props.placement || placement}
      >
        {getInput()}
      </Popover>
    );
  };

  const tabletVersion = () => {
    return mobileVersion();
  };

  const mobileVersion = () => {
    return (
      <>
        {getInput()}
        {getSuggestions("mobile")}
      </>
    );
  };

  return (
    <div className="suggestion-input">
      <AlternateComponent
        desktopVersionLarge={desktopVersion()}
        tabletVersion={tabletVersion()}
        mobileVersionLarge={mobileVersion()}
      />
    </div>
  );
};

export default SuggestionsInput;
