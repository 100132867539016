import React, { Suspense } from "react";
import { useContext } from "react";
import { Skeleton } from "antd";
import "../../i18n";
import "./rewardGenericBalance.sass";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../hooks/context/userInfo";
import { Tooltip } from "antd";
import { IntlProvider, FormattedNumber } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AvailableMoney from "./availableMoney";
import AccumulatedMoney from "./accumulatedMoney";
import {
  faCoins,
  faQuestionCircle,
  faCalendar,
  faPiggyBank,
} from "@fortawesome/free-solid-svg-icons";
import {
  usePendingCheckout,
} from "src/api/api";

const RewardGeneric = () => {
  const userContext = useContext(UserContext);
  const { t } = useTranslation(["regularizations"]);

  const pendingCheckOut = usePendingCheckout();
  return (
    <>
      <IntlProvider locale={userContext.GetLanguageUser()}>
        <div className="col-12 mb-3 row row-balance-generic">
          <div className="col-md-4 col-6 card-info">
            <div className="box-center">
              <div className="row">
                <div className="col-3 col-sm-2 p-0 content-icon-reward">
                  <FontAwesomeIcon
                    className="icons-color-regularization margin-icon"
                    icon={faCoins}
                  />
                </div>
                <div className="col-9 col-sm-10 p-0 ">
                  <div className="m-0">
                    <div className="content-available">{t("available")}</div>
                    <Tooltip
                      title={t("tooltip-available")}
                      className="hidden-sm"
                    >
                      <FontAwesomeIcon
                        className="icon-question margin-icon icon-right hidden-sm"
                        icon={faQuestionCircle}
                      />
                    </Tooltip>
                  </div>
                  <div className="text-color-main">
                    <AvailableMoney />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 col-6 card-info card-center">
            <div className="box-center">
              <div className="row">
                <div className="col-3 col-sm-2 p-0 content-icon-reward">
                  <FontAwesomeIcon
                    className="icons-color-regularization margin-icon"
                    icon={faPiggyBank}
                  />
                </div>
                <div className="col-9 col-sm-10 p-0 ">
                  <div className="m-0">
                    <div className="content-available">{t("pending")}</div>
                    <Tooltip title={t("tooltip-pending")} className="hidden-sm">
                      <FontAwesomeIcon
                        className="icon-question margin-icon icon-right hidden-sm"
                        icon={faQuestionCircle}
                      />
                    </Tooltip>
                  </div>
                  <div className="text-color-main">
                    <AccumulatedMoney />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden-sm col-md-4 card-info">
            <div className="box-center">
              <div className="row">
                <div className="col-3 col-sm-2 p-0 content-icon-reward">
                  <FontAwesomeIcon
                    className="icons-color-regularization margin-icon"
                    icon={faCalendar}
                  />
                </div>
                <div className="col-9 col-sm-10 p-0">
                  <div className="m-0">
                    <div className="content-available">
                      {t("pending-check-out")}
                    </div>
                  </div>
                  <div className="text-color-main pending-co">
                    {pendingCheckOut}
                    <span className="regularizations-text">
                      {" " +
                        (pendingCheckOut == 1
                          ? t("regularization")
                          : t("regularizations"))}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </IntlProvider>
    </>
  );
};

const RewardGenericWrapper = () => (
  <Suspense fallback={<Skeleton />}>
    <RewardGeneric />
  </Suspense>
);

export default RewardGenericWrapper;
