import * as React from 'react';
import { useState } from 'react';
import FormNewPassword from '../formNewPassword/formNewPassword';
import ModalLogin from '../login/login';

// Formulario de introducir nueva contraseña
const FormNewPasswordContent = () => {

  const [modalLogin, setmodalLogin] = useState(false)

  return (
    <>        
        <FormNewPassword setmodalLogin={ setmodalLogin } />
        { modalLogin && <ModalLogin setmodalLogin={ setmodalLogin } /> }        
    </>
  );
}

export default FormNewPasswordContent;
