import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Button } from 'antd';
import { Link } from 'react-router-dom';
import { linkActiveContext } from '../../../hooks/context/linkActive';

const { Text } = Typography;

// Componente widget para el dashboard
const RegularizationWidget = () => {

    const { t } = useTranslation(['urls', 'regularization']);
    const linkActive = useContext(linkActiveContext);

    return (
        <div className="col-12 mb-3 p-3 background-box-reward-home">
            <div className="col-12 question">
                <Text className="font-size">
                    { t("regularizations:title") }
                </Text>
            </div>
            <div className="col-12 menssage-info align-text-widget">
                { t("regularizations:paragraph-home") }
            </div>
            <div className="col-10 col-sm-6 mt-3 mb-2 p-sm-0 margin-button-home">
                <Link
                    id="link-reward"
                    to={ t("urls:private.regularization") }
                    onClick={ () => linkActive.updateValueUrl(t("urls:private.regularization")) }
                >
                    <Button
                        id="btn-reward"
                        className="col-12 primary-button"
                        size="large"
                    >
                        { t("regularizations:form.button") }
                    </Button>
                </Link>
            </div>
        </div>
    );
}

export default RegularizationWidget;
