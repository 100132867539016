// Modelo de la búsqueda de recompensas
export default class RewardSearch {
    constructor (
         public dateChecking: string = undefined,
         public dateOut: string = undefined,
         public nameId: number = undefined,
    ) {

    }
}
