import * as React from "react";
import { useContext, useState } from "react";
import "./register.sass";
import { Helmet } from "react-helmet-async";
import "../../i18n";
import { useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../hooks/context/config";
import { Typography } from "antd";
import { Steps } from "antd";
import { useStep } from "react-hooks-helper";
import SearchAgency from "./searchAgency/searchAgency";
import SignUpAgent from "./signupAgent/signupAgent";
import Agent from "../../models/register/agent";
import Agency from "../../models/register/agency";
import VerifyMail from "./verifyMail/verifyMail";
import ConfirmMail from "./confirmMail/confirmMail";
import Pagination from "../../models/pagination/pagination";
import ChooseAgentType from "./chooseAgentType/chooseAgentType";
import IndependentAgentData from "./independentAgentData/independentAgentData";
import SignUpIndependentAgent from "./signupIndependentAgent/signupIndependentAgent";
import { RestApiClientContext } from "src/hooks/context/pageContext";
import TagManager from "react-gtm-module";

const { Step } = Steps;
const { Title, Paragraph } = Typography;
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// Componente padre de registro de agente
const Register = () => {
  const infoClient = useContext(ConfigContext);
  const { t } = useTranslation(["register", "seo", "urls"]);
  let query = useQuery();

  // Data for step 0 : Agent type.
  const [agentType, setAgentType] = useState(null)

  // Data for step 1 : Agent data.
  const [searchAgencyData, setSearchAgencyData] = useState(new Agency());
  const [searchTermNameInput, setSearchTermNameInput] = useState("");
  const [searchTermAddressInput, setSearchTermAddressInput] = useState("");
  const [searchTermPhoneInput, setSearchTermPhoneInput] = useState("");
  const [searchTermTaxidInput, setSearchTermTaxidInput] = useState("");

  const [disabled, setDisabled] = useState(true);

  const [independentData, setIndependentData] = useState(null);

  // Estado que almacena la página actual y el número de elementos por página de la paginación en la tabla y lista
  const [pageResultsInput, setPageResultsInput] = useState(new Pagination());
  // Data for step 2 : Agent data.
  const [signUpAgentData, setSignUpAgentData] = useState(new Agent());
  // Data for step 3 : reenvio correo.
  const [signUpAgentAllData, setSignUpAgentAllData] = useState(new Agent());

  const invalidIndependentData =
    !independentData?.idLocation || !independentData?.idCurrency || !independentData?.address || !independentData?.postalCode

  const restApiClient = useContext(RestApiClientContext);

  const [gtmId, setGtmId] = React.useState<string>('');
  const getGtmId = () : void => {
    restApiClient.getGtmId().then(v => {
      setGtmId(v);

      if (v.length === 0) return;

      TagManager.initialize({
        gtmId: v
      });
    });
  }

  const [noIndexNoFollow, setNoIndexNoFollow] = React.useState(false);
  const getNoIndexNoFollow = () : void => {
    restApiClient.getNoIndexNoFollowConfig().then(v => {
      setNoIndexNoFollow(v);
    });
  }

  React.useEffect(() => {
    getNoIndexNoFollow();
    getGtmId();
  }, []);

  const {
    index,
    navigation: { previous, next },
  } = useStep({ steps: 5 });

  let steps = [
    {
      title: t("steps.0"),
      content: (
        <ChooseAgentType
          agentType={agentType}
          setAgentType={setAgentType}
          next={next}
        />
      ),
    },
    {
      title: t("steps.1"),
      content: (
        agentType === 1
          ? <SearchAgency
            selectedData={searchAgencyData}
            searchTermNameInput={searchTermNameInput}
            searchTermAddressInput={searchTermAddressInput}
            searchTermPhoneInput={searchTermPhoneInput}
            searchTermTaxidInput={searchTermTaxidInput}
            pageResultsInput={pageResultsInput}
            setSearchTermNameInput={setSearchTermNameInput}
            setPageResultsInput={setPageResultsInput}
            setSearchTermAddressInput={setSearchTermAddressInput}
            setSearchTermPhoneInput={setSearchTermPhoneInput}
            setSearchTermTaxidInput={setSearchTermTaxidInput}
            updateData={setSearchAgencyData}
            next={next}
            previous={previous}
            setDisabled={setDisabled}
            disabled={index === 1 && searchAgencyData.key === ""}
          />
          : <IndependentAgentData
            pageResultsInput={pageResultsInput}
            selectedData={independentData}
            updateData={setIndependentData}
            setDisabled={setDisabled}
            next={next}
            previous={previous}
            disabled={invalidIndependentData}
          />
      ),
    },
    {
      title: t("steps.2"),
      content: (
        agentType === 1
          ? <SignUpAgent
            agentType={agentType}
            searchAgencyData={searchAgencyData}
            pageResultsInput={pageResultsInput}
            selectedData={signUpAgentData}
            updateData={setSignUpAgentData}
            setSignUpAgentAllData={setSignUpAgentAllData}
            next={next}
            previous={previous}
          />
          : <SignUpIndependentAgent
            agentType={agentType}
            independentData={independentData}
            pageResultsInput={pageResultsInput}
            selectedData={signUpAgentData}
            updateData={setSignUpAgentData}
            setSignUpAgentAllData={setSignUpAgentAllData}
            next={next}
            previous={previous}
          />
      ),
    },
    {
      title: t("steps.3"),
      content: <VerifyMail signUpAgentAllData={signUpAgentAllData} />,
    },
    {
      title: t("steps.4"),
      content: <ConfirmMail confirmToken={query.get("confirmToken")} />,
    },
  ];

  let indexUrl = 0;

  if (location.search && location.search.split("=")[0].split("?")[1] === "confirmToken") {
    indexUrl = 4;
  }

  return (
    <>
      <Helmet>
        <title>
          {infoClient.incentiveSiteName} {t("seo:title.register", { 0: infoClient.incentiveSiteName })} |{" "}
          {t("seo:title.register")}
        </title>
        <meta
          name="description"
          content={t("seo:description.register", {
            0: infoClient.incentiveSiteName,
          })}
        />
        
        {
          noIndexNoFollow ? 
            <meta name="robots" content="noindex, nofollow" />
          : <meta name="robots" content="index,follow" />
        }

        <link rel="canonical" href={infoClient.urlWA + t("urls:public.register")} />
        <link rel="icon" type="image/ico" href={infoClient.favicon} sizes="32x32" />
        <link href={infoClient.urlWA + t("urls:public.register")} hrefLang="es-ES" rel="alternate" />
      </Helmet>

      <div className="main-register">
        <div className="container container-register margin-bottom-8 mt-3">
          {!!index
            ? <Title className="main-title-register mb-1">
                {t(`register:title-register${agentType === 1 ? '' : '-independent'}`)}
              </Title>
            : <Title className="main-title-register mb-1">
                {t("register:title-register-simple")}
              </Title>
          }
          {!!index
            ? <Paragraph className="main-subtitle-register">
                {t(`register:subtitle-register${agentType === 1 ? '' : '-independent'}`)}
                <b>{infoClient.incentiveSiteName}</b>
                {t(`register:subtitle-register-2${agentType === 1 ? '' : '-independent'}`)}
              </Paragraph>
            : <Paragraph className="main-subtitle-register">{t("register:subtitle-register-simple")}</Paragraph>
          }
          <Steps
            className="m-auto col-md-8"
            current={indexUrl > 0 ? indexUrl : index}
            direction="horizontal"
            labelPlacement="vertical"
          >
            {steps.map((item) => (
              <Step key={item.title} />
            ))}
          </Steps>
          <div className="content-step">{steps[indexUrl > 0 ? indexUrl : index].content}</div>
        </div>
      </div>
    </>
  );
};

export default Register;
