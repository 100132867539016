import { Modal } from 'antd';

const confirm = Modal.confirm;
const info = Modal.info;
const error = Modal.error;
const success = Modal.success;
const warning = Modal.warning;

// Modal genérica de confirmación
export const showConfirm = (title, message, button, func = null) => {
  return (
    confirm({
      title: title,
      content: message,
      okText : button,
      className:"modal-button-cancel",
      onOk() {
        if(func != null) {
          func.setmodalLogin(true);
        }
      }
    })
  );
}

// Modal genérica de información
export const showInfo = (title, message, button) => {
  return (
    info({
      title: title,
      content: message,
      className: "modal-button-cancel",
      okText: button
    })
  );
}

// Modal genérica de error
export const showError = (title, message, button) => {
  return (
    error({
      title: title,
      content: message,
      className: "modal-button-cancel",
      okText: button
    })
  );
}

// Modal genérica de OK
export const showSuccess = (title, message, button) => {
  return (
    success({
      title: title,
      content: message,
      okText : button,
      onOk() {}
    })
  );
}

// Modal genérica de advertencia
export const showWarning = (title, message, button) => {
  return (
    warning({
      title: title,
      content: message,
      okText : button
    })
  );
}
