import EndPointConfig from "src/models/api/endPointConfig";

const controller = 'RewardsConfiguration';

/// Define all EndPoints for Client Data.
export const RewardsConfigurationEP : EndPointConfig[] = [
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetOwnRewardsConfiguration')
        .setActionName("own"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetSuppliersConfiguration')
        .setActionName("suppliers"),

    new EndPointConfig()
        .setMethod('GET')
        .setController('Customizations')
        .setAlias('GetNoIndexNoFollowConfiguration')
        .setActionName("GetNoIndexNoFollowConfiguration"),

    new EndPointConfig()
        .setMethod('GET')
        .setController('Customizations')
        .setAlias('GetGtmIdConfig')
        .setActionName("GetGtmIdConfig")
];

