import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { Form, Input, Button, Modal, Checkbox, Alert } from "antd";
import { useTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import { FormattedNumber, IntlProvider } from "react-intl";
import { UserContext } from "../../../../hooks/context/userInfo";
import ModalLegal from "../../modalLegalGeneric/modalLegalGeneric";
import { convertStringToNumber } from "../../../../utils/convertStringToNumber";
import { showNotification } from "../../../../utils/notificationsGeneric";
import { numberFormat } from "../../../../utils/formatAmount";
import {
  ONLY_NUMBER_D,
  NO_CHART_REPITED,
  NO_CHART_REPITED_AND_NUMBER_DECIMAL,
} from "../../../../constants/regex";
import { useGetReward } from "src/api/api";
import { useUserInfo } from "src/api/api";
import {
  useGetBankAccount,
  useGetBankAccountCurrency,
  useGetBankAccountCountry,
} from "src/api/api";
import ModalBankData from "../../modalBankData/modalBankData";
import ModalCashBackCreated from "../../modalCashbackCreated/modalCashbackCreated";
import { RestApiClientContext } from "src/hooks/context/pageContext";
import CalculateFeeDto from "../../../profile/modelResponse";

import "../modalReward.sass";

interface ParamProps {
  isModalRewardVisible: boolean;
  setModalRewardVisible: (value: boolean) => void;
  valueOpen: any;
  setAvailableMoney: (value: any) => void;
  isOwn: boolean;
}

// Modal reward
const ModalRewardCashBack = (params: ParamProps) => {
  const [confirmRedeem, setConfirmRedeem] = useState<boolean>(false);
  const [confirmFees, setConfirmFees] = useState<boolean>(true); // lo ponemos por defecto a verdadero, porque si es falso, el botón no se va a habilitar nunca
  const [visible, setVisible] = useState(false);
  const [checkPolicy, setCheckPolicy] = useState(false);
  const [openModalLegalB, setOpenModalLegalB] = useState(false);
  const [modalCaschback, setModalCaschback] = useState(false);
  const restApiClient = useContext(RestApiClientContext);

  //const userInfo = useUserInfo(); <-- El uso de esto hace que la página se recargue de nuevo entera

  const userContext = useContext(UserContext);

  const userId = userContext.id; 

  
  const [form] = Form.useForm();
  const { t } = useTranslation(["modalLogin", "profile", "reward"]);
  const getReward = useGetReward();
  const [hasBankDataChanged, setHasBankDataChanged] = useState(false);
  
  const [bankAccount, setBankAccount] = useState("");
  const getBankAccountData = () : void => {
    restApiClient.getBankAccount(userId).then((res) => {
      setBankAccount(res);
    });
  }
  const [countryBankAccount, setCountryBankAccount] = useState("");
  const getBankAccountCountry = () : void => {
    restApiClient.getBankAccountCountry(userId).then((res) => {
      setCountryBankAccount(res);
    });
  }

  const [currency, setCurrency] = useState("");
  const getBankAccountCurrency = () : void => {
    restApiClient.getBankAccountCurrency(userId).then((res) => {
      setCurrency(res);
    });
  }

  const [bankDataModal, setBankDataModal] = useState(false);
  const [amount, setAmount] = useState(0);
  const [chainPayTaxes, setChainPayTaxes] = useState(false);
  const [fee, setFee] = useState(0);
  const [renderModalBankData, setRenderModalBankData] = useState(false);

  useEffect(()=>{
    getBankAccountData();
    getBankAccountCountry();
    getBankAccountCurrency();
  },[])

  useEffect(() => {
    restApiClient.getChainPayTaxes().then((res) => {
      setChainPayTaxes(res);
      if (res == false) {
        // esto es necesario, porque por defecto confirm fees es verdadero, pero si aparece el checkbox, inicialmente hay que ponerlo a falso
        setConfirmFees(false);
      }
    });
  }, []);

  useEffect(() => {
    if (bankDataModal && !params.isOwn && amount > 0) {
      setRenderModalBankData(true);
    } else {
      setRenderModalBankData(false);
    }
  }, [bankDataModal, params.isOwn]);

  useEffect(() => {
    if (params.valueOpen) {
      const body = {
        currency: params.valueOpen?.currency.id,
        agentId: userId,
      } as CalculateFeeDto;

      restApiClient.calculateFee(body.currency, body.agentId).then((res) => {
        setFee(res);
      });
    }
  }, [params.valueOpen]);

  useEffect(() => {
    setVisible(params.isModalRewardVisible);
  }, [params.isModalRewardVisible]);

  const onFinish = async (values) => {
    setConfirmRedeem(false);
    setConfirmFees(false);
    if (
      (hasBankDataChanged || bankAccount == null || bankAccount == "") &&
      (params.isOwn == undefined || params.isOwn == false)
    ) {
      setAmount(convertStringToNumber(values.amount));
      setBankDataModal(true);
      params.setModalRewardVisible(false);
      setVisible(false);
    } else {
      // solo se hace, si  no es una recompensa propia y ya tenemos una cuenta y no se quiere cambiar los datos (post a rewards)
      let valueConver = convertStringToNumber(values.amount);
      getReward({
        idReward: params.valueOpen?.id,
        amount: valueConver,
        currency: params.valueOpen?.currency.id,
        ownReward: params.valueOpen?.own === true ? true : false,
      })
        .then((v) => {
          if (v.type !== null && v.type != "Error") {
            form.resetFields();
            params.setModalRewardVisible(false);
            setModalCaschback(true);
            setVisible(false);
          } else {
            if (v.code === "TransactionErrorDoingTransaction") {
              showNotification(
                "error",
                t("profile:bank-account.error"),
                t(
                  "profile:bank-account.description-provider-error-transaction"
                ),
                "topRight"
              );
            } else {
              showNotification(
                "error",
                t("profile:bank-account.error"),
                t("profile:bank-account.description-provider-error"),
                "topRight"
              );
            }
            form.resetFields();
            setConfirmRedeem(false);
            setConfirmFees(false);
            params.setModalRewardVisible(false);
            setVisible(false);
          }
        })
        .catch((error) => {
          setAmount(0);
          cleanForm();
        });
    }
  };

  const cleanForm = () => {
    setHasBankDataChanged(false);
    setCheckPolicy(false);
    setConfirmRedeem(false);
    setConfirmFees(false);
    form.resetFields();
  };

  const handleCancel = () => {
    cleanForm();
    setAmount(0);
    params.setModalRewardVisible(false);
    setVisible(false);
  };

  const openModal = (e) => {
    params.setModalRewardVisible(false);
    setVisible(false);
    setOpenModalLegalB(true);
    e.stopPropagation();
  };

  return (
    <>
      <IntlProvider locale={userContext.GetLanguageUser()}>
        <Modal
          title={t("reward:modal.title")}
          visible={visible}
          onCancel={handleCancel}
          footer={
            <div className="body-modal-reward">
              <div className="col-1 display-xs-reward"></div>
              <div className="footer-modal-reward col-md-10 col-12">
                {params.valueOpen?.messageMinMaxAmount}
              </div>
              <div className="col-1 display-xs-reward"></div>
            </div>
          }
          key="1"
          className="box-modal-reward"
        >
          <div className="body-modal-reward">
            <div className="col-1 display-xs-reward"></div>
            <div className="col-md-10 col-12">
              <div className="col-12 img-modal-reward padding-elements-form-reward">
                <img src={params.valueOpen?.img} />
              </div>
              <div className="col-12 col-sm-8 mb-3 padding-elements-form-reward-green">
                {params.valueOpen?.increment > 0 && (
                  <div className="box-max-amount-modal margin-element">
                    {ReactHtmlParser(
                      t("reward:table-result.max-amount-percentage", {
                        0: params.valueOpen?.increment,
                      })
                    )}
                  </div>
                )}
              </div>
              <div className="col-12 margin-element mt-3 mb-3 padding-elements-form-reward">
                {t("reward:modal.have")}
                <strong>
                  <FormattedNumber
                    useGrouping={true}
                    value={params.valueOpen?.amount}
                    style="currency"
                    currency={params.valueOpen?.currency.id}
                  />
                </strong>
                {t("reward:modal.sentence", { 0: params.valueOpen?.name })}
              </div>
              <div className="col-12 mb-3 padding-elements-form-reward">
                <div className="margin-element">
                  <strong>{t("reward:modal.label")}</strong>
                </div>
              </div>
              <Form
                name="basic"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                form={form}
              >
                <Form.Item
                  name="amount"
                  className="col-12 row-form-item margin-element"
                  rules={[
                    {
                      required: true,
                      message: t("reward:modal.validations.required"),
                    },
                    {
                      whitespace: true,
                      message: t("reward:modal.validations.empty"),
                    },
                    {
                      validator: (_, value) =>
                        value &&
                        convertStringToNumber(value) >
                          params.valueOpen?.maximumAmount
                          ? Promise.reject(
                              t(
                                "reward:modal.validations.redeem-more-money-provider"
                              )
                            )
                          : value &&
                            convertStringToNumber(value) <
                              params.valueOpen?.minimumAmount
                          ? Promise.reject(
                              t(
                                "reward:modal.validations.redeem-less-money-provider"
                              )
                            )
                          : value
                          ? ONLY_NUMBER_D.test(value)
                            ? NO_CHART_REPITED.test(value)
                              ? NO_CHART_REPITED_AND_NUMBER_DECIMAL.test(value)
                                ? value === "0"
                                  ? Promise.reject(
                                      t("reward:modal.validations.zero")
                                    )
                                  : convertStringToNumber(value) >
                                    params.valueOpen?.amount
                                  ? Promise.reject(
                                      t(
                                        "reward:modal.validations.redeem-more-money-have"
                                      )
                                    )
                                  : params.valueOpen?.maxRedeemable !== null &&
                                    params.valueOpen?.minRedeemable !== null
                                  ? convertStringToNumber(value) >
                                    params.valueOpen?.maxRedeemable
                                    ? Promise.reject(
                                        t(
                                          "reward:modal.validations.redeem-more-money-provider"
                                        )
                                      )
                                    : convertStringToNumber(value) <
                                      params.valueOpen?.minRedeemable
                                    ? Promise.reject(
                                        t(
                                          "reward:modal.validations.redeem-less-money-provider"
                                        )
                                      )
                                    : Promise.resolve()
                                  : Promise.resolve()
                                : Promise.reject(
                                    t("reward:modal.validations.num-decimals")
                                  )
                              : Promise.reject(
                                  t("reward:modal.validations.formated")
                                )
                            : Promise.reject(
                                t("reward:modal.validations.number")
                              )
                          : Promise.resolve(),
                    },
                  ]}
                >
                  <Input
                    size="large"
                    className="main-input amount"
                    id="amount"
                    placeholder={numberFormat(
                      0.0,
                      userContext.GetLanguageUser()
                    )}
                    suffix={params.valueOpen?.simbol}
                    value={amount}
                    onChange={(e) =>
                      setAmount(convertStringToNumber(e.target.value))
                    }
                  />
                </Form.Item>
                {
                  //Checkbox para validar que se ha leido la politica de privacidad
                  params.valueOpen?.legalB && (
                    <Form.Item
                      className="col-12 margin-element-check mt-3"
                      name="legal"
                      valuePropName="checked"
                      rules={[
                        {
                          validator: (_, value) =>
                            value
                              ? Promise.resolve()
                              : Promise.reject(
                                  t("reward:modal.validations.check")
                                ),
                        },
                      ]}
                    >
                      <Checkbox
                        id="check-policy"
                        className="main-input box-check-reward"
                        checked={checkPolicy}
                      >
                        <label htmlFor="thing" onClick={openModal}>
                          <div className="align-text-check-reward">
                            {t("reward:modal.linkModal-1")}
                            <span className="span-legal-b">
                              {t("reward:modal.linkModal-2")}
                            </span>
                            {t("reward:modal.linkModal-3")}
                          </div>
                        </label>
                      </Checkbox>
                    </Form.Item>
                  )
                }

                <Form.Item className="col-12 margin-element mt-4">
                  <Button
                    id="btn-form-login"
                    className="col-12 primary-button"
                    type="primary"
                    size="large"
                    disabled={!confirmRedeem || !confirmFees}
                    htmlType="submit"
                  >
                    {t("reward:table-result.button")}
                  </Button>

                  {bankAccount && (
                    <Alert
                      style={{
                        textAlign: "justify",
                      }}
                      className="cashback-alert-message"
                      message={
                        <div>
                          {t("reward:modal.payment-method")}{" "}
                          <strong> {bankAccount}</strong>
                          <Checkbox
                            style={{ marginTop: "7px" }}
                            checked={hasBankDataChanged}
                            onChange={(e) => {
                              setHasBankDataChanged(e.target.checked);
                            }}
                          >
                            {t("reward:modal.change-payment-method")}
                          </Checkbox>
                        </div>
                      }
                      type="info"
                      showIcon
                    ></Alert>
                  )}
                </Form.Item>
              </Form>
              <div className="col-12 subtitle-modal-reward mb-3">
                {t("reward:modal.max")}
                {params.valueOpen?.minimumAmount ? (
                  <FormattedNumber
                    value={params.valueOpen?.minimumAmount}
                    style="currency"
                    currency={params.valueOpen?.currency.id}
                  />
                ) : (
                  0
                )}
                {t("reward:modal.min")}
                {params.valueOpen?.maximumAmount ? (
                  <FormattedNumber
                    value={params.valueOpen?.maximumAmount}
                    style="currency"
                    currency={params.valueOpen?.currency.id}
                  />
                ) : (
                  0
                )}

                <div style={{ marginTop: "10px" }}>
                  {!chainPayTaxes && (
                    <Checkbox
                      checked={confirmFees}
                      onChange={(e) => {
                        setConfirmFees(e.target.checked);
                      }}
                    >
                      {ReactHtmlParser(
                        t("reward:modal.checkbox-money-transfer", {
                          0: fee,
                          1: params.valueOpen?.currency.id,
                        })
                      )}

                      {amount - fee > 0 &&
                        amount <= params.valueOpen?.maximumAmount &&
                        amount >= params.valueOpen?.minimumAmount &&
                        ReactHtmlParser(
                          t("reward:modal.checkbox-money-transfer-total", {
                            2: (amount - fee).toFixed(2),
                            1: params.valueOpen?.currency.id,
                          })
                        )}
                    </Checkbox>
                  )}
                </div>
                <div style={{ marginTop: "10px" }}>
                  <Checkbox
                    checked={confirmRedeem}
                    onChange={(e) => {
                      setConfirmRedeem(e.target.checked);
                    }}
                  >
                    {t("reward:modal.confirm-redeem")}
                  </Checkbox>
                </div>
              </div>
            </div>
            <div className="col-1 display-xs-reward"></div>
          </div>
        </Modal>

        {renderModalBankData && (
          <ModalBankData
            isModalBankDataVisible={bankDataModal && !params.isOwn}
            setModalBankDataVisible={setBankDataModal}
            idReward={params.valueOpen?.id}
            amount={amount}
            ownReward={false}
            bankAccount={bankAccount}
            bankAccountCurrency={currency}
            originalCurrency={params.valueOpen?.currency.id}
            bankAccountCountry={countryBankAccount}
          />
        )}

        {modalCaschback && (
          <ModalCashBackCreated
            isModalCashbackVisible={modalCaschback}
            setIsModalCashbackVisible={setModalCaschback}
            bankAccount={bankAccount}
          />
        )}

        {openModalLegalB && (
          <ModalLegal
            legaBText={params.valueOpen?.legaBText}
            setVisible={setVisible}
            setOpenModalLegalB={setOpenModalLegalB}
          />
        )}
      </IntlProvider>
    </>
  );
};
export default ModalRewardCashBack;
