import React from "react";
import "../../i18n";
import "./rewardGenericBalance.sass";
import { IntlProvider, FormattedNumber } from "react-intl";
import { useAccumulatedMoney } from "src/api/api";

const AccumulatedMoney = () => {
  const accumulatedMoney = useAccumulatedMoney();
  return (
    <>
      <FormattedNumber
        useGrouping={true}
        value={accumulatedMoney.money}
        style="currency"
        currency={accumulatedMoney.currencies.id}
      />
    </>
  );
};

export default AccumulatedMoney;
