import React, { useState, useEffect, useCallback } from "react";
import * as AjaxAPI from "../../../api/ajaxApi";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import "./alternativeUserNames.sass";
import SuggestionsInput from "../../utils/suggestionsInputComponent/suggestionsInputComponent";
import { useGetAvailableUserName } from "src/api/api";

// Componente que obtiene una sugetencia de nombres de usuario cuando el mismo se registra en el sistema
const AlternativeUserNames = (props) => {
  const { t } = useTranslation(["signupAgents", "ajaxApi"]);

  const [userName, setUserName] = useState("");
  const [suggestionTitle, setSuggestionTitle] = useState("");
  const [suggestionTitleType, setSuggestionTitleType] = useState("");
  const [suggestionSubTitle, setSuggestionSubTitle] = useState("");
  const [availableUserNames, setAvailableUserNames] = useState([]);

  const getAvailableUserName = useGetAvailableUserName();

  useEffect(() => {
    setUserName(props.default);
    const testUsername = async (username) => {
      const isAvailable = await getAvailableUserName(userName);
      if (isAvailable.data) {
        setSuggestionTitle(t("alternative-usernames.free-username"));
        setSuggestionTitleType("info");
        setSuggestionSubTitle("");
      } else {
        setSuggestionTitle(t("alternative-usernames.used-username"));
        setSuggestionTitleType("alert");
        setSuggestionSubTitle(t(""));
      }
    };

    testUsername(props.default);
  }, [props.default]);

  // Fired when the user write in input
  const onChangeText = (event) => {
    setUserName(event.target.value);
    props.onSelectOrCloseAlternativeUserName(event.target.value);
  };

  // Fired when the user select from suggested list
  const onSelectText = (text) => {
    setUserName(text);
    props.onSelectOrCloseAlternativeUserName(text);
  };

  return (
    <SuggestionsInput
      suggestions={availableUserNames}
      placeholder=""
      id="userName"
      onChange={onChangeText}
      onSelect={onSelectText}
      value={userName}
      suggestionTitle={suggestionTitle}
      suggestionTitleType={suggestionTitleType}
      suggestionSubTitle={suggestionSubTitle}
      trigger="focus"
    />
  );
};

export default AlternativeUserNames;
