import * as React from 'react';
import { useContext } from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import './deleteAccount.sass'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { ConfigContext } from '../../hooks/context/config';

const { Paragraph, Title } = Typography;

// Componente visual de cuenta eliminada
const DeleteAccount = () => {

    const { t } = useTranslation(['deleteAccount']);
    const infoClient = useContext(ConfigContext);

    return (
        <div className="container mt-5 special-mb">
            <div className="background-color-delete align-elements">
                <FontAwesomeIcon 
                    className={ "icons-delete-account" }
                    icon={ faCheckCircle } 
                />
                <Title level={ 3 }> { t('deleteAccount:title', { 0 : infoClient.name }) } </Title>
                <Paragraph className="info-delete">
                    <strong>{ t('deleteAccount:text') }</strong>
                </Paragraph>
            </div>             
        </div>
    );
}

export default DeleteAccount;
