import * as React from "react";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Menu, Dropdown, Button } from "antd";
import "./combo-language.sass";
import "../../i18n";
import { AllLanguages, LangContext } from "../../hooks/context/lang";
import { DownOutlined } from "@ant-design/icons";
import { NavLink, useLocation } from "react-router-dom";
import { AppLanguage } from "../../routes/appLanguages/index";
import { appStrings } from "../../routes/localizations/index";
import { useIntl } from "react-intl";

// Componente que recoge la lista de idiomas a seleccionar en la aplicación
const MenuLanguages = (param) => {
  // Libreria i18n para traducir
  const { i18n } = useTranslation();
  // Contexto de los idiomas soportados por el sistema
  const allLang = useContext(AllLanguages);
  // Contexto con el idioma del usuario
  const userLangContext = useContext(LangContext);
  // Obtenemos la lista de idimas del contexto
  let userLang = userLangContext.GetLangContext();
  // Filtro para marcar en el combo el idioma seleccionado por el usuario
  const allLangUserSelect = allLang.GetLanguage().filter((e) => e.id.includes(userLang));
  // Estado para el combo y poder actualizarlo si se cambia
  const [langComboUser, setLangComboUser] = useState(allLangUserSelect);

  // Hooks para contruir las rutas y poder actualizar las url si se cambia de idioma
  const { pathname } = useLocation();
  const { locale, messages } = useIntl();

  // Funcion para el cambio de idioma
  const changeLanguage = (key) => {
    // Actualiza el contexto del idioma del usuario
    userLangContext.UpdateLangContext(key);

    // Actualiza el idima en el que se visualiza la app
    i18n.changeLanguage(key);

    // Actualiza el combo de la seleccion del idima con el nuevo idima seleccionado por el usuario
    setLangComboUser(allLang.GetLanguage().filter((e) => e.id.includes(key)));
    userLang = userLangContext.GetLangContext();

    // Se cierra el menú lateral en versiones móviles en el caso de que sea distinto de null
    if (param.onClose !== null) {
      param.onClose();
    }
  };

  // Monta las rutas que se pondran en cada una de las opciones del combo
  const getMatchingRoute = (language: string) => {
    if (!language) return false;
    // Obtenemos la clave de la ruta en la que se encuentra el usuario actualmente
    const [, route] = pathname.split("/" + locale);
    const routeKey = Object.keys(messages).find((key) => messages[key] === route);

    // Filtramos la ruta correspondiente para el nuevo idioma
    let prefix : any = appStrings[language];

    if (!prefix){
      console.error("I18N Language not found: ", language, appStrings);
      prefix = appStrings["es"];
    }

    const matchingRoute = appStrings[language][routeKey];

    // Devolvemos la url
    return `/${language}` + matchingRoute;
  };

  const menu = (
    <Menu>
      {allLang.GetLanguage().map((lang) => (
        <Menu.Item key={lang.id}>
          <NavLink to={getMatchingRoute(AppLanguage[lang.id.split("-")[0].toLowerCase()])}>
            <div className="display-flag-menu" onClick={() => changeLanguage(lang.id)}>
              <div className="secondary-links flag-combo">{lang.name}</div>
            </div>
          </NavLink>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <LangContext.Provider value={userLangContext}>
      <div className="padding-link secondary-links p-0" id="selectLang">
        <Dropdown overlay={menu}>
          <Button id="btn-languages" className="no-shadow">
            <div>{allLangUserSelect[0].name}</div>
            <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    </LangContext.Provider>
  );
};

export default MenuLanguages;
