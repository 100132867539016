import React, { useState, useEffect, useContext } from 'react';
import './index.sass';
import '../../../i18n';
import Footer from '../../footer/footer';
import HeaderPrivate from './header/header';
import LayoutBasic from '../private/layout/layout';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../hooks/context/userInfo';
import { linkActiveContext } from '../../../hooks/context/linkActive';

// Componente Base de la parte publica de nuestra aplicación
const BasePrivate = () => {

  // Libreria para el manejador de idioma de la app
  const { i18n } = useTranslation();

  const [collapsedState, setCollapsedState] = useState(false);

  const userContext = useContext(UserContext);

  const userLanguage = userContext.GetLanguageUser();

  const toggle = () => {
    setCollapsedState(!collapsedState);
  };

  // Actualizar la libreria i18n para el idioma de la web
  useEffect(() => {
    i18n.changeLanguage(userLanguage);
  }, []);

  const [active, setActive] = useState(window.location.pathname);

  return (
    <linkActiveContext.Provider
      value={
        {
          active, updateValueUrl: (url) => {
            setActive(url)
          }
        }
      }
    >
      <div className="main-content-private">
        <HeaderPrivate
          toggle={ toggle }
          collapsedState={ collapsedState }
          active={ active }
          setActive={ setActive }
        />
        <LayoutBasic
          collapsedState={ collapsedState }
          active={ active }
          setActive={ setActive }
        />
      </div>
      <Footer
        zone={ "private" }
        active={ active }
        setActive={ setActive }
      />
    </linkActiveContext.Provider>
  );
}

export default BasePrivate;
