import * as React from "react";
import { useContext, useState } from "react";
import { UserContext } from "../../../hooks/context/userInfo";
import { useTranslation } from "react-i18next";
import { Table, Typography, Button, Skeleton } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import CustomPagination from "../../utils/pagination/pagination";
import Pagination from "../../../models/pagination/pagination";
import Order from "../../../models/order/order";
import { FormattedNumber } from "react-intl";
import { getLocaleDatePicker } from "../../../utils/datePicker";
import { PickerLocale } from "antd/es/date-picker/generatePicker";
import moment from "moment";
import "./rewardTableResult.sass";
import { showWarning } from "../../../utils/modalsGenerics";
import ModalRecoverCheck from "../modalRecoverCheck/modalRecoverCheck";
import { useRecoverCheck } from "src/api/api";
import { showNotification } from "src/utils/notificationsGeneric";

const { Paragraph } = Typography;

// Componente tabla con los cheques canjeados anteriormente
const RewardTableResult = (param) => {
  const { t } = useTranslation(["reward"]);
  const [openModalRecoverCheck, setOpenModalRecoverCheck] = useState(false);
  const [dataModalRecoverCheck, setDataModalRecoverCheck] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const local: PickerLocale = getLocaleDatePicker();
  const userContext = useContext(UserContext);

  const recoverCheck = useRecoverCheck();

  const openModalReset = async (record) => {
    const res = await recoverCheck({ idAgentReward: record.id });
    if (res.data) {
      showNotification("success", res.title, res.message, "topRight");
    } else {
      showNotification("error", res.title, res.message, "topRight");
    }
  };

  const expandedRowRender = (record) => {
    return (
      <>
        <div className="row table-container">
          <div className="col-6 table-cell-container cell-edit">
            <Paragraph className="text-expanded">
              {t("table.expandedRowRender")}
            </Paragraph>
          </div>
          <div className="col-6 table-cell-container cell-edit">
            <Button
              id="btn-edit-reward"
              className="default-button"
              onClick={() => openModalReset(record)}
              icon={
                <FontAwesomeIcon
                  className="edit-icon-input-reward-table"
                  icon={faUndo}
                />
              }
            >
              {t("table.button-expanded")}
            </Button>
          </div>
        </div>
      </>
    );
  };

  const cols: any = [
    {
      title: t("reward:table.header-table.date"),
      dataIndex: "dateCheck",
      width: 200,
      sorter: {
        compare: (a, b) => a.dateCheck - b.dateCheck,
        multiple: 2,
      },
      render: (text, row, index) => {
        return (
          <div key={index}>
            {moment(param.result.rewards[index].date).format(local.dateFormat)}
          </div>
        );
      },
    },
    {
      title: t("reward:table.header-table.type-name"),
      dataIndex: "typeCheck",
      width: 200,
      render: (text, row, index) => {
        return (
          <img
            key={index}
            className="img-table-reward"
            src={param.result.rewards[index].imagen}
          />
        );
      },
    },
    {
      title: t("reward:table.header-table.value"),
      dataIndex: "valueCheck",
      width: 200,
      sorter: {
        compare: (a, b) => a.valueCheck - b.valueCheck,
        multiple: 1,
      },
      render: (text, row, index) => {
        return (
          <div key={index} className="amount-table">
            <FormattedNumber
              useGrouping={true}
              value={param.result.rewards[index].totalAmount}
              style="currency"
              currency={userContext.GetCurrency()}
            />
          </div>
        );
      },
    },
  ];

  // Función que se dispara en el evento de cambiar el elemento de paginación, de ordenación o de búsqueda de la tabla
  const onChangeCallApi = (pagination, filters, sorter, extra, conf = null) => {
    // Ordenación
    if (typeof sorter !== "undefined") {
      const ord = [];

      if (Array.isArray(sorter)) {
        for (let i of sorter) {
          ord.push(new Order(i.field, i.order));
        }
      } else {
        ord.push(new Order(sorter.field, sorter.order));
      }
      // Usuario pulsa cabecera de la tabla
      param.setOrderResults(ord);

      // Paginación
    } else {
      const pag = new Pagination(pagination.current, pagination.pageSize);

      // Usuario pulsa página
      param.setPageResults(pag);
    }
  };

  const updateExpandedRows = (expanded, element) => {
    const key = element.id;
    if (expanded && !expandedRowKeys.includes(key)) {
      expandedRowKeys.push(key);
    } else if (!expanded && expandedRowKeys.includes(key)) {
      const index = expandedRowKeys.indexOf(key);
      if (index > -1) {
        expandedRowKeys.splice(index, 1);
      }
    }
    setExpandedRowKeys(expandedRowKeys);
  };

  return (
    <>
      <div className="col-12 p-0 table-reward-display">
        <Table
          id="table-reward"
          className="col-12 padding-table"
          columns={cols}
          dataSource={param.result.rewards.map((element) => ({
            ...element,
            key: element.id,
          }))}
          pagination={false}
          expandable={{
            expandedRowRender,
            expandedRowKeys: expandedRowKeys,
            onExpand: updateExpandedRows,
          }}
          onChange={onChangeCallApi}
        />
      </div>
      <div className="col-12 p-0 pagination-container mt-4">
        <CustomPagination
          countData={param.result.totalRecord}
          callApiChange={onChangeCallApi}
          pageResults={param.pageResults}
        />
      </div>

      {openModalRecoverCheck && (
        <ModalRecoverCheck
          record={dataModalRecoverCheck}
          setOpenModalRecoverCheck={setOpenModalRecoverCheck}
        />
      )}
    </>
  );
};

export default RewardTableResult;
