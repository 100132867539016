import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import MenuLanguages  from '../../../../languageSelector/comboLanguage';
import { Link } from 'react-router-dom';
import { Drawer, Menu } from 'antd';
import { ConfigContext } from '../../../../../hooks/context/config';
import '../header.sass';
import ModalLogin from '../../../../modalsLogin/login/login'
import './mobileMenu.sass';
import { LINKS_MOBILE_PUBLIC } from '../../../../../constants/constants';
import { faUser, faBars, faStar, faHome, faQuestionCircle, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { linkActiveContext } from '../../../../../hooks/context/linkActive';
import { cleanUp } from '../../../../../utils/cleanUp';

// Menú vislble para versiones tablet o móvil (de MD para abajo)
const MobileMenu = (params) => {
   
    // Libreria i18n para traducir
    const { t } = useTranslation(['urls', 'linksNames']);
    const linkActive = useContext(linkActiveContext);

    // Estado para mostrar o ocultar modal cookie
    const [modalLogin, setmodalLogin] = useState(false);

    // Control para desplegar o ocultar menu lateral
    const [visible, setVisible] = useState(false);

    // Contexto con la configuracion
    const theme = useContext(ConfigContext);

    // Se muestra el menu lateral
    const showDrawer = () => {
        setVisible(true);
    };

    // Se oculta el menu lateral
    const onClose = (e) => {
        setVisible(false);
        linkActive.updateValueUrl(t("urls:public."+ e));
    };

    // Estados para el manejador de la funcionalidad del menu cabecera que se ocultara al hacer scroll hacia la parte inferior, pero que en el momento de subir se vuelve a mostar
    const [top , setTop] = useState("no-fixed");
    const [space , setSpace] = useState("div-aux-no-space");
    const [ascend , setascend] = useState(0);
    let url = window.location.pathname + window.location.hash;
    
    if (url.substring(url.length, url.length - 1) === '/') {
        url = url.substring(0, url.length - 1);      
    }

    // Ponemos a la escucha el scroll
    useEffect(() => {
        cleanUp();
        window.addEventListener('scroll', scrollTop);
        return () => window.removeEventListener('scroll', scrollTop);
    });

    // Comprobamos si asciende o desciende
    const scrollTop = () => {
        if (window.pageYOffset > ascend) {
            setTop("no-fixed");
            setSpace("div-aux-no-space");
            setascend(window.pageYOffset);
        } else {
            setTop("fixed");
            setSpace("div-aux-space");
            setascend(window.pageYOffset);
        }
    }

    const icons = [faHome, faStar, faQuestionCircle, faPhoneAlt];
   
    return (
        <>
            <div className={ top + " col-12 box-header-mobile" }>
                <div className="col-2 col-sm-3 menuCon position-icon padding-left-header width-items">
                    <div className="vertical-center">
                        <FontAwesomeIcon 
                            onClick={ showDrawer } 
                            className="icons-color" 
                            icon={ faBars } 
                        />
                    </div>
                    <Drawer
                        placement="left"
                        closable={ false }
                        onClose={ onClose }
                        visible={ visible }
                    >
                        <Menu mode="inline">
                            {
                                LINKS_MOBILE_PUBLIC.map((element, index) => {   
                                    return (
                                        <Menu.Item 
                                            key={ index } 
                                            className={ "padding-elements-link" + ((window.location.pathname + window.location.hash) == t("urls:public." + element) ? " disabled-li" : "")}
                                        >
                                            <Link
                                                id={ "link-public-mobile-" + element + "-" + index }
                                                key={ index }
                                                to={ index === 0 ? t("urls:public.home") : t("urls:public." + element) }
                                                className={ (window.location.pathname + window.location.hash) == t("urls:public."+ element) ? "secondary-links active" : "secondary-links" }
                                                disabled={ (window.location.pathname + window.location.hash) == t("urls:public."+ element) }
                                                onClick={ () => onClose(element) }
                                            >
                                                <div className="box-link-menu">
                                                    <div className="padding-link-desktop-public width-menu-mobile">
                                                        <FontAwesomeIcon 
                                                            className="icons-color-menu icons-color-marging" 
                                                            icon={ icons[index] } 
                                                        />
                                                        { t("linksNames:" + element) }
                                                    </div>
                                                </div>
                                            </Link>    
                                        </Menu.Item>
                                    )
                                })
                            }

                            <div className="box-link-menu">
                                <div className="vertical-center-draw secondary-links">
                                    <MenuLanguages 
                                        key="2" 
                                        onClose={ onClose }
                                    />
                                </div>
                            </div>
                        </Menu>
                    </Drawer>
                </div>
                <div className="col-8 col-sm-6 p-0 logo width-items">
                    <div className="vertical-center-logo">
                        <Link
                            id="link-public-main-logo"
                            className="logo-mobile"
                            to={ t("urls:public.home") }
                            onClick={ () => linkActive.updateValueUrl(t("urls:public.home")) }
                            disabled={ (window.location.pathname + window.location.hash) == t("urls:public.home") }
                        >
                            <img className="logo" src={ theme.logo } title={ theme.name } alt={ theme.name } />
                        </Link>
                    </div>
                </div>
                <div className="col-2 col-sm-3 sesion-init position-login-button padding-right-header width-items">
                    <div className="vertical-center-logo">
                        <div className="secondary-links-svg" onClick={ () => setmodalLogin(true) }>
                            <FontAwesomeIcon className="icons-color-menu" icon={ faUser } />
                        </div>
                    </div>
                </div>
            </div>
            <div className={ space }></div>
            <div>
                { modalLogin && <ModalLogin setmodalLogin={ setmodalLogin } /> }
            </div>
        </>
    );
}

export default MobileMenu;
