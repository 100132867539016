import EndPointConfigOld, { MockDataOld } from '../../models/api/endPointConfigOld';

import * as reservationsMocks from '../mocks/reservationsMocks.json';
import * as statesReservationsMocks from '../mocks/reservationStates.json';
import * as channelMocks from '../mocks/channelsMocks.json';
import * as hotelsMocks from '../mocks/hotelsMocks.json';
import * as numRooms from '../mocks/numRoomsMocks.json';
import * as dateDisable from '../mocks/dateDisableMocks.json';
import * as addChannelMocks from '../mocks/addChannelMocks.json';
import * as sendDataResponseRegularizationMocks from '../mocks/sendDataResponseRegularizationMocks.json'
import * as sendEditReservationRegularized from '../mocks/sendEditReservationRegularized.json'
import EndPointConfig from '../../models/api/endPointConfig';

const controllerTTOO = 'TTOO';
const controllerEstablishment = 'Establishments';
const controllerCustomization = 'Customizations';

/// Define all EndPoints for register agents methods.
export const RegularizationsEP : EndPointConfigOld[] = [

    new EndPointConfig()
        .setMethod('GET')
        .setController(controllerTTOO)
        .setAlias('GetChannels'),

    new EndPointConfig()
        .setMethod('GET')
        .setController(controllerEstablishment)
        .setAlias('GetHotels'),

    new EndPointConfig()
        .setMethod('GET')
        .setController(controllerCustomization)
        .setActionName('limitnumberrooms')
        .setAlias('GetNumberRooms'),

    new EndPointConfig()
        .setMethod('GET')
        .setController(controllerCustomization)
        .setActionName('mindatecheckin')
        .setAlias('GetDateDisable'),

    new EndPointConfig()
        .setMethod('POST')
        .setController(controllerTTOO)
        .setAlias('AddNewTTOO'),


    // Get States
    new EndPointConfigOld('GET', 'Regularizations', 'GetStates', new MockDataOld(statesReservationsMocks)),
    // Get Reservations
    new EndPointConfigOld('GET', 'Regularizations', 'GetReservations', new MockDataOld(reservationsMocks)),
    // Get list channels
    new EndPointConfigOld('GET', 'Regularizations', 'GetChannels', new MockDataOld(channelMocks)),
    // Get list hotels
    new EndPointConfigOld('GET', 'Regularizations', 'GetHotels', new MockDataOld(hotelsMocks)),
    // Get list number rooms
    new EndPointConfigOld('GET', 'Regularizations', 'GetNumRooms', new MockDataOld(numRooms)),
    // Add new channel
    new EndPointConfigOld('GET', 'Regularizations', 'AddChannel', new MockDataOld(addChannelMocks)),
    // Get date disable
    new EndPointConfigOld('GET', 'Regularizations', 'getDateDisable', new MockDataOld(dateDisable)),
    // Envio de formulario a la API
    new EndPointConfigOld('GET', 'Regularizations', 'SendFormAddReservation', new MockDataOld(sendDataResponseRegularizationMocks)),
    // Envio de formulario de editar reserva regularizada
    new EndPointConfigOld('GET', 'Regularizations', 'SendEditReservation', new MockDataOld(sendEditReservationRegularized)),
];
