import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import CustomPagination from "../../utils/pagination/pagination";
import Pagination from "../../../models/pagination/pagination";
import { Typography, List } from "antd";
import ModalRecoverCheck from "../modalRecoverCheck/modalRecoverCheck";
import "./rewardResultList.sass";
import { FormattedNumber } from "react-intl";
import moment from "moment";
import { PickerLocale } from "antd/lib/date-picker/generatePicker";
import { getLocaleDatePicker } from "../../../utils/datePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";
import { useAvailableMoney, useRecoverCheck } from "src/api/api";
import { showNotification } from "src/utils/notificationsGeneric";

const { Paragraph } = Typography;

// Componente List con cheques ya canjeados anteriormente (Historico de recompensas)
const RewardResultList = (param) => {
  const totalData: any[] = param.result.rewards;
  const [openModalRecoverCheck, setOpenModalRecoverCheck] = useState(false);
  const [dataModalRecoverCheck, setDataModalRecoverCheck] = useState(null);

  const local: PickerLocale = getLocaleDatePicker();

  const { t } = useTranslation(["reward"]);
  const availableMoney = useAvailableMoney()

  const recoverCheck = useRecoverCheck()
  
  const openModalReset = async (record) => {
    const res = await recoverCheck({ idAgentReward: record.id })
    if (res.data) {
      showNotification('success', res.title, res.message, "topRight");
    } else {
      showNotification('error', res.title, res.message, "topRight");
    }
  };

  // Función que se dispara en el evento de cambiar el elemento de paginación de la lista
  const onChangeCallApi = (pagination) => {
    const pag = new Pagination(pagination.current, pagination.pageSize);

    // Usuario pulsa página
    param.setPageResults(pag);
  };

  return (
    <>
      <div className="mt-3 mb-3">
        <List
          itemLayout="vertical"
          className="col-12 mt-3"
          size="large"
          dataSource={totalData}
          renderItem={(item) => (
            <List.Item
              key={item.key}
              id="list-panel-reward"
              className="list-panel-content"
            >
              <List.Item.Meta
                avatar={
                  <div>
                    {
                      <FontAwesomeIcon
                        className="edit-icon-input-reward-list"
                        icon={faUndo}
                        onClick={() => openModalReset(item)}
                      />
                    }
                  </div>
                }
                description={
                  <>
                    <div className="col-12 padding-box-info-list">
                      <div className="col-12 text-align-end">
                        <Paragraph className="mb-0 color-title">
                          {t("reward:list.date")}
                        </Paragraph>
                        <Paragraph className="">
                          <span className="color-data">
                            {moment(item.date).format(local.dateFormat)}
                          </span>
                        </Paragraph>
                      </div>
                      <div className="col-12 margin-top-box-img">
                        <div className="col-12 mb-2 position-img-list p-0">
                          <img
                            className="img-list-reward"
                            src={item.imagen}
                            alt="img-check"
                          />
                        </div>
                        <div className="col-12 mb-3 margin-top-box-text">
                          <Paragraph className="mb-0 color-title">
                            {t("reward:list.value")}
                          </Paragraph>
                          <span className="color-data font-w">
                            <FormattedNumber
                              useGrouping={true}
                              value={item.totalAmount}
                              style="currency"
                              currency={availableMoney.currencies.id}
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </>
                }
              />
            </List.Item>
          )}
        />

        <div className="col-12 pagination-container mt-4">
          <CustomPagination
            countData={param.result.totalRecord}
            callApiChange={onChangeCallApi}
            pageResults={param.pageResults}
          />
        </div>

        {openModalRecoverCheck && (
          <ModalRecoverCheck
            record={dataModalRecoverCheck}
            setOpenModalRecoverCheck={setOpenModalRecoverCheck}
          />
        )}
      </div>
    </>
  );
};

export default RewardResultList;
