import * as React from "react";
import { useState, useEffect } from "react";
import { Typography, Input, Collapse, Button } from "antd";
import "./search-agency.sass";
import Results from "./results";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import FormNewAgency from "../newAgency/newAgency";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const { Title, Paragraph } = Typography;
const { Panel } = Collapse;

const SearchAgency = (props) => {
  const { t } = useTranslation(["searchAgency", "ajaxApi"]);

  const columns = [
    {
      title: t("search-form.commercial-name"),
      dataIndex: "name",
    },
    {
      title: t("search-form.address"),
      dataIndex: "address",
    },
    {
      title: t("search-form.phone"),
      dataIndex: "telephone",
    },
    {
      title: t("search-form.tax-id"),
      dataIndex: "identityCode",
    },
  ];

  const [openNewAgency, setOpenNewAgency] = useState(false);
  // Estado que almacena la página actual y el número de elementos por página de la paginación en la tabla y lista
  const [pageResults, setPageResults] = useState(props.pageResultsInput);
  const [searchTermName, setSearchTermName] = useState(props.searchTermNameInput);
  const [searchTermAddress, setSearchTermAddress] = useState(props.searchTermAddressInput);
  const [searchTermPhone, setSearchTermPhone] = useState(props.searchTermPhoneInput);
  const [searchTermTaxid, setSearchTermTaxid] = useState(props.searchTermTaxidInput);
  const [searchTermKey, setSearchTermKey] = useState(props.selectedData.key);

  const handleChangeName = (e) => {
    setSearchTermName(e.target.value);
    props.setSearchTermNameInput(e.target.value);
  };

  const handleChangeAddress = (e) => {
    setSearchTermAddress(e.target.value);
    props.setSearchTermAddressInput(e.target.value);
  };

  const handleChangePhone = (e) => {
    setSearchTermPhone(e.target.value);
    props.setSearchTermPhoneInput(e.target.value);
  };

  const handleChangeTaxid = (e) => {
    setSearchTermTaxid(e.target.value);
    props.setSearchTermTaxidInput(e.target.value);
  };

  const [forceRefreshData, setForceRefreshData] = useState<boolean>(true);
  const [newAgencyTaxId, setNewAgencyTaxId] = useState<string>('');

  useEffect(()=>{
    if (!forceRefreshData && newAgencyTaxId.length > 0){
        setOpenNewAgency(false);

        //Limpiamos todos los campos excepto el tax id:
        props.setSearchTermNameInput('');
        props.setSearchTermAddressInput('');
        props.setSearchTermPhoneInput('');
        setSearchTermName('');
        setSearchTermAddress('');
        setSearchTermPhone('');
        props.setSearchTermTaxidInput(newAgencyTaxId);
        setSearchTermTaxid(newAgencyTaxId);

        setTimeout(()=>{
          setForceRefreshData(true);
        }, 1000)
    }
      
  },[forceRefreshData, newAgencyTaxId])

  const onAgencyCreated = (taxId: string) : void => {
    /**
     * Si doy de alta una agencia accediendo al formulario desde el enlace de "solicita el alta de tu agencia" 
     * que aparece cuando escribes un nombre que devuelve pocos resultados termina aquí
     * 
     * ¿Por qué? Nunca lo sabremos!
     * 
     */
    setForceRefreshData(false);
    setNewAgencyTaxId(taxId);
  }

 

  return (
    <>
      <Title className="title-search" level={4}>
        {t("title-search")}
      </Title>
      <Paragraph className="subtitle-search">{t("subtitle-search")}</Paragraph>

      <div className="row form-search-agency">
        <div className="col-xs-12 col-sm-6 label-name-search">
          <Paragraph>{t("search-form.commercial-name")}</Paragraph>
          <Input
            id="name"
            value={props.searchTermNameInput}
            onChange={handleChangeName}
            className="main-input"
            prefix={<FontAwesomeIcon className="search-agency-icon-input" icon={faSearch} />}
            allowClear
            autoFocus
          />
        </div>
        <div className="col-xs-12 col-sm-6 label-address-search">
          <Paragraph>{t("search-form.address")}</Paragraph>
          <Input
            id="address"
            value={props.searchTermAddressInput}
            onChange={handleChangeAddress}
            className="main-input"
            prefix={<FontAwesomeIcon className="search-agency-icon-input" icon={faSearch} />}
            allowClear
          />
        </div>
        <Collapse className="col-12 white-collapse" bordered={false} expandIconPosition="right">
          <Panel header={t("search-form.advanced-search")} key="1">
            <div className="row">
              <div className="col-xs-12 col-sm-6 label-phone-search">
                <Paragraph>{t("search-form.phone")}</Paragraph>
                <Input
                  id="phone"
                  value={props.searchTermPhoneInput}
                  onChange={handleChangePhone}
                  className="main-input"
                  prefix={<FontAwesomeIcon className="search-agency-icon-input" icon={faSearch} />}
                  allowClear
                />
              </div>
              <div className="col-xs-12 col-sm-6 label-tax-search">
                <Paragraph>{t("search-form.tax-id")}</Paragraph>
                <Input
                  id="taxid"
                  value={props.searchTermTaxidInput}
                  onChange={handleChangeTaxid}
                  className="main-input"
                  allowClear
                  prefix={<FontAwesomeIcon className="search-agency-icon-input" icon={faSearch} />}
                />
              </div>
            </div>
          </Panel>
        </Collapse>
        <Input id="key" className="d-none" value={searchTermKey} />
      </div>
      <>
        <Results
            forceRefreshData={forceRefreshData}
            onFinishCreationNewAgency={onAgencyCreated}
            setOpenNewAgency={setOpenNewAgency}
            searchTermName={searchTermName}
            searchTermAddress={searchTermAddress}
            searchTermPhone={searchTermPhone}
            searchTermTaxid={searchTermTaxid}
            setDisabled={props.setDisabled}
            selectedData={props.selectedData}
            cols={columns}
            selectData={(newAgencyData) => {
              props.updateData(newAgencyData);
            }}
            pageResults={pageResults}
            setPageResults={setPageResults}
            setPageResultsInput={props.setPageResultsInput}
          />
      </>

      {openNewAgency && <FormNewAgency onAgencyCreated={onAgencyCreated} openNewAgency={openNewAgency} setOpenNewAgency={setOpenNewAgency} />}

      <div className="content-action-buttons">
        <Button id="btn-next" type="primary" className="primary-button" disabled={props.disabled} onClick={props.next}>
          {t("register:steps.buttons.next")}
        </Button>
        <Button id="btn-prev" className="default-button margin-right-button" onClick={props.previous}>
          {t("register:steps.buttons.prev")}
        </Button>
      </div>
    </>
  );
};

export default SearchAgency;
