// Emparejamiento de las routes con sus respectivos enlaces
const baseStrings = {
  /** Routes */
  'routes.home': '/',
  'routes.Advantages': '/#ventajas',
  'routes.Faqs': '/#preguntas-frecuentes',
  'routes.contact': '/contacto',
  'routes.recoverPassword':'/recuperar-password',
  'routes.privacyPolicies': '/politicas-privacidad',
  'routes.cookiesPolicies': '/politicas-cookies',
  'routes.generalConditions': '/condiciones-generales',
  'routes.particularConditions': '/condiciones-particulares',
  'routes.register': '/registro',
  'routes.homePrivate':'/app',
  'routes.AdvantagesPrivate':'/app/ventajas',
  'routes.FaqsPrivate':'/app/preguntas-frecuentes',
  'routes.privacyPoliciesPrivate': '/app/politicas-privacidad',
  'routes.cookiesPoliciesPrivate': '/app/politicas-cookies',
  'routes.generalConditionsPrivate': '/app/condiciones-generales',
  'routes.particularConditionsPrivate': '/app/condiciones-particulares',
  'routes.contactPrivate': '/app/contacto',
  'routes.regularization': '/app/regularizaciones',
  'routes.remuneration': '/app/retribuciones',
  'routes.balanceHistory': '/app/movimientos',
  'routes.profile': '/app/mi-perfil',
  'routes.reward': '/app/recompensas',
  'routes.news': '/app/noticias',
  'routes.details': '/app/detalle-noticia/:id',
  'routes.deleteAccount': '/cuenta-eliminada',
  'routes.validateAddNewAgency': '/nueva-agencia/:id',
  'routes.knowUs': '/app/reserva-personal',
  'routes.legalAdvice' : '/aviso-legal',
  'routes.termsConditionsCashbackPrivate' : '/app/terminos-condiciones-revolut',
  'routes.termsConditionsCashback' : '/terminos-condiciones-revolut'
};

export type LanguageStrings = typeof baseStrings;
export const es = baseStrings;
