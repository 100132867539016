import * as React from "react";
import { useTranslation } from "react-i18next";
import "./formAddReservation.sass";
import { useHotelById } from "src/api/api";
const { Option } = Select;
import { Form, Select, Skeleton, Alert } from "antd";

const RoomNumberComponent = (param) => {
  const { t } = useTranslation(["regularizations", "translation"]);
  const { selectedEstablismentId, fieldsData, numRooms } = param;
  const establishment = useHotelById(selectedEstablismentId);

  return (
    <>
      {selectedEstablismentId ? (
        <Form.Item
          className={(param.section !== null ? "col-lg-6 col-12 " : "col-lg-3 col-12  ") + "form-item"}
          label={t("regularizations:form.labels.num-rooms")}
          name="numberRooms"
          rules={[
            {
              required: true,
              message: t("regularizations:form.errors.num-rooms")
            }
          ]}
        >
          <Select
            className="main-select"
            id="number-rooms"
            data-testid="select-number-rooms"
            placeholder={t("regularizations:form.placeholder.select")}
          >
            {numRooms.map((s: number, i) => {
              return (
                <Option key={i} id={`y-${i}`} value={JSON.stringify(s)}>
                  {s}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
      ) : (
        <Alert
          style={{ marginTop: "5px" }}
          message={t("regularizations:form.errors.room-alert")}
          type="warning"
          className={(param.section !== null ? "col-lg-6 col-12 " : "col-lg-3 col-12  ") + "alert"}
        />
      )}
      {fieldsData["numberRooms"] ? (
        <RoomsComponent
          selectedEstablismentId={selectedEstablismentId}
          fieldsData={fieldsData}
          establishment={establishment}
        />
      ) : selectedEstablismentId ? (
        <Alert
          message={
            <>
              <h4>{t("regularizations:form.errors.room-info-title")}</h4>
              <span>{t("regularizations:form.errors.room-info")}</span>
            </>
          }
          type="info"
          className={"col-12  info"}
        />
      ) : (
        ""
      )}
    </>
  );
};

const RoomsComponent = (param) => {
  const { t } = useTranslation(["regularizations", "translation"]);
  const { fieldsData, establishment } = param;

  return (
    <>
      {Array.apply(null, Array(+fieldsData["numberRooms"])).map((x, i) => (
        <>
          {" "}
          <Form.Item
            className={(param.section !== null ? "col-lg-6 col-12 " : "col-lg-3 col-12") + "form-item"}
            label={t("regularizations:form.labels.room-type") + " " + (i + 1)}
            name={`roomType${i}`}
            key={i}
            rules={[
              {
                required: true,
                message: t("regularizations:form.errors.roomType")
              },
              {
                whitespace: true,
                message: t("regularizations:form.errors.roomType")
              }
            ]}
          >
            <Select
              className="main-select"
              id="room-type"
              data-testid="select-number-rooms"
              placeholder={t("regularizations:form.placeholder.select")}
            >
              {establishment?.roomTypes
                ?.filter((h) => h?.selected)
                ?.map((r, i) => {
                  return (
                    <Option key={i} id={`y-${i}`} value={r?.idRoomTypes}>
                      {r?.name ?? r?.idRoomTypes}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>
          <Form.Item
            className={(param.section !== null ? "col-lg-6 col-12 " : "col-lg-3 col-12  ") + "form-item"}
            label={t("regularizations:form.labels.regimen") + " " + (i + 1)}
            name={`regimen${i}`}
            rules={[
              {
                required: true,
                message: t("regularizations:form.errors.regimen")
              }
            ]}
          >
            <Select
              className="main-select"
              id="regimen"
              data-testid="select-regimen"
              placeholder={t("regularizations:form.placeholder.select")}
            >
              {establishment?.regimens
                ?.filter((h) => h?.selected)
                ?.map((r, i) => {
                  return (
                    <Option key={i} id={`y-${i}`} value={r?.idRegimens}>
                      {r?.name ?? r?.idRegimens}
                    </Option>
                  );
                })}
            </Select>
          </Form.Item>{" "}
        </>
      ))}
    </>
  );
};

const RoomNumberWrapper = (param) => (
  <React.Suspense fallback={<Skeleton />}>
    <RoomNumberComponent {...param} />
  </React.Suspense>
);

export default RoomNumberWrapper;
