import * as React from "react";
import { Alert, Button, Table, Tag, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { getLocaleDatePicker } from "../../../utils/datePicker";
import { PickerLocale } from "antd/lib/date-picker/generatePicker";
import "./remunerationSummary.sass";
import { useAdvancedRetribution } from "src/api/api";
import {  UserContext } from '../../../hooks/context/userInfo';
const { Title } = Typography;

// Componente Table personalizado para mostrar en la tabla el detalle de las remuneraciones
const RemunerationSummary = (param) => {
  // Idioma para los textos
  const userData = React.useContext(UserContext);
  const { t } = useTranslation("remuneration");
  const colors = param.colors;
  const configurationId = param.selectedConfigId;
  // Local para el datePicker
  const local: PickerLocale = getLocaleDatePicker();
  const advancedRetribution = useAdvancedRetribution();
  const [modifiedRetribution, setModifiedRetribution] = React.useState();

  React.useEffect(() => {
    if (advancedRetribution) {
      const filterData = advancedRetribution?.filter(t => t?.isActive && 
                                (
                                    t?.advancedGroupsConfigurations.length > 0 && 
                                    t.advancedGroupsConfigurations.find((ac)=>{ return ac.id === configurationId && ac.selected}) !== undefined
                                )
                        );

      const newData = filterData.map((ret) => {
                        //Debe coger el groupAmount que corresponda a la divisa:
                        const goupsAmount = ret?.advancedGroupsAmount.find(aga => aga.idCurrency === userData?.currency)
                        return ({
                          name: ret?.name,
                          configurations: ret?.advancedGroupsConfigurations?.filter((cfg, i) => cfg?.selected).map((tto) => tto?.name),
                          channels: ret?.advancedGroupsTtoo?.filter((tto, i) => tto?.selected).map((tto) => tto?.name),
                          rooms: ret?.advancedGroupsRoomTypes?.filter((room, i) => room?.selected).map((room) => <p>{room?.name}</p>),
                          regimen: ret?.advancedGroupsRegimens?.filter((reg, i) => reg?.selected).map((reg) => <p>{reg?.name}</p>),
                          quantities: goupsAmount ? `${goupsAmount?.amount} ${goupsAmount?.idCurrency || '%'}` : "-"
                        })
                      });


      setModifiedRetribution(newData);
    }
  }, [advancedRetribution, configurationId]);



  const columns = [
    {
      title: t("summary.group"),
      dataIndex: "name",
      key: "name"
    },
    {
      title: t("summary.channel"),
      dataIndex: "channels",
      key: "channels",
      render: (a: any, b: any)=>{
        return <TagsInfo list={a} />
      }
    },
    {
      title: t("summary.rooms"),
      dataIndex: "rooms",
      key: "rooms",
      render: (a: any, b: any)=>{
        return  <TagsInfo list={a} />;
      }
    },
    {
      title: t("summary.regimen"),
      dataIndex: "regimen",
      key: "regimen",
      render: (a: any, b: any)=>{
        return  <TagsInfo list={a} />;
      }
    },
    {
      title: t("summary.quantities"),
      dataIndex: "quantities",
      key: "quantities"
    }
  ];
  return (
    <>
      <div className="summary-remuneration row">
        <Title className="mt-4" level={4}>
          {t("summary.title")}
        </Title>
        <div className="col-md-1"></div>
        <div className="col-12">
          <Alert message={t("summary.notice")} type="info" showIcon style={{marginBottom: "10px"}} closable/>
        </div>
        <div className="col-12">
          <Table id="table-remuneration" columns={columns} dataSource={modifiedRetribution} pagination={false} />
        </div>
        <div className="col-md-1"></div>
      </div>
    </>
  );
};

interface TagsInfoProps{
  list: any[]
}
const TagsInfo = (param: TagsInfoProps) => {

  const { list } = param;

  const [showAll, setShowAll] = React.useState<boolean>(false);

  return (
    <>
      {
        list?.map((value, index) => {
          if (showAll || index < 5){
            return <Tag key={index} className="channel-tag"><span>{value}</span></Tag>
          }
        })
      }
      {
        list?.length > 5 ? 
          <Button style={{display: "block"}} size="small" type="primary" className="primary" onClick={()=>{
            setShowAll(!showAll);
          }}>
            {
              showAll ? '-' : '+'
            }
          </Button> : 
          <></>
      }
    </>
  )
}

export default RemunerationSummary;
