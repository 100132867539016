import * as React from 'react';
import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Drawer } from 'antd';
import { ConfigContext } from '../../../../../hooks/context/config';
import '../header.sass';
import ModalLogin from '../../../../modalsLogin/login/login';
import './mobileMenu.sass';
import { LINKS_MOBILE_PRIVATE } from '../../../../../constants/constants';
import { faBars, faHome, faRetweet, faCoins, faListAlt, faChartBar, faNewspaper, faStar, faQuestionCircle, faPhoneAlt, faHotel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu } from 'antd';
import RankingPosition from '../../../../ranking/rankingMenu/ranking';
import MenuPop from '../../menuPopProfile/menuPop';
import { linkActiveContext } from '../../../../../hooks/context/linkActive';
import { cleanUp } from '../../../../../utils/cleanUp';

// Menú vislble para versiones tablet o móvil (de MD para abajo)
const MobileMenu = (params) => {

    var linksMobileMenu = LINKS_MOBILE_PRIVATE;

    // Libreria i18n para traducir
    const { t } = useTranslation(['urls', 'linksNames']);

    // Estado para mostrar o ocultar modal cookie
    const [modalLogin, setmodalLogin] = useState(false);

    // Control para desplegar o ocultar menu lateral
    const [visible, setVisible] = useState(false);

    // Contexto con la configuracion
    const theme = useContext(ConfigContext);

    // Remove KnowUs section if not active
    if (!theme.knowUsActive){
        linksMobileMenu = linksMobileMenu.filter(lm => lm != "know-us");
    }

    // Se muestra el menu lateral
    const showDrawer = () => {
        setVisible(true)
    };

    // Se oculta el menu lateral
    const onClose = (e) => {
        setVisible(false);
        linkActive.updateValueUrl(t("urls:public."+ e));
    };

    // Estados para el manejador de la funcionalidad del menu cabecera que se ocultara al hacer scroll hacia la parte inferior, pero que en el momento de subir se vuelve a mostrar
    const [top , setTop] = useState("no-fixed");
    const [space , setSpace] = useState("div-aux-no-space");
    const [ascend , setascend] = useState(0);
    const linkActive = useContext(linkActiveContext);
    let url = window.location.pathname + window.location.hash;
    const icons = [faHome, faRetweet, faCoins, faListAlt, faChartBar, faNewspaper, faPhoneAlt, faStar, faQuestionCircle, faHotel];

    if (url.substring(url.length, url.length - 1) === '/') {
        url = url.substring(0, url.length - 1);
    }

    // Ponemos a la escucha el scroll
    useEffect(() => {
        cleanUp();
        window.addEventListener('scroll', scrollTop);
        return () => window.removeEventListener('resize', scrollTop);
    });

    // Comprobamos si asciende o desciende
    const scrollTop = () => {
        if (window.pageYOffset > ascend) {
            setTop("no-fixed");
            setSpace("div-aux-no-space");
            setascend(window.pageYOffset);
        } else {
            setTop("fixed");
            setSpace("div-aux-space");
            setascend(window.pageYOffset);
        }
    }

    return (
        <>
            <div className={ top + " col-12 box-header-mobile" }>
                <div className="col-2 col-sm-3 menuCon position-icon padding-left-header width-items">
                    <div className="vertical-center">
                        <FontAwesomeIcon
                            onClick={ showDrawer }
                            className="icons-color"
                            icon={ faBars }
                        />
                    </div>
                    <Drawer
                        placement="left"
                        closable={ false }
                        onClose={ onClose }
                        visible={ visible }
                    >
                        <Menu
                            mode="inline"
                            defaultSelectedKeys={ [url] }
                        >
                            {
                                linksMobileMenu.map((element, index) => {

                                    const showDest: string = t("urls:private." + element);

                                    return (
                                        <Menu.Item
                                            key={ showDest }
                                            className={ "padding-elements-link" + ((window.location.pathname + window.location.hash) == t("urls:private." + element) ? " disabled-li" : "") }
                                        >
                                            <Link
                                                id={ "link-private-mobile-" + element + "-" + index }
                                                key={ index }
                                                to={ t("urls:private." + element) }
                                                className={ (window.location.pathname + window.location.hash) == t("urls:private." + element) ?
                                                "secondary-links active" : ((window.location.pathname.substring(0, window.location.pathname.length - 2)) == t("urls:private.details") && element === "news")? "secondary-links active" : "secondary-links" }
                                                onClick={ () => onClose(element) }
                                                disabled={ (window.location.pathname + window.location.hash) == t("urls:private." + element) }
                                            >
                                                <div className="box-link-menu">
                                                    <div className="padding-link-desktop width-menu-mobile">
                                                        <FontAwesomeIcon
                                                            className="icons-color-menu icons-color-marging"
                                                            icon={ icons[index] }
                                                        />
                                                        { t("linksNames:" + element) }
                                                    </div>
                                                </div>
                                            </Link>
                                        </Menu.Item>
                                    )
                                })
                            }
                            <RankingPosition />
                        </Menu>
                    </Drawer>
                </div>
                <div className="col-8 col-sm-6 p-0  logo width-items">
                    <div className="vertical-center-logo">
                        <Link
                            id="main-private-logo-mobile"
                            className="logo-mobile"
                            to={ t("urls:private.start") }
                            onClick={ ()=> linkActive.updateValueUrl(t("urls:private.start")) }
                            disabled={ (window.location.pathname + window.location.hash) == t("urls:private.start") }
                        >
                            <img className="logo" src={ theme.logo } title={ theme.name } alt={ theme.name } />
                        </Link>
                    </div>
                </div>
                <div className="col-2 col-sm-3 sesion-init position-login-button padding-right-header width-items">
                    <div className="vertical-center-logo">
                        <div className="secondary-links-svg">
                            <MenuPop type={ "mobile" } />
                        </div>
                    </div>
                </div>
            </div>
            <div className={ space }></div>
            <div>
                { modalLogin && <ModalLogin setmodalLogin={ setmodalLogin } /> }
            </div>
        </>
    );
}

export default MobileMenu;
