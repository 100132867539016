import * as React from 'react';
import './regularizationReservation.sass';
import { FormAddReservation } from './formAddReservation/formAddReservation';

// Componente de regularizacion parte superior
const RegularizationsReservation = (param) => {
  return (
    <>
      <div className="form-add-reservation">
        <div className="col-12 row">
          <FormAddReservation
            forceRefresh={param.forceRefresh}
            setData={ param.setData }
            section= {null}
          />
        </div>
      </div>
    </>
  );
}

export default RegularizationsReservation;
