import React, { useContext, Suspense, useEffect, useState } from "react";
import "../profile.sass";
import "../../../i18n";
import { Skeleton } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, DatePicker, Select } from "antd";
import moment from "moment";
import { getLocaleDatePicker } from "../../../utils/datePicker";
import { PickerLocale } from "antd/es/date-picker/generatePicker";
import { MIN_AGE_REGISTER } from "../../../constants/constants";
import { ONLY_NUMBERS } from "../../../constants/regex";
import { UserContext } from "../../../hooks/context/userInfo";
import { useDocumentTypes, usePositions, useUpdatePersonalInfo, useUserInfo } from "src/api/api";
import { showNotification } from "src/utils/notificationsGeneric";
import { UserType } from "src/models/user/userModel";
import GoogleMapsAutoCompleteComponent from "src/components/google/geocode/GoogleMapsAutoCompleteComponent";

// Componente de la sección de Información personal
const PersonalInfo = (param) => {
  const userContext = useContext(UserContext);
  let userToken = userContext.GetUserToken();

  const { t } = useTranslation(["profile", "newAgency"]);
  const updatePersonalInfo = useUpdatePersonalInfo();
  const documents = useDocumentTypes();
  const positions = usePositions();
  const userInfo = useUserInfo();
  const [form] = Form.useForm();

  const { Option } = Select;

  const local: PickerLocale = getLocaleDatePicker();

  let dateReduct = new Date().getFullYear() - MIN_AGE_REGISTER;
  let dateToday = new Date();
  let limitDate = new Date(dateReduct + "/" + (dateToday.getMonth() + 1) + "/" + dateToday.getDay());

  // Comparador de fechas para saber si debe estar disable o seleccionable para el usuario
  const disabledDate = (current) => {
    return current > dateToday;
  };

  useEffect(()=>{
    if (userInfo){
      form.setFieldsValue({
        "position": positions.find(position => position.name.toUpperCase() == userInfo.position.toUpperCase())?.id
      });
    }
  },[positions])

  useEffect(()=>{
  },[positions, documents, userInfo])

  // Submit boton
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
  const onFinish = (values) => {
    const body = {
      name: values.name,
      surname1: values.firstSurname,
      surname2: values.secondSurname,
      position: values.position,
      bornDate: values.birthDate.format(),
      idDocumentType: values.documentType,
      document: values.documentNumber,
      telephone: values.phone,
      googleData: googleData,
      companyName: values.companyName
    };

    setLoadingUpdate(true);
    updatePersonalInfo(body).then(res => {
      if (res.data) {
        showNotification("success", res.title, res.message, "topRight");
        setActualAddress(googleData.formatted_address);
      } else {
        showNotification("error", res.title, res.message, "topRight");
      }
    }).finally(()=>{
      setLoadingUpdate(false);
      param.setChangeData(false);
      param.setDisabledBtn(true);
    });
  };

  const [googleData, setGoogleData] = useState<any>();
  const [actualAddress, setActualAddress] = useState<string>('');
  const getExtraForm = () : JSX.Element[] => {

    let extraForm : JSX.Element[] = [
          <Form.Item
            className="col-12 form-item-profile mb-4"
            label={t("sections.data-agency.company-name")}
            name="companyName"
            initialValue={userInfo.companyName}
          >
            <Input className="main-input" placeholder="" id="second-surname" onChange={handleInputChange} />
          </Form.Item>,
          <Form.Item
            className="col-12 form-item-profile mb-4"
            label={t("sections.data-agency.address")}
            help={<>{t("sections.data-agency.actual-address")}: {actualAddress}</>}
            name="address"
          > 
            <GoogleMapsAutoCompleteComponent isRequiredField={false} onSetData={setGoogleData} showSelectedInfo={false}/>
          </Form.Item>
    ]

    return extraForm;
    
  }

  useEffect(()=>{
    if (userInfo){
      if (userInfo.idAgentType == 2){
        //Agente independiente
        if (userInfo.location){
          setActualAddress(userInfo.location.formattedAddress);
        }
      }else{
        //Agente de agencia
        setActualAddress(userInfo.agency.location.formattedAddress);
      }

    }
  },[userInfo])

  const onChangeData = () => {
    param.setDisabledBtn(false);
    param.setChangeData(true);
  };

  // Evento que controla que si el campo input ha cambiado, habilita el botón
  const handleInputChange = () => {
    param.setDisabledBtn(false);
  };

  return (
    <div className="row">
      <div className="col-lg-2 p-0"></div>
      <div className="col-lg-8 col-12 p-0">
        <Form
          name="form-personal-info"
          className="form-personal-info row"
          onFinish={onFinish}
          onChange={onChangeData}
          layout="vertical"
          form={form}
          id="form-personal-info"
          initialValues={{
            name: userInfo.name,
            firstSurname: userInfo.surname1,
            secondSurname: userInfo.surname2,
            birthDate: moment(userInfo.bornDate),
            documentType: userInfo.idDocumentType?.toString(),
            documentNumber: userInfo.document,
            phone: userInfo.telephone,
            position: positions.find((position) => position.name === userInfo.position?.toString()).idPosition,
          }}
        >
          <Form.Item
            className="col-12 form-item-profile mb-4"
            label={t("sections.personal-info.name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("validations.name"),
              },
              {
                whitespace: true,
                message: t("validations.name"),
              },
            ]}
          >
            <Input className="main-input" placeholder="" id="name" onChange={handleInputChange} />
          </Form.Item>
          <Form.Item
            className="col-12 form-item-profile mb-4"
            label={t("sections.personal-info.first-surname")}
            name="firstSurname"
            rules={[
              {
                required: true,
                message: t("validations.surname"),
              },
              {
                whitespace: true,
                message: t("validations.surname"),
              },
            ]}
          >
            <Input className="main-input" placeholder="" id="first-surname" onChange={handleInputChange} />
          </Form.Item>
          <Form.Item
            className="col-12 form-item-profile mb-4"
            label={t("sections.personal-info.second-surname")}
            name="secondSurname"
          >
            <Input className="main-input" placeholder="" id="second-surname" onChange={handleInputChange} />
          </Form.Item>
          <Form.Item
            className="col-12 form-item-profile mb-4"
            label={t("sections.personal-info.birth-date")}
            name="birthDate"
            rules={[
              {
                required: true,
                message: t("validations.birth-date"),
              },
            ]}
          >
            <DatePicker
              format={local.dateFormat}
              locale={local}
              onChange={onChangeData}
              placeholder={local.lang.dateFormat}
              disabledDate={disabledDate}
              className="main-select birth-date-personal-info"
            />
          </Form.Item>
          <Form.Item
            className="col-md-6 col-12 pr-md-1 pr-0 form-item-profile mb-4"
            label={t("sections.personal-info.document-type")}
            name="documentType"
            rules={[
              {
                required: true,
                message: t("validations.document-type"),
              },
            ]}
          >
            <Select className="main-select" placeholder={t("sections.personal-info.select-option")}>
              {documents.map((s: any, i) => {
                return (
                  <Option key={i} id={`d-${i}`} value={s.id}>
                    {s.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            className="col-md-6 col-12 pl-md-1 pl-0 form-item-profile mb-4"
            label={t("sections.personal-info.document-number")}
            name="documentNumber"
            rules={[
              {
                required: true,
                message: t("validations.document-number"),
              },
              {
                whitespace: true,
                message: t("validations.document-number"),
              },
            ]}
          >
            <Input className="main-input" placeholder="" id="document-number" />
          </Form.Item>
          <Form.Item
            className="col-12 form-item-profile mb-4"
            label={t("sections.personal-info.phone")}
            name="phone"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? ONLY_NUMBERS.test(value)
                      ? Promise.resolve()
                      : Promise.reject(t("validations.number"))
                    : Promise.resolve(),
              },
            ]}
          >
            <Input className="main-input" placeholder="" id="phone" onChange={handleInputChange} />
          </Form.Item>
          <Form.Item
            className="col-12 form-item-profile mb-4"
            label={t("sections.personal-info.position")}
            name="position"
            rules={[
              {
                required: true,
                message: t("validations.position"),
              },
            ]}
          >
            <Select
              className="main-select"
              placeholder={t("sections.personal-info.select-option")}
              onChange={onChangeData}
            >
              {positions.map((s: any, i) => {
                return (
                  <Option key={i} id={`p-${i}`} value={s.id}>
                    {s.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          {
            userInfo.idAgentType == UserType.AgentIndependent ? 
              getExtraForm()
            :
            <></>
          }
          <Form.Item className="col-12 form-item mt-3">
            <div className="row f-right">
              <Button
                id="btn-send-new-agency"
                className="primary-button"
                type="primary"
                htmlType="submit"
                disabled={param.disabledBtn || loadingUpdate}
              >
                {loadingUpdate ? <div style={{display:"flex", alignItems: "center", justifyContent: "center"}}><LoadingOutlined style={{marginRight: "3px"}}/> {t("btn.save-changes")}</div> : <>{t("btn.save-changes")}</>}
                
              </Button>
            </div>
          </Form.Item>
          
        </Form>
      </div>
      <div className="col-lg-2 p-0"></div>
    </div>
  );
};

const PersonalInfoWrapper = (param) => (
  <Suspense fallback={<Skeleton />}>
    <PersonalInfo {...param} />
  </Suspense>
);

export default PersonalInfoWrapper;
