import { useFetch } from 'ruse-fetch'
// import { API_URL_TEST } from '../constants/constants';
/*
// #region - Llamada a la API para la obtención de la configuración
export const getConfig = async () => {
    // #TODO - Parametrizar llamada URL API
    console.warn("TODO - Parametrizar llamada URL API");
    const response = await fetch(`https://preapi.beds2b.com/api/Home/WACustomerConfig/${'prewa.beds2b.com'}`);
    const json = await response.json();
    return json;
}
// #endregion

// #region - Llamada a la API para obtener la lista de posibles idiomas dentro de la aplicación
export const getLangInit = async () =>{
    console.warn("TODO - Parametrizar llamada URL API");
    const response = await fetch('https://preapi.beds2b.com/api/Language/GetLanguages');
    const json = await response.json();
    return json;
}
// #endregion
*/

import { API_URL_TEST } from "../constants/constants";

// #region - Llamada a la API para la obtención de la configuración
export const getConfig = async () => {
    // #TODO - Parametrizar llamada URL API
    const headers = new Headers();
    headers.append('Client-Tenant-URL', window.location.origin);

	const response = await fetch(
		API_URL_TEST + `/Customizations/webagent`,
        {
            headers: headers,
        }
	);
    const json = await response.json();
	return json.data;
};
// #endregion

export const useConfig = () => {
    const headers = new Headers();
    headers.append('Client-Tenant-URL', window.location.origin);

    return useFetch<any>(API_URL_TEST + `/Customizations/webagent`, {
        headers: headers,
    }).data
}

// #region - Llamada a la API para obtener la lista de posibles idiomas dentro de la aplicación
export const getLangInit = async () => {
    const headers = new Headers();
    headers.append('Client-Tenant-URL', window.location.origin);
    

	const response = await fetch(
		API_URL_TEST + "/Languages/webagent",
        {
            headers: headers,
        }
	);
	const json = await response.json();
	return json.data;
};
// #endregion

export const useLangInit = () => useFetch<any>(API_URL_TEST + "/Languages/webagent").data
