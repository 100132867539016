import * as React from 'react';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ConfigContext } from '../../../../../hooks/context/config';
import '../header.sass';
import './desktopMenu.sass';
import ModalLogin from '../../../../modalsLogin/login/login';
import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MenuPop from '../../menuPopProfile/menuPop';
import { linkActiveContext } from '../../../../../hooks/context/linkActive'

// Menú visible para versiones escritorio (de MD para arriba)
const DesktopMenu = (params) => {

  // Libreria i18n para traducir
  const { t } = useTranslation(['urls', 'linksNames']);
  
  // Hook Context de la configuracion
  const theme = useContext(ConfigContext);

  // Estado para mostrar o ocultar modal cookie
  const [modalLogin, setmodalLogin]=useState(false);
  const linkActive = useContext(linkActiveContext);

  return (
    <>
      <div className="col-12 box-header-private">
        <div className="col-6 left-part-header padding-left-header">
          <div className="col-lg-1 links-row padding-icon-collapsed">
            <div className="align-center-links">
              { 
                React.createElement(params.collapsedState ? 
                  MenuUnfoldOutlined : 
                  MenuFoldOutlined, {
                    className: 'trigger',
                    onClick: params.toggle,
                  }
                )
              }
            </div>
          </div>
          <div className="col-lg-3 p-0 links-row">
            <div className="align-center-links">
              <Link 
                id="main-logo"
                to={ t("urls:private.start") }
                onClick={ () => linkActive.updateValueUrl(t("urls:private.start")) }
                disabled={ (window.location.pathname + window.location.hash) == t("urls:private.start") }
              >
                <img className="logo" src={ theme.logo } title={ theme.name } alt={ theme.name } />
              </Link>
            </div>
          </div>
          <div className="col-9 links-row">

          </div>
        </div>
        <div className="col-6 right-part-header padding-right-header">
          <div className="col-12 p-0 links-row">
            <div className="align-center-links float-link">
              <div className="padding-link-desktop-contact">
                <Link
                  id="link-contact"
                  to={ t("urls:private.contact") }
                  className={ (window.location.pathname + window.location.hash) == t("urls:private.contact") ? "padding-link secondary-links-svg active" : "padding-link secondary-links-svg" }
                  onClick={ () => linkActive.updateValueUrl(t("urls:private.contact")) }
                  disabled={ (window.location.pathname + window.location.hash) == t("urls:private.contact") }
                >
                  <FontAwesomeIcon 
                    className="icons-color-menu icons-color-marging" 
                    icon={ faPhoneAlt } 
                  />
                </Link>
              </div>            
              <div className="padding-link-desktop secondary-links-svg secondary-links-svg align-center-links">
                <MenuPop type={ "desktop" } />
              </div>              
            </div>
          </div>
        </div>
      </div>
      <div className="aux-div-header"></div>
      <div>
        { modalLogin && <ModalLogin setmodalLogin={ setmodalLogin } /> }
      </div>
    </>
  );
}

export default DesktopMenu;
