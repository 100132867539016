import React, {Suspense, useEffect} from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "../../../models/pagination/pagination";
import { Typography, Skeleton } from "antd";
import SearchReward from "../rewardTableResult/searchReward/searchReward";
import NotResults from "../../noResultGeneric/notResults";
import RewardResult from "../rewardResult/rewardResult";
import "./rewardMain.sass";
import RewardListTextProvider from "../../../models/rewardModel/rewardListTextProvider";
import { useGetRewards } from "src/api/api";

const { Paragraph, Title } = Typography;

// Reward componente principal
const RewardMain = (param) => {
  const { t } = useTranslation(["reward"]);

  const [messageApi, setMessageApi] = useState("no-result");
  // Estado que almacena la página actual y el número de elementos por página de la paginación en la tabla y lista
  const [pageResults, setPageResults] = useState(new Pagination());
  // Estado que almacena los criterios de ordenación aplicados a la tabla
  const [orderResults, setOrderResults] = useState([]);
  //lista con textos adicionales de informacion de los provedores
  const [text, setText] = useState([new RewardListTextProvider()]);
  // Estado que almacena los criterios de búsqueda aplicados al formulario
  const [searchHeaderResults, setSearchHeaderResults] = useState(null);
  // Limpiar form por error
  const [clearForm, setClearForm] = useState(false);
  const [result, setResult] = useState(undefined);

  const getRewards = useGetRewards()
  useEffect(()=>{
    async function fetchRewardsApi() {
      let response = await getRewards(pageResults.current, pageResults.pageSize,
        searchHeaderResults && searchHeaderResults.dateChecking,
        searchHeaderResults && searchHeaderResults.dateOut,
        searchHeaderResults && searchHeaderResults.nameId,
        orderResults[0] && orderResults[0].field,
        orderResults[0] && orderResults[0].typeOrder,
        orderResults[1] && orderResults[1].field,
        orderResults[1] && orderResults[1].typeOrder)
      setResult(response.data)
    }
    fetchRewardsApi()

  }, [searchHeaderResults, pageResults, orderResults])


  return (
    <>
      {result && result.rewards.length > 0 ? (
        <>
          <div className="col-12 container-reward-reg mt-3">
            <div className="col-12 padding-box">
              <Title level={4}>{t("table.form-search.title")}</Title>
              <Paragraph>{t("table.form-search.text-description")}</Paragraph>
              <SearchReward
                filtersList={param.filtersList}
                setOrderResults={param.setOrderResults}
                setSearchHeaderResults={setSearchHeaderResults}
                clearForm={clearForm}
              />
            </div>
          </div>
          <div className="table-reward row">
            <div className="col-12 padding-list-box">
              <RewardResult
                result={result}
                setPageResults={setPageResults}
                pageResults={pageResults}
                orderResults={orderResults}
                setOrderResults={setOrderResults}
                searchHeaderResults={searchHeaderResults}
                setSearchHeaderResults={setSearchHeaderResults}
              />

              <div className="col-12 p-0 container-results padding-result">
                {result.length > 0 && (
                  <Paragraph className="total-results-content">
                    {result.length} {t("results")}
                  </Paragraph>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="col-12 padding-box mt-3">
            <div className="col-md-1"></div>
            <div className="col-12 info-reward-reg-content">
              <Title level={4}>{t("table.form-search.title")}</Title>
              <Paragraph>{t("table.form-search.text-description")}</Paragraph>
            </div>
            {searchHeaderResults !== null && (
              <SearchReward
                filtersList={param.filtersList}
                setOrderResults={setOrderResults}
                setSearchHeaderResults={setSearchHeaderResults}
                clearForm={clearForm}
                setClearForm={setClearForm}
              />
            )}
            <div className="col-md-1"></div>
          </div>

          <NotResults
            section="reward"
            searchHeaderResults={searchHeaderResults}
            setClearForm={setClearForm}
            messageApi={messageApi}
            result={result}
          />
        </>
      )}

      <div className="col-12 mt-4">
        <div className="col-12 display-info">
          <Paragraph
            className="font-info"
            ellipsis={{
              rows: 2,
              expandable: true,
            }}
          >
            {text.map((s: RewardListTextProvider, i) => {
              return s.text;
            })}
          </Paragraph>
        </div>
      </div>
    </>
  );
};

const RewardMainWrapper = (param) => (
    <Suspense fallback={<Skeleton />}>
      <RewardMain filtersList={param.filtersList} />
    </Suspense>
  );
  
export default RewardMainWrapper;