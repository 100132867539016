import React, {Suspense} from 'react';
import { useContext, useEffect } from 'react';
import './home.sass';
import '../../i18n';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Skeleton } from "antd";
import { ConfigContext } from '../../hooks/context/config';
import RewardGeneric from '../rewardGenericBalance/rewardGenericBalance';
import TopRankingPosition from '../ranking/topRanking/topRanking';
import { FormAddReservation } from '../regularizations/regularizationReservation/formAddReservation/formAddReservation';
import RewardWidget from '../reward/rewardWidget/rewardWidget';
import AlternateComponent from '../utils/alternateComponent/alternateComponent';
import RegularizationWidget from '../regularizations/regularizationWidget/regularizationWidget';
import NewsWidget from '../news/newsWidget/newsWidget';
import { useGetGeneralRanking } from 'src/api/api';
import { RestApiClientContext } from 'src/hooks/context/pageContext';

// Componente de la home de la zona privada
const Home = () => {
  const allowGeneralRanking = useGetGeneralRanking().data;

  // Contexto con la configuracion de la app
  const infoClient = useContext(ConfigContext);

  // Position del scroll en el top de la pagina
  const scrollLib = require('react-scroll');
  const scroll = scrollLib.animateScroll;

  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  // Lenguaje para los textos
  const { t } = useTranslation('seo');

  // Modulamos la info para la versión de escritorio
  const desktopVersion = () => {
    return (
      <FormAddReservation
        setData={ null }
        section="home"
      />
    )
  }

  // Modulamos la info para la versión mediana (tablet)
  const tabletVersion = () => {
      return mobileVersion();
  }

  // Modulamos la info para la versión móvil
  const mobileVersion = () => {
      return (
        <RegularizationWidget />
      )
  }

  const restApiClient = useContext(RestApiClientContext);

  const [noIndexNoFollow, setNoIndexNoFollow] = React.useState(false);
  const getNoIndexNoFollow = () : void => {
    restApiClient.getNoIndexNoFollowConfig().then(v => {
      setNoIndexNoFollow(v);
    });

  }
  React.useEffect(() => {
    getNoIndexNoFollow();
  }, []);

  return (
    <>
      <Helmet>
        <title>{ infoClient.incentiveSiteName } | { t('seo:title.home') }</title>
        <meta name="description" content={ t('seo:description.home', { 0: infoClient.incentiveSiteName }) } />
        {
          noIndexNoFollow ? 
            <meta name="robots" content="noindex, nofollow" />
          : <meta name="robots" content="index,follow" />
        }
        <link rel="canonical" href={ infoClient.urlWA } />
        <link rel="icon" type="image/ico" href={ infoClient.favicon } sizes="32x32" />
        <link href={ infoClient.urlWA } hrefLang="es-ES" rel="alternate" />
      </Helmet>

      <div className="mt-3">
        <RewardGeneric/>
        <div className="col-12 p-0 display-elements-home row">
          <div className="col-12 row order-12 order-lg-1">
            <div className={`col-xl-${allowGeneralRanking ? "8" : "12"} col-lg-${allowGeneralRanking ? "7" : "12"} col-md-12 p-md-0 p-0`}>
              <div>
                <RewardWidget />
              </div>
              <div>
                <AlternateComponent
                  desktopVersionLarge={ desktopVersion }
                  tabletVersion={ tabletVersion }
                  mobileVersionLarge={ mobileVersion }
                />
              </div>
            </div>
            {
              allowGeneralRanking ? 
                <div className="col-xl-4 col-lg-5 col-md-12 pl-lg-3 p-0">
                  <TopRankingPosition />
                </div>
                :
                <></>
            }
          </div>
          <div className="col-12 order-1 order-lg-12 p-md-0 p-0">
            <NewsWidget />
          </div>
        </div>
      </div>
    </>
  );
}

const HomeWrapper = (param) => (
  <Suspense fallback={<Skeleton />}>
    <Home {...param}/>
  </Suspense>
);

export default HomeWrapper;
