import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import './privacy-policies.sass';
import { Helmet } from 'react-helmet-async'
import '../../../i18n';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../../hooks/context/config';
import { Typography } from 'antd';
import ReactHtmlParser from 'react-html-parser';
import { RestApiClientContext } from '../../../hooks/context/pageContext';
import { ClientDataModel } from '../../../models/config/clientData';

const { Title, } = Typography;
// tslint:disable-next-line:no-var-requires
const scrollLib = require('react-scroll');
const scroll = scrollLib.animateScroll;

// Componente de información de Política de privacidad de la app
export const PrivacyPolicies = () => {

  const infoClient = useContext(ConfigContext); // TODO: Actualizar cuando obtengamos los datos de infoClient confirmados
  const restApiClient = useContext(RestApiClientContext);
  const { t } = useTranslation(['privacyPolicies', 'seo', 'urls']);

  const [clientData, setClientData] = useState<ClientDataModel>(
    new ClientDataModel()
  );

  useEffect(() => {
      scroll.scrollToTop();
  });

  useEffect(() => {
    getLegalTextApi();
  }, []);

  const getLegalTextApi = async () => {
    restApiClient.getCustomizations().then((data) => {
      if (data) {
        setClientData(data);
      }
    });
  };

  return (
    <>
      <Helmet>
        <title>{ infoClient.incentiveSiteName } | { t('seo:title.privacy-policies') }</title>
        <meta name="description" content={ t('seo:description.privacy-policies', { 0: infoClient.incentiveSiteName }) } />
        <meta name="robots" content="noindex,nofollow" />
        <link rel="canonical" href={ infoClient.urlWA + t('urls:public.privacy-policies') } />
        <link rel="icon" type="image/ico" href={ infoClient.favicon } sizes="32x32" />
        <link href={ infoClient.urlWA + t('urls:public.privacy-policies') } hrefLang="es-ES" rel="alternate" />
      </Helmet>

      <div className="container special-mb mt-3">
        <Title level={4}>{ t("privacyPolicies:main-title") }</Title>
        { ReactHtmlParser(t("privacyPolicies:main-paragraph", { 0: clientData.incentiveSiteName, 1: clientData.socialName, 2: infoClient.description, 3: clientData.identityCode, 4: clientData.socialAddress, 5: clientData.mercantileRegistry })) }

        { ReactHtmlParser(t("privacyPolicies:section.title.A")) }
        { ReactHtmlParser(t("privacyPolicies:section.paragraph.A", { 0: clientData.identityCode })) }

        { ReactHtmlParser(t("privacyPolicies:section.title.B")) }
        { ReactHtmlParser(t("privacyPolicies:section.paragraph.B")) }

        { ReactHtmlParser(t("privacyPolicies:section.title.C")) }
        { ReactHtmlParser(t("privacyPolicies:section.paragraph.C")) }

        { ReactHtmlParser(t("privacyPolicies:section.title.D")) }
        { ReactHtmlParser(t("privacyPolicies:section.paragraph.D")) }

        { ReactHtmlParser(t("privacyPolicies:section.title.E")) }
        { ReactHtmlParser(t("privacyPolicies:section.paragraph.E")) }

        { ReactHtmlParser(t("privacyPolicies:section.title.F")) }
        { ReactHtmlParser(t("privacyPolicies:section.paragraph.F")) }

        { ReactHtmlParser(t("privacyPolicies:section.title.G")) }
        { ReactHtmlParser(t("privacyPolicies:section.paragraph.G", { 0: clientData.socialName, 1: clientData.emailContact })) }

        { ReactHtmlParser(t("privacyPolicies:section.title.H")) }
        { ReactHtmlParser(t("privacyPolicies:section.paragraph.H")) }

        { ReactHtmlParser(t("privacyPolicies:section.title.I")) }
        { ReactHtmlParser(t("privacyPolicies:section.paragraph.I")) }

        { ReactHtmlParser(t("privacyPolicies:section.title.J")) }
        { ReactHtmlParser(t("privacyPolicies:section.paragraph.J")) }
      </div>
    </>
  );
}
