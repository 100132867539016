import EndPointConfig from '../../models/api/endPointConfig';

const controller = 'Rankings';

// Define all EndPoints for Top Ranking methods.
export const TopRankingEP : EndPointConfig[] = [
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetRanking'),

    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('myposition')
        .setAlias('GetRankingMyPosition'),
];
