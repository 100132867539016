import * as React from 'react';
import { useState } from 'react';
import { Typography, Button } from 'antd';
import '../../i18n';
import { useTranslation } from 'react-i18next';
import FormNewAgency from '../register/newAgency/newAgency';
import { URL_IMAGE } from '../../constants/constants';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle, faInbox, faSearch, faNewspaper, faTrophy } from "@fortawesome/free-solid-svg-icons";
import './noResults.sass';
import { Link } from 'react-router-dom';

const { Paragraph, Title } = Typography;


// Vista generica para el comportamiento cuando no hay resultado, error en una lista cualquiera o la busqueda no genera resultado
const NotResults = (param) => {

    const { t } = useTranslation(['searchAgency', 'reward', 'regularizations', "news", "ranking"]);
    const [openNewAgency, setOpenNewAgency] = useState(false);

    switch (param.section) {
        // Section reward
        case "reward":
            return (
                <div className="rewards-not-results panel-content">
                    <FontAwesomeIcon 
                        className={ "icons-color-reward-" + param.messageApi }
                        icon={ param.messageApi === "error" ? faTimesCircle : (param.searchHeaderResults ? faSearch : faInbox) } 
                    />
                    {
                        // Primera condicion: error
                        param.messageApi === "error" ? (                        
                            <>
                                <Title level={ 4 }> { t('reward:not-results.title-error') } </Title>
                                <Paragraph className="text-no-found">
                                    <strong>{ t('reward:not-results.text-error') }</strong>
                                </Paragraph>
                            </>
                        // Segunda condicion: Sin resultado en la busqueda
                        ) : (param.searchHeaderResults ? (
                            <>
                                <Paragraph className="text-no-found">
                                    <strong>{ t('reward:not-results.text-not-found') }</strong>
                                </Paragraph>
                                <Paragraph className="text-no-found-link">
                                    <strong onClick={ () => param.setClearForm(true) }>{ t('reward:not-results.link-not-found') }</strong>
                                </Paragraph>
                            </>
                        // Tercera condicion: No canjeado ningun cheque
                        ) : (
                            <Paragraph className="text-no-found">
                                { t('reward:not-results.text-not-result') }
                            </Paragraph>
                        ))
                    }
                </div>
            );
            break;
        // Section regularization
        case "regularizations":
            return (
                <div className="regularizations-not-results panel-content">
                    <FontAwesomeIcon 
                        className={ "icons-color-regularizations-" + param.messageApi }
                        icon={ param.messageApi === "error" ? faTimesCircle : (param.searchHeaderResults ? faSearch : faInbox) } 
                    />
                    {
                        // Primera condicion: error
                        param.messageApi === "error" ? (                        
                            <>
                                <Title level={ 4 }> { t('regularizations:not-results.title-error') } </Title>
                                <Paragraph className="text-no-found">
                                    <strong>{ t('regularizations:not-results.text-error') }</strong>
                                </Paragraph>
                            </>
                        // Segunda condicion: Sin resultado en la busqueda
                        ) : (param.searchHeaderResults ? (
                            <>
                                <Paragraph className="text-no-found">
                                    { t('regularizations:not-results.text-not-found') }
                                </Paragraph>
                                <Paragraph className="text-no-found-link">
                                    <span onClick={ () => param.setClearForm(true) }>{ t('regularizations:not-results.link-not-found') }</span>
                                </Paragraph>
                            </>
                        // Tercera condicion: No canjeado ningun cheque
                        ) : (
                            <Paragraph className="text-no-found">
                                { t('regularizations:not-results.text-not-result') }
                            </Paragraph>
                        ))
                    }
                </div>
            );
        // Section searchAgency
        case "searchAgency":
            return (
                <div className="agencies-not-results panel-content">
                    <img alt="Sin resultados" src={ URL_IMAGE + "register/icon-not-results.svg" } className="width-icon-not-found" />
                    <Paragraph>
                        <strong>{ t('searchAgency:not-results.text-not-found') }</strong>
                    </Paragraph>
                    <Paragraph id="link-not-results-add-agency">
                        { t('searchAgency:not-results.text-modify') }
                        <span style={{textDecoration:"underline", fontWeight:700}} onClick={ () => setOpenNewAgency(true) }>
                        { t('searchAgency:not-results.text-link') }
                        </span>
                    </Paragraph>
                    { openNewAgency && <FormNewAgency onAgencyCreated={(newIdAgency:string)=>{
                        param.onAgencyCreated(newIdAgency)
                    }} openNewAgency={ openNewAgency } setOpenNewAgency={ setOpenNewAgency } /> }
                </div>
            );
            break;
        // Section News
        case "news":
            return (
                <div className="rewards-not-results panel-content">
                    <FontAwesomeIcon 
                        className={ param.messageApi === "error" ? "icons-color-reward-error": "icons-color-reward-no-result" }
                        icon={ param.messageApi === "error" ? faTimesCircle : faNewspaper } 
                    />
                    {
                        // Primera condicion: error
                        param.messageApi === "error" ? (                        
                            <>
                                <Title level={ 4 }> { t('news:title-error') } </Title>
                                <Paragraph className="text-no-found">
                                    { t('news:text-error') }
                                </Paragraph>
                                <Link 
                                    id="link-news"
                                    to={ t("urls:private.news") }
                                >
                                    <Button 
                                        id="btn-news"
                                        className="default-button margin-button-news-error" 
                                        size="large"
                                    >
                                        { t('news:button') }
                                    </Button>
                                </Link>
                            </>
                        ) : (
                            <Paragraph className="text-no-found">
                                { t('news:no-result') }
                            </Paragraph>
                        )
                    }
                </div>
            );
            break;
        // Section Ranking
        case "ranking":
            return (
                <div className="rewards-not-results panel-content">
                    <FontAwesomeIcon 
                        className= "icons-color-reward-no-result" 
                        icon={ faTrophy } 
                    />                    
                    <Paragraph className="text-no-found">
                        { t('ranking:no-result') }
                    </Paragraph>

                </div>
            );
            break;
        default:
            return (
                <>
                    <div className="panel-content">
                        <img alt="Sin resultados" src={ URL_IMAGE + "register/icon-not-results.svg" } className="width-icon-not-found" />
                        <Paragraph>
                            <strong>{ t('searchAgency:not-results.text-not-found') }</strong>
                        </Paragraph>
                    </div>
                </>
            );
    }
}

export default NotResults;
