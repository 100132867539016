import ReactHtmlParser from 'react-html-parser';
import { API_URL_TEST } from '../constants/constants';

import EndPointConfig from '../models/api/endPointConfig';

import { RegisterEndPoints }  from './endPoints/';
import { notification } from 'antd';
import { ParamsApi } from '../models/api/paramsApi';
import { ApiResponseStatus } from '../constants/enums';
// import { hasOwnProperty } from 'tslint/lib/utils';

/// For translations
let t : any = null;
/// For use mocks or not.
let useMocks : boolean = false;
/// For use mocks with fail data
const forceFail : boolean = false;
/// For show mocks warnings
let showWarnings : boolean = false;

/// TODO : APIResponse debe ser el modelo que devuelva la API. Cuando se defina
///        habrá que modificar este fichero

interface APIResponse{
    additionalMessages: string[],
    code: string,
    data: any,
    message: string,
    title: string,
    type: string
}

interface APIResponseOld {
    type: number,
    code: number,
    data: any,
    message: string,
    additionalMessages: string[]
}


export const init = (translations : any, mock : boolean = false, forceFail : boolean = false) : void => {
    /// Translation object
    t = translations;
    useMocks = mock; // TODO: QUITAR CUANDO NO TENGAMOS MOCKS
    forceFail = forceFail; // TODO: QUITAR CUANDO NO TENGAMOS MOCKS
}


//#region  nuevo codigo

export const doAction = (alias: string, translations: any, params: ParamsApi = null): Promise<APIResponse> => {
    t = translations;
    const endPointInfo: EndPointConfig = getEndPointInfo(alias);

    if (!endPointInfo) {
        notification.error({
            message: t('ajaxApi:error-notification'),
            description: ReactHtmlParser(t("ajaxApi:end-point-undefined", { 0: alias })),
        })
        return Promise.reject(null);
    }

    const actionURL = getUrlEndPoint(endPointInfo, params);
    const init = generateInitFetch(endPointInfo, params);
    try {
        return fetch(actionURL, init)
            .then(response => checkStatus(response))
            .then(response => response.json())
            .then(response => showMessages(response))
    } catch (error) {
        notification.error({
            message: t('generic-error-notification'),
            description: error.message
        })
    }
}



// created to avoid the notification generation. The text of the notification´s messages are generated in the frontend, not came from the backend
export const doActionWithoutNotification = (alias: string,  params: ParamsApi = null): Promise<APIResponse> => {
     
    const endPointInfo: EndPointConfig = getEndPointInfo(alias);

    if (!endPointInfo) {
        notification.error({
            message: "Error",
            description: "Error",
        })
        return Promise.reject(null);
    }

    const actionURL = getUrlEndPoint(endPointInfo, params);
    const init = generateInitFetch(endPointInfo, params);
    try {
        return fetch(actionURL, init)
            .then(response => checkStatus(response))
            .then(response => response.json())
          
    } catch (error) {
        notification.error({
            message: t('generic-error-notification'),
            description: error.message
        })
    }
}


const checkStatus = (response: Response): Promise<Response> => {
    if (response.status >= 200 && response.status < 300) {
        return Promise.resolve(response);
    } else if (response.status === 401) {
        sessionStorage.removeItem("token");
        document.cookie = "userToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
        window.location.reload();
    } else {
        return response.text().then((text) => {
            throw new Error(`${response.status}: ${text}`);
        });
    }
}

const showMessages = (response: APIResponse) : Promise<APIResponse> => {
    if (response.title && response.title.length > 0 && response.message && response.message.length > 0){
        switch(response.type){
            case ApiResponseStatus.Success:
            case ApiResponseStatus.Information:
                notification.success({
                    message: response.title,
                    description: response.message,
                })
                break;

            case ApiResponseStatus.Warning:
                notification.warning({
                    message: response.title,
                    description: response.message,
                })
                break;
            case ApiResponseStatus.Error:
                notification.error({
                    message: response.title,
                    description: response.message,
                })
                break;
            case ApiResponseStatus.NoShow:
            case ApiResponseStatus.Validation:
                // No muestra mensaje de alerta
                break;
            default:
                notification.error({
                    message: t('generic-error-notification'),
                    description: t('generic-error-message'),
                })
                break;
        }
    }
    return Promise.resolve(response);
}

const getUrlEndPoint = (endPointInfo: EndPointConfig, params: ParamsApi = null): string => {
    let url = `${API_URL_TEST}/${endPointInfo.controller}`;
    if (endPointInfo.usingActionName) {
        url += `/${endPointInfo.actionName}`;
    }

    if (params && params.path) {
        // Si tengo params.pattern los seteo
        url = setPatternParams(params.path, endPointInfo.pattern, url);
    }

    if (params && params.query) {
        // Si tengo params.url los seteo
        url = setUrlParams(params.query, url);
    }

    return url;
}

const setPatternParams = (patternParams: any, pattern: string, url: string) => {

    let patternVal = "";

    Object.keys(patternParams).map((v, i) => {
        // v está definido como key en pattern
        if (pattern.indexOf(v) !== -1) {
            // reemplazamos {v} por {patternParams[v]}
            patternVal = pattern.replace(`{${v}}`, patternParams[v]);
            pattern = patternVal;
            // pattern.replace(`{${v}}`, patternParams[v]);
        } else {
            // v NO está definido como key en pattern => PROBLEMA
            console.error("Pattern doesn't match with model");
        }
    });

    return `${url}/${patternVal}`;
}

const setUrlParams = (urlParams: any, url: string, useSeparator: boolean = true, urlBase: string = '') => {

    Object.keys(urlParams).map((v, i) => {
        const separator = useSeparator ? (i === 0 ? '?' : '&') : '';

        if (urlParams[v] instanceof Object) {
            const base = `${urlBase}${separator}${v}.`;

            url += setUrlParams(urlParams[v], '', false, base);
        } else {
            url += `${urlBase}${separator}${v}=${urlParams[v]}`;
        }
    });

    return url;
}

const getEndPointInfo = (alias: string) => {
    const endPoints: EndPointConfig[] = RegisterEndPoints;
    let result: EndPointConfig;

    const coincidences: EndPointConfig[] = endPoints.filter((i: EndPointConfig) => {
        // TODO: Provisional hasta modificar todos los endpoint
        if(i.hasOwnProperty("alias")) return i.alias.toLowerCase() === alias.toLowerCase();
        return false
        // return i.alias.toLowerCase() === alias.toLowerCase();
    });

    if (coincidences.length > 1) {
        console.error("Multiple end point found: ", coincidences);
    } else {
        result = coincidences.pop();
    }

    return result;
}

const getHeaders = (): Headers => {
    const headers = new Headers();

    // TODO : Añadir aquí el token de la sesión que está guardado en la Cookie @Angel:
    if (sessionStorage.getItem('token') !== null || sessionStorage.getItem('token') !== undefined)
        headers.append('X-Authorization', sessionStorage.getItem('token'));
    // TODO : Añadir aquí el idioma en la que se está comunicando la aplicación con el usuario final (Está guardado en un contexto @Angel)
    // headers.append('User-Language', getcontext('xxxx'))

    headers.append('Content-Type', 'application/json');
    headers.append('Access-Control-Allow-Origin', '*');
    headers.append('Client-Tenant-URL', window.location.origin);

    return headers;
}

const generateInitFetch = (endPointInfo: EndPointConfig, params: ParamsApi) => {
    return {
        method: endPointInfo.method,
        headers: getHeaders(),
        body: params && JSON.stringify(params.body)
    };
}

//#endregion








/*
description:
    Lets make a call to API
Params:
    action: Action name in the EndPoint
    params: Parameters to send
    mock: For use mock data in this doAction
    fail: For use fail data in this action
Result:
    Promise<APIResponse>: Promise with info about response.
*/
export const doActionOld = (action: string, params: any = null, mock : boolean = false, fail : boolean = false) : Promise<APIResponseOld> =>
{

    if (t === null || t === undefined){
        throw new Error('For use the react-ajax-api library you need call to init method first.');
    }

    // Result of doAction method
    let result = null;

    const endPointInfo : EndPointConfig  = getEndPointInfoOld(action);

    if (endPointInfo === undefined){
        /// Mostramos notificación de error
        notification.error({
            message: t('ajaxApi:error-notification'),
            description: ReactHtmlParser(t("ajaxApi:end-point-undefined", { 0: action})),
        })
        /// Rechazamos la petición
        return Promise.reject(null);
    } else {
        const actionURL = getUrlEndPointOld(endPointInfo, params);

        // Set parameters to sendFconst
        const init = generateInitFetchOld(endPointInfo, params);

        try{
            const isMockTheEndPoint = IsMockTheEndPoint(action);

            result = (isMockTheEndPoint.IsMocked || mock)
                        ?
                            /// Si la petición está mockeada devolvemos mocks
                            getMocks(endPointInfo, (!isMockTheEndPoint.IsOkData || fail), actionURL)
                        :
                            /// En otro caso devolvemos la petición real al EndPoint
                            fetch(actionURL, init)
                                .then(response => {
                                    return response;
                                } )
                                .catch(data => {
                                    resolveError(data);
                                });

        }catch(ex){
            /// Show error
            resolveError(ex);
        }
    }

    return result;
}

/**
 * Lets show warning info when the application use mocks data
 */
const ShowConsoleMocksWarn = (message : string, data: any = undefined, otherData: any = undefined) : void => {

    if (showWarnings) {
        console.warn(message, data, otherData);
    }
}

/**
 * Lets get query params o init config to know if must get mocks data or not.
 *
 * To use you must set in URL a mocksConfig like this: mocksConfig={"mocksConfig":[{"GetAgencies":false},{"GetAvailableUserNames":false}]}
 * @param endPointName
 */
const IsMockTheEndPoint = (endPointName: string = '') => {

    const result = {
        IsMocked: false,
        IsOkData: false
    };

    if (endPointName === '') {
        return result;
    }

    /// If mocksConfig is defined in query params then use it.
    /// Else if mocksConfig is not defined in query params then seach a Cookie named mocksConfig
    /// Else if both query params and cookies is not defined then use init value
    /// Else if all equals to false then not use mocks.

    let queryParams;

    // ShowConsoleMocksWarn("Mocks: Reading mocks config from query params...");
    if (window.location.search) {
        // Search mocksConfig. Its possible that mocksConfig is not defined but window.location.search have parameters.
        queryParams = window.location.search
            .substr(1, window.location.search.length - 1)
            .split("&")
            .filter(e => {
                const data = e.split("=");
                return data[0] === "mocksConfig";
            })
            .pop()
    }

    if (queryParams === undefined){
        // query params have not defined consts with name mocksConfig or are empty. Search cookies
        // ShowConsoleMocksWarn("Mocks: Not found");
        // ShowConsoleMocksWarn("Mocks: Reading mocks config from sessionStorage");
        queryParams = sessionStorage.getItem("mocksConfig");
    }else{

        // ShowConsoleMocksWarn("Mocks: Saving mocksConfig in sessionStorage");
        // query params have defined consts with name mocksConfig, we save in cookies
        sessionStorage.setItem("mocksConfig", queryParams);
    }

    if (queryParams !== undefined && queryParams !== null){
        const mocksConfig = queryParams.split("=");
        const o = JSON.parse(mocksConfig[1].replace(/%22/g, '"'));

        showWarnings = o.ShowWarning !== undefined && o.ShowWarning !== null ? o.ShowWarning : false;

        const data = o.EndPoints.filter(oe=>{
            const keyFilter = Object.keys(oe).filter(k=>{
                return k === endPointName;
            });

            return keyFilter.length > 0
        }).pop();

        if (data !== undefined){
            // Config have data for this endPointName

            // TODO : Buscar alternativa a EVAL, agujero de seguridad, no subir a producción.
            result.IsMocked = true;
            // tslint:disable-next-line:no-eval
            result.IsOkData = eval(`data.${endPointName}`);
        }else{
            // Config haven't data for this endPoint. Using Init config
            // ShowConsoleMocksWarn(`Mocks: No data for ${endPointName} in mocksConfig. Using Init value`);

            result.IsMocked = useMocks;
            result.IsOkData = !forceFail;
        }
    }else{
        // ShowConsoleMocksWarn("Mocks: Not found");
        // ShowConsoleMocksWarn(`Mocks: Using Init o Method value for EndPoint ${endPointName}`)

        result.IsMocked = useMocks;
        result.IsOkData = !forceFail;
    }

    return result;
}


/*
description:
    If an error has ocurred the this method show an notification with the message received.
Params:
    data : object APIResponse with error data or exception object
Result:
    Promise<APIResponse>
*/
const resolveError = (data : any) => {

    if (data.type === undefined) {
        /// This is an error unknow. Data is't of type APIResponse
        notification.error({
            message: t('generic-error-notification'),
            description: data.message,
        })
    } else {
        /// This is a knowed error. Data is of type APIResponse
        switch(data.type) {
            case 0: // Success
                notification.success({
                    message: t('success-notification'),
                    description: data.message,
                });
                break;
            case 1: // warning
                notification.warning({
                    message: t('warning-notification'),
                    description: data.message,
                });
                break;
            case 2: // Info
                notification.info({
                    message: t('info-notification'),
                    description: data.message,
                });
                break;
            case 3: // error
                notification.error({
                    message: t('error-notification'),
                    description: data.message,
                });
            default:
                break;
        }
    }
}

/*
description:
    Get Mocks data from endPointInfo
Params:
    endPointInfo : object endPointInfo to get mock data
Result:
    Promise<APIResponse>
*/
const getMocks = (endPointInfo : EndPointConfig, failMockData: boolean,  finalUrl : string = '') : Promise<APIResponseOld> => {

    const result : APIResponseOld = {
        type: 1,
        code: 200,
        data: failMockData ? endPointInfo.mockData.failData : endPointInfo.mockData.okData,
        additionalMessages: null,
        message: "Mock Data is showed"
    };

    ShowConsoleMocksWarn(`Mocks Alert: Call to ${endPointInfo.actionName} is mocked. Are you in dev environment?`, `URL ${finalUrl}`, result);

    return Promise.resolve(result);
}

/*
description:
    Get the URL to call API EndPoint
Params:
    endPointInfo : Info about end point
Result:
    url : String with URL
*/
const getUrlEndPointOld = (endPointInfo : EndPointConfig, params : any = null) : string => {
    let url = `${API_URL_TEST}/${endPointInfo.controller}`;

    if (endPointInfo.usingActionName){
        url += `/${endPointInfo.actionName}`;
    }

    /// If method is GET or Delete we must send the parameters in the URL
    if (params !== null && endPointInfo.method === 'GET' || endPointInfo.method === 'DELETE'){
        Object.keys(params).map((v, i) => {
            let separator = '&';

            if (i === 0){
                separator = '?';
            }

            url += separator + v + '=' + params[v];
        });
    }

    return url;
}

/*
description:
    Get the endpoint info that matches the action name
Params:
    actionName : Action name to search
Result:
    EndPointConfig : Info about the end point.
*/
const getEndPointInfoOld = (actionName : string) => {
    const endPoints : EndPointConfig[] = RegisterEndPoints;

    return endPoints.find( (i : EndPointConfig) => {
        return i.actionName === actionName;
    });
}

/*
description:
    Create the headers to call API
Params:
    void
Result:
    Headers : Header info
*/
const getHeadersOld = () : Headers => {
    const headers = new Headers();

        // TODO : Añadir aquí el token de la sesión que está guardado en la Cookie @Angel:
        // headers.append('Session-User-Token', sessionStorage.getItem('sessionUserToken'))
        // TODO : Añadir aquí el idioma en la que se está comunicando la aplicación con el usuario final (Está guardado en un contexto @Angel)
        // headers.append('User-Language', getcontext('xxxx'))

        headers.append('Content-Type', 'application/json');
        headers.append('Access-Control-Allow-Origin', '*');

    return headers;
}

/*
description:
    Init the fetch params
Params:
    endPointInfo : Info about the end point to get the method
    params : Parameters that we can send to EndPoint
Result:
    Headers : Header info
*/
const generateInitFetchOld = (endPointInfo : EndPointConfig, params : any) => {
    // TODO Cuando es GET los parámetros van en la URL
    return {
        method: endPointInfo.method,
        headers: getHeadersOld(),
        body: endPointInfo.method !== 'GET' ? JSON.stringify(params) : undefined
    };
}
