import { CURRENT_PAGE, PAGE_SIZE } from '../../constants/constants';

// Modelo de paginación
export default class Pagination {
    constructor(
        public current: number = CURRENT_PAGE,
        public pageSize: number = PAGE_SIZE,
    ) {
        
    }
}
