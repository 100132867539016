import * as React from "react";
import { useState } from "react";
import { Form, Input, Button, Select, Divider, DatePicker, Alert} from "antd";
import { useTranslation } from "react-i18next";
import Channel from "../../../../models/regularizations/channels";
import "./formAddReservation.sass";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { showNotification } from "../../../../utils/notificationsGeneric";
import { Typography } from "antd";
import { getLocaleDatePicker } from "../../../../utils/datePicker";
import { PickerLocale } from "antd/es/date-picker/generatePicker";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import {
  requestAddChannel,
  useAddRegularization,
  useChannels,
  useHotels,
  useMaxRooms,
  useMinDate,
  useGetMinAllowedNights
} from "src/api/api";

import {
  LoadingOutlined,
} from '@ant-design/icons';


import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import RoomNumberWrapper from "./hotelsComponent";
import { UserContext } from "src/hooks/context/userInfo";

const { Option } = Select;
const { Title, Paragraph } = Typography;
const { RangePicker } = DatePicker;

const tailLayout = {
  wrapperCol: {
    span: 25
  }
};

export const FormAddReservation = (param) => {
  const { t } = useTranslation(["regularizations", "translation"]);

  const userContext = React.useContext(UserContext);

  const local: PickerLocale = getLocaleDatePicker();

  const [idNewElement, setIdNewElement] = useState<string>("");
  const [, setDisable] = useState<boolean>(true);
  const history = useHistory();
  const channels = useChannels();
  const maxRoomSize = useMaxRooms();
  const hotelsList = useHotels();
  const dateLimit = useMinDate();
  const location = useLocation();

  let numRooms = [];
  for (let i = 1; i <= maxRoomSize; i++) numRooms.push(i);

  const addChannel = requestAddChannel();
  const addRegularization = useAddRegularization();
  const getMinNights = useGetMinAllowedNights();

  const [selectedEstablismentId, setSelectedEstablismentId] = useState<string>();
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const [invalidMinNightsNumberError, setInvalidMinNightsNumberError] = useState<boolean>(false);
  const [minNights, setMinNights] = useState<number>();
  const [nightsSelected, setNightsSelected] = useState<number>();
  const [fieldsData, setFieldsData] = useState({});

  const getMinAllowedNights = async () => {
    //Tenemos que trasladar las fechas al año 2000
    const data = {
      from: moment(new Date(2000, fromDate.getMonth(), fromDate.getDate())).format("YYYY-MM-DD"),
      to: moment(new Date(2000, toDate.getMonth(), toDate.getDate())).format("YYYY-MM-DD"),
      establishmentId: selectedEstablismentId,
      isoCountryCode: userContext.GetCountryCode()
    };
    const res = await getMinNights(data);

    const dateDif = moment(toDate).diff(fromDate, "days");

    setMinNights(res.data ?? 1);
    setNightsSelected(dateDif);
    if ((res.data ?? 1) <= dateDif) {
      setInvalidMinNightsNumberError(false);
    } else {
      setInvalidMinNightsNumberError(true);
    }

    validateDate();
  };
  const addNewChannel = async () => {
    addChannel(idNewElement)
      .then((r) => {
        if (r.type?.toLocaleLowerCase() !== "warning" && r.type?.toLocaleLowerCase() !== "error") {
          setIdNewElement("");
        }
        showNotification(r.errors || r.type?.toLocaleLowerCase() == "warning" ? "error" : "success", r.title, r.message, "topRight");
      })
      .catch(() => {
        showNotification("error", "", "", "topRight");
      });
  };

  const [form] = Form.useForm();

  interface RoomConfigurationInfo {
    regimen: string;
    roomType: string;
  }

  const getRoomConfiguration = (rooms: number): RoomConfigurationInfo[] => {
    let result: RoomConfigurationInfo[] = [];

    for (let i = 0; i < rooms; i++) {
      result.push({ regimen: form.getFieldValue(`regimen${i}`), roomType: form.getFieldValue(`roomType${i}`) });
    }

    return result;
  };

  const [savingData, setSavingData] = useState<boolean>(false);
  const onFinish = async (values) => {
    const inDate = moment(values.date[0]).toDate();
    const outDate = moment(values.date[1]).toDate();

    var parameters = {
      bookingReference: values.bookingReference,
      checkIn: new Date(Date.UTC(inDate.getFullYear(), inDate.getMonth(), inDate.getDate())).toISOString(),
      checkOut: new Date(Date.UTC(outDate.getFullYear(), outDate.getMonth(), outDate.getDate())).toISOString(),
      name: values["client-name"],
      surname1: values["client-surname"],
      surname2: values["client-surname2"],
      idEstablishment: JSON.parse(values.hotels).id,
      idBookingWay: values.channel !== "-1" ? "TO" : "DS", // DS: direct / DS: ttoo
      roomsNumber: parseInt(values.numberRooms),
      idTtoo: values.channel !== "-1" ? JSON.parse(values.channel).id : undefined,
      bookingRoomsConfiguration: getRoomConfiguration(parseInt(values.numberRooms))
    };

    setSavingData(true);
    addRegularization(parameters)
      .then((r) => {
        if (r.type.toLocaleLowerCase() !== "error") {
          form.resetFields();
          setSelectedEstablismentId(undefined);
          showNotification("success", r.title, r.message, "topRight");
          setFieldsData({});
          if (param && param.forceRefresh){
            param?.forceRefresh();
          }

        }else{
          showNotification("error", r.title, r.message, "topRight");
        }
      })
      .finally(()=>{
        setSavingData(false);
      });
  };

  const updateValue = (event) => {
    setIdNewElement(event.target.value);
  };

  const disabledDate = (current) => {
    return current && current < moment(dateLimit);
  };

  const validateDate = (): void => {
    setTimeout(() => {
      if (form.isFieldTouched("date")) form.validateFields(["date"]);
    }, 1500);
  };

  const changeCalendarState = (e: any[]) => {
    if (e && e.length === 2 && e[0] && e[1]) {
      setFromDate(moment(e[0]).toDate());
      setToDate(moment(e[1]).toDate());
      validateDate();
    }

    setDisable(false);
  };

  useEffect(() => {
    if (fromDate && toDate && selectedEstablismentId) {
      getMinAllowedNights();
    }
  }, [fromDate, toDate, selectedEstablismentId]);

  useEffect(() => {
    validateDate();
  }, [minNights, nightsSelected]);

  const handleOnChange = (e) => {
    setFieldsData({ ...fieldsData, [e[0].name]: e[0].value });
  };

  return (
    <>
      <div className="col-12 container form-new-reservation">
        <div
          className={(param.section !== null ? "padding-title-home " : "padding-title ") + "align-text-regularization"}
        >
          <Title level={4}>{t("regularizations:title")}</Title>
          <Paragraph>
            {param.section !== null ? t("regularizations:paragraph-home") : t("regularizations:paragraph")}
          </Paragraph>
          <Alert style={{marginBottom: "10px"}} showIcon type="warning" message={t("regularizations:notice-add-reservation")} />
        </div>
        
          <Form
          name="addReservation"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFieldsChange={handleOnChange}
          className="row"
          layout="vertical"
          form={form}
          id="newRegularization"
        >
          <Form.Item
            className={(param.section !== null ? "col-12 " : "col-lg-6 col-12  ") + "form-item"}
            label={t("regularizations:form.labels.channels")}
            name="channel"
            rules={[
              {
                required: true,
                message: t("regularizations:form.errors.channels")
              }
            ]}
          >
            <Select
              className="main-select"
              id="channels"
              data-testid="select-channels"
              placeholder={t("regularizations:form.placeholder.select")}
              showSearch
              defaultActiveFirstOption
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: "4px 0" }} />
                  <div className="box-add">{t("regularizations:form.text-no-found")}</div>
                  <div className="box-add" style={{ display: "flex", flexWrap: "nowrap" }}>
                    <Input
                      className="main-input"
                      style={{ flex: "auto", marginRight: 8 }}
                      value={idNewElement}
                      onChange={updateValue}
                    />
                    <a
                      className="add-button"
                      style={{ flex: "none", display: "block", cursor: "pointer" }}
                      onClick={addNewChannel}
                    >
                      <FontAwesomeIcon className="icons-color-plus" icon={faPlus} /> {t("regularizations:form.add")}
                    </a>
                  </div>
                </div>
              )}
            >
              {channels.map((s: Channel, i) => {
                return (
                  <Option key={i} id={`x-${i}`} value={JSON.stringify(s)}>
                    {s.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            className={(param.section !== null ? "col-12 " : "col-lg-6 col-12  ") + "form-item"}
            label={t("regularizations:form.labels.bookingReference")}
            name="bookingReference"
            rules={[
              {
                required: true,
                message: t("regularizations:form.errors.bookingReference")
              },
              {
                whitespace: true,
                message: t("regularizations:form.errors.bookingReference")
              }
            ]}
          >
            <Input className="main-input" id="company-name" data-testid="input-company-name" maxLength={100} />
          </Form.Item>
          <Form.Item
            className={(param.section !== null ? "col-lg-6 col-12 " : "col-lg-6 col-12  ") + "form-item"}
            label={t("regularizations:form.labels.hotel-selector")}
            name="hotels"
            rules={[
              {
                required: true,
                message: t("regularizations:form.errors.hotel-selector")
              }
            ]}
          >
            <Select
              className="main-select"
              id="hotels-list"
              data-testid="select-hotels-list"
              placeholder={t("regularizations:form.placeholder.select")}
              onChange={(v: any) => {
                setSelectedEstablismentId(JSON.parse(v).id);
              }}
              showSearch
            >
              {hotelsList.map((s: Channel, i) => {
                return (
                  <Option key={i} id={`t-${i}`} value={JSON.stringify(s)}>
                    {s.name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <RoomNumberWrapper
            fieldsData={fieldsData}
            numRooms={numRooms}
            selectedEstablismentId={selectedEstablismentId}
          />
          <Form.Item
            className={(param.section !== null ? "col-12 " : "col-lg-12 col-12 ") + "form-item main-select"}
            label={t("regularizations:form.labels.date")}
            name="date"
            rules={[
              {
                required: true,
                message: t("regularizations:form.errors.date")
              },
              {
                validator: (_, value) =>
                  invalidMinNightsNumberError
                    ? Promise.reject(
                        new Error(t("regularizations:invalid-min-nights-error", { 0: minNights, 1: nightsSelected }))
                      )
                    : Promise.resolve()
              }
            ]}
          >
            <RangePicker
              className="main-select reservations-dates"
              data-testid="rp-reservation-dates"
              locale={local}
              disabledDate={disabledDate}
              onCalendarChange={changeCalendarState}
              placeholder={[local.lang.dateFormat, local.lang.dateFormat]}
              style={{ width: "100%" }}
              format={local.dateFormat}
              renderExtraFooter={() =>
                dateLimit !== null
                  ? ReactHtmlParser(
                      t("regularizations:form.text-info-date", { 0: moment(dateLimit).format(local.dateFormat) })
                    )
                  : ""
              }
            />
          </Form.Item>
          
          <Form.Item
            className={(param.section !== null ? "col-12 " : "col-lg-4 col-12  ") + "form-item"}
            label={t("regularizations:form.labels.name-client")}
            name="client-name"
            rules={[
              {
                required: true,
                message: t("regularizations:form.errors.name-client")
              },
              {
                whitespace: true,
                message: t("regularizations:form.errors.name-client")
              }
            ]}
          >
            <Input className="main-input" id="client-name" data-testid="input-client-name" maxLength={500} />
          </Form.Item>
          <Form.Item
            className={(param.section !== null ? "col-12 " : "col-lg-4 col-12 ") + "form-item"}
            label={t("regularizations:form.labels.surname-client")}
            name="client-surname"
            rules={[
              {
                required: true,
                message: t("regularizations:form.errors.surname-client")
              },
              {
                whitespace: true,
                message: t("regularizations:form.errors.surname-client")
              }
            ]}
          >
            <Input className="main-input" id="client-surname" data-testid="input-client-surname" maxLength={500} />
          </Form.Item>
          <Form.Item
            className={(param.section !== null ? "col-12 " : "col-lg-4 col-12 ") + "form-item"}
            label={t("regularizations:form.labels.surname2-client")}
            name="client-surname2"
            rules={[
              {
                whitespace: true,
                message: t("regularizations:form.errors.surname-client")
              }
            ]}
          >
            <Input className="main-input" id="client-surname2" data-testid="input-client-surname2" maxLength={500} />
          </Form.Item>
          <Button
              disabled={savingData}
              id="regularizate-reservation"
              data-testid="button-regularizate-reservation"
              className={(param.section !== null ? "col-12 " : "col-4 col-offset-9") + "primary-button"}
              style={{marginTop: "25px", marginBottom: "25px"}}
              size="large"
              type="primary"
              htmlType="submit"
            >
              {
                savingData ? <LoadingOutlined /> : `${t("regularizations:form.button")}`
              }              
            </Button>
        </Form>
      </div>
    </>
  );
};
