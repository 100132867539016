import EndPointConfig from "src/models/api/endPointConfig";

const controller = 'BankAccount';

/// Define all EndPoints for Client Data.
export const BankDataEP : EndPointConfig[] = [
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setAlias('ModifyBankAccount')
        .setActionName("modify"),
        new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller)
        .setAlias('DeleteBankAccount')
        .setActionName("delete"),
        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('getBankAccount')
        .setActionName("getBankAccount"),
        new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setAlias('SaveBankAccount')
        .setActionName("save"),
        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('getProviderId')
        .setActionName("getProviderId"),
        new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setAlias('setBankData')
        .setActionName("set"),

        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('getAccountType')
        .setActionName("getAccountType"),

        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('calculateFee')
        .setActionName("calculateFee"),

        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetBankAccountCountry')
        .setActionName("getBankAccountCountry"),

        new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetBankAccountCurrency')
        .setActionName("getBankAccountCurrency"),
];

