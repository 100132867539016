import React, { Suspense } from "react";
import "./confirm-mail.sass";
import { useContext } from "react";
import { Skeleton } from "antd";
import { Typography, Button, Divider } from "antd";
import { Link } from "react-router-dom";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "src/hooks/context/config";
import { URL_IMAGE } from "../../../constants/constants";
import { useConfirmMailId } from "src/api/api";

const { Paragraph } = Typography;

// Componente que muestra la confirmación del registro de un usuario a través del mail
const ConfirmMail = (props) => {
  const { t } = useTranslation(["confirmMail", "advantages"]);
  const theme = useContext(ConfigContext);

  useConfirmMailId(props.confirmToken);
  
  return (
    <div className="panel-confirm">
      <img
        alt="Sin resultados"
        src={URL_IMAGE + "register/icon-step-4.svg"}
        className="width-icon-verify"
      />
      <Paragraph>
        <strong>{t("text-confirm")}</strong>
      </Paragraph>
      <Paragraph className="mb-5">{t("detail-confirm")}</Paragraph>

      <Divider />

      <Paragraph className="mt-5 mb-5">{t("advantages:title-paragraph", { 0: theme.brandName })}</Paragraph>
      <Link to="">
        <Button
          id="btn-advantages"
          className="default-button col-3 mb-4"
          size="large"
        >
          {t("button-advantages")}
        </Button>
      </Link>

      <Divider />
    </div>
  );
};

const ConfirmMailWrapper = (props) => (
  <Suspense fallback={<Skeleton />}>
    <ConfirmMail {...props} />
  </Suspense>
);

export default ConfirmMailWrapper;
