import * as React from 'react';
import { Tag, Tooltip } from 'antd';
import { COLOR_PENDIENTE, COLOR_REGULARIZADA, COLOR_VALIDADA, COLOR_CANCELADA, COLOR_DUPLICADA, COLOR_RECHAZADA } from '../constants/constants';
import { InfoCircleOutlined } from '@ant-design/icons';

// Gestiona los colores del componente Tag en función del estado de una reserva
export const setTag = (state, idState, statusReason = '') => {
    let color = '#FFF';

    switch (idState) {
        case '1':
        case 'PEN':
        case 'INC': // ?
            color = COLOR_PENDIENTE;
            break;
        case '2':
        case 'REG':
            color = COLOR_REGULARIZADA;
            break;
        case '3':
        case 'VAL':
            color = COLOR_VALIDADA;
            break;
        case '4':
        case 'CAN':
            color = COLOR_CANCELADA;
            break;
        case '5':
        case 'DUP':
            color = COLOR_DUPLICADA;
            break;
        case '6':
        case 'REJ':
            color = COLOR_RECHAZADA;
            break;
        default:
            color = COLOR_PENDIENTE; // Better than invisible text...
    }

    var additionalInfo = <></>;

    if (idState == 'REJ' && statusReason.length > 0){
        additionalInfo = <Tooltip title={statusReason}>
                                <InfoCircleOutlined />
                            </Tooltip>;
    }

    return (
        <div className="tag-content">
            <Tag 
                className="tag-state" 
                color={ color } 
                key={ idState }
            >
                {additionalInfo} { state } 
            </Tag>
        </div>
    );
}