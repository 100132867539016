import React, { useContext } from "react";
import "./profile.sass";
import { Helmet } from "react-helmet-async";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../hooks/context/config";
import { UserContext } from "../../hooks/context/userInfo";
import { Typography } from "antd";
import TabsResults from "./tabs";

const { Title, Paragraph } = Typography;

// Componente de sección privada del usuario padre
const Profile = () => {
  const infoClient = useContext(ConfigContext);
  const userContext = useContext(UserContext);
  const { t } = useTranslation(["profile", "seo", "urls"]);

  return (
    <>
      <Helmet>
        <title>
          {infoClient.incentiveSiteName} | {t("seo:title.privacy-policies")}
        </title>
        <meta
          name="description"
          content={t("seo:description.privacy-policies", {
            0: infoClient.incentiveSiteName,
          })}
        />
        <meta name="robots" content="noindex,nofollow" />
        <link rel="canonical" href={infoClient.urlWA + t("urls:public.privacy-policies")} />
        <link rel="icon" type="image/ico" href={infoClient.favicon} sizes="32x32" />
        <link href={infoClient.urlWA + t("urls:public.privacy-policies")} hrefLang="es-ES" rel="alternate" />
      </Helmet>

      <div className="mt-3 profile-container">
        <Title level={4}>{t("profile:main.title")}</Title>
        <Paragraph>{t("profile:main.description")}</Paragraph>
        <TabsResults userInfo={userContext} />
      </div>
    </>
  );
};

export default Profile;
