import * as React from "react";
import { Radio, Typography, List } from "antd";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import CustomPagination from "../../utils/pagination/pagination";
import Pagination from "../../../models/pagination/pagination";

const { Paragraph } = Typography;

// Componente personalizado de List que recoge la lista de agencias disponibles
const ListResults = (param) => {
  const totalData: any[] = param.rows.agencies;

  const { t } = useTranslation(["searchAgency"]);

  // Función que se dispara en el evento de cambiar el elemento de paginación, de ordenación o de búsqueda de la tabla
  const onChangeCallApi = (pagination) => {
    // Almacenamos el valor de si el panelito de búsqueda se muestra o no
    const pag = new Pagination(pagination.current, pagination.pageSize);

    // Usuario pulsa página
    param.setPageResults(pag);
    param.setPageResultsInput(pag);
  };

  return (
    <>
      <Radio.Group
        className="col-12"
        value={param.selectedData ? param.selectedData.id : undefined}
        onChange={(e) => {
          const agency = param.rows.agencies.filter((data) => data.id === e.target.value)[0]
          param.selectData(agency);
        }}
      >
        <List
          itemLayout="vertical"
          size="large"
          pagination={false}
          dataSource={totalData}
          renderItem={(item) => (
            <List.Item key={item.id} className="list-panel-content">
              <List.Item.Meta
                avatar={<Radio value={item.id}></Radio>}
                title={
                  <Paragraph className="text-left">
                    <strong>{item.name}</strong>
                  </Paragraph>
                }
                description={
                  <>
                    <Paragraph className="text-left">{item.address}</Paragraph>
                    <div className="row base-color-list">
                      <div className="col-xs-12 col-sm-6 text-left">
                        <strong>{t("search-form.phone")} </strong>
                        {item.telephone}
                      </div>
                      <div className="col-xs-12 col-sm-6 text-right">
                        <strong>{t("search-form.tax-id-long")} </strong>
                        {item.identityCode}
                      </div>
                    </div>
                  </>
                }
              />
            </List.Item>
          )}
        />
      </Radio.Group>

      <div className="pagination-container col-12 p-0 mt-4">
        <CustomPagination
          data-testid="pagination-table-balance-history"
          countData={param.rows.totalRecord}
          callApiChange={onChangeCallApi}
          pageResults={param.pageResults}
        />
      </div>
    </>
  );
};

export default ListResults;
