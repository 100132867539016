import * as React from 'react';
import { useContext, useState } from 'react';
import { Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { URL_IMAGE } from '../../../constants/constants';
import { Link, useHistory } from 'react-router-dom';
import './validateAddNewAgency.sass'
import { linkActiveContext } from '../../../hooks/context/linkActive';
import FormNewAgency from '../newAgency/newAgency';

const { Paragraph, Title } = Typography;

// Componente visual de validacion al agregar una nueva agencia. El parametro recibido determinara si es un success o un error para mostrar una cosa u otra
const ValidateAddNewAgency = ({ match: { params: { id } } }) => {
    
    // History para cuando cerremos sesion monte la url base de la home
    let history = useHistory();
    // Idioma para los textos
    const { t } = useTranslation(['urls','newAgency']);

    // Contexto de los links
    const linkActive = useContext(linkActiveContext);

    // Muestra la modal para volver a dar de alta una agencia
    const [openNewAgency, setOpenNewAgency] = useState(false);

    // Si no es uno de los elemento esperados redirigimos a la Home por defecto
    if(id !== "error" && id !== "success" ){
        history.push("/");
    }

    const onAgencyCreated = () => {
        console.error("Please, this method is not working");
    }
    
    return (
        <>
            <div className="new-agency-add">
                <div className="success-box-align-vertical">
                    <div className="col-12 success-box-align-vertical-inside">
                        {id !== "error" ? (
                            <>
                                <img alt="success" src={ URL_IMAGE + "register/icon-step-3.svg" } className="width-icon-verify" />
                                <Title level={4}>
                                    { t('newAgency:validate-new-agency.title') }
                                </Title>
                                <Paragraph className="mb-4">
                                    { t('newAgency:validate-new-agency.sub-text') }
                                </Paragraph>
                                <Link 
                                    to={ t("urls:public.home") }
                                    onClick={ () => linkActive.updateValueUrl(t("urls:public.home")) }
                                >
                                    <Button 
                                        id="btn-advantages"
                                        className="default-button button-ok-validate col-sm-1 col-6 mb-4" 
                                        size="large"
                                    >
                                        { t('newAgency:validate-new-agency.button') }
                                    </Button>
                                </Link>
                            </>
                        ):(
                            <>
                               <FontAwesomeIcon 
                                    className={ "icons-error-validate mb-3" }
                                    icon={ faTimesCircle } 
                                />
                                <Title level={4}>
                                    { t('newAgency:error-new-agency.title') }
                                </Title>
                                <Paragraph className="mb-4">
                                    { t('newAgency:error-new-agency.sub-text') }
                                </Paragraph>                                
                                <Button 
                                    id="btn-validate"
                                    className="default-button button-error-validate col-1 mb-4 position-button-validation" 
                                    size="large"
                                    onClick={()=>setOpenNewAgency(true)}
                                >
                                    { t('newAgency:error-new-agency.button') }
                                </Button>
                                { openNewAgency && 
                                    <FormNewAgency 
                                        onAgencyCreated={onAgencyCreated}
                                        openNewAgency={ openNewAgency } 
                                        setOpenNewAgency={ setOpenNewAgency } 
                                    /> 
                                }
                            </>
                        )}
                    </div>
                </div>
            </div>        
        </>
    );
}

export default ValidateAddNewAgency;