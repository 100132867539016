import EndPointConfig from "src/models/api/endPointConfig";

const controller = 'KnowUs';

/// Define all EndPoints for Client Data.
export const KnowUsEP : EndPointConfig[] = [
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setAlias('SendFormKnowUsRequest')
        .setActionName("SendFormKnowUsRequest"),
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetKnowUsConfiguration')
        .setActionName("GetKnowUsConfiguration")
];

