import * as React from "react";
import "./init.sass";
import ModalLogin from "../modalsLogin/login/login";
import { useState, useContext } from "react";
import { Typography, Button } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { linkActiveContext } from "../../hooks/context/linkActive";
import { HomeLandingTexts } from "./interfaceInit";
import ReactHtmlParser from "react-html-parser";

const { Title, Paragraph } = Typography;

// Subcomponenete de Init que recoge la parte textual de la info de la parte principal
export const Info = (param: HomeLandingTexts) => {
  // Libreria i18n para traducir
  const { t } = useTranslation(["urls", "initSection"]);
  const linkActive = useContext(linkActiveContext);

  // Estado para mostrar o ocultar modal cookie
  const [modalLogin, setmodalLogin] = useState(false);

  // Botones de acceso y registro
  const InfoButtons = () => {
    return (
      param.principal && (
        <div className="row m-0">
          <div className="col-12 col-sm-6 no-padding-left-init">
            <Button
              id="btn-access"
              className="col-12 primary-button align-right"
              onClick={() => setmodalLogin(true)}
              type="primary"
              size="large"
            >
              {t("initSection:button.access")}
            </Button>
          </div>
          <div className="col-12 col-sm-6">
            <Link
              id="link-register"
              to={t("urls:public.register")}
              onClick={() => linkActive.updateValueUrl(t("urls:public.register"))}
            >
              <Button id="btn-register" className="col-12 default-button align-left" size="large">
                {t("initSection:button.register")}
              </Button>
            </Link>
          </div>
          <div>{modalLogin && <ModalLogin setmodalLogin={setmodalLogin} />}</div>
        </div>
      )
    );
  };

  return (
    <>
      <div className="d-none col-lg-1 col-xl-2"></div>
      <div className="block-text col-12 col-lg-10 col-xl-8 pl-0 pr-0">
        <div className="align-text">
          {param !== undefined
            ? [param].map((element, index) => {
                return (
                  <div key={index}>
                    <Paragraph className="subtitle-init">{element.subtitle}</Paragraph>
                    <Title className="title-init" level={param.principal ? 1 : 2}>
                      {element.title}
                    </Title>
                    <Paragraph className="detail-init">{ReactHtmlParser(element.body)}</Paragraph>
                  </div>
                );
              })
            : ""}
            {
              param.principal ?  <InfoButtons /> : <></>
            }
         
        </div>
      </div>
      <div className="d-none col-lg-1 col-xl-2"></div>
    </>
  );
};
