import * as React from 'react';
import { useContext, useState } from 'react';
import { Modal, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import '../popUpCookies/pop-up-cookies.sass';
import './modal-cookies.sass';
import { CookiePopUp } from '../../../../hooks/context/cookies';
import { useCookies } from 'react-cookie';
import { LIST_COOKIES_DEFAULT, DURATION_COOKIE } from '../../../../constants/constants';

// Componente con la modal de las cookies que se puede activar o desactivar segun el cliente quiera
const ModalCookies = (params) => {

    // Idioma para los textos
    const { t } = useTranslation('cookies');
    
    // Contexto cookie para ocultarlo o mostrarlo
    const popupCookies = useContext(CookiePopUp);

    // Estado para mostrar u ocultar modal
    const[visible, setVisible] = useState(true);
    // Estado con todas las opciones dispnible y que se actualizara si el usuario modifica el switch
    const [cookiesSave] = useState(LIST_COOKIES_DEFAULT);

    // Cookie con la lista de cookies 
    const [, setCookiesList] = useCookies(['cookiesList']);
    // Contexto con la cookie que determina si se aceptaron
    const [, setCookies] = useCookies(['cookies']);

    // Manejador si el usuario desea guardar la configuracion seleccionada en la modal
    const handleOk = () => {  
        var date = Date.now() + DURATION_COOKIE;       
        setCookiesList('cookiesList', cookiesSave, { path: '/', expires: new Date( date)}); 
        setCookies('cookies', "true", { path: '/' , expires: new Date( date)});       
        params.setmodalCookie(false);
        popupCookies.UpdateVisible(false);
    };

    // Manejador si el usuario le da a cancelar en la modal
    const handleCancel = e => {
        params.setmodalCookie(false);
    };
    
    // Manejador para los switch
    const onChange = (checked, e) => {
        if (checked) {
            cookiesSave.push(e.target.classList[0]);

        } else {
            const index = cookiesSave.indexOf(e.target.classList[0]);
            cookiesSave.splice(index, 1);
        }
    }

    return (
        <Modal
            title={ t("cookies:modal.title") }
            visible={ visible }
            onOk={ handleOk }
            okText={ t("cookies:modal.buttonSave") } 
            onCancel={ handleCancel }
            cancelText={ t("cookies:modal.buttonCancel") }
            okButtonProps={ { disabled: false } }
            cancelButtonProps={ { disabled: false } }
            bodyStyle={ { maxHeight: 300, overflow: 'auto' } }
            className="modal-cookies-button-cancel"
        >
            {   
                cookiesSave.map((element, i) => {
                    return (
                        <div key={ i } className="col-lg-12 text-nodal-cookies padding-col">
                            <div className="col-lg-12 title-modal-cookies padding-col">
                                <div className="col-lg-9 col-sm-9 col-8 padding-col title-modal-switch">
                                    <p> { t("cookies:modal.options.title-" + i) } </p>
                                </div>
                                <div className="col-lg-3 col-sm-3 col-4 padding-col display-switch">
                                    <div className="padding-switch">{ t("cookies:modal.no") }</div>
                                    <div>
                                        <Switch 
                                            disabled 
                                            className={ t("cookies:modal.options.name-" + i) + " switch-modal" } 
                                            defaultChecked 
                                            onChange={ onChange } 
                                        />
                                    </div>
                                    <div className="padding-switch">{ t("cookies:modal.yes") }</div>
                                </div>
                            </div>
                            <div className="col-lg-12 padding-col">
                                <p>{ t("cookies:modal.options.text-" + i) }</p> 
                            </div>
                        </div>
                    )
                })
            }
        </Modal>
    );      
}
export default ModalCookies;
