import * as React from "react";
import { useContext } from "react";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import Advantage from "./advantage";
import "./advantages.sass";
import { ConfigContext } from "../../hooks/context/config";
import ReactHtmlParser from "react-html-parser";
import { URL_IMAGE } from "../../constants/constants";

const { Title, Paragraph, Text } = Typography;

// Componente que contiene todas y cada de una de las cards de las ventajas
const Advantages = () => {
  const theme = useContext(ConfigContext);
  const { t } = useTranslation(["advantages", "urls"]);
  const titlesRoot = "titles";
  const paragraphRoot = "paragraph";

  return (
    <>
      <div id={t("urls:public.benefits#")} className="titles-section">
        <Text className="title-secondary">{t("advantages:title-section1")}</Text>
        <Title className="title-main" level={2}>
          {t("advantages:title-section2", { 0: theme.incentiveSiteName })}
        </Title>
        <Paragraph className="title-paragraph">{ReactHtmlParser(t("advantages:title-paragraph", { 0: theme.brandName }))}</Paragraph>
        <div className="row site-card-wrapper">
          <Advantage
            code="1"
            typeCard="stay"
            className="box-card-advantage align-content-right"
            title={ReactHtmlParser(t("advantages:" + titlesRoot + ".title-stay", { 0: theme.brandName }))}
            text={
              <Paragraph ellipsis={{ rows: 8, expandable: true }}>
                {ReactHtmlParser(t("advantages:" + paragraphRoot + ".content-stay", { 0: theme.brandName }))}
              </Paragraph>
            }
            img={URL_IMAGE + "advantages/ventajas-estancias.svg"}
          />

          <Advantage
            code="2"
            typeCard={"training"}
            className={"box-card-advantage align-content-left"}
            title={t("advantages:" + titlesRoot + ".title-training")}
            text={
              <Paragraph ellipsis={{ rows: 8, expandable: true }}>
                {ReactHtmlParser(t("advantages:" + paragraphRoot + ".content-training", { 0: theme.brandName }))}{" "}
              </Paragraph>
            }
            img={URL_IMAGE + "advantages/ventajas-formacion.svg"}
          />

          <Advantage
            code="3"
            typeCard="other-rewards"
            className={"box-card-advantage align-content-right"}
            title={t(titlesRoot + ".title-other-rewards")}
            text={
              <Paragraph ellipsis={{ rows: 8, expandable: true }}>
                {ReactHtmlParser(t("advantages:" + paragraphRoot + ".content-other-rewards", { 0: theme.brandName }))}
              </Paragraph>
            }
            img={URL_IMAGE + "advantages/ventajas-recompensa.svg"}
          />

          <Advantage
            code="4"
            typeCard="information"
            className={"box-card-advantage align-content-left"}
            title={t(titlesRoot + ".title-information")}
            text={
              <Paragraph ellipsis={{ rows: 8, expandable: true }}>
                {ReactHtmlParser(t("advantages:" + paragraphRoot + ".content-information", { 0: theme.brandName }))}
              </Paragraph>
            }
            img={URL_IMAGE + "advantages/ventajas-info.svg"}
          />
        </div>
      </div>
    </>
  );
};

export default Advantages;
