import React from 'react';
import { Switch, RouteProps } from 'react-router';
import { useIntl } from 'react-intl';

const LocalizedSwitch: React.FC = ({ children }) => {

  // Inyectamos parámetros y formatMessage a través de ganchos, para que podamos localizar la ruta
  const { formatMessage, locale } = useIntl();

  /**
   *
   * @param path Puede ser string, undefined o string array
   * @returns Ruta de cadena localizada o matriz de ruta
   */
  const localizeRoutePath = (path?: string | string[]) => {
    switch (typeof path) {
      case 'undefined':
        return undefined;
      case 'object':
        return path.map(key => `/${locale}` + formatMessage({ id: key }));
      default:
        const isFallbackRoute = path === '*';
        return isFallbackRoute
          ? path
          : `/${locale}` + formatMessage({ id: path });
    }
  }

  // Aplicar localización a todas las rutas y también comprueba si todos los elementos secundarios son componentes <Ruta />
  return (
    <Switch>
      { React.Children.map(children, child =>
          React.isValidElement<RouteProps>(child)
            ? React.cloneElement(child, {
                ...child.props,
                path: localizeRoutePath(child.props.path)
              })
            : child
      ) }
    </Switch>
  );
};

export default LocalizedSwitch
