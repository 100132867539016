import React, { Suspense } from "react";
import { useContext } from "react";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { Typography, Button, Skeleton } from "antd";
import { FormattedNumber } from "react-intl";
import "./rewardWidget.sass";
import { Link } from "react-router-dom";
import { linkActiveContext } from "../../../hooks/context/linkActive";
import { useAvailableMoney } from "src/api/api";

const { Text } = Typography;

// Componente widget del reward
const RewardWidget = () => {
  const { t } = useTranslation(["urls", "reward"]);
  const linkActive = useContext(linkActiveContext);
  const availableMoney = useAvailableMoney();

  return (
    <>
      <div className="col-12 mb-3 p-3 background-box-reward-home">
        <div className="col-12 question">
          <Text className="font-size">{t("reward:balance.question")}</Text>
          <div className="text-color-main money-balance-info">
            {
              <FormattedNumber
                useGrouping={true}
                value={availableMoney.money}
                style="currency"
                currency={availableMoney.currencies.id}
              />
            }
          </div>
        </div>
        <div className="col-12 menssage-info align-text-widget">
          {t("reward:message-home")}
        </div>
        <div className="col-sm-6 col-10 p-sm-0  mt-3 mb-2 margin-button-home">
          <Link
            id="link-reward"
            to={t("urls:private.reward")}
            onClick={() => linkActive.updateValueUrl(t("urls:private.reward"))}
          >
            <Button
              id="btn-reward"
              className="col-12 primary-button margin-button-home"
              size="large"
              disabled={availableMoney.money <= 0}
            >
              {t("reward:button-navegate")}
            </Button>
          </Link>
        </div>
      </div>
    </>
  );
};

const RewardWidgetWrapper = () => (
  <Suspense fallback={<Skeleton />}>
    <RewardWidget />
  </Suspense>
);

export default RewardWidgetWrapper;
