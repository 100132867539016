import * as React from 'react';
import { useState } from 'react';
import { Typography, List } from 'antd';
import '../../../i18n';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { setTag } from '../../../utils/colorTags';
import FormEditReservation from './formEditReservation/formEditReservation'
import { StateReservations } from "../../../constants/enums";
import CustomPagination from '../../utils/pagination/pagination';
import Pagination from '../../../models/pagination/pagination';
import { FormattedNumber } from 'react-intl';
import { getLocaleDatePicker } from '../../../utils/datePicker';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import moment from 'moment';

const { Paragraph } = Typography;

// Componente List de la lista de reservas regularizadas
const ListResults = (param) => {

    const totalData: any[] = param.rows;
    
    const { t } = useTranslation(['regularizations']);

    const local:PickerLocale = getLocaleDatePicker();

    // Estado que almacena el valor de si la modal de editar reserva regularizada está visible o no
    const [openEditReservation, setOpenEditReservation] = useState(false);
    // Estado que controla que el panelito de búsqueda de la cabecera de la tabla aparezca o no
    const [visibleFilter, setVisibleFilter] = useState(false);
    // Estado que almacena los valores a editar del registro de la tabla
    const [itemValues, setItemValues] = useState();
    
    // Función que controla la apertura de la modal de edición de reserva regularizada
    const setItemReservation = (value) => {
        setOpenEditReservation(true);
        setItemValues(value);
    }

    // Función que se dispara en el evento de cambiar el elemento de paginación de la lista
    const onChangeCallApi = (pagination) => {
        const pag = new Pagination(
            pagination.current,
            pagination.pageSize
        );

        // Usuario pulsa página
        param.setPageResults(pag);
    }

    return (
        <>
            <List
                data-testid="list-reservations"
                itemLayout="vertical"
                size="large"
                dataSource={ totalData }
                renderItem={
                    item => (
                        <List.Item
                            key={ item.key }
                            className="list-panel-content"
                        >
                            <List.Item.Meta
                                avatar={ 
                                    <div className="empty-avatar">
                                        {
                                            item.idState == StateReservations.Pending && (
                                                <FontAwesomeIcon 
                                                    className="edit-icon-input" 
                                                    icon={ faPen } 
                                                    onClick={ () => setItemReservation(item) }
                                                /> 
                                            )
                                        }
                                    </div>
                                }
                                title={
                                    <div className="row">
                                        <div className="col-6 text-left label-list">
                                            { t('table.regularized-date') }
                                        </div>
                                        <div className="col-6 text-right label-list">
                                            { t('table.booking-reference') }
                                        </div>
                                        <div className="col-6 text-left text-list">
                                            { moment(item.regularizedDate).format(local.dateFormat) }
                                            { /* TODO: What is this? */ }
                                        </div>
                                        <div className="col-6 text-right text-list">
                                            { item.bookingReference }
                                        </div>
                                    </div>
                                }
                                description={
                                    <>
                                        <div className="row content-info-reservation mb-3">
                                            <Paragraph className="text-left text-hotel">
                                                { item.establishmentName }
                                            </Paragraph>
                                            <Paragraph className="col-12 text-left text-dates">
                                                { moment(item.checkIn).format(local.dateFormat) } - { moment(item.checkOut).format(local.dateFormat) }
                                            </Paragraph>
                                            <Paragraph className="col-12 text-left">
                                                { item.nameSurnameClient }
                                            </Paragraph>
                                        </div>
                                        <div className="row">
                                            <div className="col-5 text-left">
                                                <div className="label-list">{ t('table.amount') }</div>
                                                <div className="text-list">
                                                    <FormattedNumber
                                                        useGrouping={ true }
                                                        value={ item.reward }
                                                        style="currency"
                                                        currency={ item.currency }
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-7 text-right tag-state">
                                                { setTag(item.status.name, item.status.id) }
                                            </div>
                                        </div>
                                    </>
                                }
                            />
                        </List.Item>
                    )
                }
            />

            <div className="pagination-container mt-4">
                <CustomPagination 
                    data-testid="pagination-list-reservations"
                    countData={ param.totalRows }
                    callApiChange={ onChangeCallApi }
                    pageResults={ param.pageResults }
                /> 
            </div>

            { 
                openEditReservation && 
                    <FormEditReservation 
                        openEditReservation={ openEditReservation } 
                        setOpenEditReservation={ setOpenEditReservation } 
                        record={ itemValues }
                    /> 
            }
        </>
    );
}

export default ListResults;
