import React, { Suspense, useContext, useEffect } from "react";
import "./ranking.sass";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrophy } from "@fortawesome/free-solid-svg-icons";
import { Typography } from "antd";
import { useGetPersonalRanking, useMyRankingPosition } from "src/api/api";
import { useAgencyData } from "src/api/api";
import { UserContext } from "src/hooks/context/userInfo";

const { Paragraph } = Typography;

// Componente que muestra la posicion del ranking del usuario logado
const RankingPosition = () => {
  const { t } = useTranslation("ranking");

  const userContext = useContext(UserContext);
  
  const numberRank = useMyRankingPosition().data;
  const agencyData = useAgencyData()

  const allowPersonalRanking = useGetPersonalRanking().data;


  return (
    <>
          <div className="position-ranking-box">
            {
              allowPersonalRanking 
              ?
                <div className="row box-inside">
                  <div className="col-12 trophy">
                    <FontAwesomeIcon className="icons-color-trophy" icon={faTrophy} />
                  </div>
                  <div className="col-12 rank-cont">
                    <Paragraph className="box-ranking">{t("ranking:text")}</Paragraph>
                    <Paragraph className="number-position-rank">{numberRank === null ? "-" : numberRank}</Paragraph>
                  </div>
                </div>
              :
                <></>
            }
            {
              agencyData?.commercialName ? 
              <div className="row agency-name-cont">
                <div className="col-12 agency-name-label">
                  <Paragraph className="box-ranking agency-name-label">{t("ranking:agency")}</Paragraph>
                </div>
                <div className="col-12 agency-name">
                  <Paragraph className="box-ranking">{agencyData?.commercialName}</Paragraph>
                </div>
              </div>
              :
              <></>
            }

            {
              userContext.GetUAID() ? 
                <div className={!agencyData?.commercialName ? 'row agency-name-cont' : 'row'}>
                  <div className="col-12 agency-name-label">
                    <Paragraph className="box-ranking agency-name-label">{t("ranking:uaid")}</Paragraph>
                  </div>
                  <div className="col-12 agency-name">
                    <Paragraph className="box-ranking">{userContext?.GetUAID()}</Paragraph>
                  </div>
                </div>
                :
                <></>
            }

            
            
          </div>
    </>
  );
};

const RankingPositionWrapper = () => (
  <Suspense fallback={<div />}>
    <RankingPosition />
  </Suspense>
);

export default RankingPositionWrapper;
