// Modelo para el idioma de la URL
export class LanguageUrlManagement {
    public langUrl: string;

    constructor() {
        this.langUrl = "";
    }

    UpdateUrlLangContext = (newValue: string) => {
        this.langUrl = newValue;
    }

    GetURLLangContext = (): string => {
        return this.langUrl;
    }
}
