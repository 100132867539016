import * as React from "react";
import { useState } from "react";
import { Table } from "antd";
import "../balanceHistory.sass";
import "../../../i18n";
import CustomPagination from "../../utils/pagination/pagination";
import Pagination from "../../../models/pagination/pagination";
import Order from "../../../models/order/order";
import { Empty } from "antd";
import { useTranslation } from "react-i18next";

// Componente table para el balanceHistory
const TableResults = (param) => {
  // Estado que controla que el panelito de búsqueda de la cabecera de la tabla aparezca o no
  const [visibleFilter, setVisibleFilter] = useState(false);
  const { t } = useTranslation(["urls", "seo", "balanceHistory"]);

  const balanceHistory = param.rows;

  // Función que se dispara en el evento de cambiar el elemento de paginación, de ordenación o de búsqueda de la tabla
  const onChangeCallApi = (pagination, filters, sorter, extra, conf = null) => {
    // Almacenamos el valor de si el panelito de búsqueda se muestra o no
    setVisibleFilter(!visibleFilter);
    if (typeof sorter !== "undefined") {
      const ord = [];

      if (Array.isArray(sorter)) {
        for (let i of sorter) {
          ord.push(new Order(i.field, i.order));
        }
      } else {
        ord.push(new Order(sorter.field, sorter.order));
      }
      param.setOrderResults(ord);

      // Paginación
    } else {
      const pag = new Pagination(pagination.current, pagination.pageSize);

      // Usuario pulsa página
      param.setPageResults(pag);
    }
  };
  return (
    <>
      <Table
        columns={param.columns}
        dataSource={balanceHistory.balanceMovements}
        onChange={onChangeCallApi}
        pagination={false}
        locale={{
          emptyText: (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t("balanceHistory:tabs.no-data")} />
          ),
        }}
      />

      <div className="pagination-container col-12 p-0 mt-4">
        <CustomPagination
          data-testid="pagination-table-balance-history"
          countData={balanceHistory.totalRecord}
          callApiChange={onChangeCallApi}
          pageResults={param.pageResults}
        />
      </div>
    </>
  );
};

export default TableResults;
