import * as React from "react";
import { useState } from "react";
import { Typography, List, Progress } from "antd";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { getLocaleDatePicker } from "../../../utils/datePicker";
import { PickerLocale } from "antd/lib/date-picker/generatePicker";
import "./remunerationList.sass";
import { FormattedNumber } from "react-intl";
import ReactHtmlParser from "react-html-parser";

const { Paragraph } = Typography;

// Componente personalizado de List para listar el detalle de las remuneraciones
const RemunerationList = (param) => {
  const totalData: any[] = param.result.periods;
  const colors = param.colors;
  const { t } = useTranslation(["remuneration"]);

  // Local para el datePicker
  const local: PickerLocale = getLocaleDatePicker();

  return (
    <>
      <Paragraph className="texto-color-title-remuneration">
        {ReactHtmlParser(t("graphic.min-title", { 0: param.result.name }))}
      </Paragraph>
      <Paragraph
        className="text-list-hotel"
        ellipsis={{
          rows: 3,
          expandable: true,
        }}
      >
        {param.result.establishments.length > 0 &&
          param.result.establishments.map((s: any, i) => {
            return (
              <span key={i}>
                {s.name} <span className="color-bar-vertical"> | </span>
              </span>
            );
          })}
      </Paragraph>
      <List
        itemLayout="vertical"
        size="large"
        dataSource={totalData}
        renderItem={(item, index) => (
          <List.Item key={item.key} className="list-panel-content remuneration">
            <List.Item.Meta
              description={
                <>
                  <div className="col-12 row content-info-reservation mb-3">
                    <Paragraph className="col-12 text-left text-hotel color-text-remuneration">
                      {t("table.heads.dates")}
                    </Paragraph>
                    <Paragraph className="color-info-remuneration col-12 mb-2 text-left text-hotel">
                      {moment(item.entryDate).format("DD/MM") +
                        " - " +
                        moment(item.endDate).format("DD/MM")}
                    </Paragraph>
                    <Paragraph className="col-12 text-left">{item.name}</Paragraph>
                    <div id="list-remuneration" className="col-6">
                      <Progress
                        strokeColor={colors[index % colors.length]}
                        percent={item.amounts[0].percent}
                        showInfo={false}
                      />
                    </div>
                  </div>
                  <div className="col-12 box-amount-list-remuneration">
                    <Paragraph className="mb-0 color-text-remuneration">{t("table.amount")}</Paragraph>
                    <Paragraph className="color-amount">
                      <FormattedNumber
                        value={item.amounts[0].value}
                        style="currency"
                        currency={item.amounts[0].currencyId}
                      />
                    </Paragraph>
                  </div>
                </>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
};

export default RemunerationList;
