import { MockData } from "./endPointConfig";

export default class EndPointConfigOld {
    constructor(
        /// GET, POST, PATCH, DELETE
        public method : string,
        public controller : string,
        public actionName : string,
        public mockData : MockData = null,
        public usingActionName : boolean = true
    ) {}
}

// tslint:disable-next-line:max-classes-per-file
export class MockDataOld {
    constructor(
        public okData : any = null,
        public failData: any = null,
        public warningData: any = null
    ) {}
}