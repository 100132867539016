import React, { useContext, Suspense, useEffect, useState } from "react";
import "../profile.sass";
import "../../../i18n";
import { Skeleton } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Select } from "antd";
import { useDocumentTypes, useGetAccountType, usePositions, useUserInfo, useGetBankAccount } from "src/api/api";

import { showNotification } from "src/utils/notificationsGeneric";
import { RestApiClientContext } from "src/hooks/context/pageContext";
import BankAccountDataDto from "../modelResponse";
import { Alert, Checkbox } from "antd";
import ReactHtmlParser from "react-html-parser";
import ModalDeleteBankData from "src/components/reward/modalDeleteBankData/modalDeleteBankData";
import { UserContext } from "src/hooks/context/userInfo";
import countryToCurrency, { Currencies, Countries } from "country-to-currency";


interface ParamProps {
  setChangeData: (value: boolean) => void;
  disabledBtn: boolean;
  setDisabledBtn: (value: boolean) => void;

}

// Componente de la sección de datos bancarios
const BankAccountData = (param: ParamProps) => {
  const { t } = useTranslation(["profile", "newAgency"]);
  const documents = useDocumentTypes();
  const positions = usePositions();
  const userInfo = useUserInfo();
  const [form] = Form.useForm();

  const restApiClient = useContext(RestApiClientContext);
  //tenemos 5 tipos: 
  // IBAN -> IBAN, BIC
  // USD -> RoutingNumber, AccountNo
  // SWIFT mexican -> CLABE, BIC
  // INR account -> IFSC, AccountNo
  // AUD account -> BSB Code, AccountNo
  // SWIFT normal -> AccountNo , BIC
  const Country = (userInfo.location !== null && userInfo.location !== undefined) ? userInfo.location.isoCountryCode : userInfo.agency.location.isoCountryCode
  const [accountType, setAccountType] = useState(useGetAccountType(Country))// [IBAN, USD, SWIFT, IN, AU, MX]
  const [bankAccount, setBankAccount] = useState(useGetBankAccount(userInfo.id));
  const [isIban, setIsIban] = useState<boolean>(false);
  const [isUS, setIsUS] = useState<boolean>(false);
  const [isMX, setIsMX] = useState<boolean>(false);
  const [isIN, setIsIN] = useState<boolean>(false);
  const [isAU, setIsAU] = useState<boolean>(false);
  const [isSWIFT, setIsSWIFT] = useState<boolean>(false);
  const [checkBoxChecked, setCheckBoxChecked] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false); // submit button
  const [visibleDeleteModal, setVisibleDeleteModal] = useState<boolean>(false);
  const [allCountries, setAllCountries] = useState([]);
  const [countrySelected, setCountrySelected] = useState<string>(Country);
  const [allCurrencies, setAllCurrencies] = useState([]);
  const userContext = useContext(UserContext);


  const updateAccountType = (c: string) => {
    switch (c) {
      case "IBAN":
        setIsIban(true)
        setIsUS(false)
        setIsSWIFT(false)
        setIsIN(false)
        setIsAU(false)
        setIsMX(false)
        break;
      case "US":
        setIsIban(false)
        setIsUS(true)
        setIsSWIFT(false)
        setIsIN(false)
        setIsAU(false)
        setIsMX(false)
        break;
      case "SWIFT":
        setIsIban(false)
        setIsUS(false)
        setIsSWIFT(true)
        setIsIN(false)
        setIsAU(false)
        setIsMX(false)
        break;
      case "IN":
        setIsIban(false)
        setIsUS(false)
        setIsSWIFT(false)
        setIsIN(true)
        setIsAU(false)
        setIsMX(false)
        break;
      case "AU":
        setIsIban(false)
        setIsUS(false)
        setIsSWIFT(false)
        setIsIN(false)
        setIsAU(true)
        setIsMX(false)
        break;
      case "MX":
        setIsIban(false)
        setIsUS(false)
        setIsSWIFT(false)
        setIsIN(false)
        setIsAU(false)
        setIsMX(true)
        break;
    }
  }

  useEffect(() => {
    switch (accountType) {
      case "IBAN":
        setIsIban(true)
        setIsUS(false)
        setIsSWIFT(false)
        setIsIN(false)
        setIsAU(false)
        setIsMX(false)
        break;
      case "US":
        setIsIban(false)
        setIsUS(true)
        setIsSWIFT(false)
        setIsIN(false)
        setIsAU(false)
        setIsMX(false)
        break;
      case "SWIFT":
        setIsIban(false)
        setIsUS(false)
        setIsSWIFT(true)
        setIsIN(false)
        setIsAU(false)
        setIsMX(false)
        break;
      case "IN":
        setIsIban(true)
        setIsUS(false)
        setIsSWIFT(false)
        setIsIN(true)
        setIsAU(false)
        setIsMX(false)
        break;
      case "AU":
        setIsIban(false)
        setIsUS(false)
        setIsSWIFT(false)
        setIsIN(false)
        setIsAU(true)
        setIsMX(false)
        break;
      case "MX":
        setIsIban(false)
        setIsUS(false)
        setIsSWIFT(false)
        setIsIN(false)
        setIsAU(false)
        setIsMX(true)
        break;
    }
    restApiClient.getAllCountries(userContext.GetLanguageUser()).then(v => {
      setAllCountries(v);
    });

    restApiClient.getAllCurrencies().then(v => {
      setAllCurrencies(v);
    });
  }, [])


  
  



  useEffect(() => {
    if (userInfo) {
      form.setFieldsValue({
        "position": positions.find(position => position.name.toUpperCase() == userInfo.position.toUpperCase())?.id
      });
    }
  }, [positions])

  useEffect(() => {
  }, [positions, documents, userInfo])

  useEffect(() => {
    const fetchAccountType = async () => {
      try {
        const accountType = await restApiClient.getAccountType(countrySelected);
        setAccountType(accountType);
        updateAccountType(accountType)
      } catch (error) {
      }
    };
    fetchAccountType();
  }, [countrySelected]);


  const handleCheckBoxChange = (e) => {
    setCheckBoxChecked(e.target.checked);
  };

  const validateCheckBox = (_, value) => {
    return checkBoxChecked ? Promise.resolve() : Promise.reject(t("profile:bank-account.validation-check-box"));
  };

  const handleBankAccountData = (id) => {
    restApiClient.getBankAccount(id).then(res => {
      setBankAccount(res);
    });
  }

  const handleEliminar = () => {
    param.setChangeData(false);
    setVisibleDeleteModal(true);
  };

  const onChangeData = () => {
    param.setDisabledBtn(false);
    param.setChangeData(true);
  };

  const handleCountryChange = (value) => {
    setCountrySelected(value);
  };



  const validateCountry: () => boolean = () => {
    return allCurrencies.some(currency => {
      return currency.id == countryToCurrency[countrySelected];

    });
  };


  const onFinish = (values) => {

    const body = {
      agentId: userInfo.id,
      iban: values.IBAN || null,
      accountNo: values.AccountNumber || null,
      bic: values.BIC || null,
      routingNumber: values.RoutingNumber || null,
      ifsc: values.IFSC || null,
      clabe: values.Clabe || null,
      bsbCode: values.BSBCode || null,
      bankAccountCountry: countrySelected,
      accountHolderName: values.AccountHolderName,
      accountHolderLastName: values.AccountHolderLastName,
      address: values.address,
      city: values.city,
      currency: countryToCurrency[countrySelected]
    } as BankAccountDataDto;

    setLoadingUpdate(true);
    restApiClient.modifyBankData(body).then(response => { // no debería poner dependiendo de si res.code == success o errorbankdata?

      if (response.code == "Success") { // no necesito el data porque el res ya tiene el data
        handleBankAccountData(userInfo.id)
        showNotification("success", t("profile:bank-account.success-saving"), t("profile:bank-account.description-success-saving"), "topRight");
        form.resetFields();
      } else if (response.code == "BankDataNotEnoughData") { // error por parte del usuario
        showNotification("error", t("profile:bank-account.error"), t("profile:bank-account.description-bad-bank-data"), "topRight");
      } else if(response.code=="UnsupportedCounterparty") { // cuenta no soportada
        showNotification("error", t("profile:bank-account.error"), t("profile:bank-account.description-unsopportedCounterparty"), "topRight");
      }else{ // error en el proveedor
        showNotification("error", t("profile:bank-account.error"), t("profile:bank-account.description-provider-error"), "topRight");
      }
    }).finally(() => {
      setLoadingUpdate(false);
      param.setChangeData(false);
      param.setDisabledBtn(true);
    });
  };


  return (
    <div className="row">
      <div className="col-lg-2 p-0"></div>
      <div className="col-lg-8 col-12 p-0">
        <div className="col-12 mb-3 text-left" style={{ fontSize: '15px' }}>
          {t("profile:bank-account.msg-introduce-data")}
        </div>

        {bankAccount != null && bankAccount != "" ? (
          <div className="alert-message row" style={{ marginBottom: "20px" }}>
            <Alert
              message={(
                <div style={{ marginLeft: "10px", marginTop: "15px" }}>
                  {ReactHtmlParser(
                    t("bank-account.info-you-have-an-account", {
                      0: bankAccount,
                    })
                  )}
                  <div style={{ textAlign: "right", marginTop: "8px", marginBottom: "2px" }}>
                    <Button style={{ backgroundColor: "#F78C75" }} onClick={handleEliminar}>
                      {t("bank-account.btn-delete")}
                    </Button>
                  </div>
                </div>
              )}
              type="info"
              className="info-data-bank-account"
              showIcon
            />
          </div>
        ) : null}

        {(bankAccount == null ||bankAccount == "")  && (
          <Form
            name="form-bank-account-info"
            className="form-personal-info row"
            onFinish={onFinish}
            onChange={onChangeData}
            layout="vertical"
            form={form}
            id="form-bank-account-info"
          >
            <Form.Item
              className="col-6 form-item-profile mb-4"
              label={t("sections.bank-account-info.country")}
              name="Country"
              initialValue={countrySelected}
              required
             /* rules={[
                {
                  validator: (_, value) => {
                    if (!validateCountry()) {
                      return Promise.reject(t("validations.country"));
                    } else {
                      return Promise.resolve();
                    }

                  },
                },
              ]} */
            >
              <Select
                value={countrySelected}
                onChange={handleCountryChange}
                showSearch={true}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {allCountries.map(country => (
                  <Select.Option key={country.name} value={country.code}>
                    {country.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>



            <Form.Item
              className="col-12 form-item-profile mb-4"
              label={t("sections.bank-account-info.account-holder-name")}
              name="AccountHolderName"
              initialValue={userInfo.name}
              rules={[
                {
                  required: true,
                  message: t("validations.account-holder-name"),
                },
                {
                  whitespace: true,
                  message: t("validations.account-holder-name"),
                },
              ]}
            >
              <Input className="main-input" placeholder="" id="account-holder-name" />
            </Form.Item>

            <Form.Item
              className="col-12 form-item-profile mb-4"
              label={t("sections.bank-account-info.account-holder-last-name")}
              name="AccountHolderLastName"
              initialValue={userInfo.surname1 + " " + userInfo.surname2}
              rules={[
                {
                  required: true,
                  message: t("validations.account-holder-last-name"),
                },
                {
                  whitespace: true,
                  message: t("validations.account-holder-last-name"),
                },
              ]}
            >
              <Input className="main-input" placeholder="" id="account-holder-last-name" />
            </Form.Item>
            {isIban && (
              <Form.Item
                className="col-12 form-item-profile mb-4"
                label={t("sections.bank-account-info.iban")}
                name="IBAN"
                rules={[
                  {
                    required: true,
                    message: t("validations.iban"),
                  },
                  {
                    whitespace: true,
                    message: t("validations.iban"),
                  },
                ]}
              >
                <Input className="main-input" placeholder="" id="iban" />
              </Form.Item>
            )}


            {(isUS || isSWIFT || isIN || isAU) && (
              <Form.Item
                className="col-12 form-item-profile mb-4"
                label={t("sections.bank-account-info.account-number")}
                name="AccountNumber"
                rules={[
                  {
                    required: true,
                    message: t("validations.account-number"),
                  },
                  {
                    whitespace: true,
                    message: t("validations.account-number"),
                  },
                ]}
              >
                <Input className="main-input" placeholder="" id="account-number" />
              </Form.Item>
            )}

            {isMX && (
              <Form.Item
                className="col-12 form-item-profile mb-4"
                label={t("sections.bank-account-info.clabe")}
                name="Clabe"
                rules={[
                  {
                    required: true,
                    message: t("validations.clabe"),
                  },
                  {
                    whitespace: true,
                    message: t("validations.clabe"),
                  },
                ]}
              >
                <Input className="main-input" placeholder="" id="clabe" />
              </Form.Item>
            )}

            {(isIban || isSWIFT || isMX) && (
              <Form.Item
                className="col-12 form-item-profile mb-4"
                label={t("sections.bank-account-info.bic")}
                name="BIC"
                rules={[
                  {
                    required: true,
                    message: t("validations.bic"),
                  },
                  {
                    whitespace: true,
                    message: t("validations.bic"),
                  },
                ]}
              >
                <Input className="main-input" placeholder="" id="bic" />
              </Form.Item>
            )}


            {isUS && (
              <Form.Item
                className="col-12 form-item-profile mb-4"
                label={t("sections.bank-account-info.routing-number")}
                name="RoutingNumber"
                rules={[
                  {
                    required: true,
                    message: t("validations.routing-number"),
                  },
                  {
                    whitespace: true,
                    message: t("validations.routing-number"),
                  },
                ]}
              >
                <Input className="main-input" placeholder="" id="routing.number" />
              </Form.Item>
            )}


            {isIN && (
              <Form.Item
                className="col-12 form-item-profile mb-4"
                label={t("sections.bank-account-info.ifsc")}
                name="IFSC"
                rules={[
                  {
                    required: true,
                    message: t("validations.ifsc"),
                  },
                  {
                    whitespace: true,
                    message: t("validations.ifsc"),
                  },
                ]}
              >
                <Input className="main-input" placeholder="" id="ifsc" />
              </Form.Item>
            )}



            {isAU && (
              <Form.Item
                className="col-12 form-item-profile mb-4"
                label={t("sections.bank-account-info.bsb-code")}
                name="BSBCode"
                rules={[
                  {
                    required: true,
                    message: t("validations.bsb-code"),
                  },
                  {
                    whitespace: true,
                    message: t("validations.bsb-code"),
                  },
                ]}
              >
                <Input className="main-input" placeholder="" id="bsb-code" />
              </Form.Item>
            )}

            <Form.Item
              className="col-12 form-item-profile mb-4"
              label={t("sections.bank-account-info.address")}
              name="address"
              rules={[
                {
                  required: true,
                  message: t("validations.address"),
                },
                {
                  whitespace: true,
                  message: t("validations.address"),
                },
              ]}
            >
              <Input className="main-input" placeholder="" id="address" />
            </Form.Item>

            <Form.Item
              className="col-12 form-item-profile mb-4"
              label={t("sections.bank-account-info.city")}
              name="city"
              rules={[
                {
                  required: true,
                  message: t("validations.city"),
                },
                {
                  whitespace: true,
                  message: t("validations.city"),
                },
              ]}
            >
              <Input className="main-input" placeholder="" id="city" />
            </Form.Item>

            <Form.Item
              className="col-12 margin-element-check "
              name="legal"
              valuePropName="terms-conditions"
              rules={[
                {
                  validator: validateCheckBox,
                },
              ]}
            >
              <Checkbox
                id="check-policy"
                className="main-input box-check-reward"
                onChange={handleCheckBoxChange}
              >
                <div className="align-text-check-terms-conditions">

                  {ReactHtmlParser(
                    t("profile:bank-account.check-box", { 0: t("urls:private.terms-conditions-revolut") })
                  )}


                </div>
              </Checkbox>
            </Form.Item>


            <Form.Item className="col-12 form-item mt-3">
              <div className="row f-right">
                <Button
                  id="btn-save-bank-data"
                  className="primary-button"
                  type="primary"
                  htmlType="submit"
                  disabled={param.disabledBtn || loadingUpdate}
                >
                  {loadingUpdate ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><LoadingOutlined style={{ marginRight: "3px" }} /> {t("btn.save-changes")}</div> : <>{t("btn.save-changes")}</>}
                </Button>
              </div>
            </Form.Item>

          </Form>
        )}
      </div>
      <div className="col-lg-2 p-0"></div>
      {visibleDeleteModal && (
        <ModalDeleteBankData
          setBankAccount={setBankAccount}
          bankAccount={bankAccount}
          agentId={userInfo.id}
          setVisibleDeleteModal = {setVisibleDeleteModal}>
        </ModalDeleteBankData>
      )}
    </div>
  );
};

const BankAccountDataWrapper = (param) => (
  <Suspense fallback={<Skeleton />}>
    <BankAccountData {...param} />
  </Suspense>
);

export default BankAccountDataWrapper;
