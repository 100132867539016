import * as React from "react";
import { useContext } from "react";
import "./verify-mail.sass";
import { Typography, Button } from "antd";
import { Link } from "react-router-dom";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { URL_IMAGE } from "../../../constants/constants";
import { linkActiveContext } from "../../../hooks/context/linkActive";
import { showNotification } from "../../../utils/notificationsGeneric";
import { useAddNewAgent } from "src/api/api";

const { Paragraph } = Typography;

// Componente que muestra un panel informativo tras el intento de registro del agente
const VerifyMail = (param) => {

  const { t } = useTranslation("verifyMail");
  const linkActive = useContext(linkActiveContext);
  const addAgent = useAddNewAgent();

  const sendData = async () => {
    const res = await addAgent(param.signUpAgentAllData);
    if (res.data) {
      showNotification("success", res.status, res.message, "topRight");
    } else {
      showNotification("error", res.status, res.message, "topRight");
    }
  };

  return (
    <div className="panel-verify">
      <img alt="Sin resultados" src={URL_IMAGE + "register/icon-step-3.svg"} className="width-icon-send" />
      <Paragraph>
        <strong>{t("text-verify")}</strong>
      </Paragraph>
      <Paragraph>{t("detail-verify")}</Paragraph>
      <Link id="link-home" to={t("urls:public.home")} onClick={() => linkActive.updateValueUrl(t("urls:public.home"))}>
        <Button id="btn-understood" className="default-button col-3" size="large">
          {t("button-understood")}
        </Button>
      </Link>
      <Paragraph className="light-text-not-received margin-block-link">{t("not-received")}</Paragraph>
      <Paragraph className="light-text-not-received">
        <Link id="link-resend-mail" className="primary-links" to="#" onClick={sendData}>
          {t("resend-mail")}
        </Link>
      </Paragraph>
    </div>
  );
};

export default VerifyMail;
