import React, { useState, useEffect, useContext } from 'react';
import './index.sass';
import '../../../i18n';
import HeaderPublic from './header/header';
import Footer from '../../footer/footer';
import RoutesContent from '../../../routes/public/routesContent';
import FormNewPasswordContent from '../../modalsLogin/newPassword/newPasswordContent';
import {linkActiveContext} from '../../../hooks/context/linkActive';
import PopUpCookies from '../../legalTexts/cookiesPolicy/popUpCookies/popUpCookies';
import { useCookies } from 'react-cookie';

// Componente Base de la parte publica de nuestra aplicación
const BasePublic = () => {

  const [active, setActive] = useState(window.location.pathname);
  const [cookies,] = useCookies(['cookies']);

  return (
    <>
      <linkActiveContext.Provider 
        value={
          {
            active, 
            updateValueUrl: (url) => {
              setActive(url)
            }
          }
        }
      >
        <div className="main-content">
          <HeaderPublic 
            active={ active }
            setActive={ setActive }
          />
          <RoutesContent />  
          <FormNewPasswordContent />       
        </div>

        { typeof cookies.cookies === 'undefined' && <PopUpCookies /> }

        <Footer 
          zone={ "public" } 
          active={ active } 
          setActive={ setActive }
        />

      </linkActiveContext.Provider>
    </>
  );
}

export default BasePublic;  
