import React, { useContext, Suspense, useEffect } from "react";
import "../profile.sass";
import { Skeleton } from "antd";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { Alert, Typography } from "antd";
import ReactHtmlParser from "react-html-parser";
import { UserContext } from "../../../hooks/context/userInfo";
import { useAgencyData } from "src/api/api";

const { Paragraph, Text } = Typography;

// Componente de la sección de Datos de la agencia
const DataAgency = (param) => {
  const { t } = useTranslation(["profile"]);

  const userContext = useContext(UserContext);

  const agencyData = useAgencyData()

  return (
    <div className="row">
      <div className="col-lg-2 p-0"></div>
      <div className="col-lg-8 col-12 p-0">
        <div className="alert-message row">
          <Alert
            message={ReactHtmlParser(
              t("sections.data-agency.message-info", {
                0: agencyData?.email,
              })
            )}
            type="info"
            className="info-data-agency"
            showIcon
          />
          <div className="col-12 col-sm-6 col-lg-4 mb-4 p-0">
            <Paragraph className="label-data-agency mb-1">
              {t("sections.data-agency.trade-name")}
            </Paragraph>
            <Text>{agencyData?.commercialName} ({agencyData?.brandName})</Text>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 mb-4 p-0">
            <Paragraph className="label-data-agency mb-1">
              {t("sections.data-agency.contact-name")}
            </Paragraph>
            <Text>{agencyData?.contact.name}</Text>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 mb-4 p-0">
            <Paragraph className="label-data-agency mb-1">
              {t("sections.data-agency.tax-id")}
            </Paragraph>
            <Text>{agencyData?.taxIdentificationCode}</Text>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 mb-4 p-0">
            <Paragraph className="label-data-agency mb-1">
              {t("sections.data-agency.phone")}
            </Paragraph>
            <Text>{agencyData?.contact.telephone}</Text>
          </div>
          <div className="col-12 col-sm-6 col-lg-4 mb-4 p-0">
            <Paragraph className="label-data-agency mb-1">
              {t("sections.data-agency.email")}
            </Paragraph>
            <Text>{agencyData?.contact.email}</Text>
          </div>
          <div className="col-12 p-0">
            <Paragraph className="label-data-agency mb-1">
              {t("sections.data-agency.address")}
            </Paragraph>
            <Text>{agencyData?.location.formattedAddress}</Text>
          </div>
        </div>
      </div>
      <div className="col-lg-2 p-0"></div>
    </div>
  );
};

const DataAgencyWrapper = (param) => (
    <Suspense fallback={<Skeleton />}>
      <DataAgency userInfo={param.userInfo}/>
    </Suspense>
  );
  

export default DataAgencyWrapper;
