import * as React from 'react';
import { useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';

// Componente modal condiciones legales de los cheques
const ModalLegal = (param) => {

    // Idioma para los textos
    const { t } = useTranslation(['reward']);
    const [visibleP, setVisibleP] = useState(true);
    
    const handleCancel = () => {
        param.setVisible(true);
        param.setOpenModalLegalB(false);
        setVisibleP(false);
    }
    
    return (
        <Modal
            title={ t("reward:modal-legal.title") }
            visible={ visibleP }
            onCancel={ handleCancel } 
            footer={ null } 
            bodyStyle ={
                { 
                    maxHeight: 300, 
                    overflow: 'auto' 
                }
            }
            key="1"
        >   
            <div className="col-12">
                { ReactHtmlParser(param.legaBText) }
            </div>
        </Modal>
    );
}

export default ModalLegal;
