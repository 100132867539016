import React, { useContext, useEffect, useState } from "react";
import './menuPop.sass';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { LoginContext, UserContext } from '../../../../hooks/context/userInfo';
import { faUser, faEdit, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Dropdown, Button, Modal, Typography, Row, Col } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { linkActiveContext } from '../../../../hooks/context/linkActive';
import { RestApiClientContext } from "../../../../hooks/context/pageContext";
import { showNotification } from "../../../../utils/notificationsGeneric";
import { LangContext, LangUrlContext } from "../../../../hooks/context/lang";
// import { DURATION_COOKIE } from "../../../../constants/constants";

const { Paragraph } = Typography;

// Component menu desplegable al pulsar en  la parte privada sobre el icono faUser
const MenuPop = (param) => {

    // Libreria i18n para traducir
    const { t } = useTranslation(['urls', 'linksNames', 'modalClosedSession']);

    // Cookie token usuario removed
    const [, , removeCookie] = useCookies(['userToken']);
    const [userLogin, setUserToken] = useCookies(["userToken"]);

    const userContext = useContext(UserContext);
    const loginContext = useContext(LoginContext);
    const userData = useContext(UserContext);
    const languageUrl = useContext(LangUrlContext);
    const restApiClient = useContext(RestApiClientContext);
    const linkActive = useContext(linkActiveContext);

    const [visible, setVisible] = useState(false);
    // const [name, setName] = useState<string>("");

    // History para cuando cerremos sesion monte la url base de la home
    const history = useHistory();

    // Cierre sesion usuario
    const handleClosedSesion = () => {
        setVisible(true);
    }

    // Manejador si el usuario le da a confirmar cerrar sesion en la modal
    const closedSession = () => {
        restApiClient.logoutAgent()
            .then((isLogout) => {
                if (isLogout) {
                    removeCookie("userToken", { path: "/" });
                    loginContext.updateValue(false);
                    history.push("/" + userData.GetLanguageURL() + "/");
                    window.location.reload();
                } else {
                    showNotification(
                        "error",
                        t("translation:error.title"),
                        t("translation:error.description"),
                        "topRight"
                    );
                }
            })
    }

    // Manejador si el usuario le da a cancelar en la modal
    const handleCancel = () => {
        setVisible(false);
    };

    const menu = (
        <Menu className="padding-element-menu-profile">
            <Menu.Item key="1">
                <Link
                    id="link-profile"
                    key="1"
                    to={ t("urls:private.profile") }
                    className="border-menu-pop"
                    onClick={ () => linkActive.updateValueUrl(t("urls:private.profile")) }
                >
                    <div className="box-link-menu">
                        <div className="vertical-center-draw">
                            <FontAwesomeIcon
                                className= "icons-color-menu icons-color-marging"
                                icon={ faEdit }
                            />
                            { t("linksNames:profile") }
                        </div>
                    </div>
                </Link>
            </Menu.Item>
            <Menu.Item key="2">
                <div id="div-logout" className="box-link-menu" onClick={ handleClosedSesion }>
                    <div className="vertical-center-draw secondary-links-svg">
                        <FontAwesomeIcon
                            className= "icons-color-menu icons-color-marging"
                            icon={ faSignOutAlt }
                        />
                        { t("linksNames:logout") }
                    </div>
                </div>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <div>
                <Dropdown overlay={ menu }>
                    <Button
                        id="btn-user-header"
                        className={ "p-0 secondary-links-svg" + (window.location.pathname === t("urls:private.profile") ? " active": "") }
                    >
                        <FontAwesomeIcon
                            className={
                                (param.type === "desktop" ? "icons-color-menu icons-color-marging" : "icons-color-menu")
                            }
                            icon={ faUser }
                        />
                        { param.type === "desktop" ? userData.GetNameUser() : "" }
                        <DownOutlined />
                    </Button>
                </Dropdown>
            </div>
            <div>
                <Modal
                    title={ t("modalClosedSession:title") }
                    visible={ visible }
                    footer={ null }
                    onOk={ closedSession }
                    okText={ t("modalClosedSession:button-closed-session") }
                    onCancel={ handleCancel }
                    cancelText={ t("modalClosedSession:button-cancel") }
                    className="modal-closed-session"
                >
                    <Paragraph>
                        { t("modalClosedSession:question") }
                    </Paragraph>
                    <Row>
                        <Col
                            span={ 24 }
                            className="align-text-buttons"
                        >
                            <Button
                                id="btn-logout"
                                type="primary"
                                onClick={ closedSession }
                                className="primary-button"
                            >
                                { t("modalClosedSession:button-closed-session") }
                            </Button>
                            <Button
                                id="btn-cancel-logout"
                                className="default-button margin-right-button"
                                onClick={ handleCancel }
                            >
                                { t("modalClosedSession:button-cancel") }
                            </Button>
                        </Col>
                    </Row>
                </Modal>
            </div>
        </>
    );
}

export default MenuPop
