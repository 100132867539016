// Modelo de los datos de una agencia
export default class Agency {
    constructor(
        public key: string = '',
        public name: string = '',
        public address: string = '',
        public phone: string = '',
        public taxid: string = '',
        public contactName: string = '',
        public companyName: string = '',
        public email: string = '',
        public emailContact: string = '',
    ) {

    }
}
