import i18n from 'i18next';
import backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';
import { DEFAULT_LANGUAGE, LANGUAGE_EN_US, LANGUAGE_DE_DE, LANGUAGE_PT_PT, LANGUAGE_FR_FR } from './constants/constants';


function SelectLanguaje() {
  let result : string = DEFAULT_LANGUAGE;

  var languaje = window.location.pathname.replace("/","").split("/")[0];

  if (languaje && languaje.length > 0){
    switch(languaje){
      case "es":
        result = DEFAULT_LANGUAGE;
        break;
      case "de":
        result = LANGUAGE_DE_DE;
        break;
      case "en":
        result = LANGUAGE_EN_US;
        break;
      case "pt":
        result = LANGUAGE_PT_PT;
        break;
      case "fr":
        result = LANGUAGE_FR_FR;
        break;
    }
  }

  return result;
}


i18n
  .use(backend)
  .use(initReactI18next)
  .init({
    lng: SelectLanguaje(),
    fallbackLng: SelectLanguaje(),
    whitelist: [DEFAULT_LANGUAGE, LANGUAGE_EN_US, LANGUAGE_DE_DE, LANGUAGE_PT_PT, LANGUAGE_FR_FR],
    backend: {
      loadPath: `${window.location.origin}/locales/{{lng}}/{{ns}}.json`
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
