import React, { useContext, useEffect, useState } from "react";
import "./app.sass";
// import '../../styles/main.sass';
import BasePublic from "../baseLayouts/public/index";
import BasePrivate from "../baseLayouts/private/index";
import { ConfigContext } from "../../hooks/context/config";
import { LoginContext, UserContext } from "../../hooks/context/userInfo";
import {
  LangContext,
  AllLanguages,
  LangUrlContext,
} from "../../hooks/context/lang";
import { BrowserRouter as Router } from "react-router-dom";
import LocalizedRouter from "../../routes/public/routes";
import { AppLanguage } from "../../routes/appLanguages";
import { appStrings } from "../../routes/localizations/index";
import LocalizedRouterPrivate from "../../routes/private/routes";
import { CookiesProvider } from "react-cookie";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { DURATION_COOKIE } from "../../constants/constants";
import { RestApiClientContext } from "../../hooks/context/pageContext";
import { RestApiClient } from "../../api/restClient";
import { User } from "../../models/user/userModel";
import { useConfig } from "../../api/config";
import { initLanguages, allLanguages } from "../../utils/languages";
import { useGetUserProfile } from "src/api/api";

// Componente principal de nuestra aplicación y que renderizamos en el main.tsx
export const App = (config) => {
  // Libreria para el manejador de idioma de la app
  const { t, i18n } = useTranslation();
  // Contexto con el idioma de usuario y en la que se visualizara la app
  const language = useContext(LangContext);
  const languageUrl = useContext(LangUrlContext);
  const restApiClient = useContext(RestApiClientContext);
  const allLang = useContext(AllLanguages);
  const userContext = useContext(UserContext);
  const configuration = useContext(ConfigContext);

  const initLangURL: string = config.allLanguages.filter(
    (f) => f.id === initLang
  ).shortId;
  let initLang: string = "";

  if (language.GetLangContext() === "") {
    initLang = config.userLang;
  } else {
    initLang = language.GetLangContext();
  }
  allLang.UpdateAllLangContext(config.allLanguages);
  language.UpdateLangContext(initLang);
  languageUrl.UpdateUrlLangContext(initLangURL);
  configuration.UpdateConfigContext(config.config);

  const [userLogin, setUserToken] = useCookies(["userToken"]);

  // Control adicional si esta o no logado el usuario
  const loginUserDone = false;

  const [login, setLogin] = useState(loginUserDone);
  const [
    forceReloadAppComponent,
    setForceReloadAppComponent,
  ] = useState<boolean>(false);

  // Carga inicial con el color destacado del cliente
  const style =
    configuration &&
    `
    :root {
      --primary-color: ${configuration.colour};
    }
  `;

  const getUserProfile = useGetUserProfile();
  // Actualizar la libreria i18n para el idioma de la web
  useEffect(() => {
    i18n.changeLanguage(initLang);

    // Comprobamos si esta informada la cookie y el contexto usuario
    if (
      typeof userLogin.userToken !== "undefined" &&
      userLogin.userToken !== "null" &&
      typeof userContext.GetNameUser() === "undefined"
    ) {
      getUserProfile()
        .then(({ data: info }) => {
          const date = Date.now() + DURATION_COOKIE;

          setUserToken("userToken", userLogin.userToken, {
            path: "/",
            expires: new Date(date),
          });

          sessionStorage.setItem("token", userLogin.userToken);
          const userContextInfo = {
            id: info.id,
            idAgentType: info.idAgentType,
            userName: info.userName,
            urlLanguage: info.language.shortId,
            language: info.language.id,
            currency: info.idCurrency,
            imageName: info.imageName,
            email: info.email,
            uaid: info.uaid,
            countryCode: info.idCountry
          } as User;

          userContext.UpdateUserContext(userContextInfo);

          setForceReloadAppComponent(!forceReloadAppComponent);
        })
        .catch((e) => {
          sessionStorage.removeItem("token");
          document.cookie =
            "userToken=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
          window.location.reload();
        });
    }
  }, []);

  return (
    // En el fallback podemos añadir un spinner (<Spinner/>)
    <>
      <LoginContext.Provider
        value={{
          login,
          updateValue: (key) => {
            setLogin(key);
          },
        }}
      >
        <CookiesProvider>
          {login ||
            (typeof userLogin.userToken !== "undefined" &&
              userLogin.userToken !== "null") ? (
            <RestApiClientContext.Provider
              value={new RestApiClient(userLogin.userToken, t)}
            >
              <LocalizedRouterPrivate
                RouterComponent={Router}
                languages={AppLanguage}
                appStrings={appStrings}
                defaultLanguage={initLangURL}
              >
                <style>{style}</style>
                <BasePrivate />
              </LocalizedRouterPrivate>
            </RestApiClientContext.Provider>
          ) : (
            <LocalizedRouter
              RouterComponent={Router}
              languages={AppLanguage}
              appStrings={appStrings}
              defaultLanguage={initLangURL}
            >
              <style>{style}</style>
              <BasePublic />
            </LocalizedRouter>
          )}
        </CookiesProvider>
      </LoginContext.Provider>
    </>
  );
};

const AppWithConfig = () => {
  // Se obtiene la configuracion del cliente
  const config: any = {
    config: useConfig(),
    userLang: initLanguages(),
    allLanguages: allLanguages(),
  };
  return <App {...config} />;
};

export default AppWithConfig;
