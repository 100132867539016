export enum UserType {
  AgentOfAgency = 1,
  AgentIndependent = 2
}

// Modelo del usuario logado
export class User {
  public idAgentType: number;
  public id: string;
  public userName: string;
  public urlLanguage: string;
  public language: string;
  public countryCode: string;
  public imageName: string;
  public token: string;
  public pendingAmount?: number;
  public totalAvailable: number;
  public currency: string;
  public idAgency: number;
  public email: string;
  public uaid: string;

  // tslint:disable-next-line:no-empty
  constructor() {}

  UpdateUserContext = (val: User) => {
    this.idAgentType = val.idAgentType;
    this.userName = val.userName;
    this.urlLanguage = val.urlLanguage;
    this.language = val.language;
    this.countryCode = val.countryCode;
    this.imageName = val.imageName;
    this.token = val.token;
    this.pendingAmount = val.pendingAmount;
    this.totalAvailable = val.totalAvailable;
    this.currency = val.currency;
    this.idAgency = val.idAgency;
    this.email = val.email;
    this.uaid = val.uaid;
    this.id = val.id;
    this.countryCode = val.countryCode;
  };

  GetUAID = () : string => {
    return this.uaid;
  }

  GetAgentType = () : number => {
    return this.idAgentType;
  }

  GetNameUser = (): string => {
      return this.userName;
  }

  GetEmail = (): string => {
    return this.email;
}

  GetLanguageURL = (): string => {
      return this.urlLanguage;
  }

  GetLanguageUser = (): string => {
    return this.language;
  }

  GetCountryCode = (): string => {
    return this.countryCode;
  }

  GetUserToken = (): string => {
    return this.token;
  }

  GetPendingAmount = (): number => {
    return this.pendingAmount;
  }

  GetTotalAvailable = (): number => {
    return this.totalAvailable;
  }

  GetCurrency = (): string => {
    return this.currency;
  }

  GetIdAgent = (): string => {
    return this.id;
  }

  GetIdAgency = (): number => {
    return this.idAgency
  }

  GetImageName = (): string => {
    return this.imageName
  }
}
