// Modelo de los datos de un agente
export default class Agent {
    constructor (
        public name : string = '',
        public surname : string = '',
        public secondSurname : string = '',
        public position: string = '',
        public mail: string = '',
        public phone: string = '',
        public birthDate: any = null,
        public language: string = '',
        public document: string = '',
        public documentNumber: string = '',
        public userName: string = '',
        public password: string = '',
        public confirmPassword: string = '',
        public commerceInfo: boolean = false,
        public keyAgency : string = '',
) 
    {

    }
}
