import * as React from "react";
import "./choose-agent-type.sass";
import { Typography, Button } from "antd";
import { URL_IMAGE } from '../../../constants/constants';
import "../../../i18n";
import { useTranslation } from "react-i18next";

const { Paragraph } = Typography;

// Componente que muestra una selección de tipo de agente
const ChooseAgentType = (props) => {

  const { t } = useTranslation("chooseAgentType");
  const handleType = type => {
    props.setAgentType(type)
    props.next()
  }

  return (
    <div className="panel-choose">
      <h2>
        <span>{t("text-choose-1")}</span>
        <br />
        <span>{t("text-choose-2")}</span>
      </h2>
      <div className="container">
        <div className="row gx-3">
          {[1, 2].map(type => (
            <div key={type} className="col-lg-6 px-4">
              <Button
                className={`card-agent-type ${props.agentType === type ? 'selected' : ''}`}
                onClick={() => handleType(type)}
              >
                <img alt="" src={ URL_IMAGE + `register/icon-choose-${type}.svg` } />
                <h3>
                  {t(`text-choose-type-${type}`)}
                </h3>
              </Button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ChooseAgentType;
