import React, { Suspense } from 'react';
import { useState } from 'react';
import { Table, Typography, Button, Input, Space, Skeleton } from 'antd';
import './searchReservations.sass'
import '../../../i18n';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import FormEditReservation from './formEditReservation/formEditReservation'
import { StateReservations } from "../../../constants/enums";
import { setTag } from '../../../utils/colorTags';
import CustomPagination from '../../utils/pagination/pagination';
import Pagination from '../../../models/pagination/pagination';
import Order from '../../../models/order/order';
import { SearchOutlined } from '@ant-design/icons';
import { CURRENT_PAGE, PAGE_SIZE } from '../../../constants/constants';
import { FormattedNumber } from 'react-intl';
import { getLocaleDatePicker } from '../../../utils/datePicker';
import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import moment from 'moment';
import { useHotels } from 'src/api/api'

const { Paragraph } = Typography;

// Componente tabla para las regularizaciones
const TableResults = (param) => {

    const { t } = useTranslation(['regularizations']);

    // Estado que almacena el valor de si la modal de editar reserva regularizada está visible o no
    const [openEditReservation, setOpenEditReservation] = useState(false);
    // Estado que controla que el panelito de búsqueda de la cabecera de la tabla aparezca o no
    const [visibleFilter, setVisibleFilter] = useState(false);
    // Array para guardar las todas las columns
    let colsArray: any[] = [];
    // Para el formato de las fechas
    const local:PickerLocale = getLocaleDatePicker();
    // Estado de control del loading para el skeleton

    // Recoge los hoteles de la API
    const hotelsList = useHotels()
    colsArray = [
        {
            title: t('regularizations:table.booking-reference'),
            dataIndex: 'bookingReference',
        },
        {
            title: t('regularizations:table.hotel-name'),
            dataIndex: 'establishmentName',
            width: 200,
            ellipsis: true,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        placeholder={ `Search ${t('regularizations:table.hotel-name')}` }
                        value={ selectedKeys[0] }
                        onChange={ e => setSelectedKeys(e.target.value ? [e.target.value] : []) }
                        onPressEnter={ () => onChangeCallApi('', selectedKeys[0], '', '') }
                        className="input-search-hotel-name"
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={ () => onChangeCallApi('', selectedKeys[0], '', '', confirm) }
                            icon={ <SearchOutlined /> }
                            size="small"
                            className="btn-search-hotel-name"
                        >
                            { t('table.search') }
                        </Button>
                        <Button
                            onClick={
                                () => {
                                    reset(clearFilters)
                                }
                            }
                            size="small"
                            className="btn-reset-hotel-name"
                        >
                            { t('table.reset') }
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: filtered =>
                <SearchOutlined
                    style={ { color: filtered ? '#1890ff' : undefined } }
                    onClick={ () => setVisibleFilter(!visibleFilter) }
                />,
        },
        {
            title: t('regularizations:table.check-in-date'),
            dataIndex: 'checkIn',
            sorter: {
                compare: (a, b) => a.checkInDate - b.checkInDate,
                multiple: 4,
            },
            render: (text, row, index) => (
                <>
                    {
                        moment(text).format(local.dateFormat)
                    }
                </>
            ),
        },
        {
            title: t('regularizations:table.check-out-date'),
            dataIndex: 'checkOut',
            sorter: {
                compare: (a, b) => a.checkOutDate - b.checkOutDate,
                multiple: 3,
            },
            render: (text, row, index) =>
                (
                    <>
                        {
                            moment(text).format(local.dateFormat)
                        }
                    </>
                ),
        },
        {
            title: t('regularizations:table.name-surname'),
            dataIndex: 'nameSurnameClient',
            width: 200,
            ellipsis: true,
        },
        {
            title: t('regularizations:table.amount'),
            dataIndex: 'reward',
            fixed: 'right',
            width: 118,
            sorter: {
                compare: (a, b) => a.amount - b.amount,
                multiple: 2,
            },
            render: (text, row, index) => (
                <>
                    {
                        <FormattedNumber
                            useGrouping={ true }
                            value={ text }
                            style="currency"
                            currency={ row.currency }
                        />
                    }
                </>
            ),
        },
        {
            title: t('regularizations:table.state'),
            dataIndex: 'status',
            fixed: 'right',
            width: 140,
            sorter: {
                compare: (a, b) => a.state - b.state,
                multiple: 1,
            },
            render: (state, obj) => {

                return setTag(state.name, state.id, obj.statusReason ? t(`regularizations:status-reason.${obj.statusReason}`) : '');
            }
        },
    ];

    if (hotelsList.length === 1) {
        colsArray.splice(1, 1);
    }

    // Función que controla el evento del reseteo del formulario del panelito de búsqueda de la cabecera de la tabla
    const reset = (clearFilters) => {
        clearFilters();
        onChangeCallApi('', '', '', '');
    }

    // Función para el control del expanded de la tabla
    const expandedRowRender = (record) => {
        return (
            <>
                <div className="row table-container">
                    <div className="col-6 table-cell-container cell-edit">
                        <Paragraph className="text-expanded">
                            { t('regularizations:table.text-edit') }
                        </Paragraph>
                    </div>
                    <div className="col-6 table-cell-container cell-edit">
                        <Button
                            id="btn-edit-reservation"
                            onClick={ () => setOpenEditReservation(true) }
                            icon={
                                <FontAwesomeIcon
                                    className="edit-icon-input"
                                    icon={ faPen }
                                />
                            }
                        >
                            { t('regularizations:table.btn-edit') }
                        </Button>
                    </div>
                </div>

                {
                    openEditReservation &&
                        <FormEditReservation
                            openEditReservation={ openEditReservation }
                            setOpenEditReservation={ setOpenEditReservation }
                            record={ record }
                        />
                }
            </>
        );
    }

    // Función que se dispara en el evento de cambiar el elemento de paginación, de ordenación o de búsqueda de la tabla
    const onChangeCallApi = (pagination, filters, sorter, extra, conf = null) => {
        // Almacenamos el valor de si el panelito de búsqueda se muestra o no
        setVisibleFilter(!visibleFilter);

        if (typeof filters !== "undefined" && // Se ha pulsado el filtro
            filters !== '' && // Valor del input del filtro
            filters.hotelName !== null) { // Si viene hotelName null, es que se ha pulsado la ordenación

            // Ejecutar el confirm de la búsqueda de la cabecera internamente
            if (conf != null) {
                conf();
            }

            // Almacenamos el valor a buscar
            param.setSearchHeaderResults(filters);

            // Reiniciamos la paginación a los valores iniciales
            const pag = new Pagination(
                CURRENT_PAGE,
                PAGE_SIZE
            );

            // Usuario pulsa página
            param.setPageResults(pag);

        // Ordenación
        } else if (typeof sorter !== "undefined") {

            const ord = [];

            for (let i of sorter) {
                ord.push(new Order(
                    i.field,
                    i.order
                ));
            }

            // Usuario pulsa cabecera de la tabla
            param.setOrderResults(ord);

        // Paginación
        } else {
            const pag = new Pagination(
                pagination.current,
                pagination.pageSize
            );

            // Usuario pulsa página
            param.setPageResults(pag);
        }
    }

    return (
        <>
            <Table
                data-testid="table-reservations"
                columns={ colsArray }
                dataSource={ param.rows }
                pagination={ false }
                scroll={ { x: 1190 } }
                tableLayout="fixed"
                expandable={
                    {
                        expandedRowRender,
                        rowExpandable: record => record.idState == StateReservations.Pending
                    }
                }
                onChange={ onChangeCallApi }
            />
            <div className="pagination-container mt-4">
                <CustomPagination
                    data-testid="pagination-table-reservations"
                    countData={ param.totalRows }
                    callApiChange={ onChangeCallApi }
                    pageResults={ param.pageResults }
                />
            </div>
        </>
    );
}

const TableResultsWrapper = (params) =>
    <Suspense fallback={<Skeleton active />}>
        <TableResults {...params} />
    </Suspense>


export default TableResultsWrapper;
