import * as React from "react";
import { Table, Progress, Typography, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { FormattedNumber } from "react-intl";
import { getLocaleDatePicker } from "../../../utils/datePicker";
import { PickerLocale } from "antd/lib/date-picker/generatePicker";
import moment from "moment";
import "./remunerationTable.sass";

const { Paragraph, Title } = Typography;

// Componente Table personalizado para mostrar en la tabla el detalle de las remuneraciones
const RemunerationTable = (param) => {
  // Idioma para los textos
  const { t } = useTranslation("remuneration");
  const colors = param.colors;
  // Local para el datePicker
  const local: PickerLocale = getLocaleDatePicker();

  // Pinta en la tootlip el texto entero que desborda
  const contentTooltip = (data: any) => {
    return (
      <div className="box-tooltip">
        <Paragraph className="text-name-tooltip">{data}</Paragraph>
      </div>
    );
  };

  const columns = [
    {
      key: 1,
      ellipsis: {
        showTitle: true,
      },
      title: t("table.heads.name"),
      dataIndex: "name",
      width: 150,
      render: (text, row, index) => {
        const period = param.result.periods[index];
        return (
          <>
            {period.name.length > 20 ? (
              <Tooltip id="tooltip-remuneration" title={() => contentTooltip(period.name)} key={period.name}>
                <div className="box-name-remuneration-table">
                  <Paragraph className="name-table-remuneration mb-0 text-list-hotel">{period.name}</Paragraph>
                </div>
              </Tooltip>
            ) : (
              <div className="box-name-remuneration-table">
                <Paragraph className="name-table-remuneration mb-0 text-list-hotel">{period.name}</Paragraph>
              </div>
            )}
          </>
        );
      },
    },
    {
      key: 2,
      ellipsis: true,
      title: "",
      dataIndex: "porcent",
      width: 200,
      render: (text, row, index) => {
        const colorIndex = index % colors.length;
        return (
          <div className="pr-5">
            <Progress
              percent={param.result.periods[index].amounts[0].percent}
              strokeColor={colors[colorIndex]}
              showInfo={false}
            />
          </div>
        );
      },
    },
    {
      key: 3,
      ellipsis: {
        showTitle: true,
      },
      title: t("table.heads.dates"),
      dataIndex: "dates",
      width: 190,
      render: (text, row, index) => {
        return (
          <Paragraph className="mb-0 color-info-remuneration">
            {moment(param.result.periods[index].startDate).format("DD/MM") +
              " - " +
              moment(param.result.periods[index].endDate).format("DD/MM")}
          </Paragraph>
        );
      },
    },
    {
      key: 3,
      ellipsis: {
        showTitle: true,
      },
      title: t("table.heads.min-nights"),
      dataIndex: "minNights",
      width: 190,
      render: (text, row, index) => {
        return (
          <Paragraph className="mb-0 color-info-remuneration">
            { t("regularizations:min-nights-resume-days", {0:row.minNightsAllowed}) }
          </Paragraph>
        );
      },
    },
    {
      key: 4,
      ellipsis: {
        showTitle: true,
      },
      title: t("table.heads.amount"),
      dataIndex: "amount",
      width: 100,
      render: (text, row, index) => {
        return (
          <FormattedNumber
            value={param.result.periods[index].amounts[0].value}
            style="currency"
            currency={param.result.periods[index].amounts[0].currencyId}
          />
        );
      },
    },
  ];

  return (
    <>
      <div className="table-remuneration row">
        <Title className="mt-4" level={4}>
          {t("table.title")}
        </Title>
        <div className="col-md-1"></div>
        <div className="col-12">
          <Table id="table-remuneration" columns={columns} dataSource={param.result.periods} pagination={false} />
        </div>
        <div className="col-md-1"></div>
      </div>
    </>
  );
};

export default RemunerationTable;
