import * as React from 'react';
import { useState, useContext } from 'react';
import { Form, Input, Button, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import * as AjaxAPI from '../../../api/ajaxApi';
import { showSuccess, showError, showWarning } from '../../../utils/modalsGenerics';
import './formResetPassword.sass';
import { useHistory } from "react-router-dom";
import { linkActiveContext } from '../../../hooks/context/linkActive';
import { UserContext } from '../../../hooks/context/userInfo';
import { LangContext, LangUrlContext } from "../../../hooks/context/lang";
import { User } from '../../../models/user/userModel';
import { useResetPassword } from 'src/api/api';

// Componente de formulario de reiniciar contraseña
const FormResetPassword = (params) => {

  // Contexto del usuario
  const userContext = useContext(UserContext);

  // Necesario para estructura del formulario
  const tailLayout = {
    wrapperCol: {
      span: 25,
    },
  };

  // Idioma para los textos
  const { t } = useTranslation(['resetPassword', 'contact', "url"]);
  const [sendForm, setSendForm] = useState(false);
  const history = useHistory();
  const linkActive = useContext(linkActiveContext);
  const language = useContext(LangContext);
  const languageUrl = useContext(LangUrlContext);
  const resetPassword = useResetPassword()
  const sendFormResetPassword = async (values) => {

    const userContextInfo = {
      userName: "",
      urlLanguage: languageUrl.GetURLLangContext(),
      language: language.GetLangContext()
    } as User;

    try {
      const res = await resetPassword(values.mail)

      setSendForm(true);
      showSuccess(res.title, res.message, t('form.buttons.accept'));
      linkActive.updateValueUrl(t("urls:public.home"));
      history.push(t("urls:public.home"));

    } catch (err) {
      params.setVisibleFormPassword(false);
      params.setmodalLogin(false);
    }
    //         } else {
    //             setSendForm(v.data.formResetPassword.send);

    //             switch(v.data.formResetPassword.state) {
    //                 case 1:
    //                 case 4:
    //                     showWarning( v.data.formResetPassword.title,v.data.formResetPassword.message, t('form.buttons.accept'));
    //                     break;
    //                 case 3:
    //                     showError( v.data.formResetPassword.title,v.data.formResetPassword.message, t('form.buttons.accept'));
    //                     break;
    //             }
    //         }

    //         params.setVisibleFormPassword(false);
    //         params.setmodalLogin(false);
    //     })
    //     // tslint:disable-next-line:no-empty
    //     .catch(e => {});
  }

  // Submit boton
  const onFinish = async (values) => {
    /// TODO : Quitar el true para que los datos no vengan mockeados.
    AjaxAPI.init(t, true);
    sendFormResetPassword(values);
  };

  // Boton cancelar
  const HandCancel = () => {
    params.setVisibleFormPassword(false);
    params.setmodalLogin(false);
  }

  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={onFinish}
    >
      <Form.Item
        label={t('form.email')}
        name="mail"
        rules={
          [
            {
              required: true,
              message: t('form.errors.email')
            },
            {
              type: "email",
              message: t('form.errors.invalid-mail')
            },
            {
              max: 50,
              message: t('form.errors.max-number')
            }
          ]
        }
      >
        <Input
          className="main-input"
          id="mail-reset"
        />
      </Form.Item>
      <Form.Item {...tailLayout}>
        <Row>
          <Col
            span={24}
            className="align-text-buttons"
          >
            <Button
              id="btn-send-reset-password"
              type="primary"
              htmlType="submit"
              className="col-4 col-sm-3 primary-button"
            >
              {t('form.buttons.send')}
            </Button>
            <Button
              id="btn-cancel-reset-password"
              onClick={HandCancel}
              className="col-4 col-sm-3 default-button margin-right-button"
            >
              {t('form.buttons.cancel')}
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
}

export default FormResetPassword;
