import * as React from 'react';
import '../../../../i18n';
import './header.sass';
import MobileMenu from './responsiveHeader/mobileMenu';
import DesktopMenu from './responsiveHeader/desktopMenu';
import AlternateComponent from '../../../utils/alternateComponent/alternateComponent';

// Componente main de la cabecera
const HeaderPublic = (params) => {
    return (
        <AlternateComponent
            desktopVersionLarge={
                <DesktopMenu
                    key="1"
                    active={ params.active }
                    setActive={ params.setActive }
                /> 
            }
            tabletVersion={ 
                <MobileMenu 
                    key="2" 
                    active={ params.active }
                    setActive={ params.setActive }
                /> 
            }
            mobileVersionLarge={
                <MobileMenu
                    key="2"
                    active={ params.active }
                    setActive={ params.setActive }
                /> 
            }
        />
    )
}

export default HeaderPublic;
