// #region Variables de entorno
export const API_URL_TEST: string = process.env.REACT_APP_API_URL!;
export const API_ENV: string = process.env.REACT_APP_CUSTOM_NODE_ENV;
export const URL_BASE: string =  process.env.REACT_APP_URL_BASE;
// #endregion

// #region Gestión de idioma
export const DEFAULT_LANGUAGE: string = "es-ES";
export const LANGUAGE_EN_US: string = "en-US";
export const LANGUAGE_DE_DE: string = "de-DE";
export const LANGUAGE_PT_PT: string = "pt-PT";
export const LANGUAGE_FR_FR: string = "fr-FR";
export const LANGUAGE_SUPPORT_SYSTEM: string[] = ["es", "en", "de", "pt", "fr"];
// #endregion

// #region cookies
export const LIST_COOKIES_DEFAULT: string[] = ["user","analytical"];
// //#endregion

// #region cookies
export const URL_COOKIES_DISCARD_ES: string = "politicas-cookies";
export const URL_COOKIES_DISCARD_US: string = "cookies-policy";
export const DURATION_COOKIE: number = 63072000000;// por 2 años
// #endregion

// #region Media queries
export const xs: number = 575; // Si está por debajo de xs => versión móvil pequeño
export const sm: number = 767; // Si está entre xs y sm => versión móvil grande
export const md: number = 991; // Si está entre sm y md => versión tablet
export const lg: number = 1199;// Si está entre md y lg => versión desktop pequeña. Superior a lg desktop grande
// #endregion

// #region Links
export const LINKS_MOBILE_PRIVATE: string[] = ["start", "regularization", "reward", "balance-history", "remuneration", "news", "contact" , "benefits", "faqs", "know-us"];
export const LINKS_LAYOUT_PRIVATE: string[] = ["start", "regularization", "reward", "balance-history", "remuneration", "news", "benefits", "faqs", "know-us"];
export const LINKS_MOBILE_PUBLIC: string[] = ["home", "benefits", "faqs", "contact"];
export const LINKS_DESKTOP_PUBLIC: string[] = ["home", "benefits", "faqs", "register", "contact"];
// #endregion

// #region Relative URL
export const URL_IMAGE: string = `${window.location.origin}/images/`;
// #endregion

// #region ValueKey de idiomas
export const LANGS = [
    { url: "es", lang: "es-ES" },
    { url: "en", lang: "en-US" },
    { url: "gb", lang: "en-GB" },
    { url: "de", lang: "de-DE" },
    { url: "pt", lang: "pt-PT" },
    { url: "fr", lang: "fr-FR" },
];
// #endregion

// #region ValueKey de idiomas
export const MIN_AGE_REGISTER: number = 16;
// #endregion

// #region Colores estados reserva regularizada
export const COLOR_PENDIENTE: string = "default";
export const COLOR_REGULARIZADA: string = "processing";
export const COLOR_VALIDADA: string = "success";
export const COLOR_DUPLICADA: string = "warning";
export const COLOR_RECHAZADA: string = "error";
export const COLOR_CANCELADA: string = "default";
// #endregion

// #region Paginación
export const CURRENT_PAGE: number = 1;
export const PAGE_SIZE: number = 6;
// #endregion

// #region Tipo Movimiento
export const BALANCE_GIFT_CARD: number = 1;
export const BALANCE_RESERVATION: number = 2;
// #endregion

// #region Tipos de archivo de imágenes
export const JPEG_TYPE: string = 'image/jpeg';
export const PNG_TYPE: string = 'image/png';
// #endregion
