// Modelo para configuracion de la app como el logo, color destacado, etc..
export class ConfigManagement {
    // tslint:disable-next-line:no-empty
    constructor() {}

    public name: string;
    public description: string;
    public incentiveSiteName: string;
    public contractNumber: string;
    public negativeLogo: string;
    public positiveLogo: string;
    public emailPositiveImageName: string;
    public emailNegativeImageName: string;
    public urlWA: string;
    public email: string;
    public minCalendarDate?: Date;
    public sessionExpireTime: number;
    public headerRefreshTime: number;
    public idLanguage: number;
    public id: number;
    public brandName: string;
    public favicon: string;
    public logo: string;
    public colour: string;
    public knowUsActive: boolean;

    UpdateConfigContext = (value: ConfigManagement) => {
        if (value){
            this.incentiveSiteName = value.incentiveSiteName;
            this.brandName = value.brandName;
            this.favicon = value.favicon;
            this.logo = value.logo;
            this.colour = value.colour;
            this.name = value.name;
            this.description = value.description;
            this.name = value.name;
            this.description = value.description;
            this.incentiveSiteName = value.incentiveSiteName;
            this.contractNumber = value.contractNumber;
            this.negativeLogo = value.negativeLogo;
            this.positiveLogo = value.positiveLogo;
            this.emailPositiveImageName = value.emailPositiveImageName;
            this.emailNegativeImageName = value.emailNegativeImageName;
            this.urlWA = value.urlWA;
            this.email = value.email;
            this.minCalendarDate = value.minCalendarDate;
            this.sessionExpireTime = value.sessionExpireTime;
            this.headerRefreshTime = value.headerRefreshTime;
            this.idLanguage = value.idLanguage;
            this.id = value.id;
            this.knowUsActive = value.knowUsActive;
        }
    }
}
