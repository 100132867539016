import React, { useState, useLayoutEffect, useEffect } from "react";
import { xs, sm, md, lg } from "../../../constants/constants";

/**
 * Permite hacer render de un componente u otro en función del ancho de la pantalla permitiendo mostrar
 * diferentes componentes en versiones móviles o tablet
 *
 * @param props
 *
 */
const AlternateComponent = (props) => {
  /// Screen width boostrap based.
  const [screenSizes, setScreenSizes] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const [mobileVersionSmall, setMobileVersionSmall] = useState(
    props.mobileVersionSmall
  );
  const [mobileVersionLarge, setMobileVersionLarge] = useState(
    props.mobileVersionLarge
  );
  const [tabletVersion, setTabletVersion] = useState(props.tabletVersion);
  const [desktopVersionSmall, setDesktopVersionSmall] = useState(
    props.desktopVersionSmall
  );
  const [desktopVersionLarge, setDesktopVersionLarge] = useState(
    props.desktopVersionLarge
  );

  const [componentShowed, setComponentShowed] = useState(
    props.setDesktopVersionLarge ||
      props.setDesktopVersionSmall ||
      props.setTabletVersion ||
      props.setMobileVersionLarge ||
      props.setMobileVersionSmall
  );

  /**
   * Select correct version screenSizes.width based
   */
  const selectCorrectComponent = () => {
    let result = null;

    if (screenSizes.width <= xs) {
      // Mobile version small
      result = mobileVersionSmall || mobileVersionLarge;
    } else if (screenSizes.width > xs && screenSizes.width <= sm) {
      // Mobile version large
      result = mobileVersionLarge || mobileVersionSmall;
    } else if (screenSizes.width > sm && screenSizes.width <= md) {
      // Tablet version
      result = tabletVersion;
    } else if (screenSizes.width > md && screenSizes.width <= lg) {
      // Desktop version small
      result = desktopVersionSmall || desktopVersionLarge;
    } else {
      // Destkop version large
      result = desktopVersionLarge || desktopVersionSmall;
    }

    setComponentShowed(result);
  };

  /**
   * Update the screen sizes on redim screem
   */
  useLayoutEffect(() => {
    const updateSize = () => {
      setScreenSizes({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", updateSize);
    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, [window.innerWidth, window.innerHeight]);

  /**
   * select correct component when the screenSizes, mobile, tablet or desktop version change
   */
  useEffect(() => {
    selectCorrectComponent();
  }, [
    screenSizes,
    mobileVersionSmall,
    mobileVersionLarge,
    tabletVersion,
    desktopVersionSmall,
    desktopVersionLarge,
  ]);

  useEffect(() => {
    setMobileVersionSmall(props.mobileVersionSmall);
    setMobileVersionLarge(props.mobileVersionLarge);
    setTabletVersion(props.tabletVersion);
    setDesktopVersionSmall(props.desktopVersionSmall);
    setDesktopVersionLarge(props.desktopVersionLarge);

    selectCorrectComponent();
  }, [props]);

  return <>{componentShowed}</>;
};

export default AlternateComponent;
