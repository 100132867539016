import React, { useEffect } from 'react';
import { Typography, Input, Button, Select, Form } from 'antd';
import './searchReservations.sass'
import '../../../i18n';
import { useTranslation } from 'react-i18next';
import OptionElement from '../../../models/register/OptionElement';
import Regularizations from '../../../models/regularizations/regularizations';
import { useReservationStates } from 'src/api/api';

const { Title, Paragraph } = Typography;
const { Option } = Select;

// Componente de búsqueda de reservas de las regularizaciones
const SearchReservations = (param) => {
    
    // Formulario de búsqueda a usar
    const [form] = Form.useForm();  
    
    // Textos - traducciones
    const { t } = useTranslation(['regularizations']);
    
    // Estado que almacena la lista de estados de una reserva regularizada
    const listStates = useReservationStates();

    // Limpiamos el formulario por que ocurrio un error
    if (param.clearForm) {
        form.resetFields();
        param.setClearForm(false);
    }

    // Función que se dispara en el evento de ejecutar el formulario
    const onFinish = (values: any) => {
        
        // Instanciamos modelo de regularizaciones
        const reg = new Regularizations();

        reg.state = form.getFieldValue("state");
        reg.reservations.bookingReference = form.getFieldValue("bookingReference");
        reg.reservations.client.nameSurname = form.getFieldValue("nameSurname");
        
        // Almacena los criterios de búsqueda en el estado para su búsqueda (GetReservations)
        param.setSearchResults(reg);
        param.setSearchHeaderResults(reg);
        param.forceRefresh();
    };

    return (
        <div className="row container-search-reg">
            <div className="col-12 info-search-reg-content">
                <Title level={ 4 }>
                    { t("search.title") }
                </Title>
                <Paragraph>
                    { t("search.detail") }
                </Paragraph>
            </div>
            <Form 
                form={ form } 
                layout="vertical" 
                id="search-reservations" 
                onFinish={ onFinish }
                className="row form-search-reservations"
                initialValues={ 
                    { 
                        bookingReference: "",
                        nameSurname: "",
                        state: 0
                    }
                }
            >
                <div className="col-12 col-sm-12 col-md-3 p-form-elements">
                    <Form.Item 
                        name="bookingReference"
                        label={ t("search.label.booking-reference") }
                    >
                        <Input 
                            id="bookingReference" 
                            data-testid="input-booking-reference"
                            className="main-input"
                        />
                    </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-3 p-form-elements">
                    <Form.Item 
                        name="nameSurname"
                        label={ t("search.label.name-surname") }
                    >
                        <Input 
                            id="nameSurname" 
                            data-testid="input-name-surname"
                            className="main-input"
                        />
                    </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-3 p-form-elements">
                    <Form.Item 
                        name="state"
                        label={ t("search.label.state") }
                    >
                        <Select
                            id="select-state"
                            data-testid="select-state"
                            style={ { width: '100%' } }
                            className="main-select"
                        >
                            <Option 
                                id={ "state-0" } 
                                key="0"
                                value={ 0 }
                            >
                                { t("search.select-all") }
                            </Option>
                            {                        
                                listStates?.map((v: OptionElement, i) => {
                                    return (
                                        <Option 
                                            id={ "state-" + v.id } 
                                            key={ v.id } 
                                            value={ v.id }
                                        >
                                            { v.description }
                                        </Option>
                                    )
                                })
                            }
                        </Select>
                    </Form.Item>
                </div>
                <div className="col-12 col-sm-12 col-md-3 p-form-elements">
                    <Form.Item 
                        name="btn-search-reservation"
                        label="&nbsp;"
                    >
                        <Button 
                            id="btn-search-reservation"
                            data-testid="search-button"
                            className="col-12 primary-button" 
                            type="primary" 
                            onClick={onFinish}
                        >
                            { t("search.btn-search") }
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
}

export default SearchReservations;
