import EndPointConfig from "src/models/api/endPointConfig";

const controller = 'Faqs';

/// Define all EndPoints for Client Data.
export const FaqsEP : EndPointConfig[] = [
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetFaqs')
       
];

