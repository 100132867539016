import * as React from "react";
import { useState, useContext, useEffect } from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { IntlProvider } from "react-intl";
import { UserContext } from "../../../hooks/context/userInfo";
import "./modalCashbackCreated.sass";


interface ParamProps {
    isModalCashbackVisible: boolean;
    setIsModalCashbackVisible: (value: boolean) => void;
    bankAccount: string;
}



const ModalCashBackCreated = (params: ParamProps) => {
    const { t } = useTranslation("profile");
    const userContext = useContext(UserContext);
    const [visible, setVisible] = useState(false);





    useEffect(() => {
        setVisible(params.isModalCashbackVisible);
    }, [params.isModalCashbackVisible]);


    const handleModal = async () => {
        params.setIsModalCashbackVisible(false);
        setVisible(false);
        window.location.reload();
    };

    return (
        <IntlProvider locale={userContext.GetLanguageUser()}>

            <Modal
                title={t("bank-account.modal-title-cashback-created")}
                visible={visible}
                onCancel={handleModal}
                footer={null} // Removing the footer to customize it in the modal content
                className="box-modal-reward"
            >
                <div className="body-modal-reward">
                    <div className="col-md-12 col-12">
                        <div className="col-12 form-item-profile mb-5">
                            {t("bank-account.modal-cashback-created-1")} <strong>{params.bankAccount} </strong>   {t("bank-account.modal-cashback-created-2")}
                        </div>
                        <div className="col-12 margin-element ">
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Button
                                    id="btn-accept-cashback-created"
                                    className="primary-button"
                                    type="primary"
                                    htmlType="button"
                                    onClick={handleModal}
                                    style={{ width: '100%' }}
                                >
                                    {t("bank-account.modal-btn-accept")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        </IntlProvider>
    );
}

export default ModalCashBackCreated;
