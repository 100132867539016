import { LanguageStrings } from './base-strings';

// Inglés Britanico cuando se añada al sistema
export const gb: LanguageStrings = {
  /** Routes */
  'routes.home': '/',
  'routes.Advantages': '/#benefits',
  'routes.Faqs': '/#faqs',
  'routes.contact': '/contact',
  'routes.recoverPassword':'/recover-password',
  'routes.privacyPolicies': '/privacy-policies',
  'routes.cookiesPolicies': '/cookies-policies',
  'routes.generalConditions': '/general-conditions',
  'routes.particularConditions': '/particular-conditions',
  'routes.register': '/register',
  'routes.homePrivate':'/app',
  'routes.AdvantagesPrivate':'/app/benefits',
  'routes.FaqsPrivate':'/app/faqs',
  'routes.privacyPoliciesPrivate': '/app/privacy-policies',
  'routes.cookiesPoliciesPrivate': '/app/cookies-policy',
  'routes.generalConditionsPrivate': '/app/general-terms-conditions',
  'routes.particularConditionsPrivate': '/app/particular-terms-conditions',
  'routes.contactPrivate': '/app/contact',
  'routes.regularization': '/app/regularizations',
  'routes.remuneration': '/app/remunerations',
  'routes.balanceHistory': '/app/balance-history',
  'routes.profile': '/app/my-profile',
  'routes.reward': '/app/rewards',
  'routes.news': '/app/news',
  'routes.details': '/app/details-news/:id',
  'routes.deleteAccount': '/delete-account',
  'routes.validateAddNewAgency': '/new-agency/:id',
  'routes.knowUs': '/app/know-us',
  'routes.legalAdvice' : '/legal-advice',
  'routes.termsConditionsCashbackPrivate' : '/app/terms-conditions-revolut',
  'routes.termsConditionsCashback' : '/terms-conditions-revolut'
};
