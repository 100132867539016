import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Form, Input, Select, DatePicker, Checkbox, Button, Row, Col, Alert, Typography } from "antd";
import AlternativeUserNames from "../alternativeUserNames/alternativeUserNames";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import "./signupAgent.sass";
import moment from "moment";
import Agent from "../../../models/register/agent";
import { linkActiveContext } from "../../../hooks/context/linkActive";
import { getLocaleDatePicker } from "../../../utils/datePicker";
import { PickerLocale } from "antd/es/date-picker/generatePicker";
import { showNotification } from "../../../utils/notificationsGeneric";
import { ConfigContext } from "../../../hooks/context/config";
import { MIN_AGE_REGISTER } from "../../../constants/constants";
import { ONLY_NUMBERS } from "../../../constants/regex";
import { UserContext } from "../../../hooks/context/userInfo";
import {
  useAddNewAgent,
  useDocumentTypes,
  useGetAvailableEmail,
  useGetAvailableUserName,
  useLanguages,
  usePositions,
} from "src/api/api";
import { useParticularConditions } from "src/api/api";
import { LangContext } from "src/hooks/context/lang";

const { Title } = Typography;

// Compomnente del formaulario de alta de agente
const SignUpAgentForm = (props) => {
  const language = useContext(LangContext);
  
  // Contexto del usuario
  const userContext = useContext(UserContext);

  // Local para el datePicker
  const local: PickerLocale = getLocaleDatePicker();
  const linkActive = useContext(linkActiveContext);
  const tailLayout = {
    wrapperCol: {
      span: 25,
    },
  };
  const { Option } = Select;
  const [form] = Form.useForm();
  const { t } = useTranslation(["signupAgents", "ajaxApi", "urls"]);
  const getAvailableEmail = useGetAvailableEmail();
  const getAvailableUserName = useGetAvailableUserName();
  const conditions = useParticularConditions("3");

  //#region States
  const theme = useContext(ConfigContext);
  const [checkPolicy, setCheckPolicy] = useState(false);
  const [checkCommerce, setCheckCommerce] = useState(false);

  const positions = usePositions();
  const documents = useDocumentTypes();
  const languages = useLanguages();
  

  const [forceShowAlternativeUsernames, setForceShowAlternativeUsernames] = useState(true);

  const [name, setName] = useState("");
  const [surname, setSurName] = useState("");
  // Estado para mostrar u ocultar alert warning
  const [visibleAlertError, setVisibleAlertError] = useState(false);
  // Estado para los mensajes a mostrar en los alert
  const [messageAlert, setMessageAlert] = useState({
    message: "",
    description: "",
  });
  //#endregion

  //#region Internal methods

  /*
        Description: 
            Lets set username from alternative usernames recommended.
        Params:
            selected: String with the selected username from recommended list.
            onlyClose: Let ignore the selected string to only close the modal with alternative usernames
        Return:
            void

    */
  const onSelectOrCloseAlternativeUserName = (selected: string, onlyClose: boolean = false): void => {
    if (!onlyClose) {
      form.setFieldsValue({
        "props.selectedData.userName": selected,
      });

      form.validateFields(["props.selectedData.userName"]);
    }

    setForceShowAlternativeUsernames(false);
  };

  const updateName = (event) => {
    setName(event.target.value);
  };

  const updateSurname = (event) => {
    setSurName(event.target.value);
  };

  const onFieldsChange = () => {
    let agent: Agent = new Agent(
      form.getFieldValue("props.selectedData.name"),
      form.getFieldValue("props.selectedData.surname"),
      form.getFieldValue("props.selectedData.secondSurname"),
      form.getFieldValue("props.selectedData.position"),
      form.getFieldValue("props.selectedData.mail"),
      form.getFieldValue("props.selectedData.phone"),
      form.getFieldValue("props.selectedData.birthDate"),
      form.getFieldValue("props.selectedData.language"),
      form.getFieldValue("props.selectedData.document"),
      form.getFieldValue("props.selectedData.documentNumber"),
      form.getFieldValue("props.selectedData.userName"),
      form.getFieldValue("props.selectedData.password"),
      form.getFieldValue("props.selectedData.confirmPassword")
    );

    props.updateData(agent);
  };

  useEffect(() => {
    if (props.selectedData !== undefined) {
      form.setFieldsValue({
        "props.selectedData.name": props.selectedData.name,
      });
      form.setFieldsValue({
        "props.selectedData.surname": props.selectedData.surname,
      });
      form.setFieldsValue({
        "props.selectedData.secondSurname": props.selectedData.secondSurname,
      });
      form.setFieldsValue({
        "props.selectedData.position": props.selectedData.position,
      });
      form.setFieldsValue({
        "props.selectedData.mail": props.selectedData.mail,
      });
      form.setFieldsValue({
        "props.selectedData.phone": props.selectedData.phone,
      });

      if (props.selectedData.birthDate !== null) {
        form.setFieldsValue({
          "props.selectedData.birthDate": moment(props.selectedData.birthDate),
        });
      }

      // If lang is not selected => set default webpage lang
      form.setFieldsValue({
        "props.selectedData.language": props.selectedData.language || language.lang,
      });
      form.setFieldsValue({
        "props.selectedData.document": props.selectedData.document,
      });
      form.setFieldsValue({
        "props.selectedData.documentNumber": props.selectedData.documentNumber,
      });
      form.setFieldsValue({
        "props.selectedData.userName": props.selectedData.userName,
      });
    }
  }, [props.updateData]);

  const addAgent = useAddNewAgent();

  const onFinish = async (values) => {
    const isAvailableMail = await getAvailableEmail(form.getFieldValue("props.selectedData.mail"));
    if (!isAvailableMail.data) {
      showNotification("error", isAvailableMail.title, isAvailableMail.message, "topRight");
      return;
    }
    const isAvailableUsername = await getAvailableUserName(form.getFieldValue("props.selectedData.userName"));
    if (!isAvailableUsername.data) {
      showNotification("error", isAvailableUsername.title, isAvailableUsername.message, "topRight");
      return;
    }

    let agent: any = {
      name: form.getFieldValue("props.selectedData.name"),
      surname1: form.getFieldValue("props.selectedData.surname"),
      surname2: form.getFieldValue("props.selectedData.secondSurname"),
      idPosition: form.getFieldValue("props.selectedData.position"),
      email: form.getFieldValue("props.selectedData.mail"),
      telephone: form.getFieldValue("props.selectedData.phone"),
      bornDate: form.getFieldValue("props.selectedData.birthDate").toDate(),
      idLanguage: form.getFieldValue("props.selectedData.language"),
      idDocumentType: form.getFieldValue("props.selectedData.document"),
      document: form.getFieldValue("props.selectedData.documentNumber"),
      userName: form.getFieldValue("props.selectedData.userName"),
      password: form.getFieldValue("props.selectedData.password"),
      receiveCommercialInformation: checkCommerce,
      idAgency: props.searchAgencyData.id,
      idCurrency: props.searchAgencyData.idCurrency,
      idAgentType: props.agentType,
    };

    const res = await addAgent(agent);
    if (res.data) {
      props.setSignUpAgentAllData(agent);
      props.next();
    } else {
      showNotification("error", res.status, res.message, "topRight");
    }
  };

  let dateReduct = new Date().getFullYear() - MIN_AGE_REGISTER;
  let dateToday = new Date();
  
  const [initialDate] = useState<any>(new Date(dateReduct, dateToday.getMonth(), dateToday.getDate()));
  // Comparador de fechas para saber si debe estar disable o seleccionable para el usuario
  const disabledDate = (current) => {
    return current > new Date(dateReduct, dateToday.getMonth(), dateToday.getDate());
  };

  const cleanErrors = () => {
    setVisibleAlertError(false);
   try{
      var element = document.getElementById("basic_props.selectedData.mail");
      if (element){
        element.parentElement.classList.remove("ant-form-item-has-error");

      }
   }catch(exception){}
  };

  const [selectedLanguage, setSelectedLanguage] = useState<string>('es-ES')

  return (
    <div className="container signup-agent">
      <div className="titles">
        <Title className="title" level={3}>
          {t("main-title")}
        </Title>
        <Title className="subtitle" level={4}>
          {t("sub-title")}
        </Title>
      </div>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        form={form}
        className="row"
        layout="vertical"
        id="signup-agent-form"
        onFieldsChange={onFieldsChange}
      >
        <Form.Item
          className="col-12 col-lg-6 form-item"
          label={t("name")}
          name="props.selectedData.name"
          rules={[
            {
              required: true,
              message: t("validations.name"),
            },
            {
              whitespace: true,
              message: t("validations.name"),
            },
          ]}
        >
          <Input className="main-input" placeholder="" id="name" onChange={updateName} />
        </Form.Item>
        <Form.Item
          className="col-12 col-lg-6 form-item"
          label={t("surname")}
          name="props.selectedData.surname"
          rules={[
            {
              required: true,
              message: t("validations.surname"),
            },
            {
              whitespace: true,
              message: t("validations.surname"),
            },
          ]}
        >
          <Input className="main-input" placeholder="" id="surname" onChange={updateSurname} />
        </Form.Item>
        <Form.Item
          className="col-12 col-lg-6 form-item"
          label={t("second-surname")}
          name="props.selectedData.secondSurname"
        >
          <Input className="main-input" placeholder="" id="secondSurname" />
        </Form.Item>
        <Form.Item
          className="col-12 col-lg-6 form-item"
          label={t("position")}
          name="props.selectedData.position"
          rules={[
            {
              required: true,
              message: t("validations.position"),
            },
          ]}
        >
          <Select className="main-select" placeholder={t("select-option")}>
            {positions?.map((s: any, i) => {
              return (
                <Option key={i} id={`p-${i}`} value={s.id}>
                  {s.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>




        <Form.Item
          className="col-12 col-lg-6 form-item"
          label={t("mail")}
          name="props.selectedData.mail"
          rules={[
            {
              required: true,
              message: t("validations.mail"),
            },
            {
              type: "email",
              message: t("validations.invalid-mail"),
            }
          ]}
        >
          <Input onCopy={(e)=>{
            e.preventDefault();
          }} onPaste={(e)=>{
            e.preventDefault();
          }} className="main-input" placeholder="" id="mail" onChange={cleanErrors} />
        </Form.Item>


        <Form.Item
          className="col-12 col-lg-6 form-item"
          label={t("mail-repeat")}
          name="props.selectedData.mailRepeat"
          rules={[
            {
              required: true,
              message: t("validations.mail"),
            },
            {
              type: "email",
              message: t("validations.invalid-mail"),
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("props.selectedData.mail") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(t("validations.email-repeat"));
              },
            }),
          ]}
        >
          <Input onCopy={(e)=>{
            e.preventDefault();
          }} onPaste={(e)=>{
            e.preventDefault();
          }} className="main-input" placeholder="" id="mailRepeat" onChange={cleanErrors} />
        </Form.Item>





        
        <Form.Item
          className="col-12 col-lg-6 form-item"
          label={t("phone")}
          name="props.selectedData.phone"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? ONLY_NUMBERS.test(value)
                    ? Promise.resolve()
                    : Promise.reject(t("validations.number"))
                  : Promise.resolve(),
            },
          ]}
        >
          <Input className="main-input" placeholder="" id="phone" />
        </Form.Item>
        <Form.Item
          className="col-12 col-lg-6 form-item"
          label={t("birth-date")}
          name="props.selectedData.birthDate"
          initialValue={moment(initialDate)}
          rules={[
            {
              required: true,
              message: t("validations.birth-date"),
            },
          ]}
        >
          <DatePicker
            format={local.dateFormat}
            locale={local}
            placeholder={local.lang.dateFormat}
            disabledDate={disabledDate}
            className="main-select reservations-dates"
          />
        </Form.Item>
        <Form.Item
          className="col-12 col-lg-6 form-item"
          label={t("language")}
          name="props.selectedData.language"
          rules={[
            {
              required: true,
              message: t("validations.language"),
            },
          ]}
        >
          <Select className="main-select" placeholder={t("select-option")} options={languages?.map((s:any, i)=>{ return {label: s.name, value:`${s.id}`}})} />
        </Form.Item>
        <Form.Item
          className="col-12 col-lg-6 form-item"
          label={t("document-type")}
          name="props.selectedData.document"
          rules={[
            {
              required: true,
              message: t("validations.document-type"),
            },
          ]}
        >
          <Select className="main-select" placeholder={t("select-option")}>
            {documents?.map((s: any, i) => {
              return (
                <Option key={i} id={`d-${i}`} value={s.id}>
                  {s.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          className="col-12 col-lg-6 form-item"
          label={t("document-number")}
          name="props.selectedData.documentNumber"
          rules={[
            {
              required: true,
              message: t("validations.document-number"),
            },
            {
              whitespace: true,
              message: t("validations.document-number"),
            },
          ]}
        >
          <Input className="main-input" placeholder="" id="documentNumber" />
        </Form.Item>
        <Form.Item
          className="col-12 form-item"
          label={t("user-name")}
          name="props.selectedData.userName"
          rules={[
            {
              required: true,
              message: t("validations.user-name"),
            },
            {
              whitespace: true,
              message: t("validations.user-name"),
            },
          ]}
        >
          <AlternativeUserNames
            name={name}
            surname={surname}
            default={props.selectedData.userName}
            forceShow={forceShowAlternativeUsernames}
            onSelectOrCloseAlternativeUserName={onSelectOrCloseAlternativeUserName}
          />
        </Form.Item>
        <Form.Item
          className="col-12 col-lg-6 form-item"
          label={t("password")}
          name="props.selectedData.password"
          extra={t("message-requirement")}
          rules={[
            {
              required: true,
              message: t("validations.password"),
            },
            {
              max: 25,
              message: t("validations.password-min-max-whitespace"),
            },
            {
              min: 8,
              message: t("validations.password-min-max-whitespace"),
            },
            {
              whitespace: true,
              message: t("validations.password-min-max-whitespace"),
            },
          ]}
        >
          <Input.Password className="main-input" placeholder="" id="password" />
        </Form.Item>
        <Form.Item
          className="col-12 col-lg-6 form-item"
          label={t("password-repeat")}
          name="props.selectedData.confirmPassword"
          rules={[
            {
              required: true,
              message: t("validations.password"),
            },
            {
              max: 25,
              message: t("validations.password-min-max-whitespace"),
            },
            {
              min: 8,
              message: t("validations.password-min-max-whitespace"),
            },
            {
              whitespace: true,
              message: t("validations.password-min-max-whitespace"),
            },
            /// The password and repeatPassword fields must be identical
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value || getFieldValue("props.selectedData.password") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(t("validations.password-repeat"));
              },
            }),
          ]}
        >
          <Input.Password className="main-input" placeholder="" id="passwordRepeat" />
        </Form.Item>

        {visibleAlertError && (
          <div className="alert-message">
            <Alert message={messageAlert.message} description={messageAlert.description} type="error" showIcon />
          </div>
        )}

        <Form.Item
          className="col-12 form-item check-policy"
          name="props.selectedData.checkPolicy"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) => (value ? Promise.resolve() : Promise.reject(t("validations.policy"))),
            },
          ]}
        >
          <Checkbox className="main-input box-check" id="check-policy" checked={checkPolicy}>
            {t("policy-text")}
            {conditions && (
              <>
                <Link
                  id="link-privacy-policies"
                  className="primary-links"
                  to={t("urls:public.particular-conditions")}
                  target="_blank"
                  onClick={() => linkActive.updateValueUrl(t("urls:public.particular-conditions"))}
                >
                  {t("link-name-cookies-policies-particular")}
                </Link>
              </>
            )}
            <Link
              id="link-privacy-policies"
              className="primary-links"
              to={t("urls:public.general-conditions")}
              target="_blank"
              onClick={() => linkActive.updateValueUrl(t("urls:public.general-conditions"))}
            >
              {t("link-name-cookies-policies")}
            </Link>
            {t("and")}
            <Link
              id="link-privacy-policies"
              className="primary-links"
              to={t("urls:public.privacy-policies")}
              target="_blank"
              onClick={() => linkActive.updateValueUrl(t("urls:public.privacy-policies"))}
            >
              {t("link-name-privacy-policies")}
            </Link>
          </Checkbox>
        </Form.Item>
        <Form.Item className="col-12 form-item" name="checkCommerce" valuePropName="checked">
          <Checkbox
            className="main-input box-check"
            id="checkCommerce"
            onChange={() => setCheckCommerce(!checkCommerce)}
            checked={checkCommerce}
          >
            {t("check-commerce", { 0: theme.incentiveSiteName })}
          </Checkbox>
        </Form.Item>
        <Form.Item className="col-12" {...tailLayout}>
          <Row>
            <Col span={24} className="align-text-buttons">
              <div className="content-action-buttons">
                <Button id="btn-next" type="primary" className="primary-button" htmlType="submit">
                  {t("register:steps.buttons.next")}
                </Button>
                <Button id="btn-prev" className="default-button margin-right-button" onClick={props.previous}>
                  {t("register:steps.buttons.prev")}
                </Button>
              </div>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </div>
  );
};

export default SignUpAgentForm;
