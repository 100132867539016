import * as React from "react";
import "./modalReward.sass";
import ModalRewardCashBack from "./cashback/ModalRewardCashBack";
import ModalRewardGeneric from "./generic/ModalRewardGeneric";

interface ParamProps {
  isModalRewardVisible: boolean;
  setModalRewardVisible: (value: boolean) => void;
  valueOpen: any;
  setAvailableMoney: (value: any) => void;
  isOwn: boolean;
}

// Modal reward
const FormRewardBalance = (params: ParamProps) => {

  const [modal, setModal] = React.useState<JSX.Element>(<></>);

  const getModal = (modalType: string) : void => {

    var result = <></>;

    switch (modalType) {
      case 'cashback':
        result = <ModalRewardCashBack {...params} />;
        break;
      case 'generic':
        result = <ModalRewardGeneric {...params} />;
        break;
      case 'empty':
        result = <></>;
        break;
    }

    setModal(result);
  
  }

  React.useEffect(() => {
      if(params?.valueOpen?.name.toUpperCase().includes('CASHBACK')){
        getModal('cashback');
      }else{
        getModal('generic');
      }
  }, [params.isModalRewardVisible]);

  return(
    <div className="modal-container">
      {
        modal
      }
    </div>
  )
};
export default FormRewardBalance;
