import * as AjaxApi from "./ajaxApi";
import { ParamsApi } from "../models/api/paramsApi";
import { UserModelSendDataLogin, UserModelResponseDataLogin } from "../models/user/userModelSendDataLogin";
import { ClientDataModel } from "../models/config/clientData";
import PhoneNumberContact from "../models/contact/phoneContact";
import DataContact from "../components/contact/formContact/dataFormContact";
import i18n from '../i18n'
import { Guid } from 'uuid';


export class RestApiClient {
	constructor(private accessToken?: string, private traslation?: any) { }

	public setAccessToken(token?: string) {
		this.accessToken = token;
	}

	public setLanguage(traslation?: any) {
		this.traslation = traslation;
    }

    // #region EndPoints Customizations
	public getCustomizations(): Promise<ClientDataModel> {
		return AjaxApi.doAction("GetCustomizations", this.traslation).then(
			(response) => response.data
		);
	}

	public getGeneralConditions(lang: string): Promise<any> {
		return AjaxApi.doAction("GeneralConditions", lang, {query: { language: lang}}).then(
			(response) => response.data
		);
	}

	public getContractNumber(): Promise<any> {
		return AjaxApi.doAction("GetContractNumber", this.traslation).then(
			(response) => response.data
		);
    }

    public getLandingInit(filter): Promise<any> {

        const params: ParamsApi = {
			query: {
				Language: filter.Language
			},
		};

		return AjaxApi.doAction("GetLandingInit", this.traslation, params).then<any>(
			(response) => response.data
		);
    }

	// #endregion

	// #region - EndPoints Users
	public getLanguageAgent(): Promise<any> {
		return AjaxApi.doAction("GetLanguageAgent", this.traslation).then<any>(
			(response) => response.data
		);
	}

	public logoutAgent(): Promise<boolean> {
		return AjaxApi.doAction("LogoutAgent", this.traslation).then<boolean>(
			(response) => response.data
		);
	}

	public loginAgent(userData: UserModelSendDataLogin): Promise<any> {
		const params: ParamsApi = {
			body: {
				agentData: userData.agentData,
				password: userData.password,
				language: userData.language,
			},
		};
		return AjaxApi.doAction("LoginAgent", this.traslation, params).then<any>(
			(response) => response
		);
	}

	public getUserName(): Promise<string> {
		return AjaxApi.doAction("GetUserName", this.traslation).then<string>(
			(response) => response.data
		);
	}
	// #endregion

	// #region Ranking

	public getRanking(): Promise<any> {
		return AjaxApi.doAction("GetRanking", this.traslation).then(
			(response) => response.data
		);
    }

	public getRankingMyPosition(): Promise<any> {
		return AjaxApi.doAction("GetRankingMyPosition", this.traslation).then<number>(
			(response) => response.data
		);
    }

	// #endregion

	public getBookings(filter: any): Promise<any>{

		const params: ParamsApi = {
			query: {
				language: i18n.language,
				...filter
			},
		};

		return AjaxApi.doAction("GetBookingFiltered", this.traslation, params).then(
			(response) => response.data
		);
	}

	// #region Contact

	public getListNumberContact(languageParam: string): Promise<PhoneNumberContact[]> {
		const params: ParamsApi = {
			query: {
				language: languageParam,
			},
		};
		return AjaxApi.doAction("GetListNumberContact", this.traslation, params).then<PhoneNumberContact[]>(
			(response) => response.data
		);
	}

	public sendFormContact(dataContactParam: DataContact): Promise<PhoneNumberContact[]> {
		const params: ParamsApi = {
			body: {
				name: dataContactParam.name,
				email: dataContactParam.email,
				subject: dataContactParam.subject,
				message: dataContactParam.message,
			},
		};
		return AjaxApi.doAction("SendFormContact", this.traslation, params).then<PhoneNumberContact[]>(
			(response) => response.data
		);
	}

	// #endregion

	//#region Rgularization
	public getChannels(): Promise<any> {

		return AjaxApi.doAction("GetChannels", this.traslation).then<any>(
			(response) => response.data
		);
	}

	public getHotels(): Promise<any> {

		return AjaxApi.doAction("GetHotels", this.traslation).then<any>(
			(response) => response.data
		);
	}

	public getNumberRooms(): Promise<any> {

		return AjaxApi.doAction("GetNumberRooms", this.traslation).then<any>(
			(response) => response.data
		);
	}

	public getDateDisable(): Promise<any> {

		return AjaxApi.doAction("GetDateDisable", this.traslation).then<any>(
			(response) => response.data
		);
	}

	public addNewTTOO(nameParam:string): Promise<any> {
		const params: ParamsApi = {
			body: nameParam,

		};
		return AjaxApi.doAction("AddNewTTOO", this.traslation, params).then<any>(
			(response) => response.data
		);
	}

	public getFilterAgencies(name: string, address: string, telephone: string, identityCode: string, page: number, pageSize: number): Promise<any> {
		const params: ParamsApi = {
			query: {
				'Pagination.Page': page,
				'Pagination.ResultPage': pageSize,
				Name: name,
				Address: address,
				Telephone: telephone,
				IdentityCode: identityCode,
				Language: i18n.language
			},

		};
		return AjaxApi.doAction("GetFilteredAgencies", this.traslation, params).then<any>(
			(response) => response.data
		);
	}
	//#endregion
	public getFaqs(language: string){
		const params: ParamsApi = {
			query: {
				languageId: language
			},

		};
		return AjaxApi.doAction("GetFaqs", this.traslation, params).then<any>(
			(response) => response.data
		);	
	}

	public sendFormKnowUsRequest(p: any){
		const params: ParamsApi = {
			body:p
		};
		return AjaxApi.doAction("SendFormKnowUsRequest", this.traslation, params).then<any>(
			(response) => response.data
		);	
	}

	public getKnowUsConfiguration(language: string){
		const params: ParamsApi = {
			query: {
				language: language
			}
		};
		return AjaxApi.doAction("GetKnowUsConfiguration", this.traslation, params).then<any>(
			(response) => response.data
		);	
	}

	public modifyBankData(data: any){
		const params: ParamsApi = {
			body: data
		};
		return AjaxApi.doActionWithoutNotification("ModifyBankAccount", params).then<any>(
			(response) => response
		);	
	}
	public saveBankData(data: any){
		const params: ParamsApi = {
			body: data
		};
		return AjaxApi.doActionWithoutNotification("SaveBankAccount", params).then<any>(
			(response) => response
		);	
	}



	public deleteBankData(data: any){
		const params: ParamsApi = {
			body: data
		};
		return AjaxApi.doActionWithoutNotification("DeleteBankAccount", params).then<any>(
			(response) => response
		);	
	}




	public getProviderId(AgentId: Guid){
		const params: ParamsApi = {
			query: {
				AgentId: AgentId
			}
		};
		return AjaxApi.doActionWithoutNotification("getProviderId", params).then<any>(
			(response) => response.data
		);	
	}

	public setBankData(data: any){
		const params: ParamsApi = {
			body: data
		};
		return AjaxApi.doActionWithoutNotification("setBankData", params).then<any>(
			(response) => response
		);	
	}


	public getAvailableMoney(){
		return AjaxApi.doActionWithoutNotification("getAvailableMoney").then<any>(
			(response) => response.data
		);	
	}


	public getAccountType(country: string){
		const params: ParamsApi = {
			query: {
				Country: country
			}
		};
		return AjaxApi.doActionWithoutNotification("getAccountType", params).then<any>(
			(response) => response.data
		);	
	}



	public getAllCountries(language: string){
		const params: ParamsApi = {
			query: {
				language: language
			}
		};
		return AjaxApi.doActionWithoutNotification("getCountries", params).then<any>(
			(response) => response.data
		);	
	}


	
	public getAllCurrencies(){		
		return AjaxApi.doActionWithoutNotification("getAllCurrencies").then<any>(
			(response) => response.data
		);	
	}


	
	public getChainPayTaxes(){
		return AjaxApi.doActionWithoutNotification("getChainPayTaxes").then<any>(
			(response) => response.data
		);	
	}

	public calculateFee( currency : string , agentId : Guid,){
		const params: ParamsApi = {
			query: {
				currency: currency,
				agentId: agentId
			}
		};

		return AjaxApi.doActionWithoutNotification("calculateFee", params).then<any>(
			(response) => response.data
		);		
	}
	
	public getRewardsConfigurationOwn(){
		return AjaxApi.doActionWithoutNotification("GetOwnRewardsConfiguration", {}).then<any>(
			(response) => {
				return response.data;0
			}
		);	
	}

	public getSuppliersConfiguration(page: number, pageSize: number){
		const params: ParamsApi = {
			query: {
				'Pagination.Page': page,
				'Pagination.ResultPage': pageSize
				}
		}

		return AjaxApi.doActionWithoutNotification("GetSuppliersConfiguration", params).then<any>(
			(response) => {
				return response.data;
			}
		);	
	}


	public getBankAccountCountry(agentId: Guid){
		const params: ParamsApi = {
			query: {
				AgentId: agentId,           
			}
		}

		return AjaxApi.doActionWithoutNotification("GetBankAccountCountry", params).then<any>(
			(response) => {
				return response.data;
			}
		);	
	}

	public getBankAccountCurrency(agentId: Guid){
		const params: ParamsApi = {
			query: {
				AgentId: agentId,           
			}
		}

		return AjaxApi.doActionWithoutNotification("GetBankAccountCurrency", params).then<any>(
			(response) => {
				return response.data;
			}
		);	
	}

	public getBankAccount(AgentId: Guid){
		const params: ParamsApi = {
			query: {
				AgentId: AgentId
			}
		};
		return AjaxApi.doActionWithoutNotification("getBankAccount", params).then<any>(
			(response) => response.data
		);	
	}

	public getNoIndexNoFollowConfig(){
		const params: ParamsApi = {};
		return AjaxApi.doActionWithoutNotification("GetNoIndexNoFollowConfiguration", params).then<any>(
			(response) => response.data
		);	
	}

	public getGtmId(){
		const params: ParamsApi = {};
		return AjaxApi.doActionWithoutNotification("GetGtmIdConfig", params).then<any>(
			(response) => response.data
		);	
	}

	
}
