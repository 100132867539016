import * as React from 'react';
import { useContext } from 'react';
import { Typography } from 'antd';
import './footer.sass';
import { ConfigContext } from '../../hooks/context/config';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { linkActiveContext } from '../../hooks/context/linkActive'
import { useParticularConditions } from "src/api/api";
const { Text } = Typography;

// Componente del pie de la aplicación
const Footer = (param) => {

    const theme = useContext(ConfigContext);
    const { t } = useTranslation(['urls', 'linksNames']);
    const linkActive = useContext(linkActiveContext)
    const conditions = useParticularConditions("Footer.tsx");

    return (
        <footer className="col-12 footer-container">
            <div className="col-12 footer-row">
                <div className="col-md-1"></div>
                <div className="col-12 col-md-3 first-half-footer">
                    <Text>{theme.incentiveSiteName}</Text>
                </div>
                <div className="col-12 col-md-7 second-half-footer">
                    {(conditions && param.zone === "private") && (<>
                        <Link
                            id="link-footer-particular-conditions"
                            className={"default-links" + ((window.location.pathname + window.location.hash) == t("urls:" + param.zone + ".particular-conditions") ? " active" : "")}
                            to={t("urls:" + param.zone + ".particular-conditions")}
                            onClick={() => linkActive.updateValueUrl(t("urls:" + param.zone + ".particular-conditions"))}
                            disabled={(window.location.pathname + window.location.hash) == t("urls:" + param.zone + ".particular-conditions")}
                        >
                            {t("linksNames:particular-conditions")}
                        </Link>
                        <span> | </span> </>
                    )}
                    <Link
                        id="link-footer-general-conditions"
                        className={"default-links" + ((window.location.pathname + window.location.hash) == t("urls:" + param.zone + ".general-conditions") ? " active" : "")}
                        to={t("urls:" + param.zone + ".general-conditions")}
                        onClick={() => linkActive.updateValueUrl(t("urls:" + param.zone + ".general-conditions"))}
                        disabled={(window.location.pathname + window.location.hash) == t("urls:" + param.zone + ".general-conditions")}
                    >
                        {t("linksNames:general-conditions")}
                    </Link>

                    <span> | </span>
                    <Link
                        id="link-footer-policy-policies"
                        className={"default-links" + ((window.location.pathname + window.location.hash) == t("urls:" + param.zone + ".privacy-policies") ? " active" : "")}
                        to={t("urls:" + param.zone + ".privacy-policies")}
                        onClick={() => linkActive.updateValueUrl(t("urls:" + param.zone + ".privacy-policies"))}
                        disabled={(window.location.pathname + window.location.hash) == t("urls:" + param.zone + ".privacy-policies")}
                    >
                        {t("linksNames:privacy-policies")}
                    </Link>
                    <span> | </span>
                    <Link
                        id="link-footer-cookies-policies"
                        className={"default-links" + ((window.location.pathname + window.location.hash) == t("urls:" + param.zone + ".cookies-policies") ? " active" : "")}
                        to={t("urls:" + param.zone + ".cookies-policies")}
                        onClick={() => linkActive.updateValueUrl(t("urls:" + param.zone + ".cookies-policies"))}
                        disabled={(window.location.pathname + window.location.hash) == t("urls:" + param.zone + ".cookies-policies")}
                    >
                        {t("linksNames:cookies-policies")}
                    </Link>
                </div>
                <div className="col-md-1"></div>
            </div>
        </footer>
    );
}

export default Footer;
