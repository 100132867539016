/*
    Description:
        Model to define the differents EndPoints availables on API
*/
export default class EndPointConfig {
    public usingActionName: boolean = false;

    constructor(
        /// GET, POST, PATCH, DELETE
        public method: string = "",
        public controller: string = "",
        public actionName: string = "",
        public pattern: string = "",
        public alias: string = "",
        public mockData: MockData = null
    ) {}

    public setActionName = (actionName: string): EndPointConfig => {
        this.usingActionName = true;
        this.actionName = actionName;

        return this;
    };

    public setController = (controller: string): EndPointConfig => {
        this.controller = controller;

        return this;
    };

    public setMethod = (method: string): EndPointConfig => {
        this.method = method;
        return this;
    };

    public setPattern = (pattern: string): EndPointConfig => {
        this.pattern = pattern;
        return this;
    };

    public setAlias = (alias: string): EndPointConfig => {
        this.alias = alias;

        return this;
    };

    public setMockData = (mockData: MockData): EndPointConfig => {
        this.mockData = mockData;
        return this;
    };
}

// tslint:disable-next-line:max-classes-per-file
export class MockData {
    constructor(
        public okData: any = null,
        public failData: any = null,
        public warningData: any = null
    ) {}
}


// tslint:disable-next-line:max-classes-per-file

