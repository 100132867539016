import * as React from "react";
import { useState, useContext } from "react";
import { Button, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { IntlProvider } from "react-intl";
import { UserContext } from "../../../hooks/context/userInfo";
import "./modalDeleteBankData.sass";
import { RestApiClientContext } from "src/hooks/context/pageContext";
import { showNotification } from "src/utils/notificationsGeneric";
import { LoadingOutlined } from "@ant-design/icons";
import ReactHtmlParser from "react-html-parser";



const ModalDeleteBankData = (params ) => {
    const { t } = useTranslation(["profile", "newAgency"]);
    const userContext = useContext(UserContext);
    const [visible, setVisible] = useState(true);
    const restApiClient = useContext(RestApiClientContext);
    const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false); // submit button


    const handleCancel = () => {
        setVisible(false);
    };



    const handleEliminar = () => {

        setLoadingUpdate(true);
        const body = {
            agentId: params.agentId
        }

        restApiClient.deleteBankData(body).then(res => { // no debería poner dependiendo de si res.code == success o errorbankdata?
            if (res.code == "Success") { // no necesito el data porque el res ya tiene el data
                params.setBankAccount(null)
                showNotification("success", t("profile:bank-account.success-deleting"), t("profile:bank-account.description-success-deleting"), "topRight");

            } else if (res.code == "Error") {
                showNotification("error", t("profile:bank-account.error"), t("profile:bank-account.description-error-deleting"), "topRight");
            }
            setLoadingUpdate(false);
            
            params.setVisibleDeleteModal(false)
            setVisible(false);
            
        });

    };

    return (
        <IntlProvider locale={userContext.GetLanguageUser()}>
            <Modal
                title={t("profile:bank-account.modal-title-delete-bank-account")}
                visible={visible}
                onCancel={handleCancel}
                footer={null} // Removing the footer to customize it in the modal content
                className="box-modal-reward"
            >
                <div className="body-modal-reward">
                    <div className="col-md-12 col-12">
                        <div className="col-12 form-item-profile mb-5">
                        { ReactHtmlParser(
                            t("profile:bank-account.modal-bank-account-delete", {0:  params.bankAccount})
                        )}
                        </div>
                        <div className="col-12 margin-element ">
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                                <Button
                                    onClick={handleEliminar}
                                    htmlType="button"
                                    style={{ width: '100%', backgroundColor: "#F78C75" }}
                                    disabled={loadingUpdate}
                                >
                                    {loadingUpdate ? (
                                        <>
                                            <LoadingOutlined style={{ marginRight: "3px" }} />
                                            <span style={{ marginLeft: "3px" }}>{t("profile:bank-account.btn-delete")}</span>
                                        </>
                                    ) : (
                                        t("profile:bank-account.btn-delete")
                                    )}


                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

            </Modal>
        </IntlProvider>
    );
}

export default ModalDeleteBankData;
