import { createContext } from "react";
import { LanguageManagement } from '../../models/language/languageUserManagement'
import { AllLanguagesManagement } from '../../models/language/allLanguagesManagement'
import { LanguageUrlManagement } from "../../models/language/languageUrlManagment";

// Valores por defecto
const defaultValueLang = new LanguageManagement();
const defaultValueUrl = new LanguageUrlManagement();
const defaultValueAllLang = new AllLanguagesManagement();

// Creación de Context para su posterior configuración propia del cliente para la aplicación
export const LangContext = createContext(defaultValueLang);
export const LangUrlContext = createContext(defaultValueUrl);
export const AllLanguages = createContext(defaultValueAllLang);
