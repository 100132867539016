import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { LINKS_LAYOUT_PRIVATE } from '../../../../constants/constants';
import { faHome, faRetweet, faCoins, faChartBar, faNewspaper, faStar, faQuestionCircle, faListAlt, faHandshake, faHotel } from "@fortawesome/free-solid-svg-icons";
import RoutesContent from '../../../../routes/private/routesContent';
import './layout.sass';
import RankingPosition from '../../../ranking/rankingMenu/ranking';
import { linkActiveContext } from '../../../../hooks/context/linkActive';
import { cleanUp } from '../../../../utils/cleanUp';
import { ConfigContext } from '../../../../hooks/context/config';

const { Sider, Content } = Layout;

// Layout menu lateral izquierdo zona privada
const LayoutBasic = (param) => {
  var linksMenu = LINKS_LAYOUT_PRIVATE;

  // Contexto con la configuracion
  const theme = useContext(ConfigContext);

  // Remove KnowUs section if not active
  if (!theme.knowUsActive){
    linksMenu = linksMenu.filter(lm => lm != "know-us");
  }
  // Libreria i18n para traducir
  const { t } = useTranslation(['urls', 'linksNames']);
  const linkActive = useContext(linkActiveContext);
  const icons = [faHome, faRetweet, faCoins, faListAlt, faChartBar, faNewspaper, faStar, faQuestionCircle, faHotel];
  let url = window.location.pathname + window.location.hash;

  if (url.substring(url.length, url.length - 1) === '/') {
    url = url.substring(0, url.length - 1);
  }

  useEffect(() => {
    cleanUp();
  });

  return (
    <Layout>
      <Sider
        id="sider"
        trigger={ null }
        collapsible
        collapsed={ param.collapsedState }
        collapsedWidth={ 60 }
        width={ 250 }
        className="site-layout"
      >
        <div className="logo"></div>
        <Menu
          mode="inline"
          className="column-menu-items"
          defaultSelectedKeys={ [url] }
        >
          {
            linksMenu.map((element, index) => {

              const showDest: string = t ("urls:private." + element);

              return (
                <Menu.Item
                  key={ showDest }
                  className={ "mt-0 padding-elements-link sider-menu " + ((window.location.pathname + window.location.hash) === t("urls:private."+ element) ? "disabled-li" : "") }
                >
                  <Link
                    id={ "link-" + element + "-" + index }
                    key={ index }
                    to={ t("urls:private." + element) }
                    className={ (window.location.pathname + window.location.hash) === t("urls:private."+ element) ?
                    "secondary-links active" : ((window.location.pathname.substring(0, window.location.pathname.length - 2)) === t("urls:private.details") && element === "news") ? "secondary-links active" : "secondary-links" }
                    onClick={ () => linkActive.updateValueUrl(t("urls:private."+ element)) }
                  >
                    <div className="box-link-menu">
                      <div className="column-icon-items">
                        <FontAwesomeIcon
                          className="icons-color-layout icons-color-marging-layout"
                          icon={ icons[index] }
                        />
                      </div>
                      <div className="column-text-items">
                        { t("linksNames:" + element) }
                      </div>
                    </div>
                  </Link>
                </Menu.Item>
              )
            })
          }
          { !param.collapsedState && <RankingPosition /> }
        </Menu>
      </Sider>
      <Content>
        <div className="container margin-bottom-8">
          <RoutesContent />
        </div>
      </Content>
    </Layout>
  );
}

export default LayoutBasic;
