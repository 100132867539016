import React, { useContext, useEffect, useState } from 'react'


import './KnowUsComponent.sass'
import { Alert, Button, Col, DatePicker, Divider, Form, Input, Row, Select, Skeleton, Tooltip, Typography } from 'antd';
import { useHotelById, useHotels, useUserInfo } from 'src/api/api';
import { UserContext } from 'src/hooks/context/userInfo';
import { useTranslation } from 'react-i18next';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import { getLocaleDatePicker } from 'src/utils/datePicker';
import moment from 'moment';
import { RestApiClientContext } from 'src/hooks/context/pageContext';
import { KnowUsCfg } from 'src/models/config/KnowUsCfg';
import { LoadingOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import ReactHtmlParser from "react-html-parser";

const { Paragraph, Text } = Typography;

const { RangePicker } = DatePicker;

const KnowUsComponent = () : JSX.Element => {
    const { t } = useTranslation(["regularizations", "translation", "knowUs"]);
    const restApiClient = useContext(RestApiClientContext)

    const [sendingRequest, setSendingRequest] = useState<boolean>(false);
    
    const local: PickerLocale = getLocaleDatePicker();

    const userInfo = useUserInfo();
    const userContext = useContext(UserContext);

    const hotelsList = useHotels();

    const [establishmentInfo, setEstablishmentInfo] = useState<any>();
    
    const [hotelId, setHotelId] = useState<string>('');
    const [hotelName, setHotelName] = useState<string>('');

    const [agentName, setAgentName] = useState<string>('');
    const [cifVat, setCifVat] = useState<string>('');
    const [agencyName, setAgencyName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const [cifVatLabel, setCifVatLabel] = useState<string>('');

    const [form] = Form.useForm();
    const [loadingData, setLoadingData] = useState<boolean>(false);

    const submitForm = () : void => {
        var formData = form.getFieldsValue();

        const childsAges = [];
        for (const key in formData) {
            if (key.startsWith("childAge")) {
                childsAges.push(formData[key]);
            }
        }

        var request = {
            agentId: userInfo.id,
            agentName: form.getFieldValue("agentName"),
            cifVatLabel: cifVatLabel,
            cifVat: form.getFieldValue("cifVat"),
            agencyName: form.getFieldValue("agencyName"),
            agentPhone: form.getFieldValue("agentPhone"),
            agentMail: form.getFieldValue("agentMail"),
            agentLanguage: userContext.GetLanguageUser(),
            hotelName: hotelName,
            checkIn: moment(formData.date[0]).format("YYYY-MM-DD"),
            checkOut: moment(formData.date[1]).format("YYYY-MM-DD"),
            adults: form.getFieldValue("adultsNumber"),
            childs: form.getFieldValue("childsNumber"),
            childAges: childsAges,
            roomTypeName: form.getFieldValue("roomTypeName"),
            regimeTypeName: form.getFieldValue("regimeTypeName"),
            observations: form.getFieldValue("observations"),
           };

           setSendingRequest(true);
           restApiClient.sendFormKnowUsRequest(request).then((r:any)=>{
                if (r){
                    resetFields();
                }
           }).finally(()=>{
                setSendingRequest(false);
           })


    }

    const onChangeHotel = (hotelId: string) : void => {
        setHotelName(hotelsList.find((h)=>h.id === hotelId)?.name);
        setHotelId(hotelId);
    }

    const onChangeChildNumber = (v: number) : void => {
        getChildAgesSelectors(v);
    }

    const [ageChildSelectors, setAgeChildSelectors] = useState<JSX.Element[]>([]);
    const getChildAgesSelectors = (v: number) : void => {
        let result : JSX.Element[] = []
        if (v > 0){
            for(let i = 0; i<v; i++){
                const newElement = <Form.Item name={`childAge${i}`} label={t('knowUs:booking-data.labels.child-age', [`${(i+1)}`])} key={i} rules={[
                    {
                        required: true,
                        message: t('knowUs:validations.mandatory-field')
                    }
                    ]}>
                                        <Select placeholder="Selecciona una opción" >
                                            <Select.Option value="0">0</Select.Option>
                                            <Select.Option value="1">1</Select.Option>
                                            <Select.Option value="2">2</Select.Option>
                                            <Select.Option value="3">3</Select.Option>
                                            <Select.Option value="4">4</Select.Option>
                                            <Select.Option value="5">5</Select.Option>
                                            <Select.Option value="6">6</Select.Option>
                                            <Select.Option value="7">7</Select.Option>
                                            <Select.Option value="8">8</Select.Option>
                                            <Select.Option value="9">9</Select.Option>
                                            <Select.Option value="11">11</Select.Option>
                                            <Select.Option value="12">12</Select.Option>
                                            <Select.Option value="13">13</Select.Option>
                                            <Select.Option value="14">14</Select.Option>
                                            <Select.Option value="15">15</Select.Option>
                                            <Select.Option value="16">16</Select.Option>
                                            <Select.Option value="17">17</Select.Option>
                                            <Select.Option value="18">18</Select.Option>
                                        </Select>
                                    </Form.Item>;
                
                result.push(newElement);
            }
        }

        setAgeChildSelectors(result);
    }

    // Comparador de fechas para saber si debe estar disable o seleccionable para el usuario
    const disabledDate = (current) => {
        // Can not select days before today and today

        if (knowUsCfg.releaseDays && knowUsCfg.releaseDays > 1){
            return current && current < moment().add(knowUsCfg.releaseDays-1, 'days');
        }else{
            return current && current < moment();
        }
    }

    const [knowUsCfg, setKnowUsCfg] = useState<KnowUsCfg>({
        emailToSendBookingRequests: '',
        isEnabled: false,
        releaseDays: 1,
        sendCopyToAgent: false,
        generalTermsAndConditions: ''
    });

    const getKnowUsConfiguration = () => {
        setLoadingData(true);
        restApiClient.getKnowUsConfiguration(userContext.GetLanguageUser()).then((r:any)=>{
            setKnowUsCfg(r);
       }).finally(()=>{
            setLoadingData(false);
       })
    }

    const resetFields = () => {

        form.resetFields();

        form.setFieldsValue({
            "agentName": `${userInfo.name} ${userInfo.surname1} ${userInfo.surname2}`.trim()
        });

        form.setFieldsValue({
            "agencyName": userInfo.companyName || (userInfo.agency && userInfo.agency.commercialName)
        });

        form.setFieldsValue({
            "agentPhone": userInfo.telephone
        });
        form.setFieldsValue({
            "agentMail": userContext.GetEmail()
        });

        form.setFieldsValue({
            "cifVat": userInfo.document
        });

        setCifVatLabel(userInfo.idDocumentType);
        setCifVat(userInfo.document);

        setAgentName(`${userInfo.name} ${userInfo.surname1} ${userInfo.surname2}`.trim());
        setAgencyName(userInfo.companyName || (userInfo.agency && userInfo.agency.commercialName));
        setPhone(userInfo.telephone);
        setEmail(userContext.GetEmail());
        setHotelId(undefined);
        setHotelName('');
        setAgeChildSelectors([]);
    }

    useEffect(()=>{
        if (userInfo){
            resetFields();

        }
    },[userInfo]);

    useEffect(()=>{
        getKnowUsConfiguration();
    },[])


    return (
        <Skeleton active loading={loadingData}>
            <div className="know-us">
                <Typography.Title level={3}>
                    {
                        t('knowUs:main-title')
                    }
                </Typography.Title>
                <Typography.Paragraph>
                    {
                        t('knowUs:main-description')
                    }
                </Typography.Paragraph>
                <Form layout='vertical' size='small' form={form} onFinish={submitForm} className={"col-12 form-item"}>
                    <Typography.Title level={4}>
                    {
                        t('knowUs:personal-data.title')
                    }
                    </Typography.Title>

                    <Row gutter={16}>
                        <Col xs={24} md={12}>
                            <Paragraph className="label-data-agency mb-1">
                                {t("knowUs:request-date")}
                            </Paragraph>
                            <Text className='input-value'>
                                {moment().format("YYYY/MM/DD")}
                            </Text>
                            <Form.Item name="requestDate" initialValue={moment().format("YYYY/MM/DD")} className="form-item no-space" >
                                <Input hidden disabled type="text" value={moment().format("YYYY-MM-DD")}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Paragraph className="label-data-agency mb-1">
                                {cifVatLabel}
                            </Paragraph>
                            <Text className='input-value'>
                                {cifVat}
                            </Text>
                            <Form.Item initialValue={cifVat} name="cifVat" className="form-item no-space" >
                                <Input hidden disabled type="text" value={cifVat}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Paragraph className="label-data-agency mb-1">
                                {t('knowUs:personal-data.labels.agent-name')}
                            </Paragraph>
                            <Text className='input-value'>
                                {agentName}
                            </Text>
                            <Form.Item name="agentName" className="form-item no-space" >
                                <Input hidden disabled type="text" placeholder={t('knowUs:personal-data.labels.fill-name')} value={agentName}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Paragraph className="label-data-agency mb-1">
                                {t('knowUs:personal-data.labels.agency-name')}
                            </Paragraph>
                            <Text className='input-value'>
                                {agencyName}
                            </Text>
                            <Form.Item name="agencyName" className="form-item no-space" >
                                <Input hidden disabled type="text" placeholder={t('knowUs:personal-data.labels.fill-agency')} value={agencyName}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Paragraph className="label-data-agency mb-1">
                                {t('knowUs:personal-data.labels.phone')}
                            </Paragraph>
                            <Text className='input-value'>
                                {phone}
                            </Text>
                            <Form.Item name="agentPhone" className="form-item no-space" >
                                <Input hidden disabled type="text" placeholder={t('knowUs:personal-data.labels.fill-phone')} value={phone}/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={12}>
                            <Paragraph className="label-data-agency mb-1">
                                {t('knowUs:personal-data.labels.email')}
                            </Paragraph>
                            <Text className='input-value'>
                                {email}
                                <small className="send-copy-advise">{knowUsCfg && knowUsCfg.sendCopyToAgent ? <strong>{t('knowUs:validations.send-copy-to-agent')}</strong> : ''}</small>
                            </Text>
                            <Form.Item name="agentMail" className="form-item no-space">
                                <Input hidden disabled type="text" placeholder={t('knowUs:personal-data.labels.fill-email')} value={email}/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Divider />

                    <Typography.Title level={4}>
                        {t('knowUs:booking-data.title')}
                    </Typography.Title>

                    <Form.Item label={t('knowUs:booking-data.labels.hotel')} name="hotelName" className="form-item" rules={[{required: true, message: t('knowUs:validations.mandatory-field')}]}>
                        <Select placeholder={t('knowUs:booking-data.labels.fill-hotel')} onChange={(v: string)=>{onChangeHotel(v);}}>
                            {
                                hotelsList?.map((h: any, index: number)=><Select.Option key={index} value={h.id}>{h.name}</Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                    

                    {
                        !hotelId ? 
                            <Alert
                                style={{ marginTop: "5px" }}
                                message={t("regularizations:form.errors.room-alert")}
                                type="warning"
                            />
                            : 
                            <div>
                                <Form.Item
                                    label={<>{t("regularizations:form.labels.date")} {(knowUsCfg.releaseDays && knowUsCfg.releaseDays > 1 ? <Tooltip placement="topLeft" title={t('knowUs:booking-data.labels.release-tooltip', [knowUsCfg.releaseDays])} ><QuestionCircleOutlined className="release-info"/></Tooltip> : '')}</>}
                                    name="date"
                                    rules={[
                                    {
                                        required: true,
                                        message: t('knowUs:validations.mandatory-field')
                                    }
                                    ]}
                                >
                                    <RangePicker
                                        defaultPickerValue={[moment().add(knowUsCfg.releaseDays, "days"), null]}
                                        className="main-select reservations-dates"
                                        data-testid="rp-reservation-dates"
                                        locale={local}
                                        disabledDate={disabledDate}
                                        placeholder={[local.lang.dateFormat, local.lang.dateFormat]}

                                        style={{ width: "100%" }}
                                        format={local.dateFormat}
                                    />
                                </Form.Item>
                                

                                <Row gutter={16}>
                                    <Col xs={24} md={12}>
                                        <Form.Item name="adultsNumber" label={t('knowUs:booking-data.labels.adults-number')} rules={[
                                    {
                                        required: true,
                                        message: t('knowUs:validations.mandatory-field')
                                    }
                                    ]}>
                                            <Select placeholder={t('knowUs:booking-data.labels.fill-adults-number')} >
                                                <Select.Option value="1">1</Select.Option>
                                                <Select.Option value="2">2</Select.Option>
                                                <Select.Option value="3">3</Select.Option>
                                                <Select.Option value="4">4</Select.Option>
                                                <Select.Option value="5">5</Select.Option>
                                                <Select.Option value="6">6</Select.Option>
                                                <Select.Option value="7">7</Select.Option>
                                                <Select.Option value="8">8</Select.Option>
                                                <Select.Option value="9">9</Select.Option>
                                                <Select.Option value="10">10</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    
                                    <Col xs={24} md={12}>
                                        <Form.Item name="childsNumber" label={t('knowUs:booking-data.labels.childs-number')} initialValue={0} rules={[
                                        {
                                            required: true,
                                            message: t('knowUs:validations.mandatory-field')
                                        }
                                        ]}>
                                            <Select placeholder={t('knowUs:booking-data.labels.fill-childs-number')} onChange={onChangeChildNumber}>
                                                <Select.Option value="1">1</Select.Option>
                                                <Select.Option value="2">2</Select.Option>
                                                <Select.Option value="3">3</Select.Option>
                                                <Select.Option value="4">4</Select.Option>
                                                <Select.Option value="5">5</Select.Option>
                                                <Select.Option value="6">6</Select.Option>
                                                <Select.Option value="7">7</Select.Option>
                                                <Select.Option value="8">8</Select.Option>
                                                <Select.Option value="9">9</Select.Option>
                                                <Select.Option value="10">10</Select.Option>
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    {
                                        ageChildSelectors.map((acs: any, index: number) => <Col key={index} xs={24} md={4}>{acs}</Col>)
                                    }

                                </Row>

                                <RoomsComponent hotelId={hotelId} />

                                
                                <Form.Item label={t('knowUs:observations')} name="observations" className="form-item">
                                    <Input.TextArea rows={6} placeholder={t('knowUs:observations-placeholder')} />
                                </Form.Item>

                                
                            </div>
                    }
                    
                    <Divider />
                    
                    {ReactHtmlParser(knowUsCfg.generalTermsAndConditions)}

                    <div className="btn-actions">
                        <Button disabled={sendingRequest} danger onClick={resetFields}>{t('knowUs:reset-fields')}</Button>
                        <Button disabled={sendingRequest} type="primary" className="btn-submit" htmlType='submit'>
                            { sendingRequest ? <LoadingOutlined /> : <></>}
                            {t('knowUs:send-request')}
                        </Button>
                    </div>
                    
                </Form>
            </div>
        </Skeleton>
    )
}


interface RoomsComponentProps {
    hotelId: string
}

const RoomsComponent = (param: RoomsComponentProps) => {
    const { t } = useTranslation(["regularizations", "translation"]);
    const establishment = useHotelById(param.hotelId);

    return (
        <Row gutter={16}>
            <Col xs={24} md={12}>
            <Form.Item
                label={t("regularizations:form.labels.room-type")}
                name={`roomTypeName`}
                rules={[
                {
                    required: true,
                    message: t('knowUs:validations.mandatory-field')
                }
                ]}
            >
                <Select
                
                className="main-select"
                id="room-type"
                data-testid="select-number-rooms"
                placeholder={t("regularizations:form.placeholder.select")}
                >
                {establishment?.roomTypes
                    ?.filter((h) => h?.selected)
                    ?.map((r, i) => {
                    return (
                        <Select.Option key={i} id={`y-${i}`} value={r?.name}>
                        {r?.name ?? r?.idRoomTypes}
                        </Select.Option>
                    );
                    })}
                </Select>
            </Form.Item>
            </Col>
            <Col xs={24} md={12}>
            <Form.Item
                label={t("regularizations:form.labels.regimen")}
                name={`regimeTypeName`}
                rules={[
                {
                    required: true,
                    message: t('knowUs:validations.mandatory-field')
                }
                ]}
            >
                <Select
                className="main-select"
                id="regimen"
                data-testid="select-regimen"
                placeholder={t("regularizations:form.placeholder.select")}
                >
                {establishment?.regimens
                    ?.filter((h) => h?.selected)
                    ?.map((r, i) => {
                    return (
                        <Select.Option key={`${i}-rg`} id={`y-${i}`} value={r?.name}>
                        {r?.name ?? r?.idRegimens}
                        </Select.Option>
                    );
                    })}
                </Select>
            </Form.Item>
            </Col>
        </Row>
    );
  };

export default KnowUsComponent;