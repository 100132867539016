import React, { useState, Suspense } from "react";
import { Collapse, Skeleton } from "antd";
import { useTranslation } from "react-i18next";
import PhoneNumberContact from "../../../models/contact/phoneContact";
import "./phoneContact.sass";
import { useContacts } from "src/api/api";

const { Panel } = Collapse;

const PhoneContact = () => {
  const { t } = useTranslation("contact");

  const [collapse, setCollapse] = useState<boolean>(true);

  const size = 2;
  let listPhone = useContacts();
  if (listPhone === null) {
    listPhone = [];
  }

  const changeHandle = () => {
    if (collapse) {
      setCollapse(false);
    } else {
      setCollapse(true);
    }
  };

  return (
    <>
      <div className="box-contain-main">
        <div className="box-contain">
          {listPhone &&
            listPhone.length > 0 &&
            listPhone.slice(0, size).map((s: PhoneNumberContact, i) => {
              return (
                <div key={i} className="box-phone">
                  <img src={s.flag} />
                  <p>{s.country}</p>
                  <p className="number-phone">
                    <strong>{s.number}</strong>
                  </p>
                </div>
              );
            })}
        </div>
      </div>
      {listPhone && listPhone.length > 2 && (
        <Collapse
          key="1"
          className="collapse-style-box"
          bordered={false}
          onChange={changeHandle}
          expandIconPosition="right"
        >
          <Panel
            className="primary-links-phone"
            header={collapse ? t("contact:phone.more-phone") : t("contact:phone.hidden-phone")}
            key="2"
          >
            <div className="box-contain-main">
              <div className="box-contain">
                {listPhone.slice(2, listPhone.length).map((s: PhoneNumberContact, i) => {
                  return (
                    <div key={i} className="box-phone">
                      <img src={s.flag} />
                      <p>{s.country}</p>
                      <p className="number-phone">
                        <strong>{s.number}</strong>
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </Panel>
        </Collapse>
      )}
      <div className="text-aditional">
        <p>{t("contact:form.sub-text")}</p>
      </div>
    </>
  );
};

const PhoneContactWrapper = (param) => (
  <Suspense fallback={<Skeleton />}>
    <PhoneContact {...param} />
  </Suspense>
);
export default PhoneContactWrapper;
