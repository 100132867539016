import * as React from 'react';
import { useState, useContext } from 'react';
import { Form, Input, Button, Modal, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../hooks/context/userInfo';
import * as AjaxAPI from '../../../api/ajaxApi';
import { showNotification } from '../../../utils/notificationsGeneric';
import './modalRecoverCheck.sass';

// Necesario para estructura del formulario
const tailLayout = {
    wrapperCol: {
        span: 25,
    },
};

// Formulario recover check
const ModalRecoverCheck = (params) => {

    const [visible , setVisible] = useState(true);
    // Estado para mostrar u ocultar alert warning
    const [visibleAlertError, setVisibleAlertError] = useState(false);
    // Estado para los mensajes a mostrar en los alert
    const[messageAlert, setMessageAlert] = useState({ message: "", description: "" });

    // Idioma para los textos
    const { t } = useTranslation('reward');
    const [form]= Form.useForm();
    const userContext = useContext(UserContext);
    const id = params.record.id;

    const handleCancel = () => {
        setVisible(false);
        params.setOpenModalRecoverCheck(false);
    };

    const onFinish = async(values: any) => {

        AjaxAPI.init(t, true);

        AjaxAPI.doActionOld('sendPasswordRecoverCheck', {
            values,
            token: userContext.GetUserToken(),
            id            
        })
          .then(v => {
              if (v.data.valid) { 
                showNotification('success', v.data.title, v.data.message, "topRight");
                setVisible(false);
                params.setOpenModalRecoverCheck(false);
              } else {
                // Mensajes con respuesta de API  en caso de error datos introducidos erroneos
                setVisibleAlertError(true);
                setMessageAlert(
                    { 
                        message: "", 
                        description: t('reward:modal-recover.form.validate.data-error') 
                    }
                );
              }          
          })
          .catch(e => {
            showNotification('error', e.data.title, e.data.message, "topRight");
          });
    }

    return (
        <Modal
            title={ t("reward:modal-recover.title") }
            visible={ visible }
            onCancel={ handleCancel }
            footer={ null } 
            key="1"
            className="box-modal-reward" 
        >
            <div className="mb-3">
                { t('reward:modal-recover.description') }
            </div>
            <Form
                name="basic"
                initialValues={ { remember: true } }  
                onFinish={ onFinish }
                form={ form }
            >
                <Form.Item
                    label={ t('reward:modal-recover.form.label') }
                    className="row-form-item"
                    name="password"
                    rules={ 
                        [
                            { 
                                required: true, 
                                message: t('reward:modal-recover.form.validate.required') 
                            },
                            {
                                max: 25,
                                message: t('reward:modal-recover.form.validate.max-length')
                            }
                        ] 
                    }
                >
                    <Input.Password 
                        className="main-input" 
                        id="password" 
                    />
                </Form.Item>
                { visibleAlertError && 
                    <div className="alert-message">
                        <Alert
                            message={ messageAlert.message }
                            description={ messageAlert.description }
                            type="error"
                            showIcon
                        />
                    </div>
                }
                <Form.Item 
                    { ...tailLayout } 
                    className="col-12 form-item mt-4"
                >
                    <div className="row f-right">
                        <Button 
                            id="btn-cancel-new-agency"
                            onClick={ handleCancel }
                            className="default-button margin-right-button"
                        >
                            { t('reward:modal-recover.form.button-cancel') }
                        </Button>
                        <Button 
                            id="btn-send-new-agency"
                            className="primary-button"
                            type="primary" 
                            htmlType="submit"
                        >
                            { t('reward:modal-recover.form.button-accept') }
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default ModalRecoverCheck;
