import * as React from 'react';
import { Card } from 'antd';
import './advantages.sass';

const { Meta } = Card;

// Componente que pinta las tarjetas con la info de las ventajas de usar bed2b
const Advantage = (param) => {

  return (
    <>
      <div className="col-xs-12 col-md-6 spaced-bottom">
        <Card
          key={ param.code }
          className={ param.className }
          cover={ 
            <img alt={ param.typeCard } src={ param.img } className="width-img-advantange" /> 
          }
        >
          <Meta className="adv-card-content" title={ param.title } description={ param.text } />
        </Card>
      </div>
    </>
  );
}

export default Advantage;
