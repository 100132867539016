import React, { useState } from "react";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Skeleton } from "antd";
import { UserContext } from "../../hooks/context/userInfo";
import "../../i18n";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../hooks/context/config";
import RewardGeneric from "../rewardGenericBalance/rewardGenericBalance";
import RewardBalance from "./rewardBalance/rewardBalance";
import RewardMain from "./rewardMain/rewardMain";
import { RestApiClientContext } from "src/hooks/context/pageContext";

// Componente de reward Index
const Reward = () => {
  const restApiClient = React.useContext(RestApiClientContext);
  const userContext = useContext(UserContext);

  const [noIndexNoFollow, setNoIndexNoFollow] = React.useState(false);
  const getNoIndexNoFollow = () : void => {
    restApiClient.getNoIndexNoFollowConfig().then(v => {
      setNoIndexNoFollow(v);
    });

  }

  React.useEffect(() => {
    getNoIndexNoFollow();
  }, []);

  const [rewardList, setRewardList] = useState<any[]>([]);
  

  const [filtersList, setFiltersList] = useState<any[]>([]);


  const getSupplierRewards = () : void => {
    restApiClient.getSuppliersConfiguration(1, 100).then((response) => {

      const suppliers = response.suppliersRewards ?? [];

      const finalRewardList = [];

      suppliers.forEach((supplierReward) => {
        if (supplierReward.currency.id === userContext.GetCurrency()) {
          finalRewardList.push(supplierReward);
        }
      });


      setRewardList(finalRewardList);

    }).finally(() => {
      proccessRewardList();
    });
  }

  const proccessRewardList = () : void => {
    
    rewardList.forEach((rewardListElement) => {
      if (filtersList.findIndex((e) => e.id === rewardListElement.id) === -1) {
        filtersList.push({
          id: rewardListElement.id,
          name: rewardListElement.name,
        });
      }
    });
    setFiltersList(filtersList);
  }

  const infoClient = useContext(ConfigContext);

  const { t } = useTranslation(["urls", "seo"]);

  // Position del scroll en el top de la pagina
  var scrollLib = require("react-scroll");
  var scroll = scrollLib.animateScroll;

  useEffect(() => {
    scroll.scrollToTop();
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const getData = () : void => {
    getSupplierRewards();
  }

  return (
    <>
      <Helmet>
        <title>
          {infoClient.incentiveSiteName}{" "}
          {t("seo:title.register", { 0: infoClient.incentiveSiteName })} |{" "}
          {t("seo:title.register")}
        </title>
        <meta
          name="description"
          content={t("seo:description.register", {
            0: infoClient.incentiveSiteName,
          })}
        />
        {
          noIndexNoFollow ? 
            <meta name="robots" content="noindex, nofollow" />
          : null
        }
        <link
          rel="canonical"
          href={infoClient.urlWA + t("urls.public.register")}
        />
        <link
          rel="icon"
          type="image/ico"
          href={infoClient.favicon}
          sizes="32x32"
        />
        <link
          href={infoClient.urlWA + t("urls.public.register")}
          hrefLang="es-ES"
          rel="alternate"
        />
      </Helmet>

      <div className="mt-3">
        <RewardGeneric />
        <RewardBalance />
        { filtersList && <RewardMain filtersList={filtersList} /> }
      </div>
    </>
  );
};

export default Reward;
