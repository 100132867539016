import { useLangInit } from '../api/config';
import { DEFAULT_LANGUAGE ,LANGUAGE_SUPPORT_SYSTEM } from '../constants/constants';

// Funcion que obtiene los idiomas de la API y comparamos con el del navegador para saber si esta soportado por el sistema
export const initLanguages = () => {

    const url = window.location.pathname;
    let userLang;


    // Obtenemos los idiomas de la API
    const apiAllLanganges: any = useLangInit();
    let param = false;

    var supportsLanguages : string[] = LANGUAGE_SUPPORT_SYSTEM;

    if ( supportsLanguages && supportsLanguages.includes(url.split("/")[1])){
        userLang = url.split("/")[1];
        param = true;
    }else{
        // Lenguaje obtenido del navegador del cliente
        userLang = navigator.language;
    }

    // Comprobamos que existe en el sistema
    const existLangInSystem = [];

    if (apiAllLanganges){
        apiAllLanganges.filter((e) => e.id === userLang);
    }

    // Si no existe, abstraemos la parte del idioma del CultureCode para mostrar ese idioma aunque no sea del pais
    if (apiAllLanganges && (!existLangInSystem.length || param)) {

        const languageCompare = apiAllLanganges.filter((e) => e.id.split('-')[0].includes(userLang.split('-')[0])
                                || e.id.split('-')[0].includes(userLang.lenght > 3? userLang.split('-')[0]: userLang));

        if (languageCompare.length) {
            userLang = languageCompare[0].id;
        } else {
            userLang = DEFAULT_LANGUAGE;
        }
    }else{
        userLang = DEFAULT_LANGUAGE;
    }
    return userLang;
}

// Funcion que obtiene todos los idiomas de la API
export const allLanguages = () => {

    // Obtenemos los idiomas de la API
    const apiAllLanganges = useLangInit();

    return apiAllLanganges;
}
