import * as React from "react";
import { useState, useEffect } from "react";
import { Form, Input, Button, Select, Typography } from "antd";
import { useTranslation } from "react-i18next";
import "./independent-agent-data.sass";
import { useMandatoryCompanyName, useCurrenciesAll, useGetLocations } from "src/api/api";
import GoogleMapsAutoCompleteComponent from "src/components/google/geocode/GoogleMapsAutoCompleteComponent";

const { Option } = Select;

const { Title } = Typography;

// Compomnente del formaulario
const IndependentAgentData = (props) => {

  // Idioma para los textos
  const { t } = useTranslation("independentAgentData");

  const [disabled, setDisabled] = useState(true);

  const [form] = Form.useForm();

  const currencies = useCurrenciesAll();

  const mandatoryCompanyName = useMandatoryCompanyName();

  const [googleData, setGoogleData] = useState<any>();
  useEffect(()=>{
    onFieldsChange();
  },[googleData])

  const onFieldsChange = () => {
    const data: any = {
      agencyName: form.getFieldValue("agencyName"),
      idCurrency: form.getFieldValue("currency"),
      address: form.getFieldValue("address"),
      postalCode: form.getFieldValue("postalCode"),
      googleData: googleData
    };

    props.updateData(data);

    setDisabled(!data.agencyName || !data.idCurrency || !data.googleData || !data.googleData.formatted_address || data.googleData.formatted_address?.length == 0);
  };

  useEffect(() => {
    if (props.selectedData) {
      form.setFieldsValue({
        "agencyName": props.selectedData.agencyName
      });
      form.setFieldsValue({
        "address": props.selectedData.address,
      });
      form.setFieldsValue({
        "postalCode": props.selectedData.postalCode,
      });
      form.setFieldsValue({
        "currency": props.selectedData.idCurrency,
      });
      form.setFieldsValue({
        "regionId": props.selectedData.regionId
      });
    }
  }, [props.updateData]);

  

  return (
    <div className="container independent-agent-data">
      <div className="titles">
        <Title className="title" level={3}>
          {t("main-title")}
        </Title>
        <Title className="subtitle" level={4}>
          {t("sub-title")}
        </Title>
      </div>
      <Form
        name="basic"
        initialValues={{ remember: true }}
        form={form}
        className="row"
        layout="vertical"
        id="signup-agent-form"
        onFieldsChange={onFieldsChange}
      >
        <Form.Item
          className="col-12 col-lg-6 form-item"
          label={!mandatoryCompanyName ? t("agency-name-optional") : t("agency-name")}
          name="agencyName"
          rules={[
            {
              required: mandatoryCompanyName,
              whitespace: true,
              message: t("validations.agency-name"),
            },
          ]}
        >
          <Input className="main-input" placeholder="" id="agencyName" />
        </Form.Item>
        <Form.Item
          className="col-12 col-lg-6 form-item"
          label={t("currency")}
          name="currency"
          rules={[
            {
              required: true,
              message: t("validations.position"),
            },
          ]}
        >
          <Select className="main-select" placeholder={t("place-holder.select-option")}>
            {currencies.map((c) => {
              return (
                <Option key={c.id} id={`c-${c.id}`} value={c.id}>
                  {`${c.id} (${c.symbol})`}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        
        <div className="col-12" style={{paddingRight: "10px", paddingLeft: "10px"}}>
          <GoogleMapsAutoCompleteComponent onSetData={setGoogleData} isRequiredField showSelectedInfo={false}/>
        </div>


          
          <Form.Item className="col-12 form-item">
            <div className="content-action-buttons">
              <Button id="btn-next" type="primary" className="primary-button" disabled={disabled} onClick={props.next}>
                {t("register:steps.buttons.next")}
              </Button>
              <Button id="btn-prev" className="default-button margin-right-button" onClick={props.previous}>
                {t("register:steps.buttons.prev")}
              </Button>
            </div>
          </Form.Item>
        </Form>
    </div>
  );
};

export default IndependentAgentData;
