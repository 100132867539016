import EndPointConfig from "src/models/api/endPointConfig";

const controller = 'Agencies';

/// Define all EndPoints for Client Data.
export const AgenciesEP : EndPointConfig[] = [
    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setAlias('GetFilteredAgencies')
        .setActionName("filtered")
];

