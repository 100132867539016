import React from 'react';
import '../../../i18n';
import { useTranslation } from 'react-i18next';
import { Tag, Typography } from 'antd';
import './suggestionsInputStyle.sass';

const { Title } = Typography;

const SuggestionList = (props : any) => {

    const { t } = useTranslation(['signupAgents', 'ajaxApi']);

    const getDesktopList = () => {
        return (
            <ul>
                {
                    props.suggestions.length > 0 &&
                        (props.suggestions.map((e, i) => {
                            return (<li key={ i } onClick={ ()=>{ props.onSelect(e) } }>{ e }</li>)
                        }))
                }
            </ul>
        )
    }

    const getMobileList = () => {
        return (
            <div className="col-12 mobile-list">
                { 
                    props.suggestions.length > 0 &&
                        (props.suggestions.map((e, i) => {
                            return (
                                <Tag 
                                    className="tag" 
                                    key={ i } 
                                    onClick={ () => {props.onSelect(e)} } 
                                    color="default"
                                >
                                    { e }
                                </Tag>
                            )
                        }))
                }
            </div>
        )
    }

    return (
        <div className='suggestion-list'>
            <Title 
                level={ 3 }
                className={ "title-suggestion " + props.titleType }>
                { t(props.title) }
            </Title>
            <Title 
                level={ 4 }
                className="subtitle-suggestion subTitle">
                { t(props.subTitle) }
            </Title>
            { props.version === 'mobile' ? getMobileList() : getDesktopList() }
        </div>
    )
}

export default SuggestionList;
