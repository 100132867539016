import React from "react";
import TableResults from "./table";
import ListResults from "./list";
import AlternateComponent from "../../utils/alternateComponent/alternateComponent";

// Componente intermedio que identifica si renderizar Table o List en función de la resolución del dispositivo
const Results = (param) => {
  // Modulamos la info para la versión de escritorio
  const desktopVersion = () => {
    return (
      <TableResults
        func={param.setDisabled}
        rows={param.rows}
        totalRows={param.totalRows}
        selectData={param.selectData}
        pageResults={param.pageResults}
        setPageResults={param.setPageResults}
        orderResults={param.orderResults}
        setOrderResults={param.setOrderResults}
        searchHeaderResults={param.searchHeaderResults}
        setSearchHeaderResults={param.setSearchHeaderResults}
      />
    );
  };

  // Modulamos la info para la versión mediana (tablet)
  const tabletVersion = () => {
    return desktopVersion();
  };

  // Modulamos la info para la versión móvil
  const mobileVersion = () => {
    return (
      <ListResults
        func={param.setDisabled}
        rows={param.rows}
        totalRows={param.totalRows}
        selectData={param.selectData}
        pageResults={param.pageResults}
        setPageResults={param.setPageResults}
      />
    );
  };

  return (
    // Condicional para cargar un componente u otro
    <AlternateComponent
      desktopVersionLarge={desktopVersion()}
      tabletVersion={tabletVersion()}
      mobileVersionLarge={mobileVersion()}
    />
  );
};

export default Results;
