import * as React from "react";
import { Table } from "antd";
import CustomPagination from "../../utils/pagination/pagination";
import Pagination from "../../../models/pagination/pagination";

// Componente Table que muestra la lista de agencias
const TableResults = (param) => {
  // Función que se dispara en el evento de cambiar el elemento de paginación, de ordenación o de búsqueda de la tabla
  const onChangeCallApi = (pagination) => {
    // Almacenamos el valor de si el panelito de búsqueda se muestra o no
    const pag = new Pagination(pagination.current, pagination.pageSize);

    // Usuario pulsa página
    param.setPageResults(pag);
    param.setPageResultsInput(pag);
  };

  return (
    <>
      <Table
        rowSelection={{
          type: "radio",
          onChange: (_key, agency) => {
            param.selectData(agency.pop());
          },
          selectedRowKeys: param.selectedData ? [param.selectedData.id] : [],
        }}
        columns={param.columns}
        dataSource={param.rows.agencies.map((agency) => ({ ...agency, key: agency.id }))}
        pagination={false}
      />
      <div className="pagination-container col-12 p-0 mt-4">
        <CustomPagination
          data-testid="pagination-table-balance-history"
          countData={param.rows.totalRecord}
          callApiChange={onChangeCallApi}
          pageResults={param.pageResults}
        />
      </div>
    </>
  );
};

export default TableResults;
