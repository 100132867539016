import EndPointConfig from '../../models/api/endPointConfig';

const controller = 'Agents';

// Define all EndPoints for login agents methods.
export const LoginEP : EndPointConfig[] = [
    new EndPointConfig()
        .setMethod('POST')
        .setController(controller)
        .setActionName('login')
        .setAlias('LoginAgent'),

    new EndPointConfig()
        .setMethod('DELETE')
        .setController(controller)
        .setActionName('login')
        .setAlias('LogoutAgent'),

    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('language')
        .setAlias('GetLanguageAgent'),

    new EndPointConfig()
        .setMethod('GET')
        .setController(controller)
        .setActionName('name')
        .setAlias('GetUserName'),
];
