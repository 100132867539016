import * as React from "react";
import { Typography, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { FormattedNumber } from "react-intl";
import { getLocaleDatePicker } from "../../../utils/datePicker";
import { PickerLocale } from "antd/lib/date-picker/generatePicker";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import "./remunerationGraphic.sass";
import locale from "antd/lib/date-picker/locale/en_US";
import roundTo from "round-to";
import Season from "../../../models/remuneration/seasons";

const { Paragraph } = Typography;

// Componente que pinta el gráfico de remuneraciones
const RemunerationGraphic = (param) => {
  // Idioma para los textos
  const { t } = useTranslation("remuneration");
  let months = [];
  const colors = param.colors;
  // Formato forzado para recoger correctamente la fecha para su posterior uso
  const year = moment(param.result.periods[0].entryDate, "MM/DD/YYYY").year();

  // Local para el datePicker
  const local: PickerLocale = getLocaleDatePicker();

  for (var i = 1; i <= 12; i++) {
    months.push(t("remuneration:graphic.month-list." + i));
  }

  const maxValue = Math.max(...param.result.periods.map((e) => e.amounts[0].value));

  const contentTooltip = (data: any) => {
    return (
      <div className="box-tooltip">
        <Paragraph className="color-text-tooltip mb-0 mt-2">
          <strong>{data.name}</strong>
        </Paragraph>
        <Paragraph className="color-text-tooltip mb-0">
          {moment(data.startDate).format("DD/MM") +
            " - " +
            moment(data.endDate).format("DD/MM")}
        </Paragraph>
        <Paragraph className="color-text-tooltip mb-0">
         { t("regularizations:min-nights-resume", {0:data.minNightsAllowed}) }
        </Paragraph>
        <Paragraph className="color-text-tooltip mb-2">
          <strong>
            <FormattedNumber value={data.amounts[0].value} style="currency" currency={data.amounts[0].currencyId} />
          </strong>
          {t("remuneration:night")}
        </Paragraph>
      </div>
    );
  };
  return (
    <>
      <Paragraph className="texto-color-title-remuneration">
        {ReactHtmlParser(t("graphic.min-title", { 0: param.result.name }))}
      </Paragraph>
      <Paragraph
        className="text-list-hotel"
        ellipsis={{
          rows: 3,
          expandable: true,
          symbol: "ver más",
        }}
      >
        {param.result.establishments.length > 0 &&
          param.result.establishments.map((s: any, i) => {
            return (
              <span key={i}>
                {s.name} <span className="color-bar-vertical"> | </span>
              </span>
            );
          })}
      </Paragraph>

      <div className="table-remuneration row">
        <div className="col-md-1"></div>
        <div className="col-12">
          <div className="bars-graphic">
            {param.result.periods.map((y: Season, i) => {
              const colorIndex = i % colors.length;
              let dateCi =
                i > 0
                  ? moment(y.startDate, locale.dateFormat).subtract(1, "days")
                  : moment(y.startDate, locale.dateFormat);
              let dateCo = moment(y.endDate, locale.dateFormat);
              let diff = dateCo.diff(dateCi, "days");
              const porcent = roundTo.up((diff * 100) / 365, Infinity);
              const porcenBeforeInv = (100 * y.amounts[0].value) / maxValue;
              const porcenBefore = 100 - porcenBeforeInv;

              return (
                <div
                  key={i}
                  style={{
                    width: porcent + "%",
                    height: 100 + "%",
                  }}
                >
                  <div
                    className={
                      i === 0
                        ? "padding-bars-before border-graphics-right "
                        : i === param.result.periods.length - 1
                        ? "padding-bars-before border-graphics-left"
                        : "padding-bars-before border-graphics"
                    }
                    style={{
                      width: 100 + "%",
                      height: porcenBefore + "%",
                    }}
                  ></div>
                  <Tooltip id="tooltip-remuneration" title={() => contentTooltip(y)} key={i}>
                    <div
                      className={
                        i === 0
                          ? "padding-bars border-graphics-right"
                          : i === param.result.periods.length - 1
                          ? "padding-bars border-graphics-left"
                          : "padding-bars border-graphics"
                      }
                      style={{ width: 100 + "%", height: porcenBeforeInv + "%", backgroundColor: colors[colorIndex] }}
                    ></div>
                  </Tooltip>
                </div>
              );
            })}
          </div>
          <div className="col-12 bar-grey"></div>
          <div className="months-vertical-season">
            {months.map((s: string, x) => {
              let numberDaysForMonth = moment(year + "/" + (x + 1), "YYYY/MM").daysInMonth();
              const porcentBoxMonth = roundTo.up((numberDaysForMonth * 100) / 365, Infinity);

              return (
                <div style={{ width: porcentBoxMonth + "%" }} key={x}>
                  {s}
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-md-1"></div>
      </div>
    </>
  );
};

export default RemunerationGraphic;
