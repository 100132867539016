import { LanguageStrings } from './base-strings';

// Inglés Americano
export const pt: LanguageStrings = {
  /** Routes */
  'routes.home': '/',
  'routes.Advantages': '/#vantagens',
  'routes.Faqs': '/#perguntas-frecuentes',
  'routes.contact': '/contacto',
  'routes.recoverPassword':'/recuperar-password',
  'routes.privacyPolicies': '/políticas-privacidade',
  'routes.cookiesPolicies': '/políticas-cookies',
  'routes.generalConditions': '/condições-gerais',
  'routes.particularConditions': '/condições-particulares',
  'routes.register': '/registro',
  'routes.homePrivate':'/app',
  'routes.AdvantagesPrivate':'/app/vantagens',
  'routes.FaqsPrivate':'/app/perguntas-frecuentes',
  'routes.privacyPoliciesPrivate': '/app/politicas-privacidade',
  'routes.cookiesPoliciesPrivate': '/app/políticas-cookies',
  'routes.generalConditionsPrivate': '/app/condições-gerais',
  'routes.particularConditionsPrivate': '/app/condições-particulares',
  'routes.contactPrivate': '/app/contacto',
  'routes.regularization': '/app/regularizações',
  'routes.remuneration': '/app/retribuições',
  'routes.balanceHistory': '/app/movimentos',
  'routes.profile': '/app/meu-perfil',
  'routes.reward': '/app/recompensas',
  'routes.news': '/app/notícias',
  'routes.details': '/app/detalhe-noticia/:id',
  'routes.deleteAccount': '/conta-excluída',
  'routes.validateAddNewAgency': '/nova-agencia/:id',
  'routes.knowUs': '/app/conheça-nos',
  'routes.legalAdvice' : '/aviso-legal',
  'routes.termsConditionsCashbackPrivate' : '/app/termos-condições-revolut',
  'routes.termsConditionsCashback' : '/termos-condições-revolut'
};