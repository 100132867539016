import * as React from "react";
import { useState, useContext } from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import "./login.sass";
import { ConfigContext } from "../../../hooks/context/config";
import FormLogin from "../formLogin/formLogin";
import FormResetPassword from "../formResetPassword/formResetPassword";

// Componente con la modal de login para que el usuario pueda iniciar sesión
const ModalLogin = (params) => {
  // Contexto con la configuracion del cliente obtenida de BBDD y poder visualizar logo, color principal, etc
  const configuration = useContext(ConfigContext);

  // Idioma para los textos
  const { t } = useTranslation(["login", "resetPassword"]);

  // Estado para mostrar u ocultar modal
  const [visible, setVisible] = useState(true);

  // Estado para mostrar u ocultar modal
  const [visibleFormPassword, setVisibleFormPassword] = useState(false);

  // Manejador si el usuario le da a cancelar en la modal
  const handleCancel = () => {
    setVisible(false);
    params.setmodalLogin(false);
  };

  return (
    <>
      <Modal
        title={t("login:title-login", { 0: configuration.incentiveSiteName })}
        visible={visible}
        onCancel={handleCancel}
        footer={null}
        key="1"
      >
        <FormLogin
          setVisible={setVisible}
          setmodalLogin={params.setmodalLogin}
          setVisibleFormPassword={setVisibleFormPassword}
        />
      </Modal>

      <Modal
        title={t("resetPassword:form.title")}
        visible={visibleFormPassword}
        onCancel={handleCancel}
        footer={null}
        key="2"
      >
        <FormResetPassword
          setVisibleFormPassword={setVisibleFormPassword}
          setmodalLogin={params.setmodalLogin}
        />
      </Modal>
    </>
  );
};

export default ModalLogin;
