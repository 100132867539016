import React, { Suspense } from "react";
import TableResults from "../results/table";
import ListResults from "../results/list";
import AlternateComponent from "../../utils/alternateComponent/alternateComponent";
import { useRewardsBalanceHistory } from "src/api/api";
import { useTranslation } from "react-i18next";
import { Typography } from "antd";
import { Skeleton } from "antd";

const { Paragraph } = Typography;

const Results = (param) => {
  const rows = useRewardsBalanceHistory(
    param.pageResults.current,
    param.pageResults.pageSize,
    param.orderResults[0] && param.orderResults[0].typeOrder !== ""
      ? param.orderResults[0].typeOrder
      : "descend",
    param.filter
  );
  const { t } = useTranslation(["urls", "seo", "balanceHistory"]);

  const desktopVersion = () => {
    return (
      <TableResults
        func={param.setDisabled}
        columns={param.cols}
        rows={rows}
        selectData={param.selectData}
        pageResults={param.pageResults}
        setPageResults={param.setPageResults}
        orderResults={param.orderResults}
        setOrderResults={param.setOrderResults}
      />
    );
  };

  // Modulamos la info para la versión mediana (tablet)
  const tabletVersion = () => {
    return desktopVersion();
  };

  // Modulamos la info para la versión móvil
  const mobileVersion = () => {
    return (
      <ListResults
        func={param.setDisabled}
        rows={rows}
        selectData={param.selectData}
        pageResults={param.pageResults}
        setPageResults={param.setPageResults}
      />
    );
  };

  return (
    // Condicional para cargar un componente u otro
    <>
      <AlternateComponent
        desktopVersionLarge={desktopVersion()}
        tabletVersion={tabletVersion()}
        mobileVersionLarge={mobileVersion()}
      />
      <Paragraph className="total-results-content">
        {rows.totalRecord} {t("balanceHistory:results")}
      </Paragraph>
    </>
  );
};

const ResultsWrapper = (param) => (
  <Suspense fallback={<Skeleton />}>
    <Results {...param} />
  </Suspense>
);
export default ResultsWrapper;
