import * as React from "react";
import { useContext, useEffect, useState } from "react";
import "./cookies-policy.sass";
import { Helmet } from "react-helmet-async";
import "../../../i18n";
import { useTranslation } from "react-i18next";
import { ConfigContext } from "../../../hooks/context/config";
import { Typography } from "antd";
import ReactHtmlParser from "react-html-parser";
import { Table } from "antd";
import {
  Columns,
  Data1,
  Data2,
  Data3,
  Data4,
  Data5,
  Data6,
} from "./cookiesPolicyCellsValues";
import { ClientDataModel } from "../../../models/config/clientData";
import { LegalTexts } from "../legalTexts";
import { URL_BASE } from "../../../constants/constants";
import scrollLib from "react-scroll";
import { RestApiClientContext } from "../../../hooks/context/pageContext";

const { Title, Paragraph } = Typography;
const scroll = scrollLib.animateScroll;

// Componente de información de Política de Cookies de la app
export const CookiesPolicy = () => {
  const infoClient = useContext(ConfigContext);
  const restApiClient = useContext(RestApiClientContext);

  const { t } = useTranslation(["cookiesPolicy", "seo", "urls"]);
  const [clientData, setClientData] = useState<ClientDataModel>(
    new ClientDataModel()
  );

  useEffect(() => {
    scroll.scrollToTop();
});

  useEffect(() => {

    getLegalTextApi();
  }, []);

  const getLegalTextApi = async () => {
    restApiClient.getCustomizations().then((data) => {
      if (data) {
        setClientData(data);
      }
    });
  };



  return (
    <>
      <Helmet>
        <title>
          {infoClient.incentiveSiteName} | {t("seo:title.cookies-policy")}
        </title>
        <meta
          name="description"
          content={t("seo:description.cookies-policy", {
            0: infoClient.incentiveSiteName,
          })}
        />
        <meta name="robots" content="noindex,nofollow" />
        <link
          rel="canonical"
          href={URL_BASE + t("urls:public.cookies-policy")}
        />
        <link
          rel="icon"
          type="image/ico"
          href={infoClient.favicon}
          sizes="32x32"
        />
        <link
          href={URL_BASE + t("urls:public.cookies-policy")}
          hrefLang="es-ES"
          rel="alternate"
        />
      </Helmet>
        <div className="container mb-5 mt-3">
          <Title level={4}>{t("cookiesPolicy:main-title")}</Title>

          {ReactHtmlParser(t("cookiesPolicy:section.1.title"))}
          {ReactHtmlParser(t("cookiesPolicy:section.1.paragraph"))}

          {ReactHtmlParser(t("cookiesPolicy:section.2.title"))}
          {ReactHtmlParser(t("cookiesPolicy:section.2.paragraph.1"))}
          {ReactHtmlParser(t("cookiesPolicy:section.2.paragraph.2"))}
          {ReactHtmlParser(t("cookiesPolicy:section.2.paragraph.3"))}

          {ReactHtmlParser(t("cookiesPolicy:section.3.title"))}
          {ReactHtmlParser(t("cookiesPolicy:section.3.paragraph"))}

          {ReactHtmlParser(t("cookiesPolicy:section.4.title"))}
          {ReactHtmlParser(t("cookiesPolicy:section.4.paragraph"))}

          {ReactHtmlParser(t("cookiesPolicy:section.5.title"))}
          {ReactHtmlParser(t("cookiesPolicy:section.5.paragraph"))}

          <Paragraph>
            {ReactHtmlParser(t("cookiesPolicy:section.6.title.1"))}
            <Table
              columns={Columns()}
              dataSource={Data1()}
              pagination={false}
              scroll={{ x: 930 }}
              bordered
            />
          </Paragraph>
          <Paragraph>
            {ReactHtmlParser(t("cookiesPolicy:section.6.title.2"))}
            <Table
              columns={Columns()}
              dataSource={Data2()}
              pagination={false}
              scroll={{ x: 930 }}
              bordered
            />
          </Paragraph>
          <Paragraph>
            {ReactHtmlParser(t("cookiesPolicy:section.6.title.3"))}
            <Table
              columns={Columns()}
              dataSource={Data3()}
              pagination={false}
              scroll={{ x: 930 }}
              bordered
            />
          </Paragraph>
          {ReactHtmlParser(t("cookiesPolicy:section.6.paragraph.1"))}
          {ReactHtmlParser(t("cookiesPolicy:section.6.paragraph.2"))}
          <Paragraph>
            {ReactHtmlParser(t("cookiesPolicy:section.6.title.4"))}
            <Table
              columns={Columns()}
              dataSource={Data4()}
              pagination={false}
              scroll={{ x: 930 }}
              bordered
            />
          </Paragraph>
          <Paragraph>
            {ReactHtmlParser(t("cookiesPolicy:section.6.title.5"))}
            <Table
              columns={Columns()}
              dataSource={Data5()}
              pagination={false}
              scroll={{ x: 930 }}
              bordered
            />
          </Paragraph>
          <Paragraph>
            {ReactHtmlParser(t("cookiesPolicy:section.6.title.6"))}
            <Table
              columns={Columns()}
              dataSource={Data6()}
              pagination={false}
              scroll={{ x: 930 }}
              bordered
            />
          </Paragraph>
          {ReactHtmlParser(t("cookiesPolicy:section.6.paragraph.3"))}

          {ReactHtmlParser(t("cookiesPolicy:section.7.title"))}
          {ReactHtmlParser(
            t("cookiesPolicy:section.7.paragraph", {
              0: clientData.socialName,
              1: clientData.incentiveSiteName,
              2: clientData.emailContact,
            })
          )}

          <Paragraph className="special-mb">
            {ReactHtmlParser(t("cookiesPolicy:section.8.title"))}
            {ReactHtmlParser(
              t("cookiesPolicy:section.8.paragraph", {
                0: clientData.ownURLPrivacyPolicy,
              })
            )}
          </Paragraph>
        </div>
    </>
  );
};
