import * as React from 'react';
import { useState, useEffect, useContext } from 'react';
import { Form, Input, Button, Row, Col, Modal, Alert } from 'antd';
import { useTranslation } from 'react-i18next';
import * as AjaxAPI from '../../../api/ajaxApi';
import { PASSWORD_MIN_MAX_LENGTH_RE, PASSWORD_HAVE_EMPTY_CHARACTERS_RE, PASSWORD_LOWER_AND_UPPER_RE } from '../../../constants/regex';
import { useHistory, useLocation } from "react-router-dom";
import { showConfirm } from '../../../utils/modalsGenerics';
import './formNewPassword.sass';
import { UserContext } from '../../../hooks/context/userInfo';
import { useChangePassword } from 'src/api/api';

const FormNewPassword = (params) => {

  // Contexto del usuario
  const userContext = useContext(UserContext);

  // Necesario para estructura del formulario
  const tailLayout = {
    wrapperCol: {
      span: 25,
    },
  };

  // Idioma para los textos
  const { t } = useTranslation(['resetPassword', 'urls']);

  // History para una vez aceptado el modal nos lleve a la home
  let history = useHistory();

  let location = useLocation();

  const [, setSendForm] = useState(false);
  const changePassword = useChangePassword()
  const [formNewPassword, setformNewPassword] = useState(false);

  // Estado para mostrar u ocultar alert error caracteres
  const [visibleAlertErrorChar, setVisibleAlertErrorChar] = useState(false);

  // Estado para mostrar u ocultar alert error
  const [visibleAlertErrorEqual, setVisibleAlertErrorEqual] = useState(false);

  useEffect(() => {

    if (location.search && location.search.split('=')[0].split('?')[1] === "changePassword") {
      setformNewPassword(true);
      //     AjaxAPI.init(t, true);

      //     // Token usuario

      //     AjaxAPI.doActionOld('ValidateTokenSession', token)
      //         .then(v => {
      //             if (v.data.validaToken) {
      //                 setformNewPassword(true);
      //             } else {
      //                 history.push(t("urls:public.token-expired"));
      //             }
      //         })
      //         .catch(e => {});
      // }
    }
  }, []);

  // Envio a la API
  const sendFormNewPassword = async (values) => {
    const queryParam = new URLSearchParams(location.search);
    const token = queryParam.get('changePassword');

    try {
      const res = await changePassword(values.password1, token)

      if (!res.data) {
        setSendForm(false);
        setformNewPassword(false);
        history.push(t("urls:public.token-expired"));
      } else {
        setSendForm(true);
        setformNewPassword(false);
        history.push(t("urls:public.home"));
        showConfirm(res.title, res.message, t("resetPassword:form.new-password.buttons.accept"), params)
      }
    } catch (err) {
      history.push(t("urls:public.token-expired"));
    }
    // AjaxAPI.doActionOld('sendFormNewPassword', {
    //     values,
    //     lang: userContext.GetLanguageUser()
    // })
    //     .then(v => {
    //         if (v.data.formNewPassword.send) {
    //         } else {
    //             setSendForm(v.data.formNewPassword.send);
    //         }

    //     })
    //     .catch(e => { });
  }

  // Submit boton
  const onFinish = async (values) => {

    // Validadores de alert para el password
    setVisibleAlertErrorChar(false);
    setVisibleAlertErrorEqual(false);

    if (
      // Contains white spaces
      PASSWORD_HAVE_EMPTY_CHARACTERS_RE.test(values.password1)
      // Can't contains empty characters
      || !PASSWORD_LOWER_AND_UPPER_RE.test(values.password1)
      // Can't have both less of 8 characters and more of 25 characters
      || !PASSWORD_MIN_MAX_LENGTH_RE.test(values.password1)
    ) {
      setVisibleAlertErrorChar(true);
    } else if (values.password1 !== values.password2) {
      setVisibleAlertErrorEqual(true);
    } else {
      AjaxAPI.init(t, true);
      sendFormNewPassword(values);
    }
  };

  // Boton cancelar
  const HandCancel = () => {
    setformNewPassword(false);
    history.push(t("urls:public.home"));
  }

  return (
    <Modal
      title={t('resetPassword:form.new-password.title')}
      visible={formNewPassword}
      onCancel={HandCancel}
      footer={null}
      key="1"
    >
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
      >
        <Form.Item
          label={t('resetPassword:form.new-password.label-1')}
          name="password1"
          className="row-form-item"
          extra={
            t('resetPassword:form.new-password.requirement')
          }
          rules={
            [
              {
                required: true,
                message: t('resetPassword:form.new-password.errors.required')
              },
              {
                max: 25,
                message: t('resetPassword:form.new-password.errors.max-number')
              }
            ]
          }
        >
          <Input.Password
            id="new-password"
            className="main-input"
          />
        </Form.Item>
        <Form.Item
          label={t('resetPassword:form.new-password.label-2')}
          name="password2"
          className="row-form-item"
          rules={
            [
              {
                required: true,
                message: t('resetPassword:form.new-password.errors.required')
              },
              /// The password and repeatPassword fields must be identical
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password1') === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(t('form.new-password.errors.password-repeat'));
                },
              }),
            ]
          }
        >
          <Input.Password
            id="repeat-new-password"
            className="main-input"
          />
        </Form.Item>
        {visibleAlertErrorChar &&
          <div className="alert-message">
            <Alert
              message=""
              description={t('resetPassword:form.new-password.requirement')}
              type="error"
              showIcon
            />
          </div>
        }
        {visibleAlertErrorEqual &&
          <div className="alert-message">
            <Alert
              message=""
              description={t('resetPassword:form.new-password.errors.passwords-not-match')}
              type="error"
              showIcon
            />
          </div>
        }

        <Form.Item {...tailLayout}>
          <Row>
            <Col
              span={24}
              className="align-text-buttons"
            >
              <Button
                id="btn-accept-new-password"
                type="primary"
                htmlType="submit"
                className="col-4 col-sm-3 primary-button"
              >
                {t('resetPassword:form.new-password.buttons.accept')}
              </Button>
              <Button
                id="btn-cancel-new-password"
                onClick={HandCancel}
                className="col-4 col-sm-3 default-button margin-right-button"
              >
                {t('resetPassword:form.new-password.buttons.cancel')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default FormNewPassword;
