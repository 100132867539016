import * as React from 'react';
import { Route, withRouter } from 'react-router-dom';
import Home from '../../components/homePrivate/home';
import Avantages from '../../components/advantages/advantages';
import { Contact }  from '../../components/contact/contact';
import {PrivacyPolicies} from '../../components/legalTexts/privacyPolicies/privacyPolicies'
import { CookiesPolicy } from '../../components/legalTexts/cookiesPolicy/cookiesPolicy';
import {GeneralConditions} from '../../components/legalTexts/generalConditions/generalConditions';
import {ParticularConditions} from '../../components/legalTexts/generalConditions/particularConditions';
import Regularization from '../../components/regularizations/regularizations';
import Remuneration from '../../components/remuneration/remuneration'
import BalanceHistory from '../../components/balanceHistory/balanceHistory';
import Reward from '../../components/reward/rewardIndex';
import Faqs from '../../components/faqs/faqs';
import Profile from '../../components/profile/profile';
import News from '../../components/news/newsIndex';
import DetailNews from '../../components/news/detailNews/detailNews';
import { AppRoute } from '../appLanguages';
import LocalizedSwitch from '../localizedSwitch';
import KnowUsComponent  from '../../components/knowUs/KnowUsComponent'
import { TermsConditionsCashback } from 'src/components/legalTexts/termsConditionsCashback/termsConditionsCashback';


const RoutesContentPrivate = () => {
    return (
        <LocalizedSwitch>
            <Route
                exact
                path={ AppRoute.HomePrivate }
                component={ Home }
            />
            <Route
                exact
                path={ AppRoute.AdvantagesPrivate }
                component={ Avantages }
            />
            <Route
                exact
                path={ AppRoute.FaqsPrivate }
                component={ Faqs }
            />
            <Route
                exact
                path={ AppRoute.KnowUs }
                component={ KnowUsComponent }
            />
            <Route
                exact
                path={ AppRoute.ContactPrivate }
                component={ Contact }
            />
            <Route
                exact
                path={ AppRoute.PrivacyPoliciesPrivate }
                component={ PrivacyPolicies }
            />
            <Route
                exact
                path={ AppRoute.CookiesPoliciesRPrivate }
                component={ CookiesPolicy }
            />
            <Route
                exact
                path={ AppRoute.GeneralConditionsRPrivate }
                component={ GeneralConditions }
            />
            <Route
                exact
                path={ AppRoute.ParticularConditionsRPrivate }
                component={ ParticularConditions }
            />
            <Route
                exact
                path={ AppRoute.Regularizations }
                component={ Regularization }
            />
            <Route
                exact
                path={ AppRoute.BalanceHistory }
                component={ BalanceHistory }
            />
            <Route
                exact
                path={ AppRoute.Reward }
                component={ Reward }
            />
            <Route
                exact
                path={ AppRoute.Remuneration }
                component={ Remuneration }
            />
            <Route
                exact
                path={ AppRoute.Profile }
                component={ Profile }
            />
            <Route
                exact
                path={ AppRoute.News }
                component={ News }
            />
            <Route
                exact
                path={ AppRoute.Details }
                component={ DetailNews }
            />
            <Route
                exact
                path={ AppRoute.TermsConditionsCashbackPrivate }
                component={TermsConditionsCashback  }
            />
            
            <Route component={ Home } />

        </LocalizedSwitch>
    );
}

export default withRouter(RoutesContentPrivate);
