import React, { useContext, useEffect } from 'react';
import './contact.sass';
import { Helmet } from 'react-helmet-async'
import '../../i18n';
import { useTranslation } from 'react-i18next';
import { ConfigContext } from '../../hooks/context/config';
import { Typography } from 'antd';
import PhoneContact from './phones/phoneContact';
import { FormContact } from './formContact/formContact';
import { RestApiClientContext } from 'src/hooks/context/pageContext';
import TagManager from 'react-gtm-module';

const { Title } = Typography;

export const Contact = () => {

  const infoClient = useContext(ConfigContext);
  const { t } = useTranslation(['contact', 'seo', 'urls']);
  const scrollLib = require('react-scroll');
  const scroll = scrollLib.animateScroll;

  const restApiClient = useContext(RestApiClientContext);

  const [gtmId, setGtmId] = React.useState<string>('');
  const getGtmId = () : void => {
    restApiClient.getGtmId().then(v => {
      setGtmId(v);

      if (v.length === 0) return;

      TagManager.initialize({
        gtmId: v
      });
    });
  }

  const [noIndexNoFollow, setNoIndexNoFollow] = React.useState(false);
  const getNoIndexNoFollow = () : void => {
    restApiClient.getNoIndexNoFollowConfig().then(v => {
      setNoIndexNoFollow(v);
    });

  }
  React.useEffect(() => {
    getNoIndexNoFollow();
    getGtmId();
  }, []);

  useEffect(() => {
      scroll.scrollToTop();
  }, []);

  return (
    <>
      <Helmet>
        <title>{ infoClient.incentiveSiteName } | { t('seo:title.contact') }</title>
        <meta name="description" content={ t('seo:description.contact', { 0: infoClient.incentiveSiteName }) } />
        {
          noIndexNoFollow ? 
            <meta name="robots" content="noindex, nofollow" />
          : <meta name="robots" content="index,follow" />
        }
        <link rel="canonical" href={ infoClient.urlWA + t('urls:public.contact') } />
        <link rel="icon" type="image/ico" href={ infoClient.favicon } sizes="32x32" />
        <link href={ infoClient.urlWA + t('urls:public.contact') } hrefLang="es-ES" rel="alternate" />
      </Helmet>

      <div className="container margin-bottom-8 mt-3">
        <Title className="title">
          { t('contact:title') }
        </Title>
        <PhoneContact />
        <FormContact />
      </div>
    </>
  );
}
